export const IMAGE_MARKER = 'IMAGE_MARKER'
export const GENERIC_MARKER = 'GENERIC_MARKER'
export const HTML_MARKER = 'HTML_MARKER'

export const ADDRESS_BIG_MARKER_HEIGHT = 60
export const ADDRESS_BIG_MARKER_WIDTH = 52
export const POI_BIG_MARKER_HEIGHT = 60
export const POI_BIG_MARKER_WIDTH = 52

export const MARKERS_Z_INDEX = {
  focus: 5,
  current: 4,
  brandedMedium: 3,
  genericMedium: 2,
  brandedSmall: 1,
  genericSmall: 0
}

export const STYLE_POI_BIG_MARKER = {
  height: POI_BIG_MARKER_HEIGHT,
  width: POI_BIG_MARKER_WIDTH,
  size: 25,
  insideSvgSize: 25
}

export const STYLE_POI_MEDIUM_MARKER = {
  height: 36,
  width: 36,
  size: 36,
  insideSvgSize: 20
}

export const STYLE_POI_SMALL_MARKER = {
  height: 24,
  width: 24,
  size: 24,
  insideSvgSize: 14 // should be even number for clean centering
}
