import { LAYER_ID_POLYLINE } from '../../store/map/map.constants'
import { isGE } from '../Device'

const EQUATORIAL_EARTH_RADIUS_IN_METER = 6_378_137.0

const toRad = value => (value * Math.PI) / 180

export const getDistanceByFlyCrow = (lng1, lat1, lng2, lat2) => {
  const R = EQUATORIAL_EARTH_RADIUS_IN_METER / 1000
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lng2 - lng1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}

export const getBBoxAroundPointer = event => {
  const pad = isGE() ? 5 : 10
  return [
    [event.point.x - pad, event.point.y - pad],
    [event.point.x + pad, event.point.y + pad]
  ]
}

export const getPolylinesAroundPointer = (map, event) =>
  map
    .queryRenderedFeatures(getBBoxAroundPointer(event))
    .filter(feature => (feature.layer?.id ?? '').match(LAYER_ID_POLYLINE))

export const isPolylineAroundPointer = (map, event) => getPolylinesAroundPointer(map, event).length > 0

export const computeBBoxFromCoordinates = radius => point => {
  const min = getDistantPoint(radius, point)
  const max = getDistantPoint(-radius, point)

  return [
    [min.lng, min.lat],
    [max.lng, max.lat]
  ]
}

const getDistantPoint = (radius, { lat, lng }) => {
  return {
    lat: lat - ((radius / EQUATORIAL_EARTH_RADIUS_IN_METER) * 180) / Math.PI,
    lng:
      lng -
      (((radius *
        Math.sqrt(
          Math.pow(EQUATORIAL_EARTH_RADIUS_IN_METER, 2) +
            EQUATORIAL_EARTH_RADIUS_IN_METER * Math.pow(Math.tan((lat * Math.PI) / 180), 2)
        )) /
        Math.pow(EQUATORIAL_EARTH_RADIUS_IN_METER, 2)) *
        180) /
        Math.PI
  }
}

export const scaleBBox =
  ratio =>
  ([minLng, minLat, maxLng, maxLat]) => {
    const boxHeight = Math.abs(maxLat - minLat)
    const boxWidth = Math.abs(maxLng - minLng)
    const dWidth = 0.5 * (ratio * boxWidth - boxWidth)
    const dHeight = 0.5 * (ratio * boxHeight - boxHeight)

    return [minLng - dWidth, minLat - dHeight, maxLng + dWidth, maxLat + dHeight]
  }

export const getBboxFromLngLatPoints = points => {
  const filteredPoints = points.filter(p => p && p.lat && p.lng)
  const lats = filteredPoints.map(({ lat }) => lat)
  const lngs = filteredPoints.map(({ lng }) => lng)
  if (lats.length < 2 || lngs.length < 2) return null
  return [
    [Math.min(...lngs), Math.min(...lats)],
    [Math.max(...lngs), Math.max(...lats)]
  ]
}

export const isPointInBbox = (point, bbox = []) => {
  if (Array.isArray(bbox) && bbox.length === 2) {
    const [p1, p2] = bbox
    const [minLng, minLat] = p1
    const [maxLng, maxLat] = p2

    if (minLng && minLat && maxLng && maxLat) {
      return point.lng > minLng && point.lng < maxLng && point.lat > minLat && point.lat < maxLat
    }
  }
}

export const convertBboxToWestSouthEastNorthBbox = bbox => {
  const [[lng1, lat1], [lng2, lat2]] = bbox
  return [
    [Math.min(lng1, lng2), Math.min(lat1, lat2)],
    [Math.max(lng1, lng2), Math.max(lat1, lat2)]
  ]
}
