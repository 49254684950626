import { bboxToSearchParameter } from '../../domain/map/conversion'
import request from '../../domain/utils/request'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import parseAddresses from '../../store/address/address.dataParser'
import { parseGeoentityListResponse } from '../../store/geoentity/geoentity.dataParser'
import { searchServiceCtx } from '../../store/search/search.service.constants'
import {
  formatFavoritesFromService,
  formatFavoriteToService,
  formatItineraryOptionsFromService
} from '../../store/userAccount/userAccount.dataParser'
import { requestSearchData } from '../search/search.requests'

export const requestUserFavorites = (authToken, homeLabel, workLabel) =>
  request
    .get(getService(SERVICES.USER_ACCOUNT, '/favorite'), { authorization: `Bearer ${authToken}` })
    .then(response => formatFavoritesFromService(response, homeLabel, workLabel))

export const requestCreateUserFavorite = (authToken, favorite) => {
  const data = formatFavoriteToService(favorite)
  return request.put(getService(SERVICES.USER_ACCOUNT, '/favorite'), {
    authorization: `Bearer ${authToken}`,
    data
  })
}

export const requestUpdateUserFavorite = (authToken, favorite) => {
  const { uuid } = favorite
  if (!uuid) return Promise.reject(new Error('no uuid so can’t update favorite'))
  const data = formatFavoriteToService(favorite)
  return request.post(getService(SERVICES.USER_ACCOUNT, `/favorite/${uuid}`), {
    authorization: `Bearer ${authToken}`,
    data
  })
}

export const requestDeleteUserFavorite = (authToken, favorite) => {
  const { uuid } = favorite
  if (!uuid) return Promise.reject(new Error('no uuid so can’t delete favorite'))
  return request.delete(getService(SERVICES.USER_ACCOUNT, `/favorite/${uuid}`), {
    authorization: `Bearer ${authToken}`
  })
}

export const requestSearchForPoiXorAddressFavorite = (locale, bbox, favorite) => {
  const { label = '', address = '' } = favorite
  const params = {
    q: `${label} ${address}`,
    f: searchServiceCtx.filter.poiXorAddress,
    limit: 1,
    bbox: bboxToSearchParameter(bbox)
  }

  return requestSearchData(params, locale).then(({ data } = {}) => {
    if (!data || !(data.addresses || data.pois)) return

    if (data.addresses) {
      const address = parseAddresses(data)?.[0] ?? {}
      const { label } = address
      return { ...favorite, address: label }
    } else {
      const poi = parseGeoentityListResponse(data)?.[0] ?? {}
      const { id, name, addressLabel } = poi
      return { ...favorite, label: name, address: addressLabel, poiId: id }
    }
  })
}

export const requestSearchForAddressFavorite = (locale, bbox, favorite) => {
  const { address = '' } = favorite
  const params = {
    q: address,
    f: searchServiceCtx.filter.address,
    limit: 1,
    bbox: bboxToSearchParameter(bbox)
  }

  return requestSearchData(params, locale).then(({ data } = {}) => {
    if (!data?.addresses) return

    const address = parseAddresses(data)?.[0] ?? {}
    const { label } = address
    return { ...favorite, address: label }
  })
}

export const requestUserItineraryOptions = authToken => {
  return request
    .get(getService(SERVICES.USER_ACCOUNT, '/preferences'), {
      authorization: `Bearer ${authToken}`
    })
    .then(({ data } = {}) => formatItineraryOptionsFromService(data))
}

export const requestSaveUserItineraryOptions = (authToken, preferences) => {
  return request.post(getService(SERVICES.USER_ACCOUNT, '/preferences'), {
    authorization: `Bearer ${authToken}`,
    data: {
      ...preferences
    }
  })
}
