import { PARAM_REDIRECT } from '../../domain/router/queryParameters.constants'
import {
  ROUTE_HOME,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY
} from '../../routes'
import { selectNavigateToGeoentityParameters } from './history.navigate.selectors'
import { selectCurrentHistoryFullRoute, selectCurrentHistoryRoute } from './history.selectors'
import { parseRouteOptions, routeFormatter } from './history.utils'
import { popHistory, pushHistory, reverseResolveRouteAndRegisterHistory, setNavigationDone } from './historySlice'

export const navigateTo =
  ({ route, routeOptions, selectedStoreState }) =>
  (dispatch, getState) => {
    const storeState = getState()
    const targetFullRoute = routeFormatter({
      storeState,
      route,
      selectedStoreState,
      routeOptions
    })

    const currentFullRoute = selectCurrentHistoryFullRoute(storeState)

    if (!targetFullRoute || currentFullRoute === targetFullRoute) return

    dispatch(
      pushHistory({
        route,
        routeOptions,
        selectedStoreState,
        fullRoute: targetFullRoute
      })
    )
  }

export const navigateBack = () => dispatch => dispatch(popHistory())

export const registerRoute = (fullRoute, routeOptions) => dispatch =>
  dispatch(
    reverseResolveRouteAndRegisterHistory({
      fullRoute,
      routeOptions: parseRouteOptions(routeOptions)
    })
  )

export const navigateToPoisOnRouteByCategory = category => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    routeOptions: { category }
  })(dispatch, getState)
}

export const navigateToPoisOnRouteBySubcategory = subcategory => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
    routeOptions: { subcategory }
  })(dispatch, getState)
}

export const navigateToPoisOnRouteByApp = app => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
    routeOptions: { app }
  })(dispatch, getState)
}

export const navigateToSearchByCategory = category => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_CATEGORY,
    routeOptions: { category }
  })(dispatch, getState)
}

export const navigateToSearchBySubcategory = subcategory => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_SUBCATEGORY,
    routeOptions: { subcategory }
  })(dispatch, getState)
}

export const navigateToSearchByApp = (app, routeOptions) => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_APP,
    routeOptions: { app, ...routeOptions }
  })(dispatch, getState)
}

export const navigateToSearchByRubric =
  (rubricId, options = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_SEARCH_BY_RUBRIC,
      routeOptions: { rubric: rubricId, ...options }
    })(dispatch, getState)
  }

export const navigateToSearchByQuery =
  (query, options = {}) =>
  (dispatch, getState) =>
    navigateTo({
      route: ROUTE_SEARCH_BY_QUERY,
      routeOptions: { query, ...options }
    })(dispatch, getState)

export const navigateToGeoentity =
  ({ id, scrollTop }) =>
  (dispatch, getState) => {
    const params = selectNavigateToGeoentityParameters(getState(), { id, scrollTop })
    navigateTo(params)(dispatch, getState)
  }

export const navigateToHomepage = () => (dispatch, getState) => {
  navigateTo({ route: ROUTE_HOME })(dispatch, getState)
}

export const noticeNavigationDone = routeContext => (dispatch, getState) => {
  const currentRoute = selectCurrentHistoryRoute(getState())

  if (!routeContext?.query?.get(PARAM_REDIRECT))
    routeContext?.query.set(PARAM_REDIRECT, routeContext.state?.query?.redirect)

  dispatch(setNavigationDone({ route: currentRoute, ...(routeContext?.query && { query: routeContext.query }) }))
}
