import { useCallback } from 'react'
import SentryService from '../error/SentryService'

export default function useErrorBoundaryCallback(method, deps = []) {
  return useCallback(
    (...args) => {
      try {
        return method(...args)
      } catch (error) {
        SentryService.captureException(error)
      }
    },
    [method, ...deps]
  )
}
