import {
  BROWSER_STORAGE_STORE_KEY,
  saveJSONInLocalStorage,
  saveJSONInSessionStorage
} from '../utils/browserStorageService'
import { debounce } from '../utils/function'
import { getObjectValueByPath, pick } from '../utils/object'

export const RECOMMENDED_DEBOUNCE_TIMEOUT = 300
export const STORE_STATES_TO_SYNC_WITH_LOCAL_STORAGE = [
  'map.vecto',
  'map.theme',
  'map.mode',
  'search.searchHistory',
  'ui.survey',
  'ui.userAccountIncentive.timestamp',
  'ui.userAccountIncentive.itineraryResultsTimestamp',
  'ui.poisOnRouteIncentive.timestamp',
  'ui.map.tooSlowTimestamp',
  'ui.homeBanner',
  'cmp',
  'abtest.enrollment',
  'incentive',
  'navigation.visitCount'
]
export const STORE_STATES_TO_SYNC_WITH_SESSION_STORAGE = ['itineraryOptions.preferredOptions']

let prevLocalSyncedState
let prevSessionSyncedState

const syncStoreWithBrowserStorage = store =>
  store.subscribe(
    debounce(() => {
      if (hasLocalSyncedStateChanged(store)) {
        prevLocalSyncedState = pick(store.getState(), STORE_STATES_TO_SYNC_WITH_LOCAL_STORAGE)
        saveJSONInLocalStorage(BROWSER_STORAGE_STORE_KEY, prevLocalSyncedState)
      }
      if (hasSessionSyncedStateChanged(store)) {
        prevSessionSyncedState = pick(store.getState(), STORE_STATES_TO_SYNC_WITH_SESSION_STORAGE)
        saveJSONInSessionStorage(BROWSER_STORAGE_STORE_KEY, prevSessionSyncedState)
      }
    }),
    RECOMMENDED_DEBOUNCE_TIMEOUT
  )

const hasLocalSyncedStateChanged = store =>
  STORE_STATES_TO_SYNC_WITH_LOCAL_STORAGE.some(hasStateChanged(store.getState(), prevLocalSyncedState))

const hasSessionSyncedStateChanged = store =>
  STORE_STATES_TO_SYNC_WITH_SESSION_STORAGE.some(hasStateChanged(store.getState(), prevSessionSyncedState))

const hasStateChanged = (prevState, currentState) => subStateAttr => {
  return getObjectValueByPath(prevState, subStateAttr) !== getObjectValueByPath(currentState, subStateAttr)
}

export const cleanPreviousSyncState = () => {
  prevLocalSyncedState = null
  prevSessionSyncedState = null
}

export default syncStoreWithBrowserStorage
