const DEAULT_ERROR_NAME = 'DefaultErrorName'
const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue, veuillez réessayer'

export const parseError = (error = {}) => {
  const { message = DEFAULT_ERROR_MESSAGE, name = DEAULT_ERROR_NAME, stack } = error

  return { message, name, stack }
}

export const parseErrorFromReduxAction = action => parseError(action?.payload?.error ?? {})
