import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  poisOnRoute: {
    lastTimeDisplayed: 0
  },
  push: {
    accepted: false,
    lastTimeDisplayed: 0
  },
  survey: {
    lastTimeDisplayed: 0
  },
  userAccount: {
    lastTimeDisplayedItineraryResults: 0,
    lastTimeDisplayedPopin: 0,
    preferencesDisplayed: false
  }
}

const incentiveSlice = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    setPushAcceptation: (state, action) => {
      state.push.accepted = true
    },
    setPushDisplayed: (state, action) => {
      state.push.lastTimeDisplayed = Date.now()
    },
    setNPSSurveyDisplayed: (state, action) => {
      state.survey.lastTimeDisplayed = Date.now()
    },
    setUserAccountIncentivePopinDisplayed: (state, action) => {
      state.userAccount.lastTimeDisplayedPopin = Date.now()
    },
    setUserAccountIncentiveItineraryResultsDisplayed: (state, action) => {
      state.userAccount.lastTimeDisplayedItineraryResults = Date.now()
    },
    showUserAccountPreferencesIncentive: (state, action) => {
      state.userAccount.preferencesDisplayed = true
    },
    hideUserAccountPreferencesIncentive: (state, action) => {
      state.userAccount.preferencesDisplayed = false
    },
    setPoisOnRouteIncentivePopin: (state, action) => {
      state.poisOnRoute.lastTimeDisplayed = Date.now()
    }
  }
})

export const {
  setPushAcceptation,
  setPushDisplayed,
  setNPSSurveyDisplayed,
  setUserAccountIncentivePopinDisplayed,
  setUserAccountIncentiveItineraryResultsDisplayed,
  showUserAccountPreferencesIncentive,
  hideUserAccountPreferencesIncentive,
  setPoisOnRouteIncentivePopin
} = incentiveSlice.actions

export default incentiveSlice
