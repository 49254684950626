export const POIS_FILTERS = {
  categories: {
    type: 'categories',
    shortLabel: 'Catégories'
  },
  mosaic: { type: 'mosaic' },
  combobox: { type: 'combobox' },
  multicombobox: { type: 'multicombobox' },
  multidatepicker: { id: 'multidatepicker-dates', type: 'multidatepicker' },
  slider: { type: 'slider' }
}

export const CATEGORY_HOTEL_HEBERGEMENT_EXCEPTION = 'hotel-hebergement'

export const CATEGORY_HOTEL_RUBRIC_ID = 'hotel'

export const DEFAULT_FILTER_COMBOBOX_PARAMETER_VALUE = 'discarded'

export const CATEGORY_DEPLACEMENT = 'deplacement'
