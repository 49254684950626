import qs from 'qs'
import UniversalRouterSync from 'universal-router/sync'
import RouteHandlingState from '../../routes/RouteHandlingState'
import routes, { ROUTE_ADDRESS, ROUTE_ADDRESS_HOME, ROUTE_SEO_ADDRESS, rawRoutes } from '../../routes/index'
import TIMEOUTS from '../../routes/timeout.constants'
import {
  selectIsCurrentHistoryRoutePageless,
  selectIsPreviousHistoryRoutePageless
} from '../../store/history/history.selectors'
import { isEmpty } from '../../utils/lang'
import ImplementationError from '../error/ImplementationError'
import RouteNotFoundError from '../error/RouteNotFoundError'
import { cleanUrlOffOptionalParams, isUrlForSeo, sanitizeRoute } from '../utils/url'

export const _reverseResolveRoute = fullRoute => {
  const identityRouteMap = routes.map(({ path, name }) => ({
    path,
    action: (_, params) => ({
      path,
      params,
      route: name
    })
  }))
  const reverseRouter = new UniversalRouterSync(identityRouteMap)

  try {
    const routeMatch = reverseRouter.resolve(sanitizeRoute(fullRoute))
    return {
      ...routeMatch,
      fullRoute
    }
  } catch (error) {
    console.error(`Error on ${fullRoute}`, error, JSON.stringify(error))
    if (__SERVER__) {
      if (error.status === 404) throw new RouteNotFoundError()
      throw error
    }
  }
}

export const _getMatchingRouteRecordOfFullRoute = fullRoute => {
  const { path: routePath } = _reverseResolveRoute(fullRoute) || {}

  return routes.find(({ path }) => path === routePath)
}

export const _getMatchingRouteRecordOfRoute = route => routes.find(({ name }) => name === route)

export const getMatchingRoute = fullRoute => _getMatchingRouteRecordOfFullRoute(fullRoute)?.name

export const isNessieRoute = fullRoute => {
  try {
    return !isEmpty(_getMatchingRouteRecordOfFullRoute(fullRoute))
  } catch (error) {
    if (error instanceof RouteNotFoundError) return false
    throw error
  }
}

export const isRouteSSROnly = fullRoute => _getMatchingRouteRecordOfFullRoute(fullRoute)?.ssrOnly ?? false

export const getRouteTimeout = fullRoute =>
  _getMatchingRouteRecordOfFullRoute(fullRoute)?.timeout ?? TIMEOUTS.NORMAL_TIMEOUT

export const getMaxAges = fullRoute => {
  const { maxAge, smaxAge } = _getMatchingRouteRecordOfFullRoute(fullRoute)
  return {
    maxAge,
    smaxAge
  }
}

const injectQueryParams = (route, queryParams) => {
  if (!queryParams) return route
  const containsHash = route.includes('#')
  return `${route}${containsHash ? '' : '#'}?${queryParams}`
}

export const generateFullRoute = (route = '', params = {}, queryStringParams = {}) => {
  const matchingRawRoute = rawRoutes.find(({ name }) => name === route)

  if (!matchingRawRoute) {
    throw new ImplementationError('Bad matching raw route')
  }

  const formattedRoute = Object.keys(params).reduce(
    (acc, curr) => (params[curr] ? acc.replace(new RegExp(`:${curr}`), encodeURIComponent(params[curr])) : acc),
    matchingRawRoute.path
  )

  if (formattedRoute.includes(':')) throw new ImplementationError(`Parameters not replaced on : ${formattedRoute}`)

  return injectQueryParams(formattedRoute, qs.stringify(queryStringParams))
}

export const generateDomainFullRoute = (route = '', params = {}, queryStringParams = {}) =>
  `${global.location.origin}${generateFullRoute(route, params, queryStringParams)}`

export const isPagelessRoute = route => _getMatchingRouteRecordOfRoute(route)?.isPageless ?? false

export const isPathTraversalAttack = route => decodeURIComponent(route).includes('../')

export const isRouteSkipableOnPop = route => _getMatchingRouteRecordOfRoute(route)?.skipOnPop ?? false

export const avoidReloadOnBackNav = route => _getMatchingRouteRecordOfRoute(route)?.avoidReloadOnBackNav ?? false

export const isFullRouteOpenedToSEO = fullRoute => {
  const route = getMatchingRoute(fullRoute)

  if (typeof route !== 'string') return false

  const matchingRawRoute = rawRoutes.find(({ name }) => name === route)

  return matchingRawRoute && isUrlForSeo(matchingRawRoute.path)
}

// Use this function very sporadically. Most of the time you should retrieve current route from the store.
export const formatFullRouteFromHistory = ({ location: { pathname = '', hash = '' } }) =>
  cleanUrlOffOptionalParams(pathname + hash)

export const isPagePlan = location =>
  [ROUTE_ADDRESS, ROUTE_SEO_ADDRESS, ROUTE_ADDRESS_HOME].some(route => route === getMatchingRoute(location))

const getQueryStringParams = (s = '') => qs.parse(s, { ignoreQueryPrefix: true })

export const extractQueryStringParams = ({ location: { search = '', hash = '' } = {} } = {}) => {
  if (search) return getQueryStringParams(search)
  if (hash) {
    const questionIdx = hash.indexOf('?')
    if (questionIdx >= 0) return getQueryStringParams(hash.substring(questionIdx))
  }
  return {}
}

export const processOnRouteLeave = ({ routeContext, params, currentOnRouteLeave }) => {
  const routeHandlingState = RouteHandlingState.getInstance()
  const { state, store } = routeContext
  const storeState = store.getState()

  const avoidRefetchingPageData = state?.avoidRefetchingPageData ?? false
  const isRouteActionPop = routeContext?.action === 'POP'
  const routeLeaveParameters = { routeContext, store, params, state, avoidRefetchingPageData }

  const currentRouteIsPageless = selectIsCurrentHistoryRoutePageless(storeState)
  const previousRouteIsPageLess = selectIsPreviousHistoryRoutePageless(storeState)

  if (!currentRouteIsPageless && !(isRouteActionPop && previousRouteIsPageLess)) {
    routeHandlingState?.onPreviousRouteLeave?.(routeLeaveParameters)
  }
  routeHandlingState?.onPreviousRouteLeavePageLess?.(routeLeaveParameters)

  if (currentRouteIsPageless) {
    routeHandlingState.onPreviousRouteLeavePageLess = currentOnRouteLeave
  } else {
    routeHandlingState.onPreviousRouteLeave = currentOnRouteLeave
    routeHandlingState.onPreviousRouteLeavePageLess = undefined
  }
}
