import { useSelector } from 'react-redux'
import { selectIsMapFullScreen, selectIsVectorMap } from '../../store/map/map.selectors'
import {
  selectIsSearchPage,
  selectIsItineraryUniverse,
  selectCurrentHistoryRouteDone
} from '../../store/history/history.selectors'
import { isGE } from '../Device'
import { PAGE_CLASSES } from '../../routes/pageClasses.constants'
import { UI_ELEMENTS } from '../../store/ui/ui.constants'
import useUIDisplay from '../ui/useUIDisplay'
import { useCallback } from 'react'

const paddingKeys = ['top', 'left', 'bottom', 'right']
const bottomMapPaddingWhenMegabanIsOpened = 105
export const searchPageTopWithFilters = 116
export const desktopMainDetailMargin = 20
export const desktopMainDetailWidth = 415
export const desktopRightButtonsMargin = 20

export const buildPaddingObject = p => {
  let padding = p
  if (typeof p === 'number') {
    padding = {}
    paddingKeys.forEach(key => {
      padding[key] = p
    })
  }
  paddingKeys.forEach(key => {
    padding[key] = Math.max(0, padding[key])
  })
  return padding
}

export const getMapBottom = () => {
  const domMain = document.querySelector(`.${PAGE_CLASSES.mainDetail}`) || document.querySelector('main')
  if (!domMain) return 0

  const viewportHeight = Math.max(document?.documentElement?.clientHeight, window?.innerHeight || 0)

  const topMainValue = (window.getComputedStyle(domMain)?.top ?? '').replace('px', '')
  const parsedTopMainValue = parseInt(topMainValue, 10) || viewportHeight

  return Math.max(0, viewportHeight - parsedTopMainValue)
}

const getMapTop = () =>
  Math.max(
    document.querySelector('header')?.clientHeight ?? 0,
    document.querySelector(`.${PAGE_CLASSES.headerDetail}`)?.clientHeight ?? 0
  )

export const computePaddingGE = ({ adBannerDisplayed, popinDisplayed, vector, isMapFullScreen }) => {
  // NES-2071: padding set to 0 due to a mapbox bug about raster tiles
  // that should be reverted when we’re finally using vector tiles (where bugs doesn’t occurs)
  const basePadding = vector ? 50 : 0

  const leftMargin = isMapFullScreen ? 0 : desktopMainDetailWidth

  return buildPaddingObject({
    top: basePadding,
    left: basePadding + (popinDisplayed ? leftMargin + desktopMainDetailMargin : 0),
    bottom: basePadding + (adBannerDisplayed ? bottomMapPaddingWhenMegabanIsOpened : 0),
    right: basePadding + desktopRightButtonsMargin
  })
}

export const computePaddingPE = ({ fullscreen, itineraryUniverse, searchPage }) => {
  if (fullscreen) return buildPaddingObject(10)

  const verticalAdditionalPadding = itineraryUniverse ? 20 : 10
  const mapTop = searchPage ? searchPageTopWithFilters : getMapTop()
  const mapBottom = searchPage ? 0 : getMapBottom()

  return buildPaddingObject({
    top: mapTop + verticalAdditionalPadding,
    left: 30,
    bottom: mapBottom + verticalAdditionalPadding,
    right: 30
  })
}

export const getSearchPadding = () => {
  return isGE()
    ? buildPaddingObject(50)
    : buildPaddingObject({
        top: searchPageTopWithFilters + 10,
        left: 30,
        bottom: 10,
        right: 30
      })
}

const useComputePaddingGetterGE = () => {
  const vector = useSelector(selectIsVectorMap)
  const isMapFullScreen = useSelector(selectIsMapFullScreen)

  const { isUIDisplayed: popinDisplayed } = useUIDisplay(UI_ELEMENTS.POPIN)
  const { isUIDisplayed: adBannerDisplayed } = useUIDisplay(UI_ELEMENTS.AD_BANNER)

  return useCallback(
    () => computePaddingGE({ adBannerDisplayed, popinDisplayed, vector, isMapFullScreen }),
    [adBannerDisplayed, popinDisplayed, vector, isMapFullScreen]
  )
}

const useComputePaddingGetterPE = () => {
  const fullscreen = useSelector(selectIsMapFullScreen)
  const itineraryUniverse = useSelector(selectIsItineraryUniverse)
  const searchPage = useSelector(selectIsSearchPage)
  const currentRouteDone = useSelector(selectCurrentHistoryRouteDone)

  /*
   * `currentRouteDone` dependency is meant to trigger the `useCallback` when the
   * `routeDone` store property has been changed. (see `selectCurrentHistoryRouteDone`)
   */
  return useCallback(
    () => computePaddingPE({ fullscreen, itineraryUniverse, searchPage }),
    [fullscreen, itineraryUniverse, searchPage, currentRouteDone]
  )
}

export default function useComputePaddingGetter() {
  const methodGE = useComputePaddingGetterGE()
  const methodPE = useComputePaddingGetterPE()

  return isGE() ? methodGE : methodPE
}
