import { createSlice } from '@reduxjs/toolkit'
import {
  CONFIG_PAGE_AD_STATUS_LOADED,
  CONFIG_PAGE_AD_STATUS_LOADING,
  CONFIG_PAGE_AD_STATUS_NONE,
  CONFIG_PAGE_AD_STATUS_REQUESTED
} from './ad.constants'

const initialState = {
  textImageAd: {
    pageId: null,
    pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_NONE
  },
  adData: []
}

const adSlice = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    pageAdConfigRequested: (state, action) => {
      state.textImageAd = {
        pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_REQUESTED,
        pageId: action.payload
      }
    },
    pageAdConfigLoading: (state, action) => {
      state.textImageAd.pageAdConfigLoadStatus = CONFIG_PAGE_AD_STATUS_LOADING
    },
    pageAdConfigLoaded: (state, action) => {
      state.textImageAd.pageAdConfigLoadStatus = CONFIG_PAGE_AD_STATUS_LOADED
    },
    pageAdConfigNone: (state, action) => {
      state.textImageAd = initialState.textImageAd
    },
    setAdData: (state, action) => {
      const { adId, data } = action.payload
      state.adData.push({ adId, data })
    },
    removeAdData: (state, action) => {
      const adId = action.payload
      state.adData = state.adData.filter(item => item.adId !== adId)
    }
  }
})

export const {
  pageAdConfigRequested,
  pageAdConfigLoading,
  pageAdConfigLoaded,
  pageAdConfigNone,
  setAdData,
  removeAdData
} = adSlice.actions

export default adSlice
