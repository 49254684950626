import {
  MULTIPATH_MODE_TC,
  MULTIPATH_MODE_TRAIN,
  PROVIDER_HEETCH,
  PROVIDER_MARCEL
} from '../../../store/itinerary/itinerary.constants'
import { compact, sortBy } from '../../../utils/array'
import { isGE } from '../../Device'

const EXCLUDED_PROVIDER_FOR_GE = [PROVIDER_HEETCH, PROVIDER_MARCEL]

export const removeExcludedProviders = ({ name }) => !(isGE() && EXCLUDED_PROVIDER_FOR_GE.includes(name))

const getPriceForRoute = ({ prices }) => parseInt(prices?.main?.value ?? '0', 10)

const decorateRouteWithOriginalPosition = (route, idx) => ({
  ...route,
  originalPosition: idx
})

export const reorderTrainRoute = routes => {
  const routesWithOriginalPosition = routes.map(decorateRouteWithOriginalPosition)
  const firstRoute = routesWithOriginalPosition[0]
  const restRoutesSortedByPrice = sortBy(routesWithOriginalPosition.slice(1), getPriceForRoute)
  const twoRoutesResortedByOriginalPosition = sortBy(
    restRoutesSortedByPrice.slice(0, 2),
    ({ originalPosition }) => originalPosition
  )

  return compact([firstRoute, ...twoRoutesResortedByOriginalPosition])
}

export const decorateTrainRoutesWithLowerPrice = (groupedByProviderRoutes = {}) => {
  if (!groupedByProviderRoutes.train) return groupedByProviderRoutes

  const routes = groupedByProviderRoutes.train
  const lower = Math.min(...routes.map(({ price }) => price.value))

  return {
    ...groupedByProviderRoutes,
    train: routes.map(route => ({
      ...route,
      isLowerPrice: lower === route.price.value
    }))
  }
}

export const isModeTrainForSpecialCase = modeId => modeId === MULTIPATH_MODE_TRAIN

export const isModeTcForSpecialCase = modeId => modeId === MULTIPATH_MODE_TC
