import { PROVIDERS_PRIORITY_LIST } from './itinerary.constants'

export const compareRoutesByDuration = (route1, route2) => {
  const result = route1?.time?.value - route2?.time?.value
  return result === 0 ? compareRoutesByProviderPriority(route1, route2) : result
}

export const compareRoutesByPrice = (route1, route2) => {
  const result = route1?.prices?.main?.value - route2?.prices?.main?.value
  return result === 0 ? compareRoutesByProviderPriority(route1, route2) : result
}

export const compareRoutesByCO2Emission = (route1, route2) => {
  const result = route1?.co2_emissions?.value - route2?.co2_emissions?.value
  return result === 0 ? compareRoutesByProviderPriority(route1, route2) : result
}

export const getRouteIndexInProviderPriority = route => {
  const index = PROVIDERS_PRIORITY_LIST.findIndex(
    ({ mode, provider }) => route.mode === mode && route.provider?.id === provider
  )
  if (index !== -1) return index
  return PROVIDERS_PRIORITY_LIST.findIndex(({ mode, provider }) => route.mode === mode && provider === undefined)
}

export const compareRoutesByProviderPriority = (route1, route2) => {
  return getRouteIndexInProviderPriority(route1) - getRouteIndexInProviderPriority(route2)
}

export const compareRoutesByTransportsServiceProviderPriority = providers => {
  if (!providers) return () => 0
  providers = providers.map(({ name }) => name)
  return ({ provider: { id: providerA } }, { provider: { id: providerB } }) =>
    providers.indexOf(providerA) - providers.indexOf(providerB)
}
