import React from 'react'
import s from './TopSearchItem.less'

const TopSearchLink = ({ url, label }) =>
  url ? (
    <a className={s.link} href={url}>
      {label}
    </a>
  ) : (
    <span className={s.link}>{label}</span>
  )

const TopSearchItem = ({ title, contents = [] }) => {
  if (contents.length === 0) return <></>

  return (
    <div>
      <h3 className={s.title}>{title}</h3>
      <ul>
        {contents.map(({ url, label }, index) => (
          <li key={url} className={s.item}>
            <TopSearchLink label={label} url={url} />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TopSearchItem
