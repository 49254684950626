import { isPE } from '../../domain/Device'
import { uniqBy } from '../../utils/array'
import { isObject } from '../../utils/object'

export const parseAbTestResponse = (config, locale) => {
  if (!Array.isArray(config)) return []

  const configWithValidFields = config.filter(t => {
    if (!isObject(t)) return false
    if (!t.id) return false
    if (!t.from || !t.to) return false
    if (!t.conditions || !isObject(t.conditions)) return false
    if (!t.conditions.platforms || !Array.isArray(t.conditions.platforms)) return false
    if (!t.conditions.locales || !Array.isArray(t.conditions.locales)) return false
    if (!t.variants || !Array.isArray(t.variants)) return false
    return Array.isArray(t.variants) && t.variants.length !== 0
  })

  return uniqBy(
    configWithValidFields
      .filter(
        // filter by out-of-range dates
        ({ from, to }) => Date.now() > Date.parse(from) && Date.now() < Date.parse(to)
      )
      .filter(
        // Filter by device (PE / GE)
        ({ conditions }) =>
          (conditions.platforms.includes('pe') && isPE()) || (conditions.platforms.includes('ge') && !isPE())
      )
      .filter(
        // Filter by locale
        ({ conditions }) => conditions.locales.includes(locale.replace('-', '_'))
      ),
    ({ id }) => id
  ) // filter test with same ids
}
