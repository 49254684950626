import { projectDistrictZipcodeToWholeCityZipcode } from '../../domain/utils/location'
import { getRouteBbox } from '../../routes/search/search.route.utils'
import { slugify } from '../../utils/string'
import { selectFirstAddress } from '../address/address.selectors'
import { selectMapActiveBbox } from '../map/map.selectors'
import { selectSearchTerms } from '../search/search.selectors'
import { searchServiceCtx } from '../search/search.service.constants'
import { formatLabelToInline } from '../suggest/suggest.selectors.utils'
import { selectIsAGeoentityOnSearchPage } from './history.selectors'

export const getSearchParams = ({ routeOptions, state, selectedStoreState }) => {
  if (routeOptions?.location) {
    return { location: routeOptions.location }
  }

  if (selectedStoreState) {
    if (selectedStoreState.address) {
      if (
        [searchServiceCtx.geocodeLevel.unnamed_road, searchServiceCtx.geocodeLevel.way].includes(
          selectedStoreState.address?.geocode_level
        )
      ) {
        const { lat, lng } = selectedStoreState.address.coordinates
        return { location: `${lat},${lng}` }
      }
      return { location: selectedStoreState?.address?.label }
    }

    if (selectedStoreState.suggest) {
      return { location: formatLabelToInline(selectedStoreState?.suggest?.labels) }
    }
  }

  const searchTerms = selectSearchTerms(state)

  if (searchTerms) {
    return { location: searchTerms }
  }
}

export const getCityParameters = ({ state, selectedStoreState }) => {
  const address = selectedStoreState?.address || selectFirstAddress(state)
  if (address && address.geocode_level === searchServiceCtx.geocodeLevel.town) {
    return { location: `${projectDistrictZipcodeToWholeCityZipcode(address.postcode)}-${slugify(address.town)}` }
  } else if (address) {
    return { location: `${address.postcode}-${slugify(address.town)}` }
  }
}

export const getCountryParameters = ({ state, selectedStoreState }) => {
  const address = selectedStoreState?.address || selectFirstAddress(state)
  if (address) {
    return { countryCode: address.countryCode, country: slugify(address.countryLabel) }
  }
}

export const getRegionParameters = ({ state, selectedStoreState }) => {
  const address = selectedStoreState?.address || selectFirstAddress(state)
  if (address) {
    return { regionCode: address.regionCode, region: slugify(address.regionLabel) }
  }
}

export const getDepartmentParameters = ({ state, selectedStoreState }) => {
  const address = selectedStoreState?.address || selectFirstAddress(state)
  if (address) {
    return { departmentCode: address.departmentCode, department: slugify(address.departmentLabel) }
  }
}

export const generateBboxParamForSearchRoute = ({ state, selectedStoreState }) => {
  if (selectIsAGeoentityOnSearchPage(state)) {
    const bbox = getRouteBbox(state)
    if (bbox) return { bbox }
  }
  const bbox = selectedStoreState?.bbox || selectMapActiveBbox(state)
  return { bbox }
}

export const searchFieldsForComparaisonCheck = ['category', 'subcategory', 'app', 'rubric', 'query']

export const searchFieldsHaveNotChanged = (routeParams, prevRouteParams) =>
  Boolean(
    searchFieldsForComparaisonCheck.find(field => routeParams[field] && routeParams[field] === prevRouteParams?.[field])
  )
