import { formatNaturalDateByLocale, getCurrentDateISO } from '../../domain/utils/date'
import { ARRIVAL_LATER, DEPARTURE_LATER, DEPARTURE_NOW } from './itineraryTimeOptionsWhenTypes'

import { PARAM_ARRIVAL_DATE_TIME, PARAM_DEPARTURE_DATE_TIME } from '../../domain/router/queryParameters.constants'
import { createCustomSelector } from '../../domain/utils/react-reselect'

export const selectItineraryTimeOptions = store => store?.itineraryOptions?.timeOptions

export const selectItineraryTimeOptionsDate = createCustomSelector(
  selectItineraryTimeOptions,
  itineraryTimeOptions => itineraryTimeOptions?.date ?? getCurrentDateISO(new Date())
)

export const selectItineraryTimeOptionsTime = createCustomSelector(
  selectItineraryTimeOptions,
  itineraryTimeOptions => itineraryTimeOptions?.time ?? '12:00'
)

export const selectItineraryTimeOptionsWhen = createCustomSelector(
  selectItineraryTimeOptions,
  itineraryTimeOptions => itineraryTimeOptions?.when
)

export const selectIsItineraryDepartureNow = createCustomSelector(
  selectItineraryTimeOptionsWhen,
  when => when === DEPARTURE_NOW
)

export const getItineraryTimeTitle = createCustomSelector(
  selectItineraryTimeOptionsWhen,
  selectItineraryTimeOptionsDate,
  selectItineraryTimeOptionsTime,
  (when, date, time) => (locale, translations) => {
    const type =
      when === DEPARTURE_NOW || when === DEPARTURE_LATER
        ? translations['itinerary.options.date.start']
        : translations['itinerary.options.date.end']
    if (when === DEPARTURE_NOW) return `${type} ${translations['date.now']}`
    const msg = formatNaturalDateByLocale({
      date,
      locale, // FIXME: not consumed
      today: translations['date.today'],
      tomorrow: translations['date.tomorrow'],
      the: translations['date.the']
    })
    return `${type} ${msg} ${translations['date.at']} ${time}`
  }
)

export const selectItineraryTimeQueryStringParams = createCustomSelector(
  selectItineraryTimeOptionsWhen,
  selectItineraryTimeOptionsDate,
  selectItineraryTimeOptionsTime,
  (when, date, time) => {
    const datetime = `${date}T${time}`
    if (when === DEPARTURE_LATER) {
      return { [PARAM_DEPARTURE_DATE_TIME]: datetime }
    } else if (when === ARRIVAL_LATER) {
      return { [PARAM_ARRIVAL_DATE_TIME]: datetime }
    }
    return {}
  }
)
