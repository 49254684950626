import { createSlice } from '@reduxjs/toolkit'
import { isPagelessRoute } from '../../domain/router/routeUtils'
import { ROUTE_HOME } from '../../routes'

const initialState = {
  route: ROUTE_HOME,
  fullRoute: '/',
  routeOptions: {},
  routeDone: undefined,
  prevFullRouteNonPageLess: '/'
}

const buildNewHistoryState = (state, action) => {
  const { route, routeOptions, fullRoute } = action.payload
  const prevFullRouteNonPageLess = isPagelessRoute(state.route) ? state?.prevFullRouteNonPageLess : state.fullRoute

  const prevRoute = state.route

  state.route = route
  state.prevRoute = prevRoute
  state.routeOptions = routeOptions
  state.fullRoute = fullRoute
  state.prevFullRouteNonPageLess = prevFullRouteNonPageLess
}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    pushHistory: buildNewHistoryState,
    reverseResolveRouteAndRegisterHistory: buildNewHistoryState,
    saveHistoryPostPop: buildNewHistoryState,
    setNavigationDone: (state, action) => {
      const { route } = action.payload
      state.routeDone = route
    },
    popHistory: (state, action) => {
      /* for middleware purpose */
    },
    navigateToExternalUrl: (state, action) => {
      /* for middleware purpose */
    }
  }
})

export const {
  reverseResolveRouteAndRegisterHistory,
  saveHistoryPostPop,
  pushHistory,
  setNavigationDone,
  popHistory,
  navigateToExternalUrl
} = historySlice.actions

export default historySlice
