import { getDistanceByFlyCrow } from '../../domain/utils/map'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectIsAnItineraryRoadbookPage } from '../history/history.selectors'
import { selectCurrentMode, selectCurrentProvider, selectIsMultimode } from './itinerary.selectors'
import { selectCurrentRoute, selectCurrentRouteId, selectRoutes } from './routes.selectors'

export const selectCurrentModePolylines = createCustomSelector(
  selectCurrentMode,
  selectRoutes,
  selectCurrentProvider,
  selectCurrentRouteId,
  (currentMode, routes, currentProvider, currentRouteId) => {
    return routes
      .filter(({ mode }) => mode === currentMode)
      .map(({ sections, provider: { id: provider }, routeId }) => ({
        provider,
        sections,
        currentMode,
        isSelectedProvider: provider === currentProvider,
        isSelectedRoute: routeId === currentRouteId,
        routeId,
        hasTraffic: ['car', 'motorbike'].includes(provider)
      }))
      .sort((p1, p2) => {
        return p1.isSelectedRoute ? 1 : p2.isSelectedRoute ? -1 : 0
      })
  }
)

export const selectCurrentPolylines = createCustomSelector(
  selectCurrentModePolylines,
  selectIsAnItineraryRoadbookPage,
  selectIsMultimode,
  (providerPolylines, roadbookRoute, isMultimode) => {
    if (roadbookRoute || isMultimode) return providerPolylines.filter(({ isSelectedRoute }) => isSelectedRoute)
    return providerPolylines
  }
)

export const selectCurrentRoutePolyline = createCustomSelector(
  selectCurrentModePolylines,
  selectCurrentRouteId,
  (polylines, currentRouteId) => polylines.find(p => p.routeId === currentRouteId)
)

export const selectCurrentRoutePolylineCoordinates = createCustomSelector(selectCurrentRoutePolyline, polyline => {
  return (polyline?.sections || [])
    .flatMap(section => section?.features || [])
    .flatMap(feature => feature?.geometry?.coordinates || [])
})

const getPolylineDistanceFromCoordinates = (coordinates = []) => {
  if (coordinates.length < 2) return 0
  const [, distance] = coordinates.slice(1).reduce(
    ([prevPoint, prevDistance], coordinate) => {
      const newPoint = coordinate
      const distance = prevDistance + getDistanceByFlyCrow(...prevPoint.concat(newPoint))
      return [newPoint, distance]
    },
    [coordinates[0], 0]
  )
  return distance
}

export const selectCurrentPolylineDistances = createCustomSelector(selectCurrentRoutePolyline, polyline => {
  const features = (polyline?.sections ?? []).flatMap(o => o?.features ?? [])
  const colorsAndLengths = features.map(feature => ({
    color: feature.properties.color,
    length: Math.round(getPolylineDistanceFromCoordinates(feature.geometry.coordinates) * 1000)
  }))
  const total = colorsAndLengths.reduce((total, feature) => total + feature.length, 0)
  return colorsAndLengths.map(feature => {
    return {
      ...feature,
      width: (feature.length / total) * 100
    }
  })
})

export const selectCurrentPolylineBbox = createCustomSelector(selectCurrentRoutePolylineCoordinates, coordinates => {
  if (coordinates.length === 0) return null
  const latitudes = coordinates.map(([, lat]) => lat)
  const longitudes = coordinates.map(([lng]) => lng)
  return [
    [Math.min(...longitudes), Math.min(...latitudes)],
    [Math.max(...longitudes), Math.max(...latitudes)]
  ]
})

export const selectNumOfTimeLineParts = createCustomSelector(
  selectCurrentPolylineDistances,
  currentPolyline => currentPolyline?.length || 0
)
const isInBounds = ([lng, lat], bbox) => {
  const [[w, s], [e, n]] = bbox
  return lng < e && lng > w && lat < n && lat > s
}

export const selectCurrentPolylineVisibleSections = (itinerary, bbox) => {
  const currentRoute = selectCurrentRoute({ itinerary })
  return (currentRoute?.sections ?? [])
    .map(section => {
      return (section?.features ?? [])
        .map(feature => {
          return feature.geometry.coordinates.filter(c => isInBounds(c, bbox))
        })
        .filter(feature => feature.length > 0)
    })
    .filter(feature => feature.length > 0)
    .reduce((previous, points) => previous.concat(points), [])
}
