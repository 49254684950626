import { MODAL_IDS } from '../ui/ui.constants'
import { displayUiModal } from '../ui/uiSlice'
import {
  setUserAccountIncentiveItineraryResultsDisplayed,
  setUserAccountIncentivePopinDisplayed
} from './incentiveSlice'

export const updateIncentiveTimestampsOnDisplay =
  ({ dispatch }) =>
  next =>
  action => {
    const { payload } = action

    if (action.type === displayUiModal.type) {
      if (payload?.modalId === MODAL_IDS.USER_ACCOUNT_INCENTIVE_POPIN) {
        dispatch(setUserAccountIncentivePopinDisplayed())
      } else if (payload?.modalId === MODAL_IDS.USER_ACCOUNT_INCENTIVE_ITINERARY_RESULTS) {
        dispatch(setUserAccountIncentiveItineraryResultsDisplayed())
      }
    }

    return next(action)
  }

export default [updateIncentiveTimestampsOnDisplay]
