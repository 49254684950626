import { NO_POLYLINE_DATA, requestPoiOnRoute } from '../../dataSource/itinerary/itinerary.poisOnRoute.requests'
import { POIS_ON_ROUTE_TYPES } from '../../domain/itinerary/poisonroute/poisOnRoute.constants'
import { parseGeoentityListResponse } from '../geoentity/geoentity.dataParser'
import {
  resetGeoentityList,
  setGeoentitiesRequested,
  setGeoentityList,
  setGeoentityNoResult
} from '../geoentity/geoentitySlice'
import { selectMapBbox, selectMapZoom } from '../map/map.selectors'
import { selectLocale } from '../navigation/navigation.selectors'
import { getGeoentityTypeDataForSearch } from '../poiassets/poiassets.selectors'
import { setSearchTerms } from '../search/searchSlice'
import { selectItineraryState } from './itinerary.selectors'
import { setPoisOnRoute } from './itinerarySlice'
import { selectActivePoisOnRoute } from './poisOnRoute.selectors'

const getType = (app, subcategory) => {
  if (app) return POIS_ON_ROUTE_TYPES.application
  if (subcategory) return POIS_ON_ROUTE_TYPES.subcategory
  return POIS_ON_ROUTE_TYPES.category
}

export const loadPoisOnRoute = appCategOrSubCateg => (dispatch, getState) => {
  const { app, category, subcategory } = appCategOrSubCateg
  const { appOrRubricIds, label } = getGeoentityTypeDataForSearch(getState(), appCategOrSubCateg)
  const type = getType(app, subcategory)

  dispatch(
    setPoisOnRoute({
      id: app || category || subcategory,
      rubric: appOrRubricIds,
      type,
      label
    })
  )

  dispatch(setSearchTerms(label))

  return execRequestPoisOnRoute(type, appOrRubricIds, dispatch, getState)
}

export const refreshPoisOnRoute = () => (dispatch, getState) => {
  const { type, rubric } = selectActivePoisOnRoute(getState())
  return execRequestPoisOnRoute(type, rubric, dispatch, getState)
}

const execRequestPoisOnRoute = (type, appOrRubricIds, dispatch, getState) => {
  dispatch(setGeoentitiesRequested())

  const state = getState()

  const itinerary = selectItineraryState(state)
  const bbox = selectMapBbox(state)
  const zoom = selectMapZoom(state)
  const locale = selectLocale(state)

  return requestPoiOnRoute(type, appOrRubricIds, itinerary, bbox, zoom, locale)
    .then(response => {
      dispatch(resetGeoentityList())
      return response
    })
    .then(response => {
      const { data } = response

      if (data && data.length > 0) {
        dispatch(
          setGeoentityList({
            pois: parseGeoentityListResponse({ pois: data })
          })
        )
      } else {
        dispatch(setGeoentityNoResult())
      }
    })
    .catch(e => {
      if (e.message !== NO_POLYLINE_DATA) {
        // Only show message if request has failed or showing no result
        dispatch(setGeoentityNoResult())
      }
    })
}
