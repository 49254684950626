import {
  BOOKING_SEARCH_EXTENSION_AID_DESKTOP,
  BOOKING_SEARCH_EXTENSION_AID_MOBILE,
  BOOKING_SEARCH_EXTENSION_LABEL_DESKTOP,
  BOOKING_SEARCH_EXTENSION_LABEL_MOBILE
} from '../../parameters'
import { changeDocumentLocation } from '../../store/history/history.utils'
import { BROWSER_STORAGE_BOOKING_SEARCH_EXTENSION_KEY } from '../../utils/browserStorageService'
import { isNumber } from '../../utils/number'
import { sendPublisherTag } from '../analytics/ATInternetService'
import { PUB_TAG, resolveClickPublisherTag } from '../analytics/PublisherTagsService'
import { isGE } from '../Device'
import { getBasePostCodeForParisLyonMarseille } from '../seo/seo'
import { getFutureDateISO, getTomorrowDateISO, ONE_DAY_MS } from '../utils/date'
import { getDistanceByFlyCrow } from '../utils/map'
import { selectArrivalStep, selectDepartureStep, selectFilledSteps } from './steps/steps.selectors'
import { isStepFilled } from './steps/steps.utils'

const RE_INSEECODE = /(-\d{5})/

const removeInseeCode = str => str.replace(RE_INSEECODE, '')

export const MINIMAL_DISTANCE_IN_KM = 50

export const setBookingSearchExtensionDisplayed = () => {
  localStorage.setItem(BROWSER_STORAGE_BOOKING_SEARCH_EXTENSION_KEY, Date.now())
}

export const shouldLaunchBookingSearchExtension = () => {
  const lastDisplayed = parseInt(localStorage.getItem(BROWSER_STORAGE_BOOKING_SEARCH_EXTENSION_KEY) ?? '0', 10)
  return Date.now() - lastDisplayed > ONE_DAY_MS
}

export const buildBookingUrl = (step, timestamp = new Date()) => {
  const aid = isGE() ? BOOKING_SEARCH_EXTENSION_AID_DESKTOP : BOOKING_SEARCH_EXTENSION_AID_MOBILE
  const label = isGE() ? BOOKING_SEARCH_EXTENSION_LABEL_DESKTOP : BOOKING_SEARCH_EXTENSION_LABEL_MOBILE

  const searchStr =
    (step?.town ?? false)
      ? `${step?.town ?? ''} ${getBasePostCodeForParisLyonMarseille(step?.postcode ?? '')}`
      : removeInseeCode(step?.label)

  const parameters = [
    `aid=${aid}`,
    `checkin=${getTomorrowDateISO(timestamp)}`,
    `checkout=${getFutureDateISO({ days: 2 }, timestamp)}`,
    `ss=${searchStr}`,
    `label=${label}-click_brand-partner`,
    'selected_currency=EUR'
  ]
  return `//booking.com/searchresults.html?${parameters.join('&')}`
}

class BookinSearchExtension {
  constructor() {
    this.popup = null
  }

  openPopup = ({ steps, idx, departureNow }) => {
    if (
      __BROWSER__ &&
      departureNow &&
      steps.length === 2 &&
      ((isStepFilled(selectDepartureStep(null, { steps })) && idx === 1) ||
        (isStepFilled(selectArrivalStep(null, { steps })) && idx === 0)) &&
      shouldLaunchBookingSearchExtension()
    ) {
      setBookingSearchExtensionDisplayed()
      this.popup = window.open('about:blank')
    }
  }

  closePopup = () => {
    if (this.popup) this.popup.close()
    this.popup = undefined
  }

  getFlyCrowDistanceBetweenSteps = steps => {
    if (steps && steps.length === 2) {
      const lat1 = steps?.[0]?.coordinates?.lat
      const lng1 = steps?.[0]?.coordinates?.lng
      const lat2 = steps?.[1]?.coordinates?.lat
      const lng2 = steps?.[1]?.coordinates?.lng
      if (isNumber(lat1) && isNumber(lng1) && isNumber(lat2) && isNumber(lng2)) {
        return getDistanceByFlyCrow(lng1, lat1, lng2, lat2)
      }
    }
    return 0
  }

  search = ({ steps }) =>
    new Promise((resolve, reject) => {
      try {
        const filledSteps = selectFilledSteps(null, { steps })
        if (
          this.popup &&
          filledSteps &&
          filledSteps.length === 2 &&
          this.getFlyCrowDistanceBetweenSteps(filledSteps) >= MINIMAL_DISTANCE_IN_KM
        ) {
          const lastStep = selectArrivalStep(null, { steps })
          const arrivalLabel = lastStep?.label
          const departureLabel = selectDepartureStep(null, { steps })?.label
          if (arrivalLabel && departureLabel) {
            sendPublisherTag(resolveClickPublisherTag(PUB_TAG.PUB_MULTIPATH_BOOKING_SEARCH_EXTENSION))
            this.popup.location = `/itineraire#/recherche/${encodeURIComponent(departureLabel)}/${encodeURIComponent(
              arrivalLabel
            )}`
            changeDocumentLocation(buildBookingUrl(lastStep))
            return reject(new Error('BookingSearchExtension redirect should stop execution')) // never throw if correctly redirected
          }
        } else {
          // if not enough, too much steps or steps too close, closing popup and continue search
          this.closePopup()
          return resolve()
        }
      } catch (e) {
        // on any problem, close the popup and let the search be launched as usual
        this.closePopup()
        return resolve()
      }
    })
}

export const BookingSearchExtensionClass = BookinSearchExtension
export default new BookinSearchExtension()
