import qs from 'qs'
import { flowRight } from '../../utils/function'

export const cleanOffQueryString = (url = '') => url.replace(/\?.*#/, '#').replace(/\?.*/, '')

export const cleanHashXtor = (url = '') => url.replace(/#xtor=.*/, '')

export const cleanDoubleSlash = (url = '') => url.replace(/\/\//g, '/').replace(/\/#\//g, '#/')

export const cleanUrlOffOptionalParams = flowRight(cleanDoubleSlash, cleanHashXtor, cleanOffQueryString)

export const sanitizeRoute = flowRight((route = '') => route.replace('#', ''), cleanUrlOffOptionalParams)

export const isUrlForSeo = (route = '') => !route.includes('#')

export const removeTrailingSlash = (url = '') => url.replace(/\/$/, '')

export const paramsSerializerRepeat = params => params && qs.stringify(params, { arrayFormat: 'repeat' })

export const paramsSerializerComma = params => params && qs.stringify(params, { arrayFormat: 'comma' })

export const containsOtherThanXtorHash = hash => !(!hash || hash.indexOf('#xtor') === 0)

export const isAbsoluteUrl = url => url.startsWith('https:') || url.startsWith('//')

export const forceProtocol = (url, forceTLS = false) => {
  if (forceTLS || __BROWSER__) {
    if (url.startsWith('http:')) return url.replace('http:', 'https:')
    if (url.startsWith('//')) return `https:${url}`
  } else {
    if (url.startsWith('//')) return `http:${url}`
  }
  return url
}

export const addParamsToUrl = (url = '', params) => {
  if (!params) return url
  const sep = url.includes('?') ? '&' : '?'
  return `${url}${sep}${paramsSerializerRepeat(params)}`
}
