import { MULTIPATH_MODE_TRAIN } from './itinerary.constants'
import { reorderTrainRoute } from '../../domain/itinerary/specialcases/itinerary.specialcases.utils'

let routeNextId = 1

export const resetForUnitTest = () => {
  routeNextId = 1
}

// Ideally, we would use a routeId sent by server, not build one ourselve...
export const decorateRouteWithId = route => ({
  routeId: routeNextId++,
  ...route
})

export const decorateRouteWithMode = mode => route => ({
  mode,
  ...route
})

export const extractRoutes = (response, mode) => {
  const routes = (response?.data?.routes ?? []).map(decorateRouteWithId).map(decorateRouteWithMode(mode))

  if (MULTIPATH_MODE_TRAIN === mode) return reorderTrainRoute(routes)

  return routes
}

export const extractColors = data => data?.colors
