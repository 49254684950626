import { POIS_ON_ROUTE_TYPES } from '../../domain/itinerary/poisonroute/poisOnRoute.constants'
import { selectArrivalStep, selectDepartureStep } from '../../domain/itinerary/steps/steps.selectors'
import {
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_COMPUTING_BY_MODE,
  ROUTE_ITINERARY_EXPENSE_REPORT,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY
} from '../../routes'
import { isEqual } from '../../utils/lang'
import {
  selectCurrentProvider,
  selectItinerarySortQueryStringParams,
  selectProviders
} from '../itinerary/itinerary.selectors'
import { selectActivePoisOnRoute, selectHasPoisOnRoute } from '../itinerary/poisOnRoute.selectors'
import { selectCurrentRouteId } from '../itinerary/routes.selectors'
import { selectItineraryTimeQueryStringParams } from '../itineraryOptions/itineraryTimeOptions.selectors'

export const getItineraryParams = ({ routeOptions, route, state, selectedStoreState }) => {
  const paramsFunctions = {
    [ROUTE_ITINERARY_COMPUTING]: [getFromToObj],
    [ROUTE_ITINERARY_COMPUTING_BY_MODE]: [getFromToObj, getModeProviderObj],
    [ROUTE_ITINERARY_RESULTS_BY_PROVIDER]: [getFromToObj, getModeProviderObj],
    [ROUTE_ITINERARY_RESULTS_BY_ROUTE]: [getFromToObj, getModeProviderObj, getRouteIdObj],
    [ROUTE_ITINERARY_ROADBOOK]: [getFromToObj, getModeProviderObj, getRouteIdObj],
    [ROUTE_ITINERARY_EXPENSE_REPORT]: [getFromToObj, getModeProviderObj, getRouteIdObj],
    [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP]: [getFromToObj, getModeProviderObj, getRouteIdObj, getPoiOnRouteObj],
    [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY]: [
      getFromToObj,
      getModeProviderObj,
      getRouteIdObj,
      getPoiOnRouteObj
    ],
    [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY]: [
      getFromToObj,
      getModeProviderObj,
      getRouteIdObj,
      getPoiOnRouteObj
    ],
    [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP]: [
      getFromToObj,
      getModeProviderObj,
      getRouteIdObj,
      getPoiOnRouteObj
    ],
    [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY]: [
      getFromToObj,
      getModeProviderObj,
      getRouteIdObj,
      getPoiOnRouteObj
    ],
    [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY]: [
      getFromToObj,
      getModeProviderObj,
      getRouteIdObj,
      getPoiOnRouteObj
    ]
  }

  return applyParams(routeOptions, state, selectedStoreState, paramsFunctions[route])
}

const getRedirectParamFromRouteOptions = routeOptions => {
  if (routeOptions?.redirect)
    return {
      redirect: routeOptions.redirect
    }
  return {}
}

export const getItineraryQueryStringParams = ({ state, routeOptions }) => {
  return {
    ...selectItineraryTimeQueryStringParams(state),
    ...selectItinerarySortQueryStringParams(state),
    ...getRedirectParamFromRouteOptions(routeOptions)
  }
}

const applyParams = (routeOptions, state, selectedStoreState, functions) => {
  const params = (functions || []).map(fn => fn(state, selectedStoreState, routeOptions))
  return Object.assign({}, ...params)
}

export const getFromToObj = (state, _, routeOptions) => {
  if (routeOptions?.from && routeOptions?.to)
    return {
      from: routeOptions.from,
      to: routeOptions.to
    }
  const [fromLabel, toLabel] = [selectDepartureStep(state), selectArrivalStep(state)].map(({ label }) => label)

  return { from: fromLabel, to: toLabel }
}

const getModeProviderObj = (state, selectedStoreState, routeOptions) => {
  if (routeOptions?.mode) return { mode: routeOptions.mode }
  const providers = selectProviders(state)
  const provider = selectedStoreState?.provider ?? selectCurrentProvider(state)
  const mode = selectedStoreState?.mode ?? getCurrentModeByProvider(providers, provider)
  return { mode, provider }
}

const getRouteIdObj = (state, selectedStoreState) => {
  const routeId = getDesiredRouteId(state, selectedStoreState)
  return { routeId }
}

const getDesiredRouteId = (state, selectedStoreState) => {
  return selectedStoreState?.routeId ?? selectCurrentRouteId(state)
}

const getCurrentModeByProvider = (providers, lookedupProvider) => {
  return (providers || []).find(p => p.name === lookedupProvider)?.mode
}

export const pickFromTo = ({ params: { from, to } }) => ({ from, to })

export const hasFromToChanged = ({ prevRoute, nextRoute }) => !isEqual(pickFromTo(prevRoute), pickFromTo(nextRoute))

export const getPoiOnRouteObj = (state, _, routeOptions) => {
  const geoentityId = routeOptions?.geoentityId ? { geoentityId: routeOptions.geoentityId } : {}

  if (routeOptions?.app) return { app: routeOptions.app, ...geoentityId }
  if (routeOptions?.category) return { category: routeOptions.category, ...geoentityId }
  if (routeOptions?.subcategory) return { subcategory: routeOptions.subcategory, ...geoentityId }

  if (selectHasPoisOnRoute(state)) {
    const { id, type } = selectActivePoisOnRoute(state)
    if (type === POIS_ON_ROUTE_TYPES.application) return { app: id, ...geoentityId }
    else if (type === POIS_ON_ROUTE_TYPES.category) return { category: id, ...geoentityId }
    else if (type === POIS_ON_ROUTE_TYPES.subcategory) return { subcategory: id, ...geoentityId }
  }
  return {}
}
