export const COUNTRIES_CODES = {
  FRANCE: '250',
  BELGIUM: '056',
  UNITED_KINGDOM: '826'
}

export const isFranceCountryCode = code => parseInt(code, 10) === parseInt(COUNTRIES_CODES.FRANCE, 10)

export const isBelgiumCountryCode = code => parseInt(code, 10) === parseInt(COUNTRIES_CODES.BELGIUM, 10)

export const isFranceOrBelgiumCountryCode = code => isFranceCountryCode(code) || isBelgiumCountryCode(code)
