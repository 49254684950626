import { setStepLocation } from '../../itinerary/itinerarySlice'
import { saveCurrentSearchResult } from '../searchSlice'

import { SET_STEP_FROM } from '../../itinerary/itinerary.constants'

import SentryService from '../../../domain/error/SentryService'
import { loadSingleAddressComputeRadiusSuccess, loadSingleAddressSuccess } from '../../address/addressSlice'
import { setSingleGeoentity } from '../../geoentity/geoentitySlice'
import { getStepDataFromActionPayload } from './searchHistory.utils'

export const searchHistoryPersistorMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case loadSingleAddressSuccess.type:
      case loadSingleAddressComputeRadiusSuccess.type:
      case setStepLocation.type:
        try {
          const { label, split_label, type } = getStepDataFromActionPayload(action.type, action.payload)
          const from = action?.payload?.options?.from
          if (from !== SET_STEP_FROM.mapAction) {
            dispatch(
              saveCurrentSearchResult({
                label,
                split_label,
                type
              })
            )
          }
        } catch (error) {
          SentryService.captureException(error)
        }
        break
      case setSingleGeoentity.type:
        try {
          const { label, split_label, type } = action.payload
          dispatch(
            saveCurrentSearchResult({
              date: Date.now(),
              label,
              split_label,
              type
            })
          )
        } catch (error) {
          SentryService.captureException(error)
        }
        break
    }
    return next(action)
  }

export default [searchHistoryPersistorMiddleware]
