import { createSlice } from '@reduxjs/toolkit'
import { isPE } from '../../domain/Device'

const initialState = {
  device: isPE() ? 'pe' : 'ge',
  locale: 'fr-FR',
  isOnBoardingContext: true,
  visitCount: 0
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.device = action.payload
    },
    setLocale: (state, action) => {
      state.locale = action.payload
    },
    toggleOffOnboardingContext: (state, action) => {
      state.isOnBoardingContext = false
    },
    incrementVisitCount: (state, action) => {
      state.visitCount = (state.visitCount ?? 0) + 1
    }
  }
})

export const { setDevice, setLocale, toggleOffOnboardingContext, incrementVisitCount } = navigationSlice.actions

export default navigationSlice
