import { removeDiacritics, removeHtmlEmSpanAndITags } from '../../utils/string'

export const normalizeInputValueKeyword = inputValue =>
  removeDiacritics(inputValue.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&').toLowerCase())

export const isLabelMatchingInputValueKeyword = (normalizedCurrentSearch, label) => {
  const lowerCasedLabel = ' ' + removeDiacritics(label).toLowerCase()
  const matches = lowerCasedLabel.match(new RegExp('(\\W)' + normalizedCurrentSearch, 'g'))

  return Boolean(matches && matches.length > 0)
}

export const formatLabelWithInputValueKeyword = (normalizedCurrentSearch, inputValue) => label => {
  const lowerCasedLabel = ' ' + removeDiacritics(label).toLowerCase()
  const matches = lowerCasedLabel.match(new RegExp('(\\W)' + normalizedCurrentSearch, 'g'))

  if (!matches || !matches.length) return label

  const foundPosition = lowerCasedLabel.indexOf(matches[0])

  const matchElement = label.substring(foundPosition, inputValue.length + foundPosition)
  return label.replace(matchElement, '<em>' + matchElement + '</em>')
}

export const normalizeLabel = label => removeHtmlEmSpanAndITags(label).replace('<br/>', ' ').toLowerCase()

export const formatLabelToInline = labels => (labels || []).map(removeHtmlEmSpanAndITags).join(' ')
