import { getDistanceValue, getPageActiveObj, replaceValues } from '../../domain/analytics/analyticsUtils'
import { sendPublisherTag } from '../../domain/analytics/ATInternetService'
import { resolveClickPublisherTag, resolveImpressionPublisherTag } from '../../domain/analytics/PublisherTagsService'
import { getProviderMode } from '../itinerary/itinerary.utils'
import { selectCurrentItineraryRoute } from '../itinerary/routes.selectors'

export const preparePublisherTag = ({ tagInfo, getState }) => {
  const { PageActive } = getPageActiveObj({ store: getState() })
  if (!PageActive) return

  const tagParamValues = {
    PageActive,
    ...tagInfo
  }

  return replaceValues(tagInfo.tag, tagParamValues)
}

export const configureAndSendPublisherTag = ({ action, getState }) => {
  const { tagInfo, isImpression } = action

  const preparedTag = preparePublisherTag({ tagInfo, getState })
  if (!preparedTag) return
  const resolveTag = isImpression ? resolveImpressionPublisherTag : resolveClickPublisherTag
  const resolvedTag = resolveTag(preparedTag)

  sendPublisherTag(resolvedTag)
}

export const getMultipathPublisherPredefinedTagInfo = ({ provider, ...otherTagInfo }, storeState) => {
  const { itinerary } = storeState
  const currentProvider = provider || itinerary.currentProvider
  const currentRoute = selectCurrentItineraryRoute(storeState)
  const length = currentRoute?.length?.value
  const distance = getDistanceValue(length)

  return {
    provider: currentProvider,
    mode: getProviderMode({
      ...itinerary,
      currentProvider
    }),
    distance,
    selectionslider: getProviderMode(itinerary),
    ...otherTagInfo
  }
}
