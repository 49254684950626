import { createSlice } from '@reduxjs/toolkit'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'

const initialState = {
  inputValue: '',
  serviceSuggestions: [],
  error: null,
  lastRequestTime: 0
}

const suggestSlice = createSlice({
  name: 'suggest',
  initialState,
  reducers: {
    setSuggestInputValue: (state, action) => {
      state.inputValue = action.payload
    },
    loadSuggestResponse: (state, action) => {
      const { data, requestTime } = action.payload
      state.serviceSuggestions = data.suggests
      state.lastRequestTime = requestTime
    },
    loadSuggestError: (state, action) => {
      state.error = parseErrorFromReduxAction(action)
      state.serviceSuggestions = []
      state.lastRequestTime = action.payload.requestTime
    },
    resetSuggestData: (state, action) => {
      return { ...initialState }
    }
  }
})

export const { setSuggestInputValue, loadSuggestResponse, loadSuggestError, resetSuggestData } = suggestSlice.actions

export default suggestSlice
