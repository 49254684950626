import { createSlice } from '@reduxjs/toolkit'
import { take, uniqBy } from '../../utils/array'
import { flow } from '../../utils/function'
import { selectSearchTerms } from './search.selectors'

const initialState = {
  parameters: {},
  searchHistory: [],
  terms: ''
}

export const SEARCH_HISTORY_SIZE = 50

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchParameters: (state, action) => {
      state.parameters = action.payload ?? {}
    },
    resetSearchParameters: (state, action) => {
      state.parameters = {}
    },
    saveCurrentSearchResult: (state, action) => {
      const searchHistory = flow(
        array => uniqBy(array, item => item.label),
        array => take(array, SEARCH_HISTORY_SIZE)
      )([{ date: Date.now(), ...action.payload }, ...state.searchHistory])

      state.searchHistory = searchHistory
    },
    resetSearchHistory: (state, action) => {
      state.searchHistory = initialState.searchHistory
    },
    setSearchTerms: (state, action) => {
      if (selectSearchTerms({ search: state }) !== action.payload) state.terms = action.payload
    },
    resetSearchTerms: (state, action) => {
      state.terms = ''
    }
  }
})

export const {
  setSearchParameters,
  resetSearchParameters,
  saveCurrentSearchResult,
  resetSearchHistory,
  setSearchTerms,
  resetSearchTerms
} = searchSlice.actions

export default searchSlice
