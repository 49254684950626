import { generateFullRoute } from '../router/routeUtils'
import { searchServiceCtx } from '../../store/search/search.service.constants'
import { pick } from '../../utils/object'
import { head } from '../../utils/array'
import { getSubDomainByLocale, LOCALES } from '../i18n'
import { isCityWithDistrict } from '../utils/location'
import { getDomain } from '../../parameters'
import { DIRECTORY_LINKS_PER_PAGE } from './seo.constants'
import { translate } from '../i18n/T'
import CityService from './CityService'
import { isStringAPositiveNumber } from '../../utils/number'
import { slugify } from '../../utils/string'
import { MULTIPATH_MODE_WALK, MULTIPATH_MODES } from '../../store/itinerary/itinerary.constants'

const NB_FIRST_ITINERARIES = 20

export const INSIDE_TOWN_GEOCODE_LEVELS = [
  searchServiceCtx.geocodeLevel.way,
  searchServiceCtx.geocodeLevel.addressPoint,
  searchServiceCtx.geocodeLevel.district
]

export const citiesWithDistricts = [
  { town: 'Paris', postcode: '75000' },
  { town: 'Marseille', postcode: '13000' },
  { town: 'Lyon', postcode: '69000' }
]

export const isMainZipForCityWithDistricts = zip => citiesWithDistricts.some(({ postcode }) => postcode === zip)

export const getTownForDistrict = location => {
  return citiesWithDistricts.find(({ postcode }) => postcode.substring(0, 2) === location.postcode.substring(0, 2))
}

export const getPostCodeFromTown = location => {
  return citiesWithDistricts.find(({ town }) => town === location)?.postcode
}

const getRawData = currentMode => {
  if (__BROWSER__) {
    console.warn('getRawData was called from browser')
    return []
  } else if (__SERVER__) {
    try {
      return require(`../../node/static/seo/seo_itineraries_${currentMode}.json`)
    } catch (e) {
      console.error(`error while requiring seo_itineraries_${currentMode}.json`, e)
      return []
    }
  }
}

export const getDirectoryContent = (rawData, pageIdx) => {
  const pageOffset = pageIdx - 1
  const startOffset = pageOffset * DIRECTORY_LINKS_PER_PAGE
  if (rawData.length > startOffset) {
    return rawData.slice(startOffset, startOffset + DIRECTORY_LINKS_PER_PAGE)
  }
  return []
}

export const getDirectoryCities = pageIdx => {
  const rawData = CityService.getAllCitiesByPoi({ locale: LOCALES.fr_FR })
  return getDirectoryContent(rawData, pageIdx)
}

export const getDirectoryItineraries = (currentMode, pageIdx) => {
  const rawData = getRawData(currentMode).slice(DIRECTORY_LINKS_PER_PAGE)
  return getDirectoryContent(rawData, pageIdx)
}

export const getCitiesCountForDirectory = () => CityService.getAllCitiesByPoi({ locale: LOCALES.fr_FR }).length

export const getFirstItineraries = currentMode => {
  const rawData = getRawData(currentMode)
  if (rawData.length > 0) {
    return rawData.slice(0, NB_FIRST_ITINERARIES)
  }
}

export const getItineraries = (currentMode, nb = 50) => {
  const rawData = getRawData(currentMode)
  if (rawData.length > 0) {
    return rawData.slice(0, nb)
  }
}

export const getNextItineraries = (currentMode, count) => {
  const rawData = getRawData(currentMode)
  const MAX = 200
  if (rawData.length > 0) {
    const end = count ? NB_FIRST_ITINERARIES + count : MAX
    return rawData.slice(NB_FIRST_ITINERARIES, end)
  }
  return []
}

export const getItinerariesTotalCount = multipathMode => {
  return getRawData(multipathMode).length
}

export const getItinerariesCountForDirectory = multipathMode => {
  const totalItinerariesCount = getItinerariesTotalCount(multipathMode)
  if (totalItinerariesCount < DIRECTORY_LINKS_PER_PAGE) return totalItinerariesCount
  return totalItinerariesCount - DIRECTORY_LINKS_PER_PAGE
}

export const getItinerariesFromOrToZip = (currentMode, postcode, limit = 10) => {
  const rawData = getRawData(currentMode)
  if (rawData.length > 0) {
    return rawData.filter(({ toZip, fromZip }) => postcode === toZip || postcode === fromZip).slice(0, limit)
  }
}

export const getItineraryForFromAndToZip = (currentMode, fromPostcode, toPostcode) => {
  const rawData = getRawData(currentMode)
  return rawData.find(({ fromZip, toZip }) => fromZip === fromPostcode && toZip === toPostcode)
}

export const getItinerariesForFromAndToZip = (excludedMode, fromPostcode, toPostcode) =>
  MULTIPATH_MODES.filter(mode => mode !== excludedMode)
    .map(mode => ({ mode, link: getItineraryForFromAndToZip(mode, fromPostcode, toPostcode) }))
    .filter(({ link }) => Boolean(link))

export const getItineraryForFromAndToUrl = (currentMode, from, to) => {
  const regex = '/itineraire/[^/]+/(.+)'
  const rawData = getRawData(currentMode)
  const formattedUrl = `/itineraire/${from}/${to}`

  return rawData.find(({ url }) => {
    const fromToGroup = url.match(regex)?.[1]
    const itineraryWithoutModeUrl = `/itineraire/${fromToGroup}`
    return formattedUrl === itineraryWithoutModeUrl
  })
}

export const getItinerariesForFromAndToUrl = (excludedMode, from, to) =>
  MULTIPATH_MODES.filter(mode => mode !== excludedMode)
    .map(mode => ({ mode, link: getItineraryForFromAndToUrl(mode, from, to) }))
    .filter(({ link }) => Boolean(link))

export const getItinerariesToZip = (currentMode, postcode, limit = 10) => {
  const rawData = getRawData(currentMode)
  if (rawData.length > 0) {
    return rawData.filter(({ toZip }) => postcode === toZip).slice(0, limit)
  }
}

export const getSeoCityPostcode = (postcode = '') => {
  if (isCityWithDistrict(postcode)) {
    return `${postcode.substring(0, 2)}000`
  }
  return postcode
}

export const getSeoStepForLandingPage = step => {
  const seoStep = pick(step, ['type', 'town', 'postcode', 'geocode_level'])
  seoStep.postcode = getSeoCityPostcode(seoStep.postcode)
  if (INSIDE_TOWN_GEOCODE_LEVELS.includes(seoStep.geocode_level)) {
    seoStep.label = `${head(step.split_label)}, ${seoStep.town}`
  } else {
    seoStep.label = head(step.split_label)
  }
  return seoStep
}

export const getSeoTownFromLocation = (location = {}) => {
  const { town, towncode, postcode, named_place, split_label, countryCode } = location
  if (!town || !towncode || !postcode) return null
  const pc = location.geocode_level === searchServiceCtx.geocodeLevel.town ? getSeoCityPostcode(postcode) : postcode
  let t = town
  if (location.geocode_level === searchServiceCtx.geocodeLevel.namedPlace) {
    t = named_place
  } else if (location.geocode_level === searchServiceCtx.geocodeLevel.district) {
    t = split_label.join(' ')
  }
  return {
    town: t,
    towncode,
    postcode: pc,
    countryCode
  }
}

export const getBasePostCodeForParisLyonMarseille = postcode => {
  if (postcode === '75001') return '75000'
  if (postcode === '69001') return '69000'
  if (postcode === '13001') return '13000'
  return postcode
}

export const generateAbsoluteBaseUrl = (locale = LOCALES.fr_FR) =>
  `https://${getSubDomainByLocale(locale)}.${getDomain()}.com`

export const generateAbsoluteRoute = (route, params = {}, locale) =>
  `${generateAbsoluteBaseUrl(locale)}${generateFullRoute(route, params)}`

const shouldDisplayPostcode = step =>
  step.postcode &&
  (step.geocode_level === searchServiceCtx.geocodeLevel.town || INSIDE_TOWN_GEOCODE_LEVELS.includes(step.geocode_level))

export const formatH2TitleFromStep = step => `${step.label}${shouldDisplayPostcode(step) ? ` (${step.postcode})` : ''}`

export const getModeLabel = (multipathMode, locale = LOCALES.fr_FR) =>
  translate(locale)(`itinerary.modes.${multipathMode}`)

export const getModeLabelWithPrefix = multipathMode =>
  `${multipathMode !== MULTIPATH_MODE_WALK ? 'en ' : ''}${getModeLabel(multipathMode, LOCALES.fr_FR).toLowerCase()}`

export const isPageIdxValid = idx => {
  return isStringAPositiveNumber && idx.match(/^\d+$/)
}

export const getTownNameWithoutDistrict = (postcode, town, district) =>
  district ? getTownForDistrict({ postcode }).town : town

export const formatLinkForSeoAddressPage = (postcode, town, district) =>
  slugify(`${postcode}-${getTownNameWithoutDistrict(postcode, town, district)}`)

export const isZipAndCityDifferentThanAddress = (address, zip, city) => {
  const { town, postcode } = address
  return (postcode !== zip || slugify(town) !== city) && !isCityWithDistrict(postcode)
}

export const slugifyTown = town => slugify(town).replace(/^(\d+)eme-/, '$1e-')

export const isDepartmentOverseas = departmentCode => departmentCode?.slice(0, 2) >= '97'
