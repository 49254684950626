export const UI_ELEMENTS = {
  SEARCH_DIALOG: 'searchDialog',
  SUGGEST: 'suggest',
  BACK_BUTTON: 'backButton',
  MENU_PE: 'menuPE',
  MAP_TOOLS_PE: 'mapToolsPE',
  ITINERARY_OPTIONS_DIALOG: 'itineraryOptionsDialog',
  ITINERARY_TIME_OPTIONS_DIALOG: 'itineraryTimeOptionsDialog',
  CMP: 'cmp',
  POPIN: 'popin',
  MODAL: 'modal',
  AD_BANNER: 'adBanner',
  USER: 'user'
}

export const USER_SCREENS = {
  default: 'default', // welcome (anonymous) || menu (connected)
  favorites: 'favorites',
  manageFavorite: 'manageFavorite',
  itineraryOptions: 'itineraryOptions'
}

export const MODAL_IDS = {
  AUTO_PROMO_POPIN: 'autopromo_popin',
  CRITAIR: 'critair',
  IMAGE_GALLERY: 'gallerie_image',
  POIS_ON_ROUTE_INCENTIVE_POPIN: 'pois_on_route_incentive_popin',
  SHARE_ITINERARY_POPIN: 'share_itinerary_popin',
  SHARE_POI_POPIN: 'share_poi_popin',
  PHONE: 'telephone',
  USER_ACCOUNT_INCENTIVE_POPIN: 'user_account_incentive_popin',
  USER_ACCOUNT_INCENTIVE_ITINERARY_RESULTS: 'user_account_incentive_itinerary_results',
  ROADBOOK_REFRESH_POPIN: 'roadbookRefreshPopin'
}
