import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectFirstAddress, selectSelectedAddressLabelForUrl } from '../address/address.selectors'
import {
  selectCurrentGeoentityId,
  selectSelectedGeoentity,
  selectSelectedGeoentityLabelForUrl
} from '../geoentity/geoentity.selectors'
import { POI } from '../search/location.types'

export const getSelectedLocation = (store, props = {}) => {
  if (props.location) return props.location

  return selectCurrentGeoentityId(store) ? selectSelectedGeoentity(store) : selectFirstAddress(store)
}

export const selectSelectedLocationCoordinates = createCustomSelector(
  getSelectedLocation,
  location => location?.coordinates
)

export const selectSelectedLocationLabelForUrl = createCustomSelector(getSelectedLocation, location =>
  location.type === POI
    ? selectSelectedGeoentityLabelForUrl(null, { geoentity: location }) // FIXME	NES-1768
    : selectSelectedAddressLabelForUrl(null, { address: location })
)
