import { createSlice } from '@reduxjs/toolkit'

import { AB_TEST_STATUS_INITIAL, AB_TEST_STATUS_LOADING, AB_TEST_STATUS_READY } from './abtest.constants'

const initialState = {
  status: AB_TEST_STATUS_INITIAL,
  config: [],
  enrollment: []
}

const abtestSlice = createSlice({
  name: 'abtest',
  initialState,
  reducers: {
    setAbTestLoading: (state, action) => {
      state.status = AB_TEST_STATUS_LOADING
    },
    setAbTestConfig: (state, action) => {
      state.config = action.payload
    },
    setAbTestEnrollment: (state, action) => {
      state.enrollment = action.payload
    },
    setAbTestReady: (state, action) => {
      state.status = AB_TEST_STATUS_READY
    }
  }
})

export const { setAbTestLoading, setAbTestConfig, setAbTestEnrollment, setAbTestReady } = abtestSlice.actions

export default abtestSlice
