import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectHasVdeGeoentities } from '../../store/geoentity/geoentity.selectors'
import {
  navigateToSearchByApp,
  navigateToSearchByRubric,
  navigateToSearchBySubcategory
} from '../../store/history/history.actions'
import { isPE } from '../Device'
import { SPD_PUSH_LINK, tagBi } from '../analytics/BiTagger'
import { useAction } from '../utils/react-redux'
import { useAdData } from './ad.utils'
import { REDIRECT_TYPE, TYPE_VDE } from './vde.constants'
import { navigateToExternalUrl } from '../../store/history/historySlice'

const hasGeoentitiesAppOrRubricIds = adData => {
  const { subcategoryId, vdeAppId, editoRubricIds } = adData?.vde ?? {}
  return Boolean(subcategoryId || vdeAppId || editoRubricIds)
}

export const useShouldDisplayVde = adId => {
  const data = useAdData(adId)
  const selectHasGeoentities = useSelector(selectHasVdeGeoentities)
  const shouldHaveGeoentities = hasGeoentitiesAppOrRubricIds(data)
  return Boolean(
    data?.vde && (isPE() || selectHasGeoentities || (data.vde.type === TYPE_VDE.EDITO && !shouldHaveGeoentities))
  )
}

export const useSendVdeBucTag = adId => {
  const shouldDisplayVde = useShouldDisplayVde(adId)
  const data = useAdData(adId)
  return useCallback(
    bucTag => {
      if (!shouldDisplayVde) return

      const {
        vde: { type, subcategoryId, vdeAppId, editoRubricIds }
      } = data

      tagBi(bucTag, { type, ids: vdeAppId || subcategoryId || editoRubricIds })
    },
    [shouldDisplayVde]
  )
}

export const useClickVde = adId => {
  const dispatch = useDispatch()
  const data = useAdData(adId)
  const { link, targetBlank, app, subcategory, rubric, redirectType } = useVdeLinkAndTags(adId)
  const sendBucTag = useSendVdeBucTag(adId)

  const execNavigateToSearchByApp = useAction(navigateToSearchByApp)
  const execNavigateToSearchBySubcategory = useAction(navigateToSearchBySubcategory)
  const execnavigateToSearchByRubric = useAction(navigateToSearchByRubric)

  return useCallback(() => {
    const {
      vde: { vdeTrackUrl }
    } = data
    sendBucTag(SPD_PUSH_LINK)
    new Image().src = vdeTrackUrl

    switch (redirectType) {
      case REDIRECT_TYPE.APP:
        execNavigateToSearchByApp(app)
        break
      case REDIRECT_TYPE.SUBCATEGORY:
        execNavigateToSearchBySubcategory(subcategory)
        break
      case REDIRECT_TYPE.RUBRIC:
        execnavigateToSearchByRubric(rubric)
        break
      case REDIRECT_TYPE.EDITO:
        if (targetBlank) window.open(link, targetBlank)
        else dispatch(navigateToExternalUrl({ url: link }))
        break
    }
  }, [
    data,
    redirectType,
    app,
    subcategory,
    rubric,
    targetBlank,
    link,
    execNavigateToSearchByApp,
    execNavigateToSearchBySubcategory,
    execnavigateToSearchByRubric,
    dispatch
  ])
}

export const getVdeLinkAndTags = (vdeData = {}) => {
  const { type, vdeAppId, subcategoryId, editoLinkUrl, editoLinkTarget, editoRubricIds } = vdeData

  if (type === TYPE_VDE.EDITO) {
    return {
      link: editoLinkUrl,
      targetBlank: Boolean((editoLinkTarget || '').match('blank')),
      tagVdeType: 'pushEdito',
      rubric: editoRubricIds,
      redirectType: editoRubricIds ? REDIRECT_TYPE.RUBRIC : REDIRECT_TYPE.EDITO
    }
  } else if (type === TYPE_VDE.VDE) {
    return {
      tagVdeType: 'pushVDE',
      app: vdeAppId,
      redirectType: REDIRECT_TYPE.APP
    }
  } else if (type === TYPE_VDE.SUBCATEGORY) {
    return {
      tagVdeType: 'pushSsCat',
      subcategory: subcategoryId,
      redirectType: REDIRECT_TYPE.SUBCATEGORY
    }
  }
  return {}
}

export const useVdeLinkAndTags = adId => {
  const data = useAdData(adId)
  if (!data || !data.vde) return {}
  return getVdeLinkAndTags(data.vde)
}
