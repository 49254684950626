import { MAP_RASTER_ZOOM_MAX, MAP_VECTOR_ZOOM_MAX } from '../../domain/map/map.constants'
import { parseDate } from '../../domain/utils/date'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { getApiKeyHeader, getBonjourDomain, getService } from '../../parameters'
import { ROUTE_ADDRESS, ROUTE_ITINERARY_RESULTS_BY_ROUTE, ROUTE_SEO_ADDRESS } from '../../routes'
import SERVICES from '../../services.constants'
import { pick } from '../../utils/object'
import {
  selectCurrentHistoryRoute,
  selectIsAGeoentityOnSearchPage,
  selectIsAGeoentityPage,
  selectIsAnItineraryRoadbookPage,
  selectIsDepartmentPage,
  selectIsQuizPage,
  selectIsRegionPage,
  selectIsZonePage
} from '../history/history.selectors'
import {
  MAP_EVENTS_STATUS,
  MAP_MODES,
  MAP_STATUS_NONE,
  MAP_STATUS_STYLED,
  MAP_STYLES_KEY_NAMES,
  MAP_THEMES
} from './map.constants'

export const selectMapState = store => store?.map

export const selectIsVectorMap = createCustomSelector(selectMapState, map => map.vecto)

export const selectMapStatus = createCustomSelector(selectMapState, state => state?.status)

export const selectAllMapEvents = createCustomSelector(selectMapState, map => map?.mapEvents ?? [])

export const selectActiveMapEvents = createCustomSelector(selectAllMapEvents, mapEvents =>
  mapEvents.filter(({ visible, status }) => visible && status === MAP_EVENTS_STATUS.ongoing)
)

export const selectMapEventPopin = createCustomSelector(selectMapState, map => map?.eventPopin)

export const selectMapTheme = createCustomSelector(selectMapState, state => state?.theme)

export const selectMapMode = createCustomSelector(selectMapState, selectActiveMapEvents, (state, mapEvents) => {
  const mode = state?.mode
  const defaultMode = { mode: MAP_MODES.neutral, static: true }
  if (!mode) return defaultMode

  const staticModes = Object.keys(MAP_MODES)
  if (staticModes.includes(mode)) return { mode, static: true }

  const dynamicModes = mapEvents.map(({ key }) => key)
  if (dynamicModes.includes(state?.mode)) return { mode, static: false }

  return defaultMode
})

export const selectIsMapInitialized = createCustomSelector(selectMapStatus, status => status !== MAP_STATUS_NONE)

export const selectIsMapReady = createCustomSelector(selectMapStatus, status => status === MAP_STATUS_STYLED)

export const selectIsMapFullScreen = createCustomSelector(selectMapState, map => map?.fullscreen)

export const selectCouldMapFullScreen = createCustomSelector(selectIsQuizPage, isQuiz => !isQuiz)

export const selectShouldDisplayToggleFullScreenGeButton = createCustomSelector(selectCurrentHistoryRoute, route =>
  [ROUTE_ADDRESS, ROUTE_SEO_ADDRESS, ROUTE_ITINERARY_RESULTS_BY_ROUTE].includes(route)
)

export const selectMapBbox = createCustomSelector(selectMapState, map => map?.bbox)
export const selectMapCenter = createCustomSelector(selectMapState, map => map?.center)
export const selectMapZoom = createCustomSelector(selectMapState, map => map?.zoom)
export const selectMapActiveBbox = createCustomSelector(selectMapState, map => map?.activeBbox)
export const selectMapSearchBbox = createCustomSelector(selectMapState, map => map?.searchBbox)

export const selectMapEventDate = createCustomSelector(selectMapState, map => map?.mapEventDate)
export const selectMapEventTime = createCustomSelector(selectMapState, map => map?.mapEventTime)

const selectOptionalDateTimeParameter = createCustomSelector(
  selectMapEventDate,
  selectMapEventTime,
  (mapEventDate, mapEventTime) => {
    if (mapEventDate && mapEventTime) {
      const d = parseDate(`${mapEventDate}T${mapEventTime}:00.000`)
      return `?current_time=${d.toISOString()}`
    }
    return ''
  }
)

export const selectMapService = createCustomSelector(
  selectIsRegionPage,
  selectIsDepartmentPage,
  selectIsQuizPage,
  selectIsVectorMap,
  selectMapTheme,
  selectMapMode,
  selectOptionalDateTimeParameter,
  (regionPage, departmentPage, quizPage, vector, mapTheme, mapMode, optionalDateTimeParameter) => {
    if (quizPage) {
      return { url: '/front-services/quiz/style', ...getApiKeyHeader() }
    } else if (regionPage) {
      return getService(SERVICES.MAP, '/1.0/vector/simplified_map_states.json')
    } else if (departmentPage) {
      return getService(SERVICES.MAP, '/1.0/vector/simplified_map_counties.json')
    } else if (!vector) {
      return getService(SERVICES.MAP, '/1.0/vector/full_style_web_v1.json')
    } else if (mapMode.mode === MAP_MODES.neutral) {
      return getService(SERVICES.MAP, `/1.0/vector/${MAP_STYLES_KEY_NAMES[mapTheme]}.json`)
    } else if (!mapMode.static) {
      return {
        url: `https://map-event.${getBonjourDomain()}.bonjour-ratp.fr/api/1.0/mappy/styles/${
          MAP_STYLES_KEY_NAMES[mapTheme]
        }_${mapMode.mode}.json${optionalDateTimeParameter}`,
        ...getApiKeyHeader()
      }
    } else {
      return getService(
        SERVICES.MAP,
        `/1.0/vector/${MAP_STYLES_KEY_NAMES[mapTheme]}_${MAP_STYLES_KEY_NAMES[mapMode.mode]}.json`
      )
    }
  }
)

export const selectMapTargetData = createCustomSelector(selectMapState, data =>
  pick(data, ['targetBbox', 'targetCenter', 'targetOptions', 'targetZoom'])
)

export const selectMapMaxZoom = createCustomSelector(selectIsVectorMap, vector =>
  vector ? MAP_VECTOR_ZOOM_MAX : MAP_RASTER_ZOOM_MAX
)

export const polylineBeforeLayerId = 'polyline'

export const selectPolylineBeforeLayerId = createCustomSelector(selectIsVectorMap, selectMapTheme, (vector, theme) =>
  vector && theme !== MAP_THEMES.satellite ? polylineBeforeLayerId : undefined
)

export const selectPopupType = createCustomSelector(selectMapState, map => map?.popupType)

export const selectShouldDisplayMapLayersButton = selectIsZonePage

export const selectShouldDisplayMapEventPopin = createCustomSelector(
  selectIsAGeoentityPage,
  selectIsAGeoentityOnSearchPage,
  selectIsAnItineraryRoadbookPage,
  (isAGeoentitiesPage, isAGeoentityOnSearchPage, isAnItineraryRoadbookPage) =>
    !(isAGeoentitiesPage || isAGeoentityOnSearchPage || isAnItineraryRoadbookPage)
)

export const selectGeoipEnabled = createCustomSelector(selectMapState, map => map?.geoip ?? false)
