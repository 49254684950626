import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { TAG_CLICK_POI } from '../../analytics/analytics.tagids.constants'
import { selectCurrentFuelFilterId, selectFuelInformation } from '../../../store/geoentity/fuels.selectors'
import { getColorFromOpeningStatus, getFuelLabelFromId } from '../../../store/geoentity/geoentity.helpers'
import { selectGeolocationPosition } from '../../../store/geolocation/geolocation.selectors'
import { selectIsItineraryUniverse } from '../../../store/history/history.selectors'
import { isPE } from '../../Device'
import TagActionButton from '../../genericComponents/TagActionButton'
import { getDistanceByFlyCrow } from '../../utils/map'
import s from './PopupFuelPoi.less'
import { ButtonsSection } from './PopupPoi'

const PopupFuelPoi = ({ poi, rubricId, onMouseEnter, onMouseLeave, onGeoentityClick }) => {
  const { additionalInfo, name, coordinates } = poi
  const fuelInfos = useSelector(selectFuelInformation)

  const fuelId = useSelector(selectCurrentFuelFilterId)
  const fuelLabel = getFuelLabelFromId(fuelId)
  const fuelColor = fuelInfos?.find(fuel => fuel.id === fuelId)?.color?.hex ?? '#E8E515'

  const fuelLastUpdate = formatFuelLastUpdate(additionalInfo)
  const fuels = additionalInfo?.fuels
  const currentFuel = fuels?.find(({ id }) => id === fuelId)

  const { message, status } = poi?.openingStatus ?? {}
  const openingMessageColor = getColorFromOpeningStatus(status)

  const isItineraryUniverse = useSelector(selectIsItineraryUniverse)

  const shouldDisplayFuelInfo = fuels && currentFuel

  return (
    <div
      className={classNames(s.fuelContainer, isItineraryUniverse && s.withItineraryUniverse)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TagActionButton
        onClick={onGeoentityClick}
        deferClick
        type={TAG_CLICK_POI}
        tagInfo={{ affichage: 'carte', appId: additionalInfo?.appId, catId: rubricId }}
        className={s.fuelTagActionButton}
      >
        <div className={s.contentContainer}>
          <div className={s.textContainer}>
            <div className={classNames(s.fuelTitle, shouldDisplayFuelInfo && s.withFuelPrice)}>{name}</div>
            <PopupFuelDistance coordinates={coordinates} />
            {additionalInfo?.fuelsLastUpdate && <div className={s.fuelLastUpdate}>Mis à jour {fuelLastUpdate}</div>}
            <div className={s.openingMessage} style={{ color: openingMessageColor }}>
              {message}
            </div>
          </div>
          {shouldDisplayFuelInfo && (
            <div className={s.gasPrice} style={{ borderColor: fuelColor }}>
              <div className={s.gasName} style={{ backgroundColor: fuelColor }}>
                <span className={s.gasText}>{fuelLabel}</span>
              </div>
              <PopupFuelPrice currentFuel={currentFuel} fuelId={fuelId} />
            </div>
          )}
        </div>
        <ButtonsSection buttonClass={s.fuelButtonsSection} poi={poi} rubricId={rubricId} />
      </TagActionButton>
    </div>
  )
}

const formatFuelLastUpdate = additionalInfo => {
  const today = new Date().toLocaleDateString('fr-FR')

  return today === additionalInfo?.fuelsLastUpdate ? "aujourd'hui" : `le ${additionalInfo?.fuelsLastUpdate}`
}

const PopupFuelDistance = ({ coordinates }) => {
  const userLocation = useSelector(selectGeolocationPosition)
  const distance =
    userLocation && coordinates
      ? getDistanceByFlyCrow(coordinates.lat, coordinates.lng, userLocation?.lat, userLocation?.lng)?.toFixed(1)
      : undefined

  return isPE && distance && <div className={s.distance}>À {distance} km</div>
}

const PopupFuelPrice = ({ currentFuel, fuelId }) => {
  const fuelPrice = currentFuel?.price ? `${currentFuel?.price}€` : currentFuel?.available === false ? 'Rupture' : '-'

  return <div className={s.gasPriceText}>{fuelPrice}</div>
}

export default PopupFuelPoi
