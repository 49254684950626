import { useEffect } from 'react'
import { selectAdDataByAdId, selectExternalTrackingUrlByAdId } from '../../store/ad/ad.selectors'
import { useSelectorWithProps } from '../utils/react-redux'

export const useAdData = adId => useSelectorWithProps(selectAdDataByAdId, { adId })

export const useSendExternalTrackingUrl = (adId, ready = true) => {
  const url = useSelectorWithProps(selectExternalTrackingUrlByAdId, { adId })
  useEffect(() => {
    if (!ready || !url) return
    new Image().src = url
  }, [url, ready])
}
