import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { pushHistory } from '../history/historySlice'
import { loadPageAdConfig, requestPageAdConfig, searchGeoentitiesForVde } from './ad.actions'
import { selectIsPageAdConfigRequested } from './ad.selectors'

import { sha256 } from 'js-sha256'
import {
  isEnvelopeFromLocalStoreValid,
  refreshEnvelopeInLocalStoreToLiveRamp,
  retrieveEnvelope,
  saveEnvelopeToLocalStore,
  shouldRefreshEnvelopeFromLocalStore,
  submitHashedEmailToLiveRamp
} from '../../domain/advertising/LiverampService'
import CMPService from '../../domain/cmp/CMPService'
import { selectHasUserConsentedForSharingEmail } from '../cmp/cmp.selectors'
import { selectUserEmail } from '../userAccount/userAccount.selectors'
import { setUserAccountConnected } from '../userAccount/userAccountSlice'
import { setAdData } from './adSlice'

export const onPageAdConfigRequested =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__ && selectIsPageAdConfigRequested(getState())) {
      loadPageAdConfig()(dispatch, getState)
    }

    return r
  }

/**
 * This middleware stands here as a workaround fix to cope with architecture complexity
 * related with usage of push instead of pop to control navigation back flow
 * which means onRouteEnter is never called on push-like-pop scenarii
 */

export const onPushLikePopRequestPageAdConfig =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      __BROWSER__ &&
      action?.type === pushHistory.type &&
      action?.payload?.routeOptions?.forceReplaceForPop === true
    ) {
      const route = selectCurrentHistoryRoute(getState())
      requestPageAdConfig(route)(dispatch, getState)
    }

    return r
  }

export const requestGeoentitiesForVde =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setAdData.type) {
      const vdeData = action?.payload?.data?.vde
      if (vdeData) searchGeoentitiesForVde(vdeData)(dispatch, getState)
    }

    return r
  }

export const sendHashedEmailToLiveRamp =
  ({ getState }) =>
  next =>
  action => {
    const n = next(action)

    if (action.type === setUserAccountConnected.type && selectHasUserConsentedForSharingEmail(getState())) {
      const isEnvelopeValid = isEnvelopeFromLocalStoreValid()
      Promise.resolve(CMPService.getConsentString())
        .then(consentString => {
          if (isEnvelopeValid) {
            if (shouldRefreshEnvelopeFromLocalStore()) {
              return refreshEnvelopeInLocalStoreToLiveRamp(consentString).then(envelope =>
                saveEnvelopeToLocalStore(envelope)
              )
            }
          } else {
            const email = selectUserEmail(getState())
            return submitHashedEmailToLiveRamp(consentString, sha256(email)).then(envelope =>
              saveEnvelopeToLocalStore(envelope)
            )
          }
        })
        .then(() => {
          globalThis.ats = globalThis.ats ?? {}
          globalThis.ats.retrieveEnvelope = retrieveEnvelope
        })
    }

    return n
  }

export default [
  onPageAdConfigRequested,
  onPushLikePopRequestPageAdConfig,
  requestGeoentitiesForVde,
  sendHashedEmailToLiveRamp
]
