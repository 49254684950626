import React from 'react'
import { Provider } from 'react-redux'
import Router from './domain/router/Router'
import ErrorHandlerProvider from './domain/error/ErrorHandlerProvider'
import './App.less'
import './domain/fonts'
import SentryService from './domain/error/SentryService'

export const appId = 'app'

function App({ store, currentPageComponent, routerService }) {
  return (
    <Provider store={store}>
      <ErrorHandlerProvider>
        <Router {...{ currentPageComponent, store, routerService }} />
      </ErrorHandlerProvider>
    </Provider>
  )
}

export default SentryService.withProfiler(App)
