import {
  requestCreateUserFavorite,
  requestDeleteUserFavorite,
  requestSaveUserItineraryOptions,
  requestSearchForAddressFavorite,
  requestSearchForPoiXorAddressFavorite,
  requestUpdateUserFavorite,
  requestUserFavorites,
  requestUserItineraryOptions
} from '../../dataSource/userAccount/userAccount.request'
import { translate } from '../../domain/i18n/T'
import { removeComma } from '../../utils/string'
import { selectPreferredOptionsForService } from '../itineraryOptions/itineraryOptions.selectors'
import { setPreferredOptions } from '../itineraryOptions/itineraryOptionsSlice'
import { selectMapBbox } from '../map/map.selectors'
import { selectLocale } from '../navigation/navigation.selectors'
import { selectIsUserConnected, selectKeycloakToken } from './userAccount.selectors'
import {
  addUserAccountOtherFavorite,
  deleteUserAccountFavorite,
  editUserAccountFavorite,
  setUserAccountFavorites
} from './userAccountSlice'

export const loadUserFavorites = () => (dispatch, getState) => {
  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  const locale = selectLocale(getState())
  const homeLabel = translate(locale)('useraccount.favorite.home')
  const workLabel = translate(locale)('useraccount.favorite.work')

  return Promise.resolve()
    .then(() => requestUserFavorites(token, homeLabel, workLabel))
    .then(favorites => {
      if (favorites) dispatch(setUserAccountFavorites({ favorites }))
    })
    .catch(error => {
      console.error(`An error has occured while loading favorites : ${error}`)
      return { error: `Une erreur est survenue lors du chargement des favoris.` }
    })
}

export const addUserOtherFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())

  if (!token) return Promise.reject(new Error('no token'))

  return requestUserFavorites(token)
    .then(allFavorites =>
      (allFavorites?.others || []).find(
        otherFavorites => removeComma(otherFavorites?.address) === removeComma(favorite?.address)
      )
    )
    .then(alreadyExistingFavorite => {
      if (alreadyExistingFavorite) {
        return {
          error: 'Ce lieu a déjà été ajouté à vos favoris.'
        }
      } else {
        dispatch(addUserAccountOtherFavorite(favorite))

        const bbox = selectMapBbox(getState())
        const locale = selectLocale(getState())
        return requestSearchForPoiXorAddressFavorite(locale, bbox, favorite).then(updatedFavorite =>
          requestCreateUserFavorite(token, updatedFavorite)
        )
      }
    })
    .catch(error => {
      console.log(`An error has occured while adding a new favorite : ${error}`)
      return { error: `Une erreur est survenue lors de l'ajout d'un autre favori.` }
    })
    .finally(() => {
      loadUserFavorites()(dispatch, getState)
    })
}

export const addOrEditHomeOrWorkUserFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  dispatch(editUserAccountFavorite(favorite))

  const bbox = selectMapBbox(getState())
  const locale = selectLocale(getState())
  return requestSearchForAddressFavorite(locale, bbox, favorite)
    .then(updatedFavorite =>
      !updatedFavorite.uuid
        ? requestCreateUserFavorite(token, updatedFavorite)
        : requestUpdateUserFavorite(token, updatedFavorite)
    )
    .catch(error => {
      console.error(`An error has occured while editing a work/home favorite : ${error}`)
      return { error: `Une erreur est survenue lors de l'ajout d'un favori maison/boulot.` }
    })
    .finally(() => loadUserFavorites()(dispatch, getState))
}

export const deleteUserFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  dispatch(deleteUserAccountFavorite(favorite))

  return requestDeleteUserFavorite(token, favorite)
    .catch(error => {
      console.error(`An error has occured while deleting a favorite : ${error}`)
      return { error: `Une erreur est survenue lors de la suppression d'un favori.` }
    })
    .finally(() => loadUserFavorites()(dispatch, getState))
}

export const loadUserItineraryOptions = () => (dispatch, getState) => {
  const token = selectKeycloakToken(getState())

  return !token
    ? Promise.reject(new Error('no token'))
    : requestUserItineraryOptions(token)
        .then(options => {
          if (options) dispatch(setPreferredOptions({ options }))
        })
        .catch(error => {
          console.error(`An error has occured while loading travel options : ${error}`)
          return { error: `Une erreur est survenue lors du chargement des options de déplacement.` }
        })
}

export const saveUserItineraryOptions = () => (dispatch, getState) => {
  if (!selectIsUserConnected(getState())) return Promise.resolve()

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  const options = selectPreferredOptionsForService(getState())
  return requestSaveUserItineraryOptions(token, options).catch(error => {
    console.error(`An error has occured while saving travel options : ${error}`)
    return { error: `Une erreur est survenue lors de la sauvegarde des options de déplacement.` }
  })
}
