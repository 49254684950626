import { createSlice } from '@reduxjs/toolkit'
import { isEqual } from '../../utils/lang'
import { selectCMPConsent } from './cmp.selectors'

const initialState = {}

const cmpSlice = createSlice({
  name: 'cmp',
  initialState,
  reducers: {
    registerConsent: (state, action) => {
      if (!isEqual(selectCMPConsent(state), action.payload)) state.consent = action.payload
    }
  }
})

export const { registerConsent } = cmpSlice.actions

export default cmpSlice
