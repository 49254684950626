export const DIRECTORY_LINKS_PER_PAGE = 200

export const COUNT_CITIES_TO_DISCOVER = 30
export const COUNT_CITIES_FOOTER_HP = 192
export const COUNT_CITIES_FOOTER_OTHER = 20

export const FRONT_SERVICES_LINK_HP = '/hp'
export const FRONT_SERVICES_LINK_ITINERARY_HP = '/hp-itinerary'

export const RUBRIC_LINKS_NAMESPACES = {
  CLOUD: 'cloud',
  USEFUL: 'useful',
  VISIT: 'visit',
  BIKE: 'bike'
}
