import { OUTDOOR_STATUS_DISPLAY, OUTDOOR_STATUS_PROMPT } from '../../domain/outdoor/Utils'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectUIState } from './ui.selectors'

export const selectUiOutdoor = createCustomSelector(selectUIState, ui => ui?.outdoor)

export const selectIsOutdoorDisplayed = createCustomSelector(
  selectUiOutdoor,
  outdoor => outdoor?.status === OUTDOOR_STATUS_DISPLAY
)

export const selectIsOutdoorPrompted = createCustomSelector(
  selectUiOutdoor,
  outdoor => outdoor?.status === OUTDOOR_STATUS_PROMPT
)

export const selectOutdoorPanoramicId = createCustomSelector(selectUiOutdoor, outdoor => outdoor?.panoramicId)

export const selectOutdoorTargetCoordinates = createCustomSelector(selectUiOutdoor, outdoor => outdoor?.target ?? {})
