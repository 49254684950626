import {
  loadJSONFromLocalStorage,
  loadJSONFromSessionStorage,
  BROWSER_STORAGE_STORE_KEY
} from './browserStorageService'
import { MAP_MODES } from '../store/map/map.constants'
import { mergeObjectsAndConcatenateArrayProperties } from './object'

const getClientState = serverSideGeneratedState =>
  mergeObjectsAndConcatenateArrayProperties(
    {},
    serverSideGeneratedState,
    loadJSONFromLocalStorage(BROWSER_STORAGE_STORE_KEY),
    loadJSONFromSessionStorage(BROWSER_STORAGE_STORE_KEY),
    serverSideGeneratedState?.map?.mode && serverSideGeneratedState.map.mode !== MAP_MODES.neutral
      ? { map: { mode: serverSideGeneratedState.map.mode } }
      : {}
  )

export default getClientState
