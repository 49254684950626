export const noop = () => {}
export const identity = (...args) => args[0]

export const debounce = (func, delay = 0) => {
  let timerId
  return (...args) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => func(...args), delay)
  }
}

export const throttle = (fn, delay = 100) => {
  let lastTime = 0
  let timeout
  return (...args) => {
    const now = Date.now()
    const fromLastTime = now - lastTime
    if (fromLastTime >= delay) {
      fn(...args)
      lastTime = now
    } else {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        fn(...args)
        lastTime = Date.now()
      }, delay - fromLastTime)
    }
  }
}

export const once = fn => {
  let called = false
  let result
  return (...args) => {
    if (!called) {
      result = fn(...args)
      called = true
    }
    return result
  }
}

export const defer = (fn, ...args) => setTimeout(() => fn(...args), 0)

export const delay = (fn, delay = 1000) => setTimeout(fn, delay)

export const flowRight =
  (...fns) =>
  args =>
    fns.reduceRight((result, fn) => fn(result), args)

export const flow =
  (...fns) =>
  args =>
    fns.reduce((result, fn) => fn(result), args)
