class MultiPolygon {
  shapes
  constructor() {
    this.shapes = []
  }

  addPolygon({ points, holes = [] }) {
    this.shapes.push([points, ...holes])
  }

  getGeometry() {
    return {
      type: 'MultiPolygon',
      coordinates: this.shapes
    }
  }
}

export default MultiPolygon
