import { selectQuizCurrentCityIndex, selectQuizCurrentGuessCoordinates } from './quiz.selectors'
import { fetchDepartmentQuizCities, fetchQuizCities, fetchRegionQuizCities } from '../../dataSource/quiz/quiz.request'
import { QUIZ_TYPES } from '../../domain/quiz/quiz.constants'
import { selectFirstAddress } from '../address/address.selectors'
import {
  setQuizCities,
  setQuizCityAnswer,
  setQuizConfig,
  setQuizCurrentCityIndex,
  setQuizGuessCoordinates,
  setQuizLevel,
  setQuizType
} from './quizSlice'

const QUIZ_TIME_BEFORE_NEXT_QUESTION = 2000

export const startNewCitiesQuiz =
  ({ level }) =>
  async (dispatch, getState) => {
    const cities = await fetchQuizCities({ level })
    dispatch(setQuizType(QUIZ_TYPES.CITIES))
    dispatch(setQuizLevel(level))
    dispatch(setQuizCities(cities))
  }

export const startNewRegionCitiesQuiz = () => async (dispatch, getState) => {
  const code = selectFirstAddress(getState()).regionCode
  const { config, list } = await fetchRegionQuizCities({ code })
  dispatch(setQuizType(QUIZ_TYPES.REGION_CITIES))
  dispatch(setQuizConfig(config))
  dispatch(setQuizCities(list))
}

export const startNewDepartmentCitiesQuiz = () => async (dispatch, getState) => {
  const code = selectFirstAddress(getState()).departmentCode
  const { config, list } = await fetchDepartmentQuizCities({ code })
  dispatch(setQuizType(QUIZ_TYPES.DEPARTMENT_CITIES))
  dispatch(setQuizConfig(config))
  dispatch(setQuizCities(list))
}

export const setCurrentCityGuessCoordinatesQuiz = lngLat => (dispatch, getState) => {
  if (selectQuizCurrentGuessCoordinates(getState())) return
  const cityIndex = selectQuizCurrentCityIndex(getState())
  dispatch(
    setQuizGuessCoordinates({
      cityIndex,
      guessCoordinates: lngLat
    })
  )
}

export const waitForNextQuestion = () => (dispatch, getState) => {
  const index = selectQuizCurrentCityIndex(getState())
  setTimeout(() => {
    dispatch(setQuizCurrentCityIndex(index + 1))
  }, QUIZ_TIME_BEFORE_NEXT_QUESTION)
}

export const setCurrentCityAnswer = answer => (dispatch, getState) => {
  const cityIndex = selectQuizCurrentCityIndex(getState())
  dispatch(
    setQuizCityAnswer({
      cityIndex,
      answer
    })
  )
}
