import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  connected: undefined,
  token: undefined,
  identity: {
    picture: undefined,
    name: ''
  },
  favorites: {
    home: undefined,
    work: undefined,
    others: []
  },
  error: undefined
}

const userAccountSlice = createSlice({
  name: 'userAccount',
  initialState,
  reducers: {
    setUserAccountConnected: (state, action) => {
      state.connected = true
      state.token = action?.payload?.token
      state.identity = action?.payload?.identity ?? {}
    },
    setUserAccountNotConnected: state => {
      state.connected = false
      state.token = undefined
      state.identity = undefined
    },
    setUserAccountToken: (state, action) => {
      if (action.payload) {
        state.token = action.payload
      }
    },
    setUserAccountFavorites: (state, action) => {
      state.favorites = action.payload.favorites
    },
    addUserAccountOtherFavorite: (state, action) => {
      state.favorites.others = [...state.favorites.others, action.payload]
    },
    editUserAccountFavorite: (state, action) => {
      const previousFavorite = state?.favorites?.[action.payload?.type] ?? {}
      state.favorites = {
        ...state.favorites,
        [action.payload.type]: {
          ...previousFavorite,
          ...action.payload
        }
      }
    },
    deleteUserAccountFavorite: (state, action) => {
      const uuidToRemove = action.payload.uuid
      if (uuidToRemove === state.favorites?.work?.uuid) {
        state.favorites.work = undefined
      } else if (uuidToRemove === state.favorites?.home?.uuid) {
        state.favorites.home = undefined
      } else {
        state.favorites.others = state.favorites.others.filter(fav => fav.uuid !== uuidToRemove)
      }
    }
  }
})

export const {
  setUserAccountConnected,
  setUserAccountNotConnected,
  setUserAccountToken,
  setUserAccountFavorites,
  addUserAccountOtherFavorite,
  editUserAccountFavorite,
  deleteUserAccountFavorite
} = userAccountSlice.actions

export default userAccountSlice
