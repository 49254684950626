import { createSlice } from '@reduxjs/toolkit'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'

const initialState = {
  viewport: undefined,
  addresses: [],
  description: undefined,
  contour: null
}

const setAddressesAndViewport = (state, action) => {
  const {
    data: { addresses, viewport }
  } = action.payload

  state.addresses = addresses
  state.viewport = viewport
}

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    loadAddressRequest: (state, action) => {
      /* for middleware purpose */
    },
    loadAreaFromGeocode: (state, action) => {
      setAddressesAndViewport(state, action)
    },
    loadMultiAddressSuccess: (state, action) => {
      setAddressesAndViewport(state, action)
    },
    loadSingleAddressSuccess: (state, action) => {
      setAddressesAndViewport(state, action)
    },
    loadSingleAddressComputeRadiusSuccess: (state, action) => {
      setAddressesAndViewport(state, action)
    },
    addContourToFirstAddress: (state, action) => {
      state.contour = action.payload
    },
    loadAddressError: (state, action) => {
      return {
        error: parseErrorFromReduxAction(action)
      }
    },
    removeAddressError: (state, action) => {
      state.error = undefined
    },
    loadDescription: (state, action) => {
      state.description = action.payload
    },
    resetDescription: (state, action) => {
      state.description = undefined
    },
    resetAddress: (state, action) => {
      return { ...initialState }
    }
  }
})

export const {
  loadAreaFromGeocode,
  loadMultiAddressSuccess,
  loadSingleAddressSuccess,
  loadSingleAddressComputeRadiusSuccess,
  addContourToFirstAddress,
  loadAddressError,
  removeAddressError,
  loadDescription,
  resetDescription,
  resetAddress,
  loadAddressRequest
} = addressSlice.actions

export default addressSlice
