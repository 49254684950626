import { findAddressforGeolocation } from '../../dataSource/address/address.request'
import {
  requestInventory,
  requestLocation,
  requestRoute,
  requestTransports
} from '../../dataSource/itinerary/itinerary.requests'
import AddressNotFoundError from '../../domain/error/AddressNotFoundError'
import NoRouteError from '../../domain/error/NoRouteError'
import ServerError from '../../domain/error/ServerError'
import TimeoutError from '../../domain/error/TimeoutError'
import BookinSearchExtension from '../../domain/itinerary/BookingSearchExtension'
import {
  areStepsFilled,
  selectArrivalStep,
  selectBboxFromSteps,
  selectDepartureStep,
  selectFilledSteps,
  selectStepsLength,
  selectStopSteps
} from '../../domain/itinerary/steps/steps.selectors'
import { isStepFilled } from '../../domain/itinerary/steps/steps.utils'
import { PREFERRED_VEHICLE_ID } from '../../domain/itineraryOptions/ItineraryOptions.constants'
import { PARAM_SORT } from '../../domain/router/queryParameters.constants'
import { isCoords, lngLatString } from '../../domain/utils/location'
import { ROUTE_ITINERARY_RESULTS_BY_PROVIDER, ROUTE_ITINERARY_RESULTS_BY_ROUTE } from '../../routes'
import { head, uniqBy } from '../../utils/array'
import { selectGeolocationPosition } from '../geolocation/geolocation.selectors'
import { navigateTo } from '../history/history.actions'
import {
  selectPreferredOptions,
  selectPreferredSpeed,
  selectSkippedProviders
} from '../itineraryOptions/itineraryOptions.selectors'
import { selectItineraryTimeOptions } from '../itineraryOptions/itineraryTimeOptions.selectors'
import { ARRIVAL_LATER, DEPARTURE_NOW } from '../itineraryOptions/itineraryTimeOptionsWhenTypes'
import { requestMove } from '../map/map.actions'
import { selectMapBbox } from '../map/map.selectors'
import { selectLocale } from '../navigation/navigation.selectors'
import { POI } from '../search/location.types'
import { SET_STEP_FROM, SORT_TYPES } from './itinerary.constants'
import { decorateRouteWithId, extractColors } from './itinerary.dataParser'
import { selectCurrentMode, selectCurrentProvider, selectProviderByName, selectProviders } from './itinerary.selectors'
import { getDesiredProvider, removeSimplifiedAndOptionalForProvidersInCurrentMode } from './itinerary.utils'
import {
  addRoutes,
  dropPreviousProviderRoutes,
  removeProviderSimpliedOrOptionalStatus,
  routeApiUnknownError,
  routeNotFoundError,
  setActiveSort,
  setCurrentMode,
  setCurrentProvider,
  setCurrentRoute,
  setCurrentSteps,
  setItineraryIsComplete,
  setItineraryIsComputing,
  setProviderIsComputing,
  setProviders,
  setProvidersColors,
  setStepIdx,
  setStepLocation,
  setStepResolvedLocation,
  setItineraryAvailabilityInfo
} from './itinerarySlice'
import { selectCurrentPolylineBbox } from './polylines.selectors'
import { selectCurrentRouteId, selectRoutes, selectSortedRoutes } from './routes.selectors'

export const setStepFromSuggestion =
  ({ suggest }) =>
  dispatch => {
    const { label, split_label, lng, lat, from, type } = suggest
    dispatch(
      setStepLocation({
        location: {
          label,
          split_label,
          coordinates: { lng, lat },
          from,
          type
        },
        options: { from: SET_STEP_FROM.searchForm }
      })
    )
  }

export const setStepFromAddress =
  (address, type = setStepLocation.type, options = { from: SET_STEP_FROM.searchForm }) =>
  dispatch => {
    dispatch({
      type,
      payload: {
        location: address,
        options
      }
    })
  }

export const setStepFromPoint = (lngLat, idx) => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses }) => {
      if (addresses.length < 1) return Promise.reject(new AddressNotFoundError('no address found at this point'))
      return addresses[0]
    })
    .then(address => {
      dispatch(setStepIdx(idx))
      setStepFromAddress(address, setStepLocation.type, { from: SET_STEP_FROM.mapAction })(dispatch, getState)
    })
}

export const setDepartureFromPoint = lngLat => (dispatch, getState) => setStepFromPoint(lngLat, 0)(dispatch, getState)

export const setArrivalFromPoint = lngLat => (dispatch, getState) => {
  const stepsLength = selectStepsLength(getState())
  return setStepFromPoint(lngLat, stepsLength - 1)(dispatch, getState)
}

export const setStepFromParams =
  (steps, options = {}) =>
  (dispatch, getState) => {
    if (!areStepsFilled(getState()) || options.force) {
      dispatch(setCurrentSteps(steps))
    }
  }

export const setRouteFromParams =
  ({ mode, provider, routeId }) =>
  (dispatch, getState) => {
    const currentRouteId = selectCurrentRouteId(getState())
    const routes = selectRoutes(getState())
    const currentProvider = selectCurrentProvider(getState())

    if (mode) dispatch(setCurrentMode(mode))
    if (provider && provider !== currentProvider) dispatch(setCurrentProvider(provider))

    if (routeId && routeId !== currentRouteId) {
      const typedRouteId = parseInt(routeId, 10)
      const routeWanted = routes.find(route => route?.routeId === typedRouteId)
      if (routeWanted) dispatch(setCurrentRoute(typedRouteId))
    }
  }

export const findLocation = (location, idx) => (dispatch, getState) => {
  if (isCoords(location.coordinates) && location.type !== POI && location.countryCode) {
    return Promise.resolve(location)
  } else {
    const locale = selectLocale(getState())
    const bbox = selectMapBbox(getState())
    return requestLocation(location, bbox, locale).then(location => {
      if (location) {
        dispatch(setStepIdx(idx))
        return setStepFromAddress(location, setStepResolvedLocation.type)(dispatch, getState)
      }

      return Promise.reject(new AddressNotFoundError('no address found at this location'))
    })
  }
}

const getStepParameters = store =>
  selectStopSteps(store)
    .filter(isStepFilled)
    .map(({ coordinates }) => lngLatString(coordinates))

export const findTransports = () => (dispatch, getState) => {
  const state = getState()
  const locale = selectLocale(state)
  const preferredOptions = selectPreferredOptions(state)
  const [from, to] = [selectDepartureStep(state), selectArrivalStep(state)]
  return requestTransports({
    from,
    to,
    options: {
      stop: getStepParameters(state),
      vehicle: preferredOptions?.car?.[PREFERRED_VEHICLE_ID] ?? undefined,
      preferred_tm: preferredOptions?.favoriteMode ?? undefined,
      skipped_providers: selectSkippedProviders(state).join(',')
    },
    locale
  })
}

export const findRoute = provider => (dispatch, getState) => {
  const state = getState()
  const itineraryTimeOptions = selectItineraryTimeOptions(state)
  const locale = selectLocale(state)
  const { name, qid } = provider

  return requestRoute({
    provider,
    from: selectDepartureStep(state),
    to: selectArrivalStep(state),
    stop: getStepParameters(state),
    dateTimeOptions: getDateTimeOptions(itineraryTimeOptions),
    ModePreferredOptions: getModePreferredOptions(name, state),
    currentMode: selectCurrentMode(state),
    locale
  })
    .then(routes => {
      const requestedProviderStillValid = selectProviders(getState()).some(p => p.name === name && p.qid === qid)
      if (!requestedProviderStillValid) return []

      if (routes?.availability_info) {
        dispatch(setItineraryAvailabilityInfo(routes.availability_info))

        return []
      }

      if ((routes || []).length === 0) {
        throw new NoRouteError('no route for mode')
      }

      dispatch(addRoutes(routes))
      return routes
    })
    .catch(err => {
      const isExpectedError = [NoRouteError.name, TimeoutError.name].includes(err?.name)
      const error = isExpectedError ? err : new NoRouteError('no route for selected mode')
      if (!isExpectedError) console.error(err)

      const { name, mode, label } = provider
      dispatch(
        routeNotFoundError({
          provider: name,
          mode: {
            id: mode,
            label
          },
          error
        })
      )
      throw err
    })
}

const addOptionalRoutes = () => (dispatch, getState) => {
  const providers = selectProviders(getState())
  const currentMode = selectCurrentMode(getState())

  const routes = uniqBy(providers, ({ mode }) => mode)
    .filter(({ optional, mode }) => optional && mode !== currentMode)
    .map(({ name, icon, mode }) =>
      decorateRouteWithId({
        provider: {
          id: name
        },
        icon,
        mode,
        sections: [],
        isFake: true,
        isOptional: true
      })
    )
  if (routes.length > 0) dispatch(addRoutes(routes))
}

export const computeProvider = providerName => (dispatch, getState) => {
  dispatch(removeProviderSimpliedOrOptionalStatus(providerName))
  dispatch(dropPreviousProviderRoutes(providerName))

  const provider = selectProviderByName(providerName)(getState)

  dispatch(setProviderIsComputing())

  return findRoute(provider)(dispatch, getState).catch(error => console.warn('computeProvider/findRoute', error))
}

export const setRouteIdForCurrentProviderOrMode = () => (dispatch, getState) => {
  const routes = selectSortedRoutes(getState())
  const currentProvider = selectCurrentProvider(getState())
  const firstRouteForProvider = routes.find(({ provider: { id } }) => id === currentProvider)
  if (firstRouteForProvider) {
    navigateToItineraryRoute({
      routeId: firstRouteForProvider.routeId,
      provider: firstRouteForProvider.provider.id
    })(dispatch, getState)
    return
  }
  const currentMode = selectCurrentMode(getState())
  const firstRouteForMode = routes.find(({ mode }) => mode === currentMode)
  if (firstRouteForMode) {
    navigateToItineraryRoute({
      routeId: firstRouteForMode.routeId,
      provider: firstRouteForMode.provider.id
    })(dispatch, getState)
  }
}

export const compute = () => (dispatch, getState) => {
  dispatch(setItineraryIsComputing())

  const steps = selectFilledSteps(getState())
  const currentProvider = selectCurrentProvider(getState())
  const currentMode = selectCurrentMode(getState())

  return Promise.all(steps.map((step, idx) => findLocation(step, idx)(dispatch, getState)))
    .then(() => BookinSearchExtension.search({ steps: selectFilledSteps(getState()) }))
    .then(() => {
      fetchProvidersColors()(dispatch, getState)
      return findTransports()(dispatch, getState)
    })
    .then(rawProviders => {
      const currentProviderMode = rawProviders.find(({ name }) => name === currentProvider)?.mode
      const providers = removeSimplifiedAndOptionalForProvidersInCurrentMode(
        rawProviders,
        currentMode || currentProviderMode,
        currentProvider
      )
      dispatch(setProviders(providers))

      const desiredProvider = getDesiredProvider({ currentProvider, currentMode, providers })
      dispatch(setCurrentProvider(desiredProvider.name))

      const desiredProviderRoutesPromise = findRoute(desiredProvider)(dispatch, getState).catch(error =>
        console.warn('compute/findRoute desiredProvider', error)
      )
      const otherProvidersRoutesPromises = providers
        .filter(p => p.name !== desiredProvider.name)
        .filter(p => p.optional === false)
        .map(provider =>
          findRoute(provider)(dispatch, getState).catch(error => console.warn('compute/findRoute otherProvider', error))
        )

      Promise.all([desiredProviderRoutesPromise, ...otherProvidersRoutesPromises]).then(() => {
        addOptionalRoutes()(dispatch, getState)
        dispatch(setItineraryIsComplete())
      })

      return desiredProviderRoutesPromise
    })
    .then(routes => {
      dispatch(setCurrentRoute(routes?.[0]?.routeId))
    })
    .catch(err => {
      const isExpectedError = [NoRouteError.name, AddressNotFoundError.name, TimeoutError.name].includes(err?.name)
      const error = isExpectedError ? err : new ServerError(err)
      if (!isExpectedError) console.error(err)
      dispatch(routeApiUnknownError({ error }))
    })
}

export const computeETAFromGeoloc = toPosition => (dispatch, getState) => {
  const geolocationPosition = selectGeolocationPosition(getState())
  if (!geolocationPosition || !toPosition) return Promise.resolve()
  const from = { coordinates: geolocationPosition }
  const to = { coordinates: toPosition }
  const locale = selectLocale(getState())

  return requestTransports({ locale, from, to })
    .then(providers => {
      if (!providers?.length || providers.length === 0) throw new NoRouteError('missing provider')
      return requestRoute({ provider: providers[0], from, to, locale })
    })
    .then(routes => {
      if (Array.isArray(routes)) return head(routes)
    })
    .catch(e => {
      console.error('computeETAFromGeoloc', e) // fail silently
    })
}

const getDateTimeOptions = itineraryTimeOptions => {
  if (!itineraryTimeOptions) return {}
  const { when, date, time } = itineraryTimeOptions
  const datetime = when !== DEPARTURE_NOW && date && time ? `${formatDate(date)}T${formatTime(time)}` : undefined
  return {
    departure: when !== ARRIVAL_LATER,
    datetime
  }
}

const getModePreferredOptions = (name, storeState) => {
  const { itineraryOptions } = storeState
  switch (name) {
    case 'car':
    case 'motorbike': {
      const { vehicleId, fuelTypeId, fuelPrice, fuelConsumption } = itineraryOptions?.preferredOptions?.[name] ?? {}
      const prefix = name === 'motorbike' ? `${name}_` : ''
      return {
        [`${prefix}vehicle`]: vehicleId,
        [`${prefix}fuel`]: fuelTypeId,
        gas_cost: fuelPrice,
        [`${name}_consumption`]: fuelConsumption
      }
    }
    case 'bss':
      return {
        bike_speed: selectPreferredSpeed('bike')(storeState),
        walk_speed: selectPreferredSpeed('walk')(storeState)
      }
    case 'walk':
    case 'tc':
      return {
        walk_speed: selectPreferredSpeed('walk')(storeState)
      }
    case 'bike':
      return {
        bike_speed: selectPreferredSpeed('bike')(storeState)
      }
    case 'trottinette':
      return {
        trottinette_speed: selectPreferredSpeed('trottinette')(storeState)
      }
  }
  return {}
}

export const fetchProvidersColors = () => (dispatch, getState) => {
  const locale = selectLocale(getState())

  return requestInventory(locale).then(data => {
    dispatch(setProvidersColors(extractColors(data)))
  })
}

const formatDate = date => date.replace(/-/g, '')
const formatTime = time => time.replace(':', '')

export const mapFitCurrentSteps = () => (dispatch, getState) => {
  const bbox = selectBboxFromSteps(getState())
  if (bbox) {
    requestMove({ bbox })(dispatch, getState)
  }
}

export const mapFitCurrentPolyline = () => (dispatch, getState) => {
  const bbox = selectCurrentPolylineBbox(getState())
  if (bbox) {
    requestMove({ bbox })(dispatch, getState)
  }
}

export const navigateToItineraryRoute =
  ({ routeId, provider }, routeOptions = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
      routeOptions: {
        avoidRefetchingPageData: true,
        forceNotReplace: true,
        ...routeOptions
      },
      selectedStoreState: {
        routeId,
        provider
      }
    })(dispatch, getState)
  }

export const navigateToFirstSortedItineraryRoute = () => (dispatch, getState) => {
  const sortedRoutes = selectSortedRoutes(getState())
  if (sortedRoutes.length === 0) return
  const { routeId, provider } = sortedRoutes[0]
  navigateToItineraryRoute({ routeId, provider: provider.id })(dispatch, getState)
}

export const navigateToProvider =
  ({ provider, mode }, routeOptions = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
      routeOptions: {
        avoidRefetchingPageData: true,
        forceNotReplace: true,
        ...routeOptions
      },
      selectedStoreState: {
        provider,
        mode
      }
    })(dispatch, getState)
  }

export const setItinerarySortOptionFromQueryParams = query => (dispatch, getState) => {
  if (query.has(PARAM_SORT)) {
    const sortKey = query.get(PARAM_SORT)
    if (Object.keys(SORT_TYPES).includes(sortKey)) {
      dispatch(setActiveSort(sortKey))
    }
  }
}
