export default class RouteHandlingState {
  constructor() {
    this._onPreviousRouteLeave = undefined
    this._renderPage = undefined
  }

  set renderPage(renderPage) {
    if (renderPage) this._renderPage = renderPage
  }

  get renderPage() {
    return this._renderPage
  }

  set onPreviousRouteLeave(onRouteLeave) {
    this._onPreviousRouteLeave = onRouteLeave
  }

  get onPreviousRouteLeave() {
    return this._onPreviousRouteLeave
  }

  set onPreviousRouteLeavePageLess(onRouteLeave) {
    this._onPreviousRouteLeavePageLess = onRouteLeave
  }

  get onPreviousRouteLeavePageLess() {
    return this._onPreviousRouteLeavePageLess
  }

  static instance

  static getInstance() {
    if (__SERVER__) return new RouteHandlingState() // We should always return a new state on server side
    if (!RouteHandlingState.instance) RouteHandlingState.instance = new RouteHandlingState()
    return RouteHandlingState.instance
  }
}
