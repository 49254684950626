let geolocId = null

const isSupport = __BROWSER__ && navigator && navigator.geolocation

const GEOLOCATION_TIMEOUT = 5000
const GEOLOCATION_MAXIMUM_AGE = 60000

export const watchPosition = ({ positionHandler, errorHandler, options }) => {
  if (!isSupport) return console.error('geolocation is not support')
  if (geolocId) return console.error('geolocation already watched')

  geolocId = navigator.geolocation.watchPosition(positionHandler, errorHandler, {
    maximumAge: GEOLOCATION_MAXIMUM_AGE,
    timeout: GEOLOCATION_TIMEOUT,
    enableHighAccuracy: true,
    ...options
  })
}

export const getCurrentPosition = ({ positionHandler, errorHandler, options }) => {
  if (!isSupport) return console.error('geolocation is not support')

  navigator.geolocation.getCurrentPosition(positionHandler, errorHandler, {
    maximumAge: GEOLOCATION_MAXIMUM_AGE,
    timeout: GEOLOCATION_TIMEOUT,
    enableHighAccuracy: true,
    ...options
  })
}

export const clearWatch = () => {
  if (!isSupport) return console.error('geolocation not support')
  if (geolocId) {
    navigator.geolocation.clearWatch(geolocId)
    geolocId = null
  }
}

export default {
  watchPosition,
  getCurrentPosition,
  clearWatch
}
