import { ReponseItineraireChoix } from './analytics.constants'
import { PAGE_TAG } from './PageTagsService'
import {
  ROUTE_ADDRESS,
  ROUTE_ADDRESS_HOME,
  ROUTE_BICYCLE_CITY,
  ROUTE_BICYCLE_DEPARTMENT,
  ROUTE_BICYCLE_HOME,
  ROUTE_BICYCLE_REGION,
  ROUTE_DEPARTMENT_HOME,
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_HOME,
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_EXPENSE_REPORT,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_OPTIONS_DIALOG,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_TIME_OPTIONS_DIALOG,
  ROUTE_ITINERARY_TO,
  ROUTE_QUIZ_CITIES,
  ROUTE_QUIZ_CITIES_DEPARTMENT,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_QUIZ_HOME,
  ROUTE_REGION_HOME,
  ROUTE_RESTRICTION_CITY,
  ROUTE_RESTRICTION_DEPARTMENT,
  ROUTE_RESTRICTION_HOME,
  ROUTE_RESTRICTION_REGION,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_CITY_ACTIVITY,
  ROUTE_SEO_CITY_APP,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_GEOENTITY,
  ROUTE_SEO_ITINERARY,
  ROUTE_SEO_ITINERARY_MODE,
  ROUTE_SEO_REGION,
  ROUTE_TOOLS_COMPUTE_RADIUS,
  ROUTE_TRAFFIC_CITY,
  ROUTE_TRAFFIC_DEPARTMENT,
  ROUTE_TRAFFIC_HOME,
  ROUTE_TRAFFIC_REGION,
  ROUTE_ZFE_CITY,
  ROUTE_ZFE_DEPARTMENT,
  ROUTE_ZFE_HOME,
  ROUTE_ZFE_REGION
} from '../../routes'
import { selectPageTagContext } from '../../store/history/history.selectors'

export const ROUTE_TO_PAGE_TAG = {
  [ROUTE_HOME]: () => PAGE_TAG.PAGE_LIEUX,
  [ROUTE_ADDRESS_HOME]: () => PAGE_TAG.PAGE_HOME_PLAN,
  [ROUTE_ADDRESS]: () => PAGE_TAG.PAGE_ADDRESS,
  [ROUTE_SEO_ADDRESS]: () => PAGE_TAG.PAGE_ADDRESS,
  [ROUTE_SEO_DEPARTMENT]: () => PAGE_TAG.PAGE_ADDRESS,
  [ROUTE_SEO_REGION]: () => PAGE_TAG.PAGE_ADDRESS,
  [ROUTE_SEO_COUNTRY]: () => PAGE_TAG.PAGE_ADDRESS,
  [ROUTE_GEOENTITY_AT_ADDRESS]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_SEO_GEOENTITY]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_SEARCH_BY_CATEGORY]: () => PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP,
  [ROUTE_SEARCH_BY_SUBCATEGORY]: () => PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP,
  [ROUTE_SEARCH_BY_APP]: () => PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP,
  [ROUTE_SEARCH_BY_RUBRIC]: () => PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP,
  [ROUTE_SEARCH_BY_QUERY]: () => PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP,
  [ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_GEOENTITY_ON_SEARCH_BY_APP]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY]: () => PAGE_TAG.PAGE_POI,
  [ROUTE_ITINERARY_HOME]: () => PAGE_TAG.PAGE_ITINERARY,
  [ROUTE_ITINERARY_TO]: () => PAGE_TAG.PAGE_ITINERARY,
  [ROUTE_ITINERARY_HP_BY_MODE]: () => PAGE_TAG.PAGE_ITINERARY,
  [ROUTE_ITINERARY_COMPUTING]: () => PAGE_TAG.PAGE_LIST_ITINERARY,
  [ROUTE_ITINERARY_RESULTS_BY_ROUTE]: store =>
    selectPageTagContext(store) === ReponseItineraireChoix
      ? PAGE_TAG.PAGE_LIST_ITINERARY_FOCUS
      : PAGE_TAG.PAGE_LIST_ITINERARY,
  [ROUTE_ITINERARY_RESULTS_BY_PROVIDER]: store =>
    selectPageTagContext(store) === ReponseItineraireChoix && PAGE_TAG.PAGE_LIST_ITINERARY_FOCUS,
  [ROUTE_ITINERARY_ROADBOOK]: () => PAGE_TAG.PAGE_DETAIL_ITINERARY,
  [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP]: () => PAGE_TAG.PAGE_RESPONSE_POI_SMR,
  [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY]: () => PAGE_TAG.PAGE_RESPONSE_POI_SMR,
  [ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY]: () => PAGE_TAG.PAGE_RESPONSE_POI_SMR,
  [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP]: () => PAGE_TAG.PAGE_POI_SMR,
  [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY]: () => PAGE_TAG.PAGE_POI_SMR,
  [ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY]: () => PAGE_TAG.PAGE_POI_SMR,
  [ROUTE_ITINERARY_EXPENSE_REPORT]: () => PAGE_TAG.PAGE_PRINT_NDF,
  [ROUTE_ITINERARY_OPTIONS_DIALOG]: () => PAGE_TAG.PAGE_OPTIONS_DEPLACEMENT,
  [ROUTE_ITINERARY_TIME_OPTIONS_DIALOG]: () => PAGE_TAG.PAGE_OPTIONS_DATETIME,
  [ROUTE_SEO_ITINERARY]: () => PAGE_TAG.PAGE_LANDING_ITINERARY,
  [ROUTE_SEO_ITINERARY_MODE]: () => PAGE_TAG.PAGE_LANDING_ITINERARY,
  [ROUTE_TOOLS_COMPUTE_RADIUS]: () => PAGE_TAG.PAGE_COMPUTE_RADIUS,
  [ROUTE_SEO_CITY_ACTIVITY]: () => PAGE_TAG.PAGE_LANDING_CITY_ACTIVITY,
  [ROUTE_DEPARTMENT_HOME]: () => PAGE_TAG.PAGE_HP_DEPARTEMENT,
  [ROUTE_REGION_HOME]: () => PAGE_TAG.PAGE_HP_REGION,
  [ROUTE_SEO_CITY_APP]: () => PAGE_TAG.PAGE_LANDING_CITY_APP,
  [ROUTE_TRAFFIC_HOME]: () => PAGE_TAG.PAGE_TRAFFIC_HOME,
  [ROUTE_BICYCLE_HOME]: () => PAGE_TAG.PAGE_BICYCLE_HOME,
  [ROUTE_ZFE_HOME]: () => PAGE_TAG.PAGE_ZFE_HOME,
  [ROUTE_RESTRICTION_HOME]: () => PAGE_TAG.PAGE_RESTRICTION_HOME,
  [ROUTE_TRAFFIC_CITY]: () => PAGE_TAG.PAGE_TRAFFIC_RESPONSE,
  [ROUTE_BICYCLE_CITY]: () => PAGE_TAG.PAGE_BICYCLE_RESPONSE,
  [ROUTE_ZFE_CITY]: () => PAGE_TAG.PAGE_ZFE_RESPONSE,
  [ROUTE_RESTRICTION_CITY]: () => PAGE_TAG.PAGE_RESTRICTION_RESPONSE,
  [ROUTE_TRAFFIC_DEPARTMENT]: () => PAGE_TAG.PAGE_TRAFFIC_RESPONSE,
  [ROUTE_BICYCLE_DEPARTMENT]: () => PAGE_TAG.PAGE_BICYCLE_RESPONSE,
  [ROUTE_ZFE_DEPARTMENT]: () => PAGE_TAG.PAGE_ZFE_RESPONSE,
  [ROUTE_RESTRICTION_DEPARTMENT]: () => PAGE_TAG.PAGE_RESTRICTION_RESPONSE,
  [ROUTE_TRAFFIC_REGION]: () => PAGE_TAG.PAGE_TRAFFIC_RESPONSE,
  [ROUTE_BICYCLE_REGION]: () => PAGE_TAG.PAGE_BICYCLE_RESPONSE,
  [ROUTE_ZFE_REGION]: () => PAGE_TAG.PAGE_ZFE_RESPONSE,
  [ROUTE_RESTRICTION_REGION]: () => PAGE_TAG.PAGE_RESTRICTION_RESPONSE,
  [ROUTE_QUIZ_HOME]: () => PAGE_TAG.PAGE_HOME_QUIZ,
  [ROUTE_QUIZ_CITIES]: () => PAGE_TAG.PAGE_QUIZ_DES_VILLES,
  [ROUTE_QUIZ_CITIES_REGION]: () => PAGE_TAG.PAGE_QUIZ_DES_VILLES_DE_REGION,
  [ROUTE_QUIZ_CITIES_DEPARTMENT]: () => PAGE_TAG.PAGE_QUIZ_DES_VILLES_DE_DEPARTEMENT
}
