import wait from '../utils/wait'

export default class ScriptLoader {
  static load = (id, url) =>
    new Promise((resolve, reject) => {
      if (!global.document) reject(new Error('Should not call this script server side'))

      const existingScript = global.document.getElementById(id)

      if (!existingScript) {
        const script = global.document.createElement('script')

        script.onload = () => wait(100).then(resolve)
        script.onerror = reject

        script.src = url
        script.id = id

        global.document.body.appendChild(script)
      } else {
        resolve()
      }
    })

  static loadInlineJS = (id, js) => {
    if (!global.document) throw new Error('Should not call this script server side')

    const existingScript = global.document.getElementById(id)

    if (!existingScript) {
      const script = global.document.createElement('script')
      script.innerText = js
      global.document.body.appendChild(script)
    }
  }
}
