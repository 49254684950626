import { getDefaults } from '../../parameters'
import { selectHasUserConsentedForTracking } from '../../store/cmp/cmp.selectors'
import { selectLocale } from '../../store/navigation/navigation.selectors'
import { hashCode } from '../../utils/string'
import { OnceWithCondition } from '../utils/OnceWithCondition'

global.ATInternet = global.ATInternet || {}
global.ATInternet.onTrackerLoad = onTrackerLoad

export const tagsBuffer = []

export const callAbTestOnceWithCondition = new OnceWithCondition()

let tag

export function onTrackerLoad() {
  if (!tagsBuffer.length) return

  while (tagsBuffer.length !== 0) {
    const pendingTagFn = tagsBuffer.shift()

    if (typeof pendingTagFn === 'function') pendingTagFn()
    else console.error('tagsBuffer entries should all be functions')
  }
}

export const _emptyCacheTagForTest = () => {
  tag = undefined
}

export const getCookieDomain = host => host.replace(/^[a-z-]+\./, '')

export const getTag = () => {
  if (tag) return tag

  const storeState = global.__mappy__store__rare_usage.getState()
  const locale = selectLocale(storeState)
  const consentTracking = selectHasUserConsentedForTracking(storeState)

  global.ATInternet.Utils.consentReceived(consentTracking)

  tag = new global.ATInternet.Tracker.Tag({
    secure: true,
    cookieDomain: getCookieDomain(location.host),
    site: getDefaults()[locale].xtsite
  })

  return tag
}

const sendTag = type => info => {
  doAtInternetAction(tag => {
    tag[type].send(info)
  })
}

export const doAtInternetAction = fn => {
  try {
    fn(getTag())
  } catch (err) {
    tagsBuffer.push(() => {
      fn(getTag())
    })
  }
}

export const sendPageTag = info => {
  sendTag('page')(info)
  callAbTestOnceWithCondition.setCondition(true)
}
export const sendClickTag = sendTag('click')
export const sendPublisherTag = sendTag('publisher')

export const sendEventTag = (tagType, tagData) => {
  doAtInternetAction(tag => {
    tag.events.send(tagType, {
      tag_version: 'V3',
      ...tagData
    })
  })
}

const buildATAbTestString = (value, key) => `${Math.abs(hashCode(key || value))}[${value}]`

export const sendAbTestTags = (enrolledVariants = []) => {
  const tagFn = () => {
    enrolledVariants
      .filter(item => item?.id && item?.variant && item?.analytics)
      .forEach(({ id, variant }) => sendMvTestingTag(id, variant))
  }

  callAbTestOnceWithCondition.setFn(tagFn)
}

export const sendMvTestingTag = (id, variant) => {
  doAtInternetAction(tag => {
    const variantId = variant.id
    const variantData = variant.data
    const abTestVariant = {
      test: buildATAbTestString(id),
      waveId: 1,
      creation: buildATAbTestString(variantId, id + variantId)
    }
    tag.mvTesting.set(abTestVariant)
    if (variantData) {
      Object.keys(variantData).forEach(key => {
        const value = variantData[key]
        tag.mvTesting.add({
          variable: buildATAbTestString(key),
          version: buildATAbTestString(value, key + value)
        })
      })
    }
    tag.dispatch()
  })
}

export const setUserIdInTag = id => {
  doAtInternetAction(tag => {
    tag.identifiedVisitor.set({ id })
  })
}

export const unsetUserIdInTag = () => {
  doAtInternetAction(tag => {
    tag.identifiedVisitor.unset()
  })
}
