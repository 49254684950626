import { createSlice } from '@reduxjs/toolkit'
import { areLatLngAtMeterPrecisionEquals } from '../../domain/utils/location'
import { GEOLOC_STATUS_DISABLED } from '../map/map.constants'
import {
  selectGeolocationPosition,
  selectGeolocationRequestStatus,
  selectGeolocationStatus
} from './geolocation.selectors'

const initialState = {
  status: GEOLOC_STATUS_DISABLED,
  position: null,
  requestStatus: null,
  fetchOptions: null
}

const prepareStatusAndOptions = (status, options) => ({
  payload: { status, options }
})

const geolocationSlice = createSlice({
  name: 'geolocation',
  initialState,
  reducers: {
    setGeolocationStatus: {
      reducer: (state, action) => {
        const { status, options } = action.payload
        const currentStatus = selectGeolocationStatus({ geolocation: state })
        if (status === currentStatus) return
        state.status = status
        state.fetchOptions = options
      },
      prepare: prepareStatusAndOptions
    },
    setGeolocationPosition: (state, action) => {
      const currentPosition = selectGeolocationPosition({ geolocation: state })
      const position = action.payload
      if (!areLatLngAtMeterPrecisionEquals(position, currentPosition)) {
        state.position = position
      }
    },
    requestGeolocationStatus: {
      reducer: (state, action) => {
        const { status, options } = action.payload
        const currentStatus = selectGeolocationRequestStatus({ geolocation: state })
        if (status === currentStatus) return
        state.requestStatus = status
        state.fetchOptions = options
      },
      prepare: prepareStatusAndOptions
    },
    cleanGeolocationAfterFetch: (state, action) => {
      state.requestStatus = null
      state.fetchOptions = null
    }
  }
})

export const { setGeolocationStatus, setGeolocationPosition, requestGeolocationStatus, cleanGeolocationAfterFetch } =
  geolocationSlice.actions

export default geolocationSlice
