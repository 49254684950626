import classnames from 'classnames'
import debug from 'debug'
import React, { useCallback, useEffect, useState } from 'react'
import { requestSvgIcon, requestSvgUrl } from '../../dataSource/poiassets/poiassets.requests'
import { getSvgIconUrl } from '../../parameters'
import { withEmptyFallbackErrorBoundary } from '../error/EmptyFallbackErrorBoundary'
import s from './SVGIcon.less'
import pointSVG from './point'

const d = debug('SVGIcon')

const iconCache = {}

const SVGIcon = (props = {}) => {
  const { icon, url, className, title, ssrGrey = true, ssrWhite = false, alt } = props

  const cacheKey = url || icon
  const hasIconOrUrl = Boolean(icon || url)
  const isIconOrUrlAString = typeof cacheKey === 'string'

  if (!hasIconOrUrl) console.error('An SVGIcon has been called without url and icon !', props)
  if (!isIconOrUrlAString) console.error('An SVGIcon has been called with url/icon NOT being a string !', props)

  const pointIcon = icon === 'point' || !hasIconOrUrl
  const [displayed, setDisplayed] = useState(true)
  const [svg, setSVG] = useState(pointIcon ? pointSVG : iconCache[cacheKey])

  const fetchIcon = useCallback(async () => {
    if (d.enabled) d('fetchIcon', url ? `url: ${url}` : `icon: ${icon}`)
    return (url ? requestSvgUrl(url) : requestSvgIcon(global.__SVG_TIMESTAMP__, icon))
      .then(({ data: svg }) => {
        if (displayed) setSVG((iconCache[cacheKey] = svg))
      })
      .catch(() => {
        if (displayed) setSVG((iconCache[cacheKey] = pointSVG))
      })
  }, [url, icon, setSVG])

  // necessary to avoid a react warning if component is unmounted before SVG has been received
  useEffect(() => () => setDisplayed(false), [])

  useEffect(() => {
    if (__BROWSER__) {
      if (!iconCache[cacheKey] && !pointIcon && hasIconOrUrl && isIconOrUrlAString) fetchIcon()
      if (iconCache[cacheKey]) setSVG(iconCache[cacheKey])
    }
  }, [pointIcon, hasIconOrUrl, isIconOrUrlAString, fetchIcon, setSVG])

  if (!isIconOrUrlAString) return <></>

  if (__BROWSER__) {
    if (svg?.startsWith('<svg'))
      return <span className={className} dangerouslySetInnerHTML={{ __html: svg }} title={title} />
    return <></>
  }

  if (!hasIconOrUrl) return <></>

  /*
   * On server, we’re using an <img> image to make page lighter (no inline SVG) and avoid react-ssr-prepass to fetch icons
   * You can change color via a CSS filter, see SVGIcon.less
   */
  return (
    <span className={className}>
      <img
        className={classnames(s.ssrImg, ssrGrey && s.ssrImgGrey, ssrWhite && s.ssrImgWhite)}
        src={url || getSvgIconUrl(global.__SVG_TIMESTAMP__, icon)}
        alt={alt}
        title={title}
      />
    </span>
  )
}
export default withEmptyFallbackErrorBoundary(SVGIcon)
