import debug from 'debug'
import {
  ROUTE_360_VIEW_FULLSCREEN,
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_MODAL
} from '../../routes'
import { TAG_MULTIPATH_CTA, TAG_PUBLISHER } from './analytics.tagids.constants'
import {
  configureAndSendPublisherTag,
  getMultipathPublisherPredefinedTagInfo
} from '../../store/analytics/publisherTags.utils'
import {
  selectCurrentHistoryFullRoute,
  selectCurrentHistoryRoute,
  selectPrevFullRouteNonPageLess,
  selectPreviousHistoryRoute,
  selectShouldAvoidSendPageTag
} from '../../store/history/history.selectors'
import { selectIsOnboardingContext } from '../../store/navigation/navigation.selectors'
import { noop } from '../../utils/function'
import { omit } from '../../utils/object'
import { sendClickTag, sendEventTag, sendPageTag } from './ATInternetService'
import { configureTagClick } from './clickTags.utils'
import { ROUTE_TO_PAGE_TAG } from './pageTags.constants'
import { resolvePageTag } from './PageTagsService'
import { configureTagEvent, TAG_TYPES } from './v3/tagsV3.utils'

const d = debug('sendTags')

/**
 * This function receives mainly a tag and provider and  build predefined tag informations for multipath
 * then it delegates the rest to generic publishTags (which given some tagInfo, it formats the tag for the analytics lib and send it )
 * It is currently used for handling:
 * - CTA impression and clicks tags through <TagActionButton /> or <TagActionLink />
 * - all multipath route related click tags (impression is handled in batch in sendAllMultipathRoutePublisherImpressionTags())
 */
export const sendPublisherMultipathTag = ({ isImpression, tagInfo }, storeState) => {
  const multipathPredefinedTagInfo = getMultipathPublisherPredefinedTagInfo(tagInfo, storeState)

  if (!multipathPredefinedTagInfo) return

  configureAndSendTag(
    {
      type: TAG_PUBLISHER,
      isImpression: Boolean(isImpression),
      tagInfo: {
        ...tagInfo,
        ...multipathPredefinedTagInfo
      }
    },
    storeState
  )
}

export const configureAndSendTag = (tag, storeState) => {
  if (!tag) return

  if (tag.type === TAG_MULTIPATH_CTA) {
    const { isImpression, tagInfo } = tag
    sendPublisherMultipathTag({ isImpression, tagInfo }, storeState)
    return
  }

  if (tag.type === TAG_PUBLISHER) {
    configureAndSendPublisherTag({ action: tag, getState: () => storeState })
    return
  }

  const configuredTagV3 = configureTagEvent({ action: tag, store: storeState }, TAG_TYPES.CLICK)
  if (configuredTagV3) {
    const event_name = configuredTagV3.event_name ?? 'click.action'
    sendEventTag(event_name, omit(configuredTagV3, ['event_name']))
  }

  const configuredTag = configureTagClick({ action: tag, store: storeState })
  if (configuredTag) {
    d('sendingClickTag', configuredTag)
    sendClickTag(configuredTag)
  }
}

export const configureAndSendPageTag = (action, storeState) => {
  if (__SERVER__) return

  const currentHistoryRoute = selectCurrentHistoryRoute(storeState)
  const currentHistoryFullRoute = selectCurrentHistoryFullRoute(storeState)
  const previousHistoryRoute = selectPreviousHistoryRoute(storeState)
  const prevFullRouteNonPageLess = selectPrevFullRouteNonPageLess(storeState)
  const isOnboardingContext = selectIsOnboardingContext(storeState)

  if (
    // do not send tag page for navigation with special attribut or
    // for "calculation" routes because they don't have physically page.
    currentHistoryRoute === ROUTE_ITINERARY_RESULTS_BY_PROVIDER ||
    currentHistoryRoute === ROUTE_ITINERARY_COMPUTING ||
    selectShouldAvoidSendPageTag(storeState)
  )
    return
  if (!isOnboardingContext && prevFullRouteNonPageLess === currentHistoryFullRoute) return
  if (previousHistoryRoute === ROUTE_MODAL || previousHistoryRoute === ROUTE_360_VIEW_FULLSCREEN) return

  d('send page tag for route', currentHistoryFullRoute)

  try {
    const query = action?.payload?.query
    const pageTag = (ROUTE_TO_PAGE_TAG[currentHistoryRoute] || noop)(storeState)
    if (!pageTag) {
      d(`No tag have been defined for ${currentHistoryFullRoute}`)
      return
    }

    const resolvedPageTag = resolvePageTag({ pageTag, store: storeState, query })
    d('resolved Page Tag', resolvedPageTag)

    sendPageTag(resolvedPageTag)
  } catch (error) {
    console.error(error)
  }
}

export const configureAndSendPageTagV3 = (action, storeState) => {
  const route = selectCurrentHistoryRoute(storeState)
  const fullRoute = selectCurrentHistoryFullRoute(storeState)
  const configuredTag = configureTagEvent({ action, store: storeState, route }, TAG_TYPES.PAGE)

  if (
    route === ROUTE_ITINERARY_RESULTS_BY_PROVIDER ||
    route === ROUTE_ITINERARY_COMPUTING ||
    selectShouldAvoidSendPageTag(storeState)
  ) {
    // do not send tag page for navigation with special attribut or
    // for "calculation" routes because they don't have physically page.
    d(`skipping tagging for ${fullRoute}`)
    return
  }

  if (!configuredTag) {
    d(`No tag have been defined for ${fullRoute}`)
    return
  }

  if (configuredTag) {
    d('sending page tag V3 ', configuredTag)
    const event_name = configuredTag.event_name ?? 'affichage.page'
    sendEventTag(event_name, configuredTag)
  }
}
