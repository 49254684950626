import { string, shape, arrayOf, object, number, bool } from 'prop-types'

export const illustrationPropTypes = shape({
  numberOfImages: number,
  url: string
})

export const cornerShopPropTypes = shape({
  id: string,
  tabUrl: string,
  iconUrl: string
})

export const transportInfoPropTypes = shape({
  mode: string,
  lines: arrayOf(string)
})

export const geoentityBlocksPropTypes = arrayOf(object)

export const geoentityTabContentPropTypes = shape({
  title: string,
  blocks: geoentityBlocksPropTypes,
  context: string
})

export const geoentityTabPropTypes = shape({
  appId: string,
  url: string,
  content: arrayOf(geoentityTabContentPropTypes)
})

export const geoentityPropTypes = shape({
  id: string,
  name: string,
  town: string,
  way: string,
  towncode: string,
  regionCode: number,
  countryCode: number,
  openingHours: string,
  pjId: string,
  contextualPoiUrl: string,
  prov: string,
  offer: string,
  brand: string,
  brandIconUrl: string,
  closestPanoramicId: string,
  cornerShops: arrayOf(cornerShopPropTypes),
  transportInfo: arrayOf(transportInfoPropTypes),
  rubric: shape({
    label: string,
    id: string,
    parent: string
  }),
  tabs: arrayOf(geoentityTabPropTypes),
  additionalInfo: shape({
    illustration: illustrationPropTypes,
    promo: bool,
    teasing: string,
    secondTeasing: string,
    reservationTemplatizedUrl: string,
    prestations: string,
    reviews: shape({
      averageNote: number,
      maxNote: number,
      numberOfReviews: number
    })
  }),
  communication: shape({
    phone: shape({
      againstDirectMarketing: bool,
      number: string
    }),
    website: string
  })
})

export const geoentityIdentityCardPropTypes = shape({
  name: string,
  way: string,
  type: string,
  icon: string,
  rating: string
})

export const geoentitiesPropTypes = arrayOf(geoentityPropTypes)

export const geoentityLatLngPropTypes = shape({
  lat: number,
  lng: number
})

export const geoentityAddressCompomentsPropTypes = shape({
  way: string,
  town: string,
  postcode: string,
  countryCode: number
})

export const geoentityReviewsPropTypes = shape({
  averageNote: number,
  maxNote: number,
  numberOfReviews: number
})
