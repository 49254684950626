import { isEmpty } from '../utils/lang'

export const DEVICE_BREAKPOINT = '(min-width: 999px)'

const GE = global.matchMedia ? global.matchMedia(DEVICE_BREAKPOINT).matches : false

export const isGE = () => GE

export const isPE = () => !GE

export const withOnlyGE = Component => {
  if (!isGE()) return () => null

  return Component
}

export const withOnlyPE = Component => {
  if (isGE()) return () => null

  return Component
}

export const withOnlyBrowser = Component => {
  if (__BROWSER__) return Component
  else return () => null
}

export const getDevicePixelRatio = () => {
  const dpr = Math.floor(global.devicePixelRatio || 1)
  return Math.max(2, Math.min(dpr, 4))
}

export const hasTouchSupport = () => {
  // From http://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript/4819886#4819886
  return Boolean('ontouchstart' in window) || Boolean('msmaxtouchpoints' in window.navigator)
}

export const isAndroid = input => {
  const { userAgent } = input || navigator

  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }

  return /android/i.test(userAgent)
}

export const isIos = input => {
  const { userAgent } = input || navigator

  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }
  return /ipad/i.test(userAgent) || /iphone/i.test(userAgent)
}

export const isRetina = () => typeof window !== 'undefined' && window.devicePixelRatio > 1

const BOTS_KEYWORD_LIST = ['bingbot']

export const isBotNotWebGlCompliant = input => {
  const { userAgent } = input || navigator
  if (isEmpty(userAgent)) {
    throw new Error('Parameter userAgent missing')
  }
  return BOTS_KEYWORD_LIST.some(botKeyword => userAgent.includes(botKeyword))
}
