import { createSlice } from '@reduxjs/toolkit'
import { MAP_MODES, MAP_STATUS_INIT, MAP_STATUS_NONE, MAP_THEMES } from './map.constants'
import { selectIsMapFullScreen } from './map.selectors'

export const initialState = {
  vecto: true,
  geoip: false,
  bbox: null,
  center: undefined,
  activeBbox: null,
  searchBbox: null,
  targetBbox: undefined,
  targetCenter: undefined,
  targetOptions: undefined,
  targetZoom: undefined,
  theme: MAP_THEMES.normal,
  mode: MAP_MODES.neutral,
  fullscreen: false,
  status: MAP_STATUS_NONE,
  popupType: null,
  mapEvents: undefined
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    sendMapLoadingTime: (state, action) => {
      /* for middleware purpose */
    },
    mapUpdated: (state, action) => {
      const { zoom, bbox, activeBbox, searchBbox } = action.payload
      state.zoom = zoom
      state.bbox = bbox
      state.center = undefined
      state.activeBbox = activeBbox
      state.searchBbox = searchBbox
    },
    setDefaultBbox: (state, action) => {
      const { bbox } = action.payload
      state.bbox = bbox
    },
    requestMapMove: (state, action) => {
      const { bbox, center, options } = action.payload
      state.targetBbox = bbox
      state.targetCenter = center
      state.targetOptions = options
    },
    acknowledgeMapMove: (state, action) => {
      state.targetBbox = undefined
      state.targetCenter = undefined
      state.targetOptions = undefined
    },
    requestMapZoom: (state, action) => {
      const { zoom } = action.payload
      state.targetZoom = zoom
    },
    acknowledgeMapZoom: (state, action) => {
      state.targetZoom = undefined
    },
    setMapTheme: (state, action) => {
      state.theme = action.payload
    },
    setMapMode: (state, action) => {
      state.mode = action.payload
    },
    activateGeoIp: (state, action) => {
      state.geoip = true
    },
    setMapStatus: (state, action) => {
      state.status = action.payload
    },
    resetMapStatus: (state, action) => {
      state.status = MAP_STATUS_INIT
    },
    mapDestroyed: (state, action) => {
      return { ...initialState, bbox: state.bbox }
    },
    toggleFullscreen: (state, action) => {
      state.fullscreen = !state.fullscreen
    },
    turnOffFullscreen: (state, action) => {
      if (selectIsMapFullScreen({ map: state })) state.fullscreen = false
    },
    displayMapPopup: (state, action) => {
      state.popupType = action.payload
    },
    hideMapPopup: (state, action) => {
      state.popupType = null
    },
    switchMapType: (state, action) => {
      state.vecto = !state.vecto
    },
    setMapEvents: (state, action) => {
      state.mapEvents = action.payload ?? []
    },
    setMapEventsPopin: (state, action) => {
      state.eventPopin = action.payload
    },
    resetMapEventsPopin: (state, action) => {
      state.eventPopin = undefined
    },
    setMapEventsDate: (state, action) => {
      state.mapEventDate = action.payload
    },
    setMapEventsTime: (state, action) => {
      state.mapEventTime = action.payload
    }
  }
})

export const {
  sendMapLoadingTime,
  mapUpdated,
  setDefaultBbox,
  requestMapMove,
  acknowledgeMapMove,
  requestMapZoom,
  acknowledgeMapZoom,
  setMapTheme,
  setMapMode,
  activateGeoIp,
  setMapStatus,
  resetMapStatus,
  mapDestroyed,
  toggleFullscreen,
  turnOffFullscreen,
  displayMapPopup,
  hideMapPopup,
  switchMapType,
  setMapEvents,
  setMapEventsPopin,
  resetMapEventsPopin,
  setMapEventsDate,
  setMapEventsTime
} = mapSlice.actions

export default mapSlice
