import {
  ROUTE_ADDRESS,
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_HOME,
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_COMPUTING_BY_MODE,
  ROUTE_ITINERARY_EXPENSE_REPORT,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_TO,
  ROUTE_MODAL,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_REGION
} from '../../routes'
import { generateFullRoute } from '../../domain/router/routeUtils'
import {
  generateBboxParamForSearchRoute,
  getDepartmentParameters,
  getRegionParameters,
  getCountryParameters,
  getSearchParams,
  getCityParameters
} from './history.search'
import { selectFirstAddress } from '../address/address.selectors'
import { getItineraryParams, getItineraryQueryStringParams } from './history.itinerary.utils'
import { routeParameterToBbox } from '../../domain/map/conversion'

export const routeFormatter = ({ storeState, route, selectedStoreState, routeOptions }) => {
  try {
    switch (route) {
      case ROUTE_ADDRESS: {
        return generateFullRoute(
          route,
          getSearchParams({
            route,
            routeOptions,
            state: storeState,
            selectedStoreState
          })
        )
      }

      case ROUTE_SEO_ADDRESS:
        return generateFullRoute(route, getCityParameters({ state: storeState, selectedStoreState }))

      case ROUTE_SEO_COUNTRY:
        return generateFullRoute(route, getCountryParameters({ state: storeState, selectedStoreState }))

      case ROUTE_SEO_REGION:
        return generateFullRoute(route, getRegionParameters({ state: storeState, selectedStoreState }))

      case ROUTE_SEO_DEPARTMENT:
        return generateFullRoute(route, getDepartmentParameters({ state: storeState, selectedStoreState }))

      case ROUTE_GEOENTITY_AT_ADDRESS: {
        const address = selectFirstAddress(storeState)

        const { location } = getSearchParams({
          route,
          state: storeState,
          selectedStoreState: { address }
        })

        return generateFullRoute(route, {
          location,
          ...routeOptions
        })
      }

      case ROUTE_ITINERARY_COMPUTING:
      case ROUTE_ITINERARY_COMPUTING_BY_MODE:
      case ROUTE_ITINERARY_RESULTS_BY_PROVIDER:
      case ROUTE_ITINERARY_RESULTS_BY_ROUTE:
      case ROUTE_ITINERARY_EXPENSE_REPORT:
      case ROUTE_ITINERARY_ROADBOOK: {
        const params = getItineraryParams({
          route,
          routeOptions,
          state: storeState,
          selectedStoreState
        })
        return generateFullRoute(route, params, getItineraryQueryStringParams({ state: storeState, routeOptions }))
      }

      // FIXME: this redundant with next default case
      case ROUTE_ITINERARY_TO: {
        const { to } = routeOptions || {}
        return generateFullRoute(route, { to })
      }

      case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP:
      case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
      case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY:
      case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP:
      case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
      case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY: {
        const params = getItineraryParams({
          route,
          routeOptions,
          state: storeState,
          selectedStoreState
        })
        return generateFullRoute(route, params)
      }

      case ROUTE_SEARCH_BY_APP:
      case ROUTE_SEARCH_BY_CATEGORY:
      case ROUTE_SEARCH_BY_SUBCATEGORY:
      case ROUTE_SEARCH_BY_RUBRIC:
      case ROUTE_SEARCH_BY_QUERY:
      case ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY:
      case ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY:
      case ROUTE_GEOENTITY_ON_SEARCH_BY_APP:
      case ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC:
      case ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY: {
        return generateFullRoute(route, {
          ...generateBboxParamForSearchRoute({ state: storeState, selectedStoreState }),
          ...routeOptions
        })
      }

      case ROUTE_MODAL:
      case ROUTE_HOME:
      case ROUTE_ITINERARY_HOME:
      default:
        return generateFullRoute(route, routeOptions)
    }
  } catch (e) {
    console.warn('routeFormatter failed', e.message, e)
  }
}

export const changeDocumentLocation = url => {
  if (__BROWSER__) {
    document.location = url
  }
}

export const parseRouteOptions = (routeOptions = {}) => ({
  ...routeOptions,
  ...(routeOptions.bbox && typeof routeOptions.bbox === 'string'
    ? { bbox: routeParameterToBbox(routeOptions.bbox) }
    : {})
})
