import { createSlice } from '@reduxjs/toolkit'
import { PREFERRED_FUEL_ID, PREFERRED_VEHICLE_ID } from '../../domain/itineraryOptions/ItineraryOptions.constants'
import { getCurrentDateISO, getCurrentTime } from '../../domain/utils/date'
import { merge, pick } from '../../utils/object'
import { ARRIVAL_LATER, DEPARTURE_LATER, DEPARTURE_NOW } from './itineraryTimeOptionsWhenTypes'

export const initialState = {
  preferredOptions: {},
  defaultOptions: {
    vehiclesOptions: [],
    fuelPrices: {},
    transportModes: []
  },
  timeOptions: {
    when: DEPARTURE_NOW,
    date: undefined,
    time: undefined
  }
}

const compileRelevantPrevPreferredOptionsForMode = ({ modeId, preferredOptions, newPreferredOptionsForMode }) => {
  const shouldOverrideVehicleOptions = Boolean(newPreferredOptionsForMode[PREFERRED_VEHICLE_ID])
  const shouldOverrideGasOptions = Boolean(newPreferredOptionsForMode[PREFERRED_FUEL_ID])

  if (shouldOverrideVehicleOptions) {
    return {}
  }

  if (shouldOverrideGasOptions) {
    return pick(preferredOptions[modeId], PREFERRED_VEHICLE_ID)
  }

  return preferredOptions[modeId]
}

const itineraryOptionsSlice = createSlice({
  name: 'itineraryOptions',
  initialState,
  reducers: {
    setDefaultVehicleOptions: (state, action) => {
      const { vehiclesOptions, transportModes } = action.payload
      if (vehiclesOptions) state.defaultOptions.vehiclesOptions = vehiclesOptions
      if (transportModes) state.defaultOptions.transportModes = transportModes
    },
    setDefaultFuelPrices: (state, action) => {
      const { fuelPrices } = action.payload
      state.defaultOptions.fuelPrices = fuelPrices
    },
    setPreferredOptions: (state, action) => {
      const { options } = action.payload
      state.preferredOptions = merge(state.preferredOptions, options)
    },
    setPreferredOptionsForMode: (state, action) => {
      const { modeId, ...newPreferredOptionsForMode } = action.payload
      const relevantPrevPreferredOptionsForMode = compileRelevantPrevPreferredOptionsForMode({
        modeId,
        preferredOptions: state.preferredOptions,
        newPreferredOptionsForMode
      })

      state.preferredOptions = {
        ...state.preferredOptions,
        [modeId]: {
          ...relevantPrevPreferredOptionsForMode,
          ...newPreferredOptionsForMode
        }
      }
    },
    setPreferredMode: (state, action) => {
      const { modeId } = action.payload
      state.preferredOptions.favoriteMode = modeId
    },
    setItineraryDepartureNow: state => {
      state.timeOptions = initialState.timeOptions
    },
    setItineraryDepartureLater: state => {
      state.timeOptions = {
        when: DEPARTURE_LATER,
        date: state.timeOptions.date || getCurrentDateISO(),
        time: state.timeOptions.time || getCurrentTime()
      }
    },
    setItineraryArrivalLater: state => {
      state.timeOptions = {
        when: ARRIVAL_LATER,
        date: state.timeOptions.date || getCurrentDateISO(),
        time: state.timeOptions.time || getCurrentTime()
      }
    },
    setItineraryDate: (state, action) => {
      if (state.timeOptions.when !== DEPARTURE_NOW) {
        state.timeOptions.date = action?.payload?.date
      }
    },
    setItineraryTime: (state, action) => {
      if (state.timeOptions.when !== DEPARTURE_NOW) {
        state.timeOptions.time = action?.payload?.time
      }
    }
  }
})

export const {
  setDefaultVehicleOptions,
  setDefaultFuelPrices,
  setPreferredOptions,
  setPreferredOptionsForMode,
  setPreferredMode,
  setItineraryDepartureNow,
  setItineraryDepartureLater,
  setItineraryArrivalLater,
  setItineraryDate,
  setItineraryTime
} = itineraryOptionsSlice.actions

export default itineraryOptionsSlice
