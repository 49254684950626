import { selectLocale } from '../../store/navigation/navigation.selectors'
import { LOCALES } from '../i18n'
import { genericOrangeAdConfig } from './orangeGenericAdConfig'

export const ORANGE_AD_LIB_CONFIG_SCRIPT = 'https://cdn.adgtw.orangeads.fr/build/adgw-spa.min.js'

const idBuilders = {
  [LOCALES.fr_FR]: id => `map_${id}`,
  [LOCALES.fr_BE]: id => `mbe_${id}.fs`,
  [LOCALES.nl_BE]: id => `mbe_${id}.nl`,
  [LOCALES.en_GB]: id => `men_${id}`
}

let adConfig

export const getOrangeAdConfig = () => {
  if (__SERVER__) return []
  if (!adConfig) {
    const storeState = global.__mappy__store__rare_usage.getState()
    const locale = selectLocale(storeState)

    const builder = idBuilders[locale] || idBuilders[LOCALES.fr_FR]
    adConfig = genericOrangeAdConfig.map(item => ({
      ...item,
      pageAdId: builder(item.pageAdId),
      ads: item.ads.map(ad => ({
        ...ad,
        adId: builder(ad.adId)
      }))
    }))
  }
  return adConfig
}

// method used only for unit tests
export const clearOrangeAdConfig = () => {
  adConfig = null
}

export const getPageAdConfigByPageId = pageId =>
  getOrangeAdConfig().find(config => config.pageIds && config.pageIds.find(id => id === pageId)) || {}

export const getPageAdConfigByPageAdId = pageAdId => getOrangeAdConfig().find(config => config.pageAdId === pageAdId)

export const getPageAdIdByPageId = pageId => getPageAdConfigByPageId(pageId).pageAdId

export const getAdConfigByName = (adName, pageId) => {
  const orangeAdConfig = getOrangeAdConfig()

  const ads = pageId
    ? orangeAdConfig.find(({ pageIds }) => pageIds.includes(pageId))?.ads
    : orangeAdConfig.flatMap(({ ads }) => ads)

  return ads?.find(adConfig => adConfig.adName === adName)
}

export const getAdIdByAdName = (adName, pageId) => getAdConfigByName(adName, pageId)?.adId

export const doesAdBelongToPage = ({ adName, pageId }) => {
  const pageAdId = getPageAdIdByPageId(pageId)
  const pageAdConfig = getPageAdConfigByPageAdId(pageAdId)
  const adConfig = getAdConfigByName(adName, pageId)

  if (!pageAdConfig || !adConfig) return false

  return pageAdConfig.ads.includes(adConfig)
}
