/* eslint-disable sonarjs/no-identical-functions */
import 'react'

import { FIVE_MINUTES, ONE_DAY, ONE_HOUR, ONE_MINUTE, ONE_WEEK } from '../domain/utils/date'
import { sanitizeRoute } from '../domain/utils/url'
import { NO_CACHE } from '../node/cache'
import TIMEOUTS from './timeout.constants'

export const ROUTE_HOME = 'ROUTE_HOME'
export const ROUTE_ITINERARY_HOME = 'ROUTE_ITINERARY_HOME'
export const ROUTE_ITINERARY_HP_BY_MODE = 'ROUTE_ITINERARY_HP_BY_MODE'
export const ROUTE_ITINERARY_TO = 'ROUTE_ITINERARY_TO'
export const ROUTE_ITINERARY_COMPUTING = 'ROUTE_ITINERARY_COMPUTING'
export const ROUTE_ITINERARY_COMPUTING_BY_MODE = 'ROUTE_ITINERARY_COMPUTING_BY_MODE'
export const ROUTE_ITINERARY_RESULTS_BY_PROVIDER = 'ROUTE_ITINERARY_RESULTS_BY_PROVIDER'
export const ROUTE_ITINERARY_RESULTS_BY_ROUTE = 'ROUTE_ITINERARY_RESULTS_BY_ROUTE'
export const ROUTE_ITINERARY_EXPENSE_REPORT = 'ROUTE_ITINERARY_EXPENSE_REPORT'
export const ROUTE_ITINERARY_ROADBOOK = 'ROUTE_ITINERARY_ROADBOOK'
export const ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP = 'ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP'
export const ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY = 'ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY'
export const ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY = 'ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY'
export const ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP =
  'ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP'
export const ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY =
  'ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY'
export const ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY =
  'ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY'
export const ROUTE_SEO_ADDRESS = 'ROUTE_SEO_ADDRESS'
export const ROUTE_ADDRESS = 'ROUTE_ADDRESS'
export const ROUTE_GEOENTITY_AT_ADDRESS = 'ROUTE_GEOENTITY_AT_ADDRESS'
export const ROUTE_SEARCH_BY_CATEGORY = 'ROUTE_SEARCH_BY_CATEGORY'
export const ROUTE_SEARCH_BY_SUBCATEGORY = 'ROUTE_SEARCH_BY_SUBCATEGORY'
export const ROUTE_SEARCH_BY_APP = 'ROUTE_SEARCH_BY_APP'
export const ROUTE_SEO_SEARCH_BY_APP = 'ROUTE_SEO_SEARCH_BY_APP'
export const ROUTE_SEARCH_BY_RUBRIC = 'ROUTE_SEARCH_BY_RUBRIC'
export const ROUTE_SEO_SEARCH_BY_RUBRIC = 'ROUTE_SEO_SEARCH_BY_RUBRIC'
export const ROUTE_SEARCH_BY_QUERY = 'ROUTE_SEARCH_BY_QUERY'
export const ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY = 'ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY'
export const ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY = 'ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY'
export const ROUTE_GEOENTITY_ON_SEARCH_BY_APP = 'ROUTE_GEOENTITY_ON_SEARCH_BY_APP'
export const ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC = 'ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC'
export const ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY = 'ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY'
export const ROUTE_SUGGEST = 'ROUTE_SUGGEST'
export const ROUTE_ITINERARY_OPTIONS_DIALOG = 'ROUTE_ITINERARY_OPTIONS_DIALOG'
export const ROUTE_ITINERARY_TIME_OPTIONS_DIALOG = 'ROUTE_ITINERARY_TIME_OPTIONS_DIALOG'
export const ROUTE_GDT = 'ROUTE_GDT'
export const ROUTE_TNR = 'ROUTE_TNR'
export const ROUTE_SEO_GEOENTITY = 'ROUTE_SEO_GEOENTITY'
export const ROUTE_LEGACY_SEO_GEOENTITY = 'ROUTE_LEGACY_SEO_GEOENTITY'
export const ROUTE_SEO_ITINERARY = 'ROUTE_SEO_ITINERARY'
export const ROUTE_SEO_ITINERARY_MODE = 'ROUTE_SEO_ITINERARY_MODE'
export const ROUTE_SEO_CITY_ACTIVITY = 'ROUTE_SEO_CITY_ACTIVITY'
export const ROUTE_SEO_CITY_APP = 'ROUTE_SEO_CITY_APP'
export const ROUTE_SEO_DIRECTORY_ITINERARY = 'ROUTE_SEO_DIRECTORY_ITINERARY'
export const ROUTE_SEO_DIRECTORY_ITINERARY_BY_MODE = 'ROUTE_SEO_DIRECTORY_ITINERARY_BY_MODE'
export const ROUTE_SEO_DIRECTORY_CITY = 'ROUTE_SEO_DIRECTORY_CITY'
export const ROUTE_SEO_DIRECTORY_CITY_POIS = 'ROUTE_SEO_DIRECTORY_CITY_POIS'
export const ROUTE_SEO_DIRECTORY_CITY_ACTIVITY = 'ROUTE_SEO_DIRECTORY_CITY_ACTIVITY'
export const ROUTE_SEO_DIRECTORY_CITY_OVM_POIS = 'ROUTE_SEO_DIRECTORY_CITY_OVM_POIS'
export const ROUTE_SEO_DIRECTORY_CITY_OVM_POIS_ACTIVITY = 'ROUTE_SEO_DIRECTORY_CITY_OVM_POIS_ACTIVITY'
export const ROUTE_SEO_DEPARTMENT = 'ROUTE_SEO_DEPARTMENT'
export const ROUTE_SEO_REGION = 'ROUTE_SEO_REGION'
export const ROUTE_SEO_COUNTRY = 'ROUTE_SEO_COUNTRY'
export const ROUTE_REGION_HOME = 'ROUTE_REGION_HOME'
export const ROUTE_DEPARTMENT_HOME = 'ROUTE_DEPARTMENT_HOME'
export const ROUTE_ADDRESS_HOME = 'ROUTE_ADDRESS_HOME'
export const ROUTE_TOOLS_COMPUTE_RADIUS = 'ROUTE_TOOLS_COMPUTE_RADIUS'
export const ROUTE_MODAL = 'ROUTE_MODAL'
export const ROUTE_USER_ACCOUNT = 'ROUTE_USER_ACCOUNT'
export const ROUTE_360_VIEW_FULLSCREEN = 'ROUTE_360_VIEW_FULLSCREEN'
export const ROUTE_TRAFFIC_HOME = 'ROUTE_TRAFFIC_HOME'
export const ROUTE_BICYCLE_HOME = 'ROUTE_BICYCLE_HOME'
export const ROUTE_ZFE_HOME = 'ROUTE_ZFE_HOME'
export const ROUTE_RESTRICTION_HOME = 'ROUTE_RESTRICTION_HOME'
export const ROUTE_TRAFFIC_CITY = 'ROUTE_TRAFFIC_CITY'
export const ROUTE_BICYCLE_CITY = 'ROUTE_BICYCLE_CITY'
export const ROUTE_ZFE_CITY = 'ROUTE_ZFE_CITY'
export const ROUTE_RESTRICTION_CITY = 'ROUTE_RESTRICTION_CITY'
export const ROUTE_TRAFFIC_DEPARTMENT = 'ROUTE_TRAFFIC_DEPARTMENT'
export const ROUTE_BICYCLE_DEPARTMENT = 'ROUTE_BICYCLE_DEPARTMENT'
export const ROUTE_ZFE_DEPARTMENT = 'ROUTE_ZFE_DEPARTMENT'
export const ROUTE_RESTRICTION_DEPARTMENT = 'ROUTE_RESTRICTION_DEPARTMENT'
export const ROUTE_TRAFFIC_REGION = 'ROUTE_TRAFFIC_REGION'
export const ROUTE_BICYCLE_REGION = 'ROUTE_BICYCLE_REGION'
export const ROUTE_ZFE_REGION = 'ROUTE_ZFE_REGION'
export const ROUTE_RESTRICTION_REGION = 'ROUTE_RESTRICTION_REGION'
export const ROUTE_QUIZ_HOME = 'ROUTE_QUIZ_HOME'
export const ROUTE_QUIZ_CITIES = 'ROUTE_QUIZ_CITIES'
export const ROUTE_QUIZ_CITIES_REGION = 'ROUTE_QUIZ_CITIES_REGION'
export const ROUTE_QUIZ_CITIES_DEPARTMENT = 'ROUTE_QUIZ_CITIES_DEPARTMENT'

export const rawRoutes = [
  {
    name: ROUTE_SUGGEST,
    path: '/#/suggestion',
    isPageless: true,
    avoidReloadOnBackNav: false,
    action: () => import(/* webpackChunkName: "ROUTE_SUGGEST" */ './pageless/suggest.route')
  },
  {
    name: ROUTE_ITINERARY_OPTIONS_DIALOG,
    path: '/itineraire#/option_de_deplacement',
    isPageless: true,
    avoidReloadOnBackNav: false,
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_OPTIONS_DIALOG" */ './pageless/itineraryOptions.route')
  },
  {
    name: ROUTE_ITINERARY_TIME_OPTIONS_DIALOG,
    path: '/itineraire#/date_et_heure',
    isPageless: true,
    avoidReloadOnBackNav: false,
    action: () =>
      import(/* webpackChunkName: "ROUTE_ITINERARY_TIME_OPTIONS_DIALOG" */ './pageless/itineraryTimeOptions.route')
  },
  {
    name: ROUTE_MODAL,
    path: '/#/modale/:modalId',
    isPageless: true,
    avoidReloadOnBackNav: true,
    action: () => import(/* webpackChunkName: "ROUTE_MODAL" */ './pageless/modal.route')
  },
  {
    name: ROUTE_USER_ACCOUNT,
    path: '/#/moncompte',
    isPageless: true,
    avoidReloadOnBackNav: true,
    action: () => import(/* webpackChunkName: "ROUTE_USER_ACCOUNT" */ './pageless/userAccount.route')
  },
  {
    name: ROUTE_360_VIEW_FULLSCREEN,
    path: '/#/360-plein-ecran/:panoramicId',
    isPageless: true,
    avoidReloadOnBackNav: true,
    action: () => import(/* webpackChunkName: "ROUTE_360_VIEW_FULLSCREEN" */ './pageless/360view.route')
  },
  {
    name: ROUTE_HOME,
    path: '/',
    action: () => import(/* webpackChunkName: "ROUTE_HOME" */ './home/home.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_ITINERARY_HOME,
    path: '/itineraire',
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_HOME" */ './itinerary/itineraryHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_ITINERARY_HP_BY_MODE,
    path: '/itineraire/:mode',
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_HP_BY_MODE" */ './itinerary/itineraryHPByMode.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_ITINERARY_TO,
    path: '/itineraire#/vers/:to',
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_TO" */ './itinerary/itineraryHPTo.route')
  },
  {
    name: ROUTE_ITINERARY_COMPUTING,
    path: '/itineraire#/recherche/:from/:to',
    skipOnPop: true,
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_COMPUTING" */ './itinerary/itinerary-results.route')
  },
  {
    name: ROUTE_ITINERARY_COMPUTING_BY_MODE,
    path: '/itineraire#/recherche/:mode/:from/:to',
    skipOnPop: true,
    action: () =>
      import(/* webpackChunkName: "ROUTE_ITINERARY_COMPUTING_BY_MODE" */ './itinerary/itinerary-results.route')
  },
  {
    name: ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
    path: '/itineraire#/:mode/:from/:to/:provider',
    action: () =>
      import(/* webpackChunkName: "ROUTE_ITINERARY_RESULTS_BY_PROVIDER" */ './itinerary/itinerary-results.route')
  },
  {
    name: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId',
    action: () =>
      import(/* webpackChunkName: "ROUTE_ITINERARY_RESULTS_BY_ROUTE" */ './itinerary/itinerary-results.route')
  },
  {
    name: ROUTE_ITINERARY_ROADBOOK,
    path: '/itineraire#/:mode/:from/:to/:provider/feuille_de_route/:routeId',
    action: () => import(/* webpackChunkName: "ROUTE_ITINERARY_ROADBOOK" */ './itinerary/roadbook.route')
  },
  {
    name: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/application/:app',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP" */ './itinerary/itinerary-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/categorie/:category',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY" */ './itinerary/itinerary-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/sous-categorie/:subcategory',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY" */ './itinerary/itinerary-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/application/:app/poi/:geoentityId',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP" */ './itinerary/itinerary-geoentity-on-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/categorie/:category/poi/:geoentityId',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY" */ './itinerary/itinerary-geoentity-on-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
    path: '/itineraire#/:mode/:from/:to/:provider/:routeId/sous-categorie/:subcategory/poi/:geoentityId',
    action: () =>
      import(
        /* webpackChunkName: "ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY" */ './itinerary/itinerary-geoentity-on-search-pois.route'
      )
  },
  {
    name: ROUTE_ITINERARY_EXPENSE_REPORT,
    path: '/itineraire#/:mode/:from/:to/:provider/feuille_de_route/:routeId/note_de_frais',
    action: () =>
      import(/* webpackChunkName: "ROUTE_ITINERARY_EXPENSE_REPORT" */ './itinerary/itinerary-expense-report.route')
  },
  {
    name: ROUTE_SEO_GEOENTITY,
    path: '/poi/:geoentityId',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_GEOENTITY" */ './geoentity/poi.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_LEGACY_SEO_GEOENTITY,
    ssrOnly: true,
    path: '/activite/:rubric/:zip-:city/:poiname/:geoentityId',
    action: () => import(/* webpackChunkName: "ROUTE_LEGACY_SEO_GEOENTITY" */ './geoentity/legacy.poi.route')
  },
  {
    name: ROUTE_TOOLS_COMPUTE_RADIUS,
    path: '/outils/calcul-rayon',
    action: () => import(/* webpackChunkName: "ROUTE_TOOLS_COMPUTE_RADIUS" */ './tools/ComputeRadius.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_HOUR
  },
  {
    name: ROUTE_REGION_HOME,
    path: '/plan/region',
    action: () => import(/* webpackChunkName: "ROUTE_REGION_HOME" */ './zones/regionHP.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_DEPARTMENT_HOME,
    path: '/plan/departement',
    action: () => import(/* webpackChunkName: "ROUTE_DEPARTMENT_HOME" */ './zones/departmentHP.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DEPARTMENT,
    path: '/plan/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_DEPARTMENT" */ './SEO/department.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_REGION,
    path: '/plan/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_REGION" */ './SEO/region.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_COUNTRY,
    path: '/plan/pays/:countryCode-:country',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_COUNTRY" */ './SEO/country.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_ADDRESS_HOME,
    path: '/plan',
    action: () => import(/* webpackChunkName: "ROUTE_ADDRESS_HOME" */ './address/addressHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_SEARCH_BY_CATEGORY,
    path: '/plan#/recherche/categorie/:category?bbox=:bbox',
    action: () => import(/* webpackChunkName: "ROUTE_SEARCH_BY_CATEGORY" */ './search/search.route')
  },
  {
    name: ROUTE_SEARCH_BY_SUBCATEGORY,
    path: '/plan#/recherche/sous-categorie/:subcategory?bbox=:bbox',
    action: () => import(/* webpackChunkName: "ROUTE_SEARCH_BY_SUBCATEGORY" */ './search/search.route')
  },
  {
    name: ROUTE_SEARCH_BY_APP,
    path: '/app/:app#?bbox=:bbox',
    action: () => import(/* webpackChunkName: "ROUTE_SEARCH_BY_APP" */ './search/search.route')
  },
  {
    name: ROUTE_SEO_SEARCH_BY_APP,
    path: '/app/:app',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_SEARCH_BY_APP" */ './search/search.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEARCH_BY_RUBRIC,
    path: '/activite/:rubric#?bbox=:bbox',
    action: () => import(/* webpackChunkName: "ROUTE_SEARCH_BY_RUBRIC" */ './search/search.route')
  },
  {
    name: ROUTE_SEO_SEARCH_BY_RUBRIC,
    path: '/activite/:rubric',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_SEARCH_BY_RUBRIC" */ './search/search.route')
  },
  {
    name: ROUTE_SEARCH_BY_QUERY,
    path: '/plan#/recherche/:query?bbox=:bbox',
    action: () => import(/* webpackChunkName: "ROUTE_SEARCH_BY_QUERY" */ './search/search.route')
  },
  {
    name: ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
    path: '/plan#/recherche/categorie/:category/poi/:geoentityId?bbox=:bbox',
    action: () =>
      import(/* webpackChunkName: "ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY" */ './search/geoentity-on-search.route')
  },
  {
    name: ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
    path: '/plan#/recherche/sous-categorie/:subcategory/poi/:geoentityId?bbox=:bbox',
    action: () =>
      import(/* webpackChunkName: "ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY" */ './search/geoentity-on-search.route')
  },
  {
    name: ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
    path: '/app/:app#/poi/:geoentityId?bbox=:bbox',
    action: () =>
      import(/* webpackChunkName: "ROUTE_GEOENTITY_ON_SEARCH_BY_APP" */ './search/geoentity-on-search.route')
  },
  {
    name: ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
    path: '/activite/:rubric#/poi/:geoentityId?bbox=:bbox',
    action: () =>
      import(/* webpackChunkName: "ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC" */ './search/geoentity-on-search.route')
  },
  {
    name: ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
    path: '/plan#/recherche/:query/poi/:geoentityId?bbox=:bbox',
    action: () =>
      import(/* webpackChunkName: "ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY" */ './search/geoentity-on-search.route')
  },
  {
    name: ROUTE_SEO_ADDRESS,
    path: '/plan/:location',
    action: () => import(/* webpackChunkName: "ROUTE_SEO_ADDRESS" */ './address/address.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_ADDRESS,
    path: '/plan#/:location',
    action: () => import(/* webpackChunkName: "ROUTE_ADDRESS" */ './address/address.route')
  },
  {
    name: ROUTE_GEOENTITY_AT_ADDRESS,
    path: '/plan#/:location/poi/:geoentityId',
    action: () => import(/* webpackChunkName: "ROUTE_GEOENTITY_AT_ADDRESS" */ './geoentity/geoentityAtAddress.route')
  },
  {
    name: ROUTE_TRAFFIC_HOME,
    path: '/info-trafic',
    action: () => import(/* webpackChunkName: "ROUTE_TRAFFIC_HOME" */ './traffic/trafficHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_BICYCLE_HOME,
    path: '/pistes-cyclables',
    action: () => import(/* webpackChunkName: "ROUTE_BICYCLE_HOME" */ './bicycle/bicycleHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_ZFE_HOME,
    path: '/zfe',
    action: () => import(/* webpackChunkName: "ROUTE_ZFE_HOME" */ './zfe/zfeHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_RESTRICTION_HOME,
    path: '/restrictions-circulation',
    action: () => import(/* webpackChunkName: "ROUTE_RESTRICTION_HOME" */ './restriction/restrictionHP.route'),
    maxAge: ONE_MINUTE,
    smaxAge: FIVE_MINUTES
  },
  {
    name: ROUTE_TRAFFIC_CITY,
    path: '/info-trafic/:location',
    action: () => import(/* webpackChunkName: "ROUTE_TRAFFIC_CITY" */ './traffic/trafficCity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_BICYCLE_CITY,
    path: '/pistes-cyclables/:location',
    action: () => import(/* webpackChunkName: "ROUTE_BICYCLE_CITY" */ './bicycle/bicycleCity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_ZFE_CITY,
    path: '/zfe/:location',
    action: () => import(/* webpackChunkName: "ROUTE_ZFE_CITY" */ './zfe/zfeCity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_RESTRICTION_CITY,
    path: '/restrictions-circulation/:location',
    action: () => import(/* webpackChunkName: "ROUTE_RESTRICTION_CITY" */ './restriction/restrictionCity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_TRAFFIC_DEPARTMENT,
    path: '/info-trafic/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_TRAFFIC_DEPARTMENT" */ './zones/trafficDepartment.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_BICYCLE_DEPARTMENT,
    path: '/pistes-cyclables/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_BICYCLE_DEPARTMENT" */ './zones/bicycleDepartment.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_ZFE_DEPARTMENT,
    path: '/zfe/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_ZFE_DEPARTMENT" */ './zones/zfeDepartment.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_RESTRICTION_DEPARTMENT,
    path: '/restrictions-circulation/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_RESTRICTION_DEPARTMENT" */ './zones/restrictionDepartment.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },

  {
    name: ROUTE_TRAFFIC_REGION,
    path: '/info-trafic/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_TRAFFIC_REGION" */ './zones/trafficRegion.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_BICYCLE_REGION,
    path: '/pistes-cyclables/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_BICYCLE_REGION" */ './zones/bicycleRegion.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_ZFE_REGION,
    path: '/zfe/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_ZFE_REGION" */ './zones/zfeRegion.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_RESTRICTION_REGION,
    path: '/restrictions-circulation/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_RESTRICTION_REGION" */ './zones/restrictionRegion.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_GDT,
    ssrOnly: true,
    path: '/gdt',
    action: () => __SERVER__ && import(/* webpackChunkName: "ROUTE_GDT" */ './tests/gdt.route'),
    timeout: TIMEOUTS.TESTS_TIMEOUT,
    maxAge: NO_CACHE,
    smaxAge: NO_CACHE
  },
  {
    name: ROUTE_TNR,
    ssrOnly: true,
    path: '/tnr',
    action: () => __SERVER__ && import(/* webpackChunkName: "ROUTE_TNR" */ './tests/tnr.route'),
    timeout: TIMEOUTS.TESTS_TIMEOUT,
    maxAge: NO_CACHE,
    smaxAge: NO_CACHE
  },
  {
    name: ROUTE_SEO_ITINERARY,
    ssrOnly: true,
    timeout: TIMEOUTS.LANDING_PAGE_TIMEOUT,
    path: '/itineraire/:from/:to',
    action: () =>
      __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_ITINERARY" */ './SEO/itineraryLandingPage.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_WEEK
  },
  {
    name: ROUTE_SEO_ITINERARY_MODE,
    ssrOnly: true,
    timeout: TIMEOUTS.LANDING_PAGE_TIMEOUT,
    path: '/itineraire/:mode/:from/:to',
    action: () =>
      __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_ITINERARY_MODE" */ './SEO/itineraryLandingPage.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_WEEK
  },
  {
    name: ROUTE_SEO_CITY_ACTIVITY,
    ssrOnly: true,
    path: '/activite/:rubricId/:zip-:city',
    action: () =>
      __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_CITY_ACTIVITY" */ './SEO/cityActivityLandingPage.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_WEEK
  },
  {
    name: ROUTE_SEO_CITY_APP,
    ssrOnly: true,
    path: '/app/:appId/:zip-:city',
    action: () => __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_CITY_APP" */ './SEO/cityAppLandingPage.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_WEEK
  },
  {
    name: ROUTE_SEO_DIRECTORY_ITINERARY,
    ssrOnly: true,
    path: '/annuaire-itineraire/page:pageIdx',
    action: () =>
      __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_ITINERARY" */ './SEO/directoryItinerary.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_ITINERARY_BY_MODE,
    ssrOnly: true,
    path: '/annuaire-itineraire-:mode/page:pageIdx',
    action: () =>
      __SERVER__ &&
      import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_ITINERARY_BY_MODE" */ './SEO/directoryItinerary.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_CITY,
    ssrOnly: true,
    path: '/annuaire-ville/page:pageIdx',
    action: () => __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_CITY" */ './SEO/directoryCity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_CITY_POIS,
    ssrOnly: true,
    path: '/poi/liste/:zip-:city/page:pageIdx',
    action: () =>
      __SERVER__ && import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_CITY_POIS" */ './SEO/directoryCityPois.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_CITY_ACTIVITY,
    ssrOnly: true,
    path: '/activite/liste/:zip-:city/page:pageIdx',
    action: () =>
      __SERVER__ &&
      import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_CITY_ACTIVITY" */ './SEO/directoryCityActivity.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_CITY_OVM_POIS,
    ssrOnly: true,
    path: '/poi/populaire/:zip-:city/page:pageIdx',
    action: () =>
      __SERVER__ &&
      import(/* webpackChunkName: "ROUTE_SEO_DIRECTORY_CITY_OVM_POIS" */ './SEO/directoryCityOvmPois.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_SEO_DIRECTORY_CITY_OVM_POIS_ACTIVITY,
    ssrOnly: true,
    path: '/poi/populaire/:rubricId/:zip-:city/page:pageIdx',
    action: () =>
      __SERVER__ &&
      import(
        /* webpackChunkName: "ROUTE_SEO_DIRECTORY_CITY_OVM_POIS_ACTIVITY" */ './SEO/directoryCityOvmPoisActivity.route'
      ),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_QUIZ_HOME,
    path: '/quiz',
    action: () => import(/* webpackChunkName: "ROUTE_QUIZ_HOME" */ './quiz/quizHP.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_QUIZ_CITIES,
    path: '/quiz/villes',
    action: () => import(/* webpackChunkName: "ROUTE_QUIZ_CITIES" */ './quiz/quizCities.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_QUIZ_CITIES_REGION,
    path: '/quiz/villes/region/:regionCode-:region',
    action: () => import(/* webpackChunkName: "ROUTE_QUIZ_CITIES_REGION" */ './quiz/quizRegionCities.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  },
  {
    name: ROUTE_QUIZ_CITIES_DEPARTMENT,
    path: '/quiz/villes/departement/:departmentCode-:department',
    action: () => import(/* webpackChunkName: "ROUTE_QUIZ_CITIES_DEPARTMENT" */ './quiz/quizDepartmentCities.route'),
    maxAge: ONE_HOUR,
    smaxAge: ONE_DAY
  }
]

export const getSanitizedRoutes = (browser = __BROWSER__) =>
  rawRoutes
    .filter(({ path }) => browser || !path.includes('#'))
    .map(route => ({
      ...route,
      path: sanitizeRoute(route.path)
    }))

export default getSanitizedRoutes()
