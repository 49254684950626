import { isNumber } from '../../utils/number'
import { ACCENTS_CHARS } from '../../utils/string'

export const zipRegex = new RegExp(`\\w*\\d[\\w\\d\\s]*`) // should contain at least a number and could contains letters

export const cityRegex = new RegExp(`[a-zA-Z${ACCENTS_CHARS}-]+`)

const zipDashCity = new RegExp(`^(?<zip>${zipRegex.source})-(?<city>${cityRegex.source})$`)

const fictiveZipOfCityWithDistricts = /(75|69|13)000/

export const isCoords = coords => coords && isNumber(coords.lng) && isNumber(coords.lat)

export const locationHasCoords = location => location && isCoords(location.coordinates)

export const lngLatString = ({ lng, lat }) => {
  if (lat === undefined || lng === undefined) throw Error('lat and lng should both be defined')
  const fixedLat = lat.toFixed ? lat.toFixed(6) : lat
  const fixedLng = lng.toFixed ? lng.toFixed(6) : lng

  return `${fixedLng},${fixedLat}`
}

const arePointSameAtMeterPrecision = (l1, l2) => {
  // The 6th decimal point is meter precision
  // see https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
  return Math.abs(l1 - l2) <= 0.00001
}

export const areLatLngAtMeterPrecisionEquals = (p1, p2) => {
  if (p1 && p2) {
    const { lat: lat1, lng: lng1 } = p1
    const { lat: lat2, lng: lng2 } = p2
    return arePointSameAtMeterPrecision(lat1, lat2) && arePointSameAtMeterPrecision(lng1, lng2)
  }
  return false
}

export const isZipDashCity = token => zipDashCity.test(token)

export const hasFictiveZipOfCityWithDistricts = token => fictiveZipOfCityWithDistricts.test(token)

export const parseCityWithDistrict = (postcode = '') => String(postcode).match(/^(75|69|13)0[0-2][0-9]$/)

export const isCityWithDistrict = (postcode = '') => Boolean(parseCityWithDistrict(postcode))

export const projectZipcodeToFirstDistrict = zipcode => {
  if (!isCityWithDistrict(zipcode)) return zipcode

  return parseCityWithDistrict(zipcode)[1] + '001'
}

export const projectDistrictZipcodeToWholeCityZipcode = zipcode => {
  if (!isCityWithDistrict(zipcode)) return zipcode

  return parseCityWithDistrict(zipcode)[1] + '000'
}

export const checkSeoPostCode = (locationPostCode, addressPostCode) => {
  return (
    locationPostCode === addressPostCode ||
    (locationPostCode === '75000' && addressPostCode === '75001') ||
    (locationPostCode === '13000' && addressPostCode === '13001') ||
    (locationPostCode === '69000' && addressPostCode === '69001')
  )
}

export const extractZipCity = token => {
  const results = zipDashCity.exec(token)
  const { zip, city } = results?.groups ?? {}
  return zip && city ? { postcode: zip, town: city } : {}
}
