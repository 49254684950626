import { getCountryByLocale, localeCountryIsFrance, localeLanguageIsFrench } from '../../domain/i18n'
import { createCustomSelector } from '../../domain/utils/react-reselect'

const selectNavigationState = store => store?.navigation

export const selectIsPE = createCustomSelector(selectNavigationState, navigation => navigation?.device === 'pe')

export const selectIsGE = createCustomSelector(selectNavigationState, navigation => navigation?.device === 'ge')

export const selectLocale = createCustomSelector(selectNavigationState, navigation => navigation?.locale)

export const selectIsFrenchSite = createCustomSelector(selectLocale, locale => localeCountryIsFrance(locale))

export const selectIsFrenchLanguage = createCustomSelector(selectLocale, locale => localeLanguageIsFrench(locale))

export const selectCountryForLocale = createCustomSelector(selectLocale, locale => getCountryByLocale(locale))

export const selectIsOnboardingContext = createCustomSelector(
  selectNavigationState,
  navigation => navigation?.isOnBoardingContext ?? false
)

export const selectVisitCount = createCustomSelector(selectNavigationState, navigation => navigation?.visitCount ?? 0)
