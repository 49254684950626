import { createCustomSelector } from '../../domain/utils/react-reselect'

const selectQuizState = store => store.quiz

export const selectQuizCurrentCityIndex = createCustomSelector(selectQuizState, quiz => quiz.currentCityIndex)

export const selectQuizCities = createCustomSelector(selectQuizState, quiz => quiz.cities ?? [])

export const selectQuizType = createCustomSelector(selectQuizState, quiz => quiz.type)

export const selectQuizConfig = createCustomSelector(selectQuizState, quiz => quiz.config)

export const selectQuizLevel = createCustomSelector(selectQuizState, quiz => quiz.level)

export const selectQuizCurrentCity = createCustomSelector(
  selectQuizCities,
  selectQuizCurrentCityIndex,
  (cities, index) => cities?.[index]
)

export const selectQuizCurrentGuessCoordinates = createCustomSelector(
  selectQuizCurrentCity,
  city => city?.answer.guessCoordinates
)

export const selectQuizCurrentResultCoordinates = createCustomSelector(
  selectQuizCurrentCity,
  city => city?.answer.guessCoordinates && city?.data.coordinates
)

export const selectQuizCitiesWithAnswer = createCustomSelector(selectQuizCities, cities =>
  cities.filter(city => city.answer.guessCoordinates)
)

export const selectQuizTotalScore = createCustomSelector(selectQuizCitiesWithAnswer, cities =>
  cities.reduce((total, { answer }) => total + answer.points + answer.bonus, 0)
)

export const selectQuizGuessInProgress = createCustomSelector(selectQuizCurrentCity, currentCity =>
  Boolean(currentCity)
)

export const selectQuizHasStarted = createCustomSelector(selectQuizCities, cities => cities.length > 0)

export const selectQuizIsFinished = createCustomSelector(
  selectQuizGuessInProgress,
  selectQuizHasStarted,
  (inProgress, hasStart) => hasStart && !inProgress
)

export const selectQuizLinks = createCustomSelector(selectQuizState, quiz => quiz.links)
