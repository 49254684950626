import { sendAbTestTags } from '../../domain/analytics/ATInternetService'
import { selectAbTestVariants } from '../abtest/abtest.selectors'
import { setAbTestReady } from '../abtest/abtestSlice'

export const sendTagsWhenAbTestIsLoaded =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__ && action.type === setAbTestReady.type) {
      const enrolledVariants = selectAbTestVariants(getState())
      if (enrolledVariants.length) sendAbTestTags(enrolledVariants)
    }

    return r
  }

export default [sendTagsWhenAbTestIsLoaded]
