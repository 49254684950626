import React, { useState } from 'react'
import removeEmptyKeyValuePairs from '../utils/removeEmptyKeyValuePairs'
import { forceProtocol } from '../utils/url'

const defaultImageStyle = {
  objectFit: 'cover',
  objectPosition: 'center'
}

export default function CrossBrowserImage({ src, style = {}, className, alt }) {
  const [hasError, setError] = useState(false)

  if (!src || hasError)
    return (
      <div
        className={className}
        style={{
          ...style,
          background: '#f5f5f5'
        }}
      ></div>
    )

  return (
    <img
      {...removeEmptyKeyValuePairs({
        src: forceProtocol(src, true),
        alt,
        className
      })}
      style={{
        ...defaultImageStyle,
        ...style
      }}
      loading="lazy"
      onError={() => setError(true)}
    />
  )
}
