import { useStore } from 'react-redux'
import { configureAndSendTag } from './analytics.utils'

const useConfigureAndSendTag = () => {
  const { getState } = useStore()

  return tag => {
    configureAndSendTag(tag, getState())
  }
}

export default useConfigureAndSendTag
