import { MAP_BUTTON_MODE } from './map.constants'

const parseMapEvent = mapEvent => ({
  key: mapEvent.mode,
  visible: mapEvent.visible,
  status: mapEvent.status,
  icon: mapEvent.icon,
  label: mapEvent.display_label,
  ariaLabel: mapEvent.accessibility_display_label,
  tooltipLabel: mapEvent?.tooltip?.label,
  availableInRaster: false,
  type: MAP_BUTTON_MODE,
  isEventMode: true
})

export const parseMapEvents = mapEvents => (mapEvents ?? []).map(mapEvent => parseMapEvent(mapEvent))
