import debug from 'debug'
import tagsParamResolvers from '../TagsParamResolvers'
import { replaceValues } from '../analyticsUtils'
import { ACTION_TO_CLICK_TAG } from './clickTagsV3.constants'
import { ROUTE_TO_PAGE_TAG } from './pageTagsV3.constants'

const d = debug('tagsV3Middleware')

export const TAG_TYPES = {
  CLICK: 'click',
  PAGE: 'page'
}

const resolveTag = (tag, store, paramsToResolve) => {
  if (!paramsToResolve) return tag
  const resolvedTag = { ...tag }
  paramsToResolve.forEach(paramName => {
    if (!tagsParamResolvers[paramName]) return
    resolvedTag[paramName] = tagsParamResolvers[paramName](store)
  })
  return resolvedTag
}

export const configureTagEvent = ({ action, store, route }, type) => {
  if (!type) return

  const tagLookup = type === TAG_TYPES.CLICK ? ACTION_TO_CLICK_TAG[action.type] : ROUTE_TO_PAGE_TAG[route]

  if (!tagLookup) return
  const tagObj = tagLookup({ action, store })
  const { tag, paramFns = [], paramsToResolve } = tagObj

  d(`configure tag (event) for ${type} `, tag?.tag_id)

  const resolvedTag = resolveTag(tag, store, paramsToResolve)
  const params = paramFns.map(fn => fn({ store, action }))

  return replaceValues(resolvedTag, Object.assign(...params))
}
