import { CLICKED_BUTTON, NA } from '../analytics.constants'
import {
  getCurrentModeAndProviderObj,
  getCurrentRouteParsedDetailsObj,
  getItinerariesActiveSort,
  getItineraryPoiTypesObj,
  getItineraryZipCodesObj,
  getNumberOfDisplayedItinerariesObj,
  getPageActiveObj,
  getQidObj,
  getRouteLabelObj,
  getTagInfo,
  getZoneObj
} from '../analyticsUtils'
import {
  ACTION,
  MODE_ID,
  NUMBER_OF_DISPLAYED_ROUTES_RESULTS,
  PAGE_ACTIVE,
  POI_ARRIVAL_TYPE,
  POI_DEPARTURE_TYPE,
  POI_POSITION,
  POI_TYPE,
  PROVIDER_ID,
  QID,
  ROUTE_DURATION,
  ROUTE_LABEL,
  ROUTE_LENGTH,
  ROUTE_POSITION_CLICK,
  ROUTE_PRICE,
  ROUTE_RESULTS_ORDER_TYPE,
  ZIP_ARRIVAL,
  ZIP_DEPARTURE
} from '../ClickTagsService'
import {
  RI_CLIC_ITI,
  RI_CLIC_ITI_MORE_RESULTS,
  SUGGEST_CLICK,
  TAG_CLICK_COMPUTE_RADIUS,
  TAG_CLICK_EVENT_MODE,
  TAG_CLICK_HELP_BUTTON,
  TAG_CLICK_ITI_LP_COMPARE_MODES,
  TAG_CLICK_ITI_LP_SUMMARY,
  TAG_CLICK_ITI_LP_THEMATIC,
  TAG_CLICK_POI_SEE_ON_MAP,
  TAG_CLICK_QUIZ_PLAY,
  TAG_CLICK_QUIZ_REPLAY,
  TAG_CLICK_QUIZ_SHARE,
  TAG_CLICK_TOGGLE_FULLSCREEN,
  TAG_SEARCH_GEOLOCATION
} from '../analytics.tagids.constants'

export const ACTION_TO_CLICK_TAG = {
  [TAG_SEARCH_GEOLOCATION]: () => ({
    tag: {
      tag_id: 'XTC_CARTE_LOC',
      click_chapter1: 'Carte',
      click_chapter2: NA,
      click_chapter3: NA,
      click: 'Geoloc',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_CLICK_QUIZ_REPLAY]: () => ({
    tag: {
      tag_id: 'XTC_QUIZ_VILLES_REJOUER',
      click_chapter1: 'Carte',
      click_chapter2: '#type',
      click_chapter3: '#zone',
      click: 'Rejouer',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getZoneObj, getTagInfo]
  }),
  [TAG_CLICK_QUIZ_SHARE]: () => ({
    tag: {
      tag_id: 'XTC_QUIZ_VILLES_PARTAGER',
      click_chapter1: 'Carte',
      click_chapter2: '#type',
      click_chapter3: '#zone',
      click: 'Partager',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getZoneObj, getTagInfo]
  }),
  [TAG_CLICK_QUIZ_PLAY]: () => ({
    tag: {
      tag_id: 'XTC_QUIZ_VILLES_LANCER_PARTIE',
      click_chapter1: 'Carte',
      click_chapter2: '#type',
      click_chapter3: '#level:#zone',
      click: 'LancerUnePartie',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getZoneObj, getTagInfo]
  }),
  [TAG_CLICK_COMPUTE_RADIUS]: () => ({
    tag: {
      tag_id: 'XTC_CALCUL_RAYON',
      click_chapter1: 'Carte',
      click_chapter2: 'CalculRayon',
      click_chapter3: '#action', // Rayon | Adresse
      click: 'Valider',
      page: `#${PAGE_ACTIVE}`
    },
    paramsToResolve: ['x6', 'x11'],
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_CLICK_HELP_BUTTON]: () => ({
    tag: {
      tag_id: 'XTC_BESOIN_AIDE',
      click_chapter1: 'Footer',
      click_chapter2: 'InfoAideViePrivée',
      click_chapter3: NA,
      click: 'BesoinAide',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_CLICK_TOGGLE_FULLSCREEN]: () => ({
    tag: {
      tag_id: 'XTC_CHEVRON_BANDEAU_LATERAL',
      click_chapter1: 'Carte',
      click_chapter2: 'BandeauLateral',
      click_chapter3: 'Chevron',
      click: `#${ACTION}`,
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [RI_CLIC_ITI]: () => ({
    tag: {
      tag_id: RI_CLIC_ITI,
      event_name: 'clic.Itineraire',
      mode_de_transport: `#${MODE_ID}_#${PROVIDER_ID}`,
      nombre_resultats: `!${NUMBER_OF_DISPLAYED_ROUTES_RESULTS}`,
      code_postal_depart: `#${ZIP_DEPARTURE}`,
      code_postal_arrivee: `#${ZIP_ARRIVAL}`,
      type_depart: `#${POI_DEPARTURE_TYPE}`,
      type_arrivee: `#${POI_ARRIVAL_TYPE}`,
      resultat_clique: `#${ROUTE_LABEL}`,
      tarif_resultat_clique: `!${ROUTE_PRICE}`,
      duree_resultat_clique: `!${ROUTE_DURATION}`,
      position_resultat_clique: `!${ROUTE_POSITION_CLICK}`,
      distance_resultat_clique: `!${ROUTE_LENGTH}`,
      id_recherche_iti: `#${QID}`,
      bouton_clique: `#${CLICKED_BUTTON}`,
      tri_resultats: `#${ROUTE_RESULTS_ORDER_TYPE}`,
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [
      getPageActiveObj,
      getTagInfo,
      getCurrentModeAndProviderObj,
      getItineraryZipCodesObj,
      getItineraryPoiTypesObj,
      getRouteLabelObj,
      getQidObj,
      getCurrentRouteParsedDetailsObj,
      getNumberOfDisplayedItinerariesObj,
      getItinerariesActiveSort
    ]
  }),
  [RI_CLIC_ITI_MORE_RESULTS]: () => ({
    tag: {
      tag_id: RI_CLIC_ITI,
      event_name: 'clic.Itineraire',
      mode_de_transport: `#${MODE_ID}_#${PROVIDER_ID}`,
      nombre_resultats: `!${NUMBER_OF_DISPLAYED_ROUTES_RESULTS}`,
      code_postal_depart: `#${ZIP_DEPARTURE}`,
      code_postal_arrivee: `#${ZIP_ARRIVAL}`,
      type_depart: `#${POI_DEPARTURE_TYPE}`,
      type_arrivee: `#${POI_ARRIVAL_TYPE}`,
      id_recherche_iti: `#${QID}`,
      bouton_clique: `#${CLICKED_BUTTON}`,
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [
      getPageActiveObj,
      getTagInfo,
      getCurrentModeAndProviderObj,
      getItineraryZipCodesObj,
      getItineraryPoiTypesObj,
      getRouteLabelObj,
      getQidObj,
      getNumberOfDisplayedItinerariesObj
    ]
  }),
  [SUGGEST_CLICK]: () => ({
    tag: {
      tag_version: 'V3',
      tag_id: SUGGEST_CLICK,
      event_name: 'suggest_click',
      page: `#${PAGE_ACTIVE}`,
      poi_type: `#${POI_TYPE}`,
      position: `#${POI_POSITION}`
    },
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_CLICK_EVENT_MODE]: () => ({
    tag: {
      tag_id: 'XTC_LAYER_ZONE_RESTRICTION',
      click_chapter1: 'Carte',
      click_chapter2: 'LayerZoneRestriction',
      click_chapter3: NA,
      click: 'Cliquer',
      page: `#${PAGE_ACTIVE}`
    },
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_CLICK_ITI_LP_SUMMARY]: () => ({
    tag: {
      tag_id: 'XTC_BLOC_RESUME',
      click_chapter1: 'Itineraire',
      click_chapter2: 'BlocResume:#distance',
      click_chapter3: `#${MODE_ID}`,
      click: 'LandingItineraire:voirDetails',
      page: `#${PAGE_ACTIVE}`
    },
    paramsToResolve: ['x7'],
    paramFns: [getPageActiveObj, getCurrentModeAndProviderObj, getTagInfo]
  }),
  [TAG_CLICK_ITI_LP_THEMATIC]: () => ({
    tag: {
      tag_id: 'XTC_VIGNETTE_THEMATIQUE',
      click_chapter1: 'Itineraire',
      click_chapter2: '#rubric:#arrival',
      click_chapter3: `#${MODE_ID}`,
      click: 'LandingItineraire:VignetteThematique',
      page: `#${PAGE_ACTIVE}`
    },
    paramsToResolve: ['x7'],
    paramFns: [getPageActiveObj, getCurrentModeAndProviderObj, getTagInfo]
  }),
  [TAG_CLICK_ITI_LP_COMPARE_MODES]: () => ({
    tag: {
      tag_id: 'XTC_COMPARER_MODES',
      click_chapter1: 'Itineraire',
      click_chapter2: 'ComparerLesModes',
      click_chapter3: `#${MODE_ID}`,
      click: 'LandingItineraire:comparerMode',
      page: `#${PAGE_ACTIVE}`
    },
    paramsToResolve: ['x7'],
    paramFns: [getPageActiveObj, getCurrentModeAndProviderObj, getTagInfo]
  }),
  [TAG_CLICK_POI_SEE_ON_MAP]: () => ({
    tag: {
      tag_id: 'XTC_VOIR_SUR_CARTE',
      click_chapter1: 'Annuaire',
      click_chapter2: NA,
      click_chapter3: NA,
      click: 'VoirSurLaCarte',
      page: `#${PAGE_ACTIVE}`
    },
    paramsToResolve: ['x11'],
    paramFns: [getPageActiveObj]
  })
}
