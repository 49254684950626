import { getImageUrl } from '../../domain/utils/image'
import { getDevicePixelRatio } from '../../domain/Device'

export const extractApps = conf => {
  const applications = conf?.apps ?? []
  return applications.map(({ id, name, morePoisLabel, menu, mob_roundImage, banner, pushSeo }) => {
    return {
      id,
      name,
      morePoisLabel,
      menu: getImageUrl(menu),
      mob_roundImage: getImageUrl(mob_roundImage, Math.max(4, getDevicePixelRatio())),
      banner: getImageUrl(banner, Math.max(4, getDevicePixelRatio())),
      pushSeo
    }
  })
}

export const extractFreeApps = conf => conf?.freeApps ?? []

export const extractCategories = conf => {
  const categories = conf?.categories ?? []
  return categories.map(({ id, shortLabel, label, iconId, apps, color, segment }) => {
    return { id, shortLabel, label, iconId, apps, color, segment }
  })
}

export const extractSubcategories = conf => {
  const subcategories = conf?.subcategories ?? []
  return subcategories.map(({ id, label, iconId, color, rubricIds, filterApps }) => {
    return { id, label, iconId, color, rubricIds, filterApps }
  })
}

export const extractMenus = conf => conf?.menus

export const parseGeoentitiesConfig = data => {
  if (!(data instanceof Array)) return [] // TODO: should return an error ?

  return data.map(({ id, type, label, from, to, logo }) => ({
    id,
    type,
    label,
    from,
    to,
    logo: getImageUrl(logo, Math.max(2, getDevicePixelRatio()))
  }))
}
