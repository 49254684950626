import polylineEncoder from '@mapbox/polyline'
import { getBIParams, SPD_RESPONSE_SEARCH_ITINERARY } from '../../domain/analytics/BiTagger'
import NoRoadbookError from '../../domain/error/NoRoadbookError'
import { getLocaleUnderscored } from '../../domain/i18n'
import { removeExcludedProviders } from '../../domain/itinerary/specialcases/itinerary.specialcases.utils'
import { bboxToSearchParameter } from '../../domain/map/conversion'
import { lngLatString } from '../../domain/utils/location'
import request from '../../domain/utils/request'
import { getService, getServiceParameters } from '../../parameters'
import SERVICES from '../../services.constants'
import parseAddresses from '../../store/address/address.dataParser'
import { parseGeoentityListResponse } from '../../store/geoentity/geoentity.dataParser'
import { MULTIPATH_MODE_TC } from '../../store/itinerary/itinerary.constants'
import { extractRoutes } from '../../store/itinerary/itinerary.dataParser'
import { searchServiceCtx } from '../../store/search/search.service.constants'
import { merge } from '../../utils/object'
import { requestSearchData } from '../search/search.requests'

export const requestTransports = ({ from, to, options, locale }) => {
  const params = {
    from: lngLatString(from.coordinates),
    to: lngLatString(to.coordinates),
    lang: getLocaleUnderscored(locale),
    ...options,
    ...getBIParams()
  }
  const API_TRANSPORTS_SERVICE = getService(SERVICES.MULTIPATH, '/transports')
  return request.get(API_TRANSPORTS_SERVICE, { params, locale }).then(response => {
    const modes = response.data.transport_modes
    if (modes.length === 0) throw new Error('no mode')

    return modes.reduce((acc, current) => {
      return acc.concat(
        current.providers.filter(removeExcludedProviders).map(({ name, label, simplified }) => ({
          name,
          label: label || current.label,
          simplified,
          optional: current.optional,
          qid: response.data.qid,
          mode: current.id,
          icon: current.icon
        }))
      )
    }, [])
  })
}

export const requestRoute = ({
  provider,
  from,
  to,
  stop = [],
  dateTimeOptions,
  ModePreferredOptions,
  currentMode,
  locale
}) => {
  const { name, mode, simplified, qid } = provider
  const actualMode = simplified ? currentMode : undefined

  const params = merge(
    {
      from: lngLatString(from.coordinates),
      to: lngLatString(to.coordinates),
      stop,
      lang: getLocaleUnderscored(locale),
      providers: name,
      simplified: mode === actualMode ? false : simplified,
      statistics: true,
      qid,
      // La donnée départ/arrivée de la FDR n'est pas utilisée, donc address_to/address_from sont inutiles, mais les renseigner évite une requete de geoloc coté route
      address_from: '-',
      address_to: '-',
      ...getBIParams({ qid })
    },
    dateTimeOptions,
    ModePreferredOptions
  )

  const timeout = __SERVER__ ? 6 * 1000 : undefined // override request timeout on server for itinerary landing pages

  const API_ROUTES_SERVICE = getService(SERVICES.MULTIPATH, '/routes')
  return request.get(API_ROUTES_SERVICE, { params, timeout, locale }).then(response => {
    if (!response.data) {
      return
    }
    if (mode === MULTIPATH_MODE_TC && response?.data?.availability_info)
      return { availability_info: response.data.availability_info }

    return extractRoutes(response, mode)
  })
}

export const requestInventory = locale => {
  return request
    .get(getService(SERVICES.MULTIPATH, '/inventory'), {
      params: { ...getBIParams(), lang: getLocaleUnderscored(locale) },
      locale
    })
    .then(response => {
      if (!response.data) throw new Error('no data')
      return response.data
    })
}

export const requestLocation = (location, bbox, locale) => {
  const params = {
    q: location.label,
    f: searchServiceCtx.filter.poiXorAddress,
    limit: location.fetchSeoAddress ? 10 : 1,
    bbox: bboxToSearchParameter(bbox),
    ...getServiceParameters(SERVICES.SEARCH, locale),
    ...getBIParams({ tagid: SPD_RESPONSE_SEARCH_ITINERARY })
  }

  return requestSearchData(params, locale).then(({ data }) => {
    if (!data || !(data.addresses || data.pois)) {
      return
    }

    return (data.addresses ? parseAddresses(data) : parseGeoentityListResponse(data))?.[0]
  })
}

export const requestRoadbook = (currentRoute, locale) => {
  const roadbookLink = currentRoute?.details_link || currentRoute?.roadbook_link
  if (!roadbookLink) {
    return Promise.reject(new NoRoadbookError('Missing details_link or roadbook_link in currentRoute'))
  }
  const service = getService(SERVICES.MULTIPATH, roadbookLink)
  return request.get(service, { params: getBIParams(), locale })
}

export const requestElevation = (flatPolyline, locale) => {
  const service = getService(SERVICES.MULTIPATH, '/elevation')
  const data = {
    encoded_polyline: polylineEncoder.encode(flatPolyline, 6)
  }
  return request.post(service, { data, params: getBIParams(), locale })
}
