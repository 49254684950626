import React from 'react'
import TagActionButton from '../TagActionButton'
import TagActionLink from '../TagActionLink'

const ActionButton = props => {
  const {
    children,
    className,
    onClick,
    onClickBeforeTag,
    deferClick = false,
    tagData,
    href,
    targetBlank = false,
    disableNoreferrer = false,
    nofollow = false,
    noNavigate,
    dataset = {},
    elementRef
  } = props

  const wrapperProps = {
    className,
    onClick,
    dataset
  }

  if (href) {
    if (tagData) {
      return (
        <TagActionLink
          {...wrapperProps}
          {...tagData}
          noNavigate={noNavigate}
          href={href}
          targetBlank={targetBlank}
          disableNoreferrer={disableNoreferrer}
          nofollow={nofollow}
          elementRef={elementRef}
        >
          {children}
        </TagActionLink>
      )
    }
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        {...wrapperProps}
        href={href}
        target={targetBlank ? '_blank' : '_self'}
        rel={`noopener${disableNoreferrer ? '' : ' noreferrer'}${nofollow ? ' nofollow' : ''}`}
        ref={elementRef}
      >
        {children}
      </a>
    )
  }

  if (tagData) {
    return (
      <TagActionButton
        {...wrapperProps}
        {...tagData}
        deferClick={deferClick}
        elementRef={elementRef}
        onClickBeforeTag={onClickBeforeTag}
      >
        {children}
      </TagActionButton>
    )
  }
  return (
    <span className={className} onClick={onClick} ref={elementRef}>
      {children}
    </span>
  )
}

export default ActionButton
