import { polylineActionSanitizer, polylineStateSanitizer } from './storeSanitizers'

const shouldUseReduxDevTools = () => __BROWSER__ && global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

export const getReduxDevToolsOptions = () =>
  shouldUseReduxDevTools()
    ? {
        trace: true,
        maxAge: 150,
        latency: 5000,
        actionSanitizer: polylineActionSanitizer,
        stateSanitizer: polylineStateSanitizer
      }
    : false
