export function deferredWindowLocation(href) {
  setTimeout(() => {
    window.location.href = href
  }, 50)
}

export function deferredWindowOpen({ url, popupName = 'default', width = 600, height = 400 }) {
  setTimeout(() => {
    window.open(url, popupName, `width=${width},height=${height}`)
  }, 50)
}
