import useErrorBoundaryCallback from './useErrorBoundaryCallback'

export default function useEventCallback(method, deps = []) {
  return useErrorBoundaryCallback(
    (e, ...args) => {
      e.preventDefault()
      e.stopPropagation()
      return method(e, ...args)
    },
    [method, ...deps]
  )
}
