import React from 'react'
import SentryService from './SentryService'
import { isProduction } from '../../parameters'

const fallback = e => {
  if (__BROWSER__ && !isProduction()) {
    alert(
      `ErrorHandlerProvider: Erreur globale dans l'application, ceci n'est pas un exercice #AlerteMaximale #Rollback`
    )
    console.error('ErrorHandlerProvider', e.error)
  }
  return <div style={{ color: '#aaa' }}>{"Une erreur s'est produite."}</div>
}

export default function ErrorHandlerProvider(props) {
  return <SentryService.ErrorBoundary fallback={fallback}>{props.children}</SentryService.ErrorBoundary>
}
