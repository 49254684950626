import {
  getCurrentModeAndProviderForDisplayTagObj,
  getItinerariesActiveSort,
  getItinerariesParsedDetailsObj,
  getItineraryPoiTypesObj,
  getItineraryZipCodesObj,
  getNumberOfDisplayedItinerariesObj,
  getPageActiveObj,
  getQidObj
} from '../analyticsUtils'
import {
  MODE_ID,
  NUMBER_OF_DISPLAYED_ROUTES_RESULTS,
  PAGE_ACTIVE,
  POI_ARRIVAL_TYPE,
  POI_DEPARTURE_TYPE,
  PROVIDER_ID,
  QID,
  ROUTE_RESULTS_DURATIONS,
  ROUTE_RESULTS_LABELS,
  ROUTE_RESULTS_LENGTHS,
  ROUTE_RESULTS_ORDER_TYPE,
  ROUTE_RESULTS_PRICES,
  ZIP_ARRIVAL,
  ZIP_DEPARTURE
} from '../ClickTagsService'
import { ROUTE_ITINERARY_RESULTS_BY_ROUTE } from '../../../routes'
import { RI_AFFICHAGE_ITI } from '../analytics.tagids.constants'

export const ROUTE_TO_PAGE_TAG = {
  [ROUTE_ITINERARY_RESULTS_BY_ROUTE]: () => {
    return {
      tag: {
        tag_id: RI_AFFICHAGE_ITI,
        event_name: 'affichage.Itineraire',
        mode_de_transport: `#${MODE_ID}_#${PROVIDER_ID}`,
        nombre_resultats: `!${NUMBER_OF_DISPLAYED_ROUTES_RESULTS}`,
        code_postal_depart: `#${ZIP_DEPARTURE}`,
        code_postal_arrivee: `#${ZIP_ARRIVAL}`,
        type_depart: `#${POI_DEPARTURE_TYPE}`,
        type_arrivee: `#${POI_ARRIVAL_TYPE}`,
        libelle_resultats: `#${ROUTE_RESULTS_LABELS}`,
        tarif_resultats: `#${ROUTE_RESULTS_PRICES}`,
        duree_resultats: `#${ROUTE_RESULTS_DURATIONS}`,
        distance_resultats: `#${ROUTE_RESULTS_LENGTHS}`,
        id_recherche_iti: `#${QID}`,
        tri_resultats: `#${ROUTE_RESULTS_ORDER_TYPE}`,
        page: `#${PAGE_ACTIVE}`
      },
      paramFns: [
        getPageActiveObj,
        getCurrentModeAndProviderForDisplayTagObj,
        getItineraryZipCodesObj,
        getItineraryPoiTypesObj,
        getQidObj,
        getNumberOfDisplayedItinerariesObj,
        getItinerariesParsedDetailsObj,
        getItinerariesActiveSort
      ]
    }
  }
}
