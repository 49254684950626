import { NA } from './analytics.constants'

export const PUB_TAG = {}

PUB_TAG.PUB_MULTIPATH_RESULTAT_TOP = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'result_#selectionslider_resultatTop'
}

PUB_TAG.PUB_MULTIPATH_RESULTAT = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'result_#selectionslider_resultat'
}

PUB_TAG.PUB_MULTIPATH_TRAIN_NO_RESULT = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'train_no_result'
}

PUB_TAG.PUB_ATC_MULTIPATH_TRI_RESULTAT = {
  B_campaignId: 'multipath',
  C_creation: NA,
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'tri_resultat',
  H_detailedPlacement: '#libelle_CTA'
}

PUB_TAG.PUB_MULTIPATH_VOIRPLUSDETRAJETS = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'result_#selectionslider_voirplusdetrajets'
}

PUB_TAG.PUB_MULTIPATH_ROADBOOK = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'goto_roadbook'
}

PUB_TAG.PUB_MULTIPATH_GOTO = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'goto',
  H_detailedPlacement: '#placement'
}

PUB_TAG.PUB_MULTIPATH_GOTO_MOBILE = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'goto_mobile'
}

PUB_TAG.PUB_MULTIPATH_GOTO_MOBILE_POI = {
  B_campaignId: NA,
  C_creation: '#Rubrique',
  D_variant: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'goto_mobile'
}

PUB_TAG.PUB_MULTIPATH_GPS = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'goto_gps'
}

PUB_TAG.PUB_MULTIPATH_SURMAROUTE = {
  B_campaignId: NA,
  C_creation: NA,
  D_variant: '#mode_#distance',
  E_format: '#PageActive',
  F_generalPlacement: 'surmaroute'
}

PUB_TAG.PUB_ATI_MULTIPATH_BONS_PLANS_DEPLACEMENT = {
  B_campaignId: 'multipath',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'Bons_plans'
}

PUB_TAG.PUB_ATC_MULTIPATH_BONS_PLANS_DEPLACEMENT = {
  B_campaignId: 'multipath',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'Bons_plans_#PartnerLabel'
}

PUB_TAG.PUB_FEUILLE_DE_ROUTE_BANDEAU_RESTRICTION = {
  B_campaignId: 'multipath',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'bandeau_restriction'
}

PUB_TAG.PUB_MULTIPATH_BOOKING_SEARCH_EXTENSION = {
  B_campaignId: 'multipath',
  C_creation: NA,
  D_variant: NA,
  E_format: 'Itineraire',
  F_generalPlacement: 'goto_booking_search_extension'
}

PUB_TAG.PUB_FICHEPOI_SITE = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'gotoSite',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_FICHEPOI_EMAIL = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'email',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_FICHEPOI_AFFICHERNUMERO = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'afficherNumero',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_FICHEPOI_CALL = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'gotoCall',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_FICHEPOI_REDIRECTIONRESERVATION = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'goto',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_LISTEREPONSE_REDIRECTIONRESERVATION = {
  B_campaignId: '#Rubrique',
  C_creation: '#Application',
  E_format: '#PageActive',
  F_generalPlacement: 'goto',
  H_detailedPlacement: '#Disponibilite'
}

PUB_TAG.PUB_AFFICHAGE_BARRECATEGORIE = {
  B_campaignId: 'BarreDeCategorie',
  C_creation: NA,
  D_variant: '#catId',
  E_format: '#PageActive'
}

PUB_TAG.PUB_LANDING_ACTIVITE_VILLE_FAQ = {
  B_campaignId: '#Rubrique',
  C_creation: NA,
  D_variant: 'FAQ',
  E_format: '#PageActive'
}

PUB_TAG.PUB_LANDING_ITINERAIRE_FAQ = {
  B_campaignId: '#mode',
  C_creation: NA,
  D_variant: 'FAQ',
  E_format: '#PageActive'
}

PUB_TAG.PUB_FOCUS_CHAMP_SAISI = {
  B_campaignId: 'Recherche',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'focus_champ_saisi'
}

PUB_TAG.PUB_COMPTE_PERSO_INCITATION = {
  B_campaignId: 'ComptePerso',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: '#Incitation'
}

PUB_TAG.PUB_MAP_POP_IN_MAP_TOO_SLOW = {
  B_campaignId: 'Carte',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'pop_in_carte_trop_lente'
}

PUB_TAG.PUB_BANNIERE_EDITO_PUB = {
  B_campaignId: 'Carte',
  C_creation: NA,
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'banniere_edito_pub'
}

PUB_TAG.PUB_MAP_LEGENDE_LAYER_CARTE = {
  B_campaignId: 'Carte',
  C_creation: '#title_popin',
  D_variant: NA,
  E_format: '#PageActive',
  F_generalPlacement: 'legende_layer_carte'
}

PUB_TAG.PUB_MULTIPATH_TC_NO_RESULT = {
  B_campaignId: 'multipath',
  C_creation: '#provider',
  D_variant: '#mode',
  E_format: '#PageActive',
  F_generalPlacement: 'tc_no_result'
}

const keyPrefix = /^[A-Z]_/

const resolvePublisherTag = tagType => preparedTag => ({
  [tagType]: Object.keys(preparedTag).reduce(
    (acc, currTagKey) => ({
      ...acc,
      [currTagKey.replace(keyPrefix, '')]: `[${preparedTag[currTagKey]}]`
    }),
    {}
  )
})

export const resolveImpressionPublisherTag = resolvePublisherTag('impression')
export const resolveClickPublisherTag = resolvePublisherTag('click')
