export const PE_MAIN_START_POS = {
  min: 'min', // 60 from bottom
  low: 'low', // 170 from bottom (60 + 110 scroll)
  high: 'high', // right under map (minheight map 250)
  highNoMap: 'highNoMap' // right under subheader
}

export const PE_MAIN_VALUES = {
  min: 60,
  low: 170
}

export const MINI_MAP_HEIGHT = 250 // css @miniMapBottom
