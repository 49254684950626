const toLatLng = l => [l[1], l[0]]

const truncate = p => p.toFixed(5)

const round = a => a.map(b => truncate(b))
// 5 digits is about 1 meter precision - https://stackoverflow.com/questions/7167604/how-accurately-should-i-store-latitude-and-longitude

export const bboxToSearchParameter = bbox => bbox.map(round).map(toLatLng).join(',')

export const bboxToRouteParameter = bbox => bbox.map(round).join(',')

export const routeParameterToBbox = (bboxStr = '') => {
  const splittedValues = bboxStr.split(',')
  if (splittedValues.length !== 4) return
  const [lng1, lat1, lng2, lat2] = splittedValues
  return [
    [parseFloat(lng1), parseFloat(lat1)],
    [parseFloat(lng2), parseFloat(lat2)]
  ]
}

export const getBboxCenter = bbox => {
  const [[lng1, lat1], [lng2, lat2]] = bbox
  return { lng: middle(lng1, lng2), lat: middle(lat1, lat2) }
}

const middle = (p1, p2) => {
  const diff = Math.abs(p2 - p1)
  return truncate(p1 + diff / 2)
}

export const bboxArrayToBboxArrays = bbox => {
  if (bbox && bbox.length === 4) {
    const [lng1, lat1, lng2, lat2] = bbox
    return [
      [lng1, lat1],
      [lng2, lat2]
    ]
  }
  return bbox
}
