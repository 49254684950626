import { debounce } from '../../utils/function'
import { selectOptionsForRequestMove, selectSelectedGeoentityLatLng } from '../geoentity/geoentity.selectors'
import { setSingleGeoentity } from '../geoentity/geoentitySlice'
import { selectIsItineraryUniverse, selectIsSearchOnItineraryRoute } from '../history/history.selectors'
import { requestMove } from '../map/map.actions'
import { mapUpdated } from '../map/mapSlice'
import { DELAY_BEFORE_SEARCH_GEOENTITIES, THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI } from '../search/search.middlewares'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { showUiElement } from '../ui/uiSlice'
import { refreshPoisOnRoute } from './itinerary.poisOnRoute.actions'

const debouncedRefreshPoisOnRoute = debounce((dispatch, getState) => {
  refreshPoisOnRoute()(dispatch, getState)
}, DELAY_BEFORE_SEARCH_GEOENTITIES)

export const refreshPoisOnRouteWhenMapMoved =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (selectIsSearchOnItineraryRoute(getState()) && action.type === mapUpdated.type) {
      const { distanceFromPreviousChange, fromAppAction } = action.payload
      const shouldRefresh = !fromAppAction && distanceFromPreviousChange > THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI
      if (shouldRefresh) {
        debouncedRefreshPoisOnRoute(dispatch, getState)
      }
    }

    return r
  }

export const handleMapPositioningOnGeoentityOnRouteSelected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    const isItineraryUniverse = selectIsItineraryUniverse(getState())
    const actionIsSetSingleGeoentity = action.type === setSingleGeoentity.type
    const actionIsShowPopin = action.type === showUiElement.type && action.payload === UI_ELEMENTS.POPIN

    if (isItineraryUniverse && (actionIsSetSingleGeoentity || actionIsShowPopin)) {
      const options = selectOptionsForRequestMove(getState())
      const center = selectSelectedGeoentityLatLng(getState())
      if (center && center.lat) requestMove({ center, options })(dispatch, getState)
    }

    return r
  }

export default [refreshPoisOnRouteWhenMapMoved, handleMapPositioningOnGeoentityOnRouteSelected]
