import { createSelector, createSelectorCreator, lruMemoize } from 'reselect'

/*
 * reselect 5.x introduces WeakMap cache by default instead of previous LRU cached.
 * WeakMap allows multiple previous value whereas LRU caches only last entry.
 * This caused a memory leak issue on node in may 2024.
 * So we WANT previous behavior (LRU) on server but we can use WeakMap on browser
 */
export const createCustomSelector = __SERVER__
  ? createSelectorCreator({ memoize: lruMemoize, argsMemoize: lruMemoize })
  : createSelector
