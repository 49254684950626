import { pushHistory, reverseResolveRouteAndRegisterHistory } from '../history/historySlice'
import { selectIsOnboardingContext } from './navigation.selectors'
import { toggleOffOnboardingContext } from './navigationSlice'

export const onChangingRouteToggleoffOnboardingContext =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (
      selectIsOnboardingContext(getState()) &&
      action.type === pushHistory.type &&
      action.origType !== reverseResolveRouteAndRegisterHistory.type
    ) {
      dispatch(toggleOffOnboardingContext())
    }

    return next(action)
  }

export default [onChangingRouteToggleoffOnboardingContext]
