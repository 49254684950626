export class OnceWithCondition {
  constructor() {
    this.fnToBeCalledOnce = undefined
    this.condition = false
  }

  setFn(fn) {
    this.fnToBeCalledOnce = fn
    this.call()
  }

  setCondition(condition) {
    this.condition = condition
    this.call()
  }

  call() {
    if (this.condition && typeof this.fnToBeCalledOnce === 'function') {
      try {
        this.fnToBeCalledOnce()
      } catch (e) {
        console.error('OnceWithCondition fn error', e)
      }
      this.fnToBeCalledOnce = undefined
    }
  }
}
