import MultiPolygon from './MultiPolygon'

const N = 90
const S = -90
const W = -180
const E = 180

export const worldSquareShape = [
  [W, N],
  [E, N],
  [E, S],
  [W, S],
  [W, N]
]

export const convertPolygonGeometryToMultiPolygon = geometry => ({
  type: 'MultiPolygon',
  coordinates: [[...geometry.coordinates]]
})

export const getPlainShapesFromMultiPolygonGeometry = geometry => geometry.coordinates.map(([firstShape]) => firstShape)

export const getHoleShapesFromMultiPolygonGeometry = geometry => {
  return geometry.coordinates
    .map(([_, ...holeShapes]) => holeShapes)
    .filter(shapes => shapes.length > 0)
    .reduce((acc, item) => [...acc, ...item], [])
}

export const reverseShape = geometry => {
  const multiPolygonGeometry = geometry.type === 'Polygon' ? convertPolygonGeometryToMultiPolygon(geometry) : geometry

  if (multiPolygonGeometry.type !== 'MultiPolygon') return

  const shape = new MultiPolygon()
  shape.addPolygon({
    points: worldSquareShape,
    holes: getPlainShapesFromMultiPolygonGeometry(multiPolygonGeometry)
  })
  getHoleShapesFromMultiPolygonGeometry(multiPolygonGeometry).forEach(points => {
    shape.addPolygon({ points })
  })

  return shape.getGeometry()
}

export const getViewportFromGeometry = geometry => {
  const multiPolygonGeometry = geometry.type === 'Polygon' ? convertPolygonGeometryToMultiPolygon(geometry) : geometry
  const points = multiPolygonGeometry?.coordinates?.flat()?.flat()
  const latList = points.map(([_, lat]) => lat)
  const lngList = points.map(([lng]) => lng)

  return [Math.min(...lngList), Math.min(...latList), Math.max(...lngList), Math.max(...latList)]
}
