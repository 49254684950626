import debug from 'debug'
import request from '../../domain/utils/request'
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/browserStorageService'
import { ONE_MINUTE_MS, getFutureDate } from '../utils/date'

const d = debug('ad:liveramp')

const LIVERAMP_EXPIRE_IN_DAYS = 15
const LIVERAMP_REFRESH_DELAY_IN_MS = 30 * ONE_MINUTE_MS

const LIVERAMP_KEY_ENVELOPE = '_lr_env'
const LIVERAMP_KEY_ENVELOPE_EXPIRE = '_lr_env_exp'
const LIVERAMP_KEY_ENVELOPE_TIMESTAMP = '_lr_env_timestamp'

// LiveRamp constant - https://developers.liveramp.com/authenticatedtraffic-api/docs/the-quickstart-guide
const pid = '13739' // Your partner/placement ID (API key).
const ct = 4 // The type of consent passed to the API. "4" represents a TCFv2 compatible string. Consent is required for processing in the EU.

const getEnvelopeFromLocalStore = () => getFromLocalStorage(LIVERAMP_KEY_ENVELOPE)

export const isEnvelopeFromLocalStoreValid = () => {
  const expireDate = getFromLocalStorage(LIVERAMP_KEY_ENVELOPE_EXPIRE)
  const valid = Boolean(expireDate && expireDate > Date.now())
  if (d.enabled) {
    d('isEnvelopeFromLocalStoreValid', valid)
  }
  return valid && Boolean(decodeEnvelopeFromLocalStorage())
}

export const shouldRefreshEnvelopeFromLocalStore = () => {
  const timestamp = getFromLocalStorage(LIVERAMP_KEY_ENVELOPE_TIMESTAMP)
  const shouldRefresh = Boolean(timestamp && Date.now() - timestamp > LIVERAMP_REFRESH_DELAY_IN_MS)
  if (d.enabled) {
    d('shouldRefreshEnvelopeFromLocalStore', shouldRefresh)
  }
  return shouldRefresh
}

export const decodeEnvelopeFromLocalStorage = () => {
  const envelope = getEnvelopeFromLocalStore()
  if (!envelope) {
    if (d.enabled) {
      d('no enveloppe found')
    }
    return
  }
  const decodedEnvelope = JSON.parse(atob(envelope))?.envelope

  if (d.enabled) {
    d('enveloppe decoded ', decodedEnvelope)
  }
  return decodedEnvelope
}

export const saveEnvelopeToLocalStore = envelope => {
  if (!envelope) return
  const envelopeInBase64 = btoa(JSON.stringify(envelope))
  if (d.enabled) {
    d('saveEnvelopeToLocalStore: enveloppe in base64', envelopeInBase64)
  }
  const expireDate = getFutureDate({ days: LIVERAMP_EXPIRE_IN_DAYS }).getTime()
  saveToLocalStorage(LIVERAMP_KEY_ENVELOPE, envelopeInBase64)
  saveToLocalStorage(LIVERAMP_KEY_ENVELOPE_EXPIRE, expireDate)
  saveToLocalStorage(LIVERAMP_KEY_ENVELOPE_TIMESTAMP, Date.now())
  return envelopeInBase64
}

export const submitHashedEmailToLiveRamp = (consentString, hashedEmail) => {
  const it = 4 // The identifier type of the call. "4" corresponds to hashed emails
  const iv = hashedEmail // The hashed version of the raw email.
  const cv = consentString // The relevant consent string value.

  const liveRampCreateUrl = `https://api.rlcdn.com/api/identity/envelope?pid=${pid}&it=${it}&iv=${iv}&ct=${ct}&cv=${cv}`

  if (d.enabled) {
    d('calling', liveRampCreateUrl)
  }

  return request.get(liveRampCreateUrl).then(response => {
    const envelope = response?.data?.envelope
    if (d.enabled) {
      d('enveloppe received', envelope)
    }
    return { envelope }
  })
}

export const refreshEnvelopeInLocalStoreToLiveRamp = consentString => {
  const envelopeValue = decodeEnvelopeFromLocalStorage()
  if (!envelopeValue) {
    if (d.enabled) {
      d('no enveloppe found')
    }
    return Promise.reject(new Error('no envelope'))
  }
  const it = 19 // "19" corresponds to regular ATS envelopes.
  const iv = envelopeValue // envelope value
  const cv = consentString // The relevant consent string value.

  const liveRampRefreshUrl = `https://api.rlcdn.com/api/identity/v2/envelope/refresh?pid=${pid}&it=${it}&iv=${iv}&ct=${ct}&cv=${cv}`

  if (d.enabled) {
    d('calling', liveRampRefreshUrl)
  }
  return request.get(liveRampRefreshUrl).then(response => {
    const envelope = response?.data?.envelopes?.[0]?.value
    if (d.enabled) {
      d('refreshed envelope', envelope)
    }
    return { envelope }
  })
}

export const retrieveEnvelope = callback => {
  try {
    const env = {
      envelope: getEnvelopeFromLocalStore()
    }
    const envJson = JSON.stringify(env)
    if (callback) {
      callback(envJson)
    } else {
      return envJson
    }
  } catch (e) {
    console.error('retrieveEnvelope error', e)
  }
}
