import request from '../../domain/utils/request'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'

export const requestMapEvents = locale =>
  request
    .get(getService(SERVICES.MAP_EVENT), {
      params: { lang: locale }
    })
    .then(({ data }) => data)
    .catch(e => {
      console.error(e)
    })
