import { getVdeLinkAndTags } from '../../domain/advertising/ad.vde.utils'
import { REDIRECT_TYPE } from '../../domain/advertising/vde.constants'
import {
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_TO,
  ROUTE_SEO_GEOENTITY
} from '../../routes'
import { selectAdDataForFirstVde } from '../ad/ad.selectors'
import {
  selectCurrentGeoentity,
  selectCurrentGeoentityLabelForUrl,
  selectGeoentityById
} from '../geoentity/geoentity.selectors'
import {
  isASearchOnItineraryPage,
  isAddressRoute,
  isGeoentityOnSearchRoute,
  isSearchRoute,
  selectCurrentHistoryRoute,
  selectGeoentityOnSearchRouteBaseParameters
} from './history.selectors'

import { createCustomSelector } from '../../domain/utils/react-reselect'
import { isEmpty } from '../../utils/lang'
import { selectFirstAddress, selectHasFirstAddress } from '../address/address.selectors'

export const selectNavigateToGeoentityParameters = createCustomSelector(
  selectCurrentHistoryRoute,
  selectGeoentityOnSearchRouteBaseParameters,
  selectAdDataForFirstVde,
  selectGeoentityById,
  selectHasFirstAddress,
  (_, props) => props?.id,
  (_, props) => props?.scrollTop,
  (currentRoute, poiOnSearchBaseParameters, vdeAdData, geoentity = {}, hasAddress, geoentityId, scrollTop) => {
    const { vde } = geoentity
    if (hasAddress && isAddressRoute(currentRoute) && !vde) {
      return {
        route: ROUTE_GEOENTITY_AT_ADDRESS,
        routeOptions: {
          avoidRefetchingPageData: true,
          geoentityId
        }
      }
    }
    if (vde) {
      const { redirectType, app, subcategory, rubric } = getVdeLinkAndTags(vdeAdData)
      switch (redirectType) {
        case REDIRECT_TYPE.APP:
          return {
            route: ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
            routeOptions: { app, geoentityId }
          }
        case REDIRECT_TYPE.SUBCATEGORY:
          return {
            route: ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
            routeOptions: { subcategory, geoentityId }
          }
        case REDIRECT_TYPE.RUBRIC:
          return {
            route: ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
            routeOptions: { rubric, geoentityId }
          }
      }
    }
    if (isSearchRoute(currentRoute) || isGeoentityOnSearchRoute(currentRoute)) {
      return {
        route: poiOnSearchBaseParameters.route,
        routeOptions: {
          ...poiOnSearchBaseParameters.routeOptions,
          geoentityId,
          scrollTop
        }
      }
    }
    if (isASearchOnItineraryPage(currentRoute)) {
      const routeOptions = { geoentityId, scrollTop }
      switch (currentRoute) {
        case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP:
        case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP:
          return { route: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP, routeOptions }
        case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
        case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY:
          return { route: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY, routeOptions }
        case ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY:
        case ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY:
          return { route: ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY, routeOptions }
      }
    }

    return { route: ROUTE_SEO_GEOENTITY, routeOptions: { geoentityId } }
  }
)

export const selectItineraryOptions = createCustomSelector(
  selectFirstAddress,
  selectCurrentGeoentity,
  selectCurrentGeoentityLabelForUrl,
  (address, geoentity, currentGeoentityLabelForUrl) => {
    if (!isEmpty(address)) {
      return {
        route: ROUTE_ITINERARY_TO,
        routeOptions: {
          to: address.label,
          toAddress: address,
          avoidReset: true
        }
      }
    }
    if (geoentity) {
      return {
        route: ROUTE_ITINERARY_TO,
        routeOptions: {
          to: currentGeoentityLabelForUrl,
          toAddress: geoentity,
          avoidReset: true
        }
      }
    }
    return { route: ROUTE_ITINERARY_HOME }
  }
)
