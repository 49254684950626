import { OUTDOOR_STATUS_NONE } from '../../domain/outdoor/Utils'
import { pick } from '../../utils/object'
import { UI_ELEMENTS, USER_SCREENS } from './ui.constants'
import { selectOutdoorStatus, selectUIGeoentityActiveFilterId } from './ui.selectors'
import {
  displayUiOutdoor,
  hideUiElement,
  hideUiOutdoor,
  setUiGeoentityActiveFilter,
  setUiUserScreen,
  showUiElement
} from './uiSlice'

export const displayCompleteSuggest = () => (dispatch, getState) => {
  dispatch(showUiElement(UI_ELEMENTS.SUGGEST))
  dispatch(showUiElement(UI_ELEMENTS.SEARCH_DIALOG))
}

export const hideCompleteSuggest = () => (dispatch, getState) => {
  dispatch(hideUiElement(UI_ELEMENTS.SEARCH_DIALOG))
  dispatch(hideUiElement(UI_ELEMENTS.SUGGEST))
}

export const displayOutdoor = panoData => dispatch =>
  dispatch(displayUiOutdoor(pick(panoData, ['status', 'panoramicId', 'target', 'lat', 'lng', 'url'])))

export const hideOutdoor = () => (dispatch, getState) =>
  selectOutdoorStatus(getState()) !== OUTDOOR_STATUS_NONE && dispatch(hideUiOutdoor())

export const setGeoentityActiveFilter = id => (dispatch, getState) => {
  const currentId = selectUIGeoentityActiveFilterId(getState())
  dispatch(setUiGeoentityActiveFilter({ id: currentId !== id ? id : undefined }))
}

export const hideAndResetUserUIScreen = () => (dispatch, getState) => {
  dispatch(hideUiElement(UI_ELEMENTS.USER))
  dispatch(setUiUserScreen({ name: USER_SCREENS.default }))
}
