export const ONE_MINUTE = 60
export const FIVE_MINUTES = 5 * ONE_MINUTE
export const ONE_HOUR = 60 * ONE_MINUTE
export const ONE_HOUR_IN_MINUTES = 60
export const ONE_DAY = 24 * ONE_HOUR
export const ONE_WEEK = 7 * ONE_DAY
export const ONE_MONTH = 30 * ONE_DAY

export const ONE_SECOND_MS = 1000
export const ONE_MINUTE_MS = ONE_MINUTE * ONE_SECOND_MS
export const ONE_HOUR_MS = ONE_HOUR * ONE_SECOND_MS
export const ONE_DAY_MS = ONE_DAY * ONE_SECOND_MS
export const ONE_WEEK_MS = ONE_WEEK * ONE_SECOND_MS
export const ONE_MONTH_MS = ONE_MONTH * ONE_SECOND_MS

export function pad(n) {
  return `${n < 10 ? '0' : ''}${n}`
}

export const parseDate = dateStr => {
  // new Date() with a string from routemm roadbook summary is parsed without timezone in browsers except in Safari where it’s UTC
  // To avoid that bug in Safari, we add `Z` to be parsed in UTC in all browsers and we add the timezone offset
  // In node, behavior is different so we sadly cannot test that function...
  const date = new Date(`${dateStr}Z`)
  const offset = date.getTimezoneOffset() * 60 * 1000
  return new Date(date.getTime() + offset)
}

export const getCurrentTime = (date = new Date()) => {
  return `${pad(date.getHours())}:${pad(date.getMinutes())}`
}

const formatISODate = date => date.toISOString().split('T')[0]

export const getCurrentDateISO = (date = new Date()) => {
  return formatISODate(date)
}

export const getTomorrowDate = (dateIn = new Date()) => getFutureDate({ days: 1 }, dateIn)

export const getTomorrowDateISO = (dateIn = new Date()) => {
  const date = getTomorrowDate(dateIn)
  return formatISODate(date)
}

export const getFutureDate = ({ days = 0 }, dateIn = new Date()) => {
  const date = new Date(dateIn)
  date.setDate(date.getDate() + days)
  return date
}

export const getFutureDateISO = ({ days = 0 }, dateIn = new Date()) => {
  const date = getFutureDate({ days }, dateIn)
  return formatISODate(date)
}

export const formatNaturalDateByLocale = ({ date, today, tomorrow, the }) => {
  if (date === getCurrentDateISO()) return today
  if (date === getTomorrowDateISO()) return tomorrow
  return `${the} ${formatDate(date)}`
}

export const formatDate = date => {
  const d = new Date(date)
  return `${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`
}

export const hasDateAlreadyPassed = date => {
  const dateToTest = new Date(date).setHours(0, 0, 0, 0)
  const currentDate = new Date().setHours(0, 0, 0, 0)

  return dateToTest < currentDate
}

export const isFirstDateAfterSecondDate = (dateA, dateB, strictlyAfter = false) => {
  const a = new Date(dateA).setHours(0, 0, 0, 0)
  const b = new Date(dateB).setHours(0, 0, 0, 0)

  return strictlyAfter ? a > b : a >= b
}

export const convertSecondsToMinutes = seconds => Math.floor(seconds / ONE_MINUTE)
export const convertMinutesToHours = minutes => Math.floor(minutes / ONE_HOUR_IN_MINUTES)

export const convertHourAndMinuteFormatToSeconds = (hoursToMinuteFormat, separator = 'h') => {
  const arrayToSeconds = hoursToMinuteFormat?.split(separator)

  if (!arrayToSeconds) return NaN

  return arrayToSeconds[0] * ONE_HOUR + arrayToSeconds[1] * ONE_MINUTE
}

export const convertMinutesFormatToSeconds = (minutesToSecondFormat, separator = 'min') => {
  const arrayToMinutes = minutesToSecondFormat?.split(separator)

  if (!arrayToMinutes) return NaN

  return parseInt(arrayToMinutes[0]) * ONE_MINUTE
}

export const convertSecondsToHourAndMinuteFormat = (seconds, separator = 'h') => {
  const secondsToMinutes = convertSecondsToMinutes(seconds)
  const minutesToHours = convertMinutesToHours(secondsToMinutes)

  return `${minutesToHours % 24}${separator}${pad(secondsToMinutes - minutesToHours * 60)}`
}

export const convertDateToHourAndMinuteFormat = dateString => {
  const date = new Date(dateString)

  if (!date.getTime()) return undefined

  const hours = date.getHours()
  const minutes = pad(date.getMinutes())
  return `${hours}h${minutes}`
}
