import UniversalRouter from 'universal-router'
import routes from '../../routes'

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (context?.route?.action?.default) {
      return context.route.action({ routeContext: context, params })
    }

    if (typeof context.route.action === 'function') {
      return context.route.action().then(action => action.default({ routeContext: context, params }))
    }
  }
})
