import { isPE } from '../../domain/Device'
import { createCustomSelector } from '../../domain/utils/react-reselect'

export const selectSearchState = store => store?.search

export const selectSearchParameters = createCustomSelector(selectSearchState, search => search?.parameters ?? {})

export const selectSearchCategoryParameter = createCustomSelector(selectSearchParameters, ({ category }) => category)

export const selectSearchAppParameter = createCustomSelector(selectSearchParameters, ({ app }) => app)

export const getSearchMaxPois = () => (__SERVER__ || isPE() ? 30 : 130)

export const selectSearchTerms = createCustomSelector(selectSearchState, search => search?.terms ?? '')
