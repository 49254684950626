export const GEOLOC_STATUS_DISABLED = 'disabled'
export const GEOLOC_STATUS_FOLLOW = 'follow'
export const GEOLOC_STATUS_ACTIVE = 'active'
export const GEOLOC_STATUS_FETCH_ACTIVE = 'fetch-active'
export const GEOLOC_STATUS_FETCH_FOLLOW = 'fetch-follow'
export const GEOLOC_STATUS_BLOCKED = 'blocked'

export const GEOLOC_STATUS_BUTTON_WORKFLOW = {
  [GEOLOC_STATUS_BLOCKED]: GEOLOC_STATUS_FETCH_FOLLOW,
  [GEOLOC_STATUS_DISABLED]: GEOLOC_STATUS_FETCH_FOLLOW,
  [GEOLOC_STATUS_FETCH_ACTIVE]: GEOLOC_STATUS_DISABLED,
  [GEOLOC_STATUS_FETCH_FOLLOW]: GEOLOC_STATUS_DISABLED,
  [GEOLOC_STATUS_ACTIVE]: GEOLOC_STATUS_FOLLOW,
  [GEOLOC_STATUS_FOLLOW]: GEOLOC_STATUS_ACTIVE
}

export const GEOLOC_STATUS_FETCH_WORKFLOW = {
  [GEOLOC_STATUS_FETCH_ACTIVE]: GEOLOC_STATUS_ACTIVE,
  [GEOLOC_STATUS_FETCH_FOLLOW]: GEOLOC_STATUS_FOLLOW
}

export const GEOLOC_MOVE_FROM_FOLLOW = 'geolocation:follow'
export const GEOLOC_MOVE_FROM_INITIAL = 'geolocation:initial'

export const LAYER_ID_POLYLINE = /polyline_route_(\d+)/
export const LAYER_ID_CMM = /cmm_.*_active$/

export const MAPLIBRE_EVENT_MOVE = 'move'
export const MAPLIBRE_EVENT_ZOOM = 'zoom'

export const MAP_STATUS_NONE = 'none'
export const MAP_STATUS_INIT = 'init'
export const MAP_STATUS_STYLED = 'styled'
export const MAP_STATUS_RELOADING = 'reloading'

export const MAP_THEMES = {
  normal: 'normal',
  satellite: 'satellite',
  nature: 'nature',
  simple: 'simple',
  dark: 'dark'
}

export const MAP_MODES = {
  neutral: 'neutral',
  bicycle: 'bicycle',
  traffic: 'traffic',
  tc: 'tc',
  zfe: 'zfe'
}

export const MAP_STYLES_KEY_NAMES = {
  [MAP_THEMES.normal]: 'mappy_map',
  [MAP_THEMES.satellite]: 'mappy_photo',
  [MAP_THEMES.dark]: 'mappy_map_dark',
  [MAP_THEMES.nature]: 'nature_map',
  [MAP_THEMES.simple]: 'simplified_map',
  [MAP_MODES.bicycle]: 'bicycle',
  [MAP_MODES.traffic]: 'traffic',
  [MAP_MODES.tc]: 'transports',
  [MAP_MODES.zfe]: 'zfe'
}

export const MAP_EVENTS_STATUS = {
  planned: 'planned',
  ongoing: 'ongoing',
  expired: 'expired'
}

export const MAP_BUTTON_THEME = 'theme'
export const MAP_BUTTON_MODE = 'mode'

export const MINIMUM_DISTANCE_TO_SHOW_GAP_POLYLINE_IN_KM = 0.01

export const MAP_EVENT_LAYER_NAME = 'restrictions-circulation'
