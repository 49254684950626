import { formatFullRouteFromHistory, getMatchingRoute } from '../../domain/router/routeUtils'
import { deferredWindowLocation } from '../../domain/utils/deferredWindowActions'
import { selectIsMapInitialized } from '../map/map.selectors'
import { selectSearchParameters } from '../search/search.selectors'
import { navigateTo } from './history.actions'
import { selectCurrentHistoryRoute, selectIsSearchPage, selectPreviousHistoryRoute } from './history.selectors'
import {
  navigateToExternalUrl,
  popHistory,
  pushHistory,
  reverseResolveRouteAndRegisterHistory,
  saveHistoryPostPop
} from './historySlice'
import { getForcedPopRoute, shouldNativePop, shouldReplace } from './historyWaybackEngine'

import { MAP_FROM_APP_ACTION_PADDING } from '../../domain/map/map.utils'
import { mapUpdated } from '../map/mapSlice'

/*
 * History v5.0.0 introduced a behavior change [1] which keep the hash/search even if new location don’t have one.
 * So we need to explictly path an object with an empty hash if we’re going to an URL without a hash to avoid keeping it.
 * [1] https://github.com/ReactTraining/history/issues/814
 */
export const prepareUrlForHistory = url =>
  !url.includes('#')
    ? {
        pathname: url,
        hash: ''
      }
    : url

export const navigationMiddleware = (store, history) => next => action => {
  switch (action.type) {
    case pushHistory.type: {
      const { route, routeOptions, fullRoute } = action.payload
      const storeState = (store && store.getState()) || {}
      const currentRoute = selectCurrentHistoryRoute(storeState)

      if (shouldReplace(currentRoute, route, routeOptions)) {
        history.replace(prepareUrlForHistory(fullRoute), routeOptions)
      } else {
        history.push(prepareUrlForHistory(fullRoute), routeOptions)
      }

      return next(action)
    }
    case popHistory.type: {
      const storeState = store.getState()
      const currentRoute = selectCurrentHistoryRoute(storeState)
      const previousRoute = selectPreviousHistoryRoute(storeState)

      if (shouldNativePop(currentRoute, previousRoute, storeState)) {
        history.back() // this operation is asynchronous, this is why we have to dispatch saveHistoryPostPop.type on event 'POP' to register actual url
      } else {
        const { route, external, routeOptions } = getForcedPopRoute(currentRoute, storeState)

        if (external) {
          store.dispatch(navigateToExternalUrl({ url: route }))
        } else {
          navigateTo({
            route,
            routeOptions: {
              ...routeOptions,
              forceReplaceForPop: !routeOptions?.forceNotReplace
            }
          })(store.dispatch, store.getState)
        }
      }

      break
    }
    case saveHistoryPostPop.type: {
      const currentFullRoute = formatFullRouteFromHistory(history)
      const currentRoute = getMatchingRoute(currentFullRoute)

      return next({
        ...action,
        payload: {
          ...action.payload,
          route: currentRoute,
          fullRoute: currentFullRoute
        }
      })
    }
    case reverseResolveRouteAndRegisterHistory.type: {
      const { fullRoute } = action.payload

      const route = getMatchingRoute(fullRoute)

      if (!route) return

      if (__SERVER__) history.push(prepareUrlForHistory(fullRoute))

      return next({
        ...action,
        type: pushHistory.type,
        origType: reverseResolveRouteAndRegisterHistory.type,
        payload: {
          ...action.payload,
          route
        }
      })
    }
    case navigateToExternalUrl.type: {
      const { url } = action.payload
      deferredWindowLocation(url)

      return next(action)
    }
  }

  return next(action)
}

export const updateSearchRouteWithBboxOnMapUpdate =
  ({ getState, dispatch }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === mapUpdated.type) {
      const storeState = getState()
      const fromAppAction = action?.payload?.fromAppAction
      if (
        selectIsMapInitialized(storeState) &&
        selectIsSearchPage(storeState) &&
        fromAppAction !== MAP_FROM_APP_ACTION_PADDING
      ) {
        const route = selectCurrentHistoryRoute(storeState)
        const routeOptions = {
          ...selectSearchParameters(storeState),
          avoidDispatchingAnyPageAction: true,
          avoidPageTag: true
        }
        navigateTo({
          route,
          routeOptions
        })(dispatch, getState)
      }
    }
    return r
  }

export default [navigationMiddleware, updateSearchRouteWithBboxOnMapUpdate]
