export const ACCENTS_CHARS = 'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'

export const slugify = (string = '') =>
  removeDiacritics(string)
    .toLowerCase()
    // dash special characters
    .replace(/[^a-z0-9]/g, '-')
    // Compress multiple dashes
    .replace(/-+/g, '-')
    // Trim dashes
    .replace(/^-|-$/g, '')

export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

export const dashToUnderscore = (str = '') => str.replace(/-/g, '_')

export const underscoreToDash = (str = '') => str.replace(/_/g, '-')

export const truncate =
  (max = 100) =>
  (str = '') =>
    str.length <= max ? str : str.substring(0, max) + '...'

export const hashCode = (s = '') =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)

export const removeHtmlEmTags = (str = '') => str.replace(/<\/?em>/g, '')

const reStrip = new RegExp('</?em>|</?span>|</?i>', 'g')
export const removeHtmlEmSpanAndITags = (str = '') => str.replace(reStrip, '')

export const removeDiacritics = str => {
  if (str && str.normalize) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/œ/g, 'oe')
      .replace(/æ/g, 'ae')
      .replace(/Œ/g, 'OE')
      .replace(/Æ/g, 'AE')
  }

  return str // iOS 9 hasn’t normalize
}

export const equalsIgnoreCase = (s1, s2) => s1.toLowerCase() === s2.toLowerCase()

export const spaceAfter = str => (str ? str + ' ' : '')

export const injectArgsInString = (str, ...args) => args.reduce((acc, v) => acc.replace('%s', v), str)

export const replaceKeysInString = (str, params) =>
  Object.keys(params).reduce((acc, key) => acc.replace(new RegExp(`\\[${key}\\]`, 'g'), params[key]), str)

export const isString = str => str != null && typeof str.valueOf() === 'string'

export const removeComma = str => str.replace(',', '')
