import { createCustomSelector } from '../../domain/utils/react-reselect'
import { AB_TEST_STATUS_INITIAL, AB_TEST_STATUS_READY } from './abtest.constants'

const selectAbTestState = store => store?.abtest ?? {}

const selectAbTestStatus = createCustomSelector(selectAbTestState, ({ status }) => status)

export const selectShouldLoadAbTest = createCustomSelector(
  selectAbTestStatus,
  status => status === AB_TEST_STATUS_INITIAL
)

export const selectAreAbTestsReady = createCustomSelector(selectAbTestStatus, status => status === AB_TEST_STATUS_READY)

export const selectAbTestConfig = createCustomSelector(selectAbTestState, abtest => abtest?.config ?? [])

export const selectAbTestEnrollment = createCustomSelector(selectAbTestState, abtest => abtest?.enrollment ?? [])

const findTestInConfigById = (config, abTestId) => config.find(({ id }) => id === abTestId)
const findVariantEnrolled = (variantsForThatTest, randomForThatTest) =>
  variantsForThatTest.find(({ from, to }) => randomForThatTest >= from && randomForThatTest < to)

export const selectVariantForAbTestId = createCustomSelector(
  selectAbTestConfig,
  selectAbTestEnrollment,
  (_, { id } = {}) => id,
  (config, enrollment, abTestId) => {
    if (!abTestId) return ''

    const abTest = findTestInConfigById(config, abTestId)
    if (!abTest) return ''
    const variantsForThatTest = abTest?.variants ?? []
    const randomForThatTest = enrollment.find(({ id }) => id === abTestId)?.randomId ?? 0
    return { ...findVariantEnrolled(variantsForThatTest, randomForThatTest), analytics: variantsForThatTest.analytics }
  }
)

export const selectVariantIdForAbTestId = createCustomSelector(selectVariantForAbTestId, variant => variant?.id ?? '')

export const selectAbTestVariants = createCustomSelector(
  selectAbTestConfig,
  selectAbTestEnrollment,
  (config, enrollment) =>
    enrollment
      .filter(({ id }) => findTestInConfigById(config, id))
      .map(({ id, randomId }) => {
        const abTest = findTestInConfigById(config, id)
        const variantsForThatTest = abTest?.variants ?? []
        const variantEnrolled = findVariantEnrolled(variantsForThatTest, randomId)
        return { id, variant: variantEnrolled, analytics: abTest.analytics }
      })
)

export const selectAbTestVariantsIds = createCustomSelector(selectAbTestVariants, abtestsVariants =>
  abtestsVariants.map(({ id, variant, analytics }) => ({ id, variant: variant?.id, analytics }))
)

export const selectAbTestVariantsIdsForTracking = createCustomSelector(selectAbTestVariants, abtestsVariants =>
  abtestsVariants
    .filter(({ analytics }) => analytics !== false)
    .map(({ id, variant }) => ({ id, variant: variant?.id }))
)
