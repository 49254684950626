import { number, shape } from 'prop-types'
import { createCustomSelector } from '../../domain/utils/react-reselect'

const selectToolsState = store => store?.tools

export const selectComputePosition = createCustomSelector(selectToolsState, tools => tools?.position ?? {})
selectComputePosition.propTypes = shape({
  lat: number,
  lng: number
})

export const selectComputeRadius = createCustomSelector(selectToolsState, tools => tools?.radius)
selectComputeRadius.propTypes = number
