import debug from 'debug'
import { isGE } from '../../domain/Device'
import { POPUP_TYPE_ADDRESS, POPUP_TYPE_POI } from '../../domain/map/popup/popup.selectors'
import { ONE_WEEK_MS } from '../../domain/utils/date'
import { ROUTE_GEOENTITY_AT_ADDRESS } from '../../routes'
import { closeVde } from '../ad/ad.actions'
import { resetGeoentityList, setGeoentityFocusedPoiId, unfocusGeoentity } from '../geoentity/geoentitySlice'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { pushHistory, setNavigationDone } from '../history/historySlice'
import { selectMapTooSlowTimestamp } from '../ui/ui.selectors'
import { displayUiMapTooSloowTooltip } from '../ui/uiSlice'
import geolocMiddlewares from './map.geoloc.middlewares'
import { selectIsMapFullScreen, selectIsVectorMap, selectMapEventPopin, selectPopupType } from './map.selectors'
import {
  displayMapPopup,
  hideMapPopup,
  resetMapEventsPopin,
  resetMapStatus,
  sendMapLoadingTime,
  setMapEventsPopin,
  setMapMode,
  setMapTheme,
  switchMapType,
  turnOffFullscreen
} from './mapSlice'

const d = debug('map')

export const VECTO_LOAD_TIME_AFTER_SHOWING_TOO_SLOW_POPIN = 6

export const TIME_BETWEEN_TOO_SLOW_POPIN_DISPLAYS = ONE_WEEK_MS

export const mapPopupMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case unfocusGeoentity.type: {
        if (selectPopupType(getState()) === POPUP_TYPE_POI) dispatch(hideMapPopup())
        break
      }
      case resetGeoentityList.type: {
        if (selectPopupType(getState()) !== POPUP_TYPE_ADDRESS) dispatch(hideMapPopup())
        break
      }
      case setGeoentityFocusedPoiId.type: {
        if (selectCurrentHistoryRoute(getState()) !== ROUTE_GEOENTITY_AT_ADDRESS) {
          dispatch(displayMapPopup(POPUP_TYPE_POI))
        } else {
          dispatch(hideMapPopup())
        }
        break
      }
    }
    return r
  }

export const mapLoadedMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === sendMapLoadingTime.type) {
      const { loadTime, mapHasGoodSupport } = action.payload
      const tooSlowTimestamp = selectMapTooSlowTimestamp(getState())
      const delta = Date.now() - tooSlowTimestamp
      if (
        selectIsVectorMap(getState()) &&
        loadTime > VECTO_LOAD_TIME_AFTER_SHOWING_TOO_SLOW_POPIN &&
        delta > TIME_BETWEEN_TOO_SLOW_POPIN_DISPLAYS
      ) {
        d('map is slow to load', loadTime, delta, mapHasGoodSupport)
        if (!mapHasGoodSupport) {
          d('displaying too slow popup')
          dispatch(displayUiMapTooSloowTooltip())
        }
      }
    }
    return next(action)
  }

export const disableFullScreenOnNavigation =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (isGE() && action.type === pushHistory.type && selectIsMapFullScreen(getState())) {
      dispatch(turnOffFullscreen())
    }
    return next(action)
  }

export const mapEventPopinLifeCycle =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === setMapEventsPopin.type) {
      closeVde()(dispatch, getState)
    } else if (
      (action.type === setNavigationDone.type || action.type === setMapMode.type || action.type === setMapTheme.type) &&
      selectMapEventPopin(getState())
    ) {
      dispatch(resetMapEventsPopin())
    }

    return next(action)
  }

export const resetMapStatusOnTypeSwitch =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === switchMapType.type) {
      dispatch(resetMapStatus())
    }
    return next(action)
  }

export default [
  mapPopupMiddleware,
  mapLoadedMiddleware,
  disableFullScreenOnNavigation,
  mapEventPopinLifeCycle,
  resetMapStatusOnTypeSwitch,
  ...geolocMiddlewares
]
