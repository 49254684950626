import {
  CRITAIR_DEFAULT_ID,
  CRITAIR_ID,
  DEFAULT_TRAVEL_SPEED,
  PREFERRED_FUEL_CONSUMPTION,
  PREFERRED_FUEL_ID,
  PREFERRED_FUEL_PRICE,
  PREFERRED_VEHICLE_ID,
  VEHICLE_OWN
} from '../../domain/itineraryOptions/ItineraryOptions.constants'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { head } from '../../utils/array'

const selectItineraryOptionsState = store => store?.itineraryOptions

export const selectPreferredOptions = createCustomSelector(
  selectItineraryOptionsState,
  itineraryOptions => itineraryOptions?.preferredOptions ?? {}
)

export const selectTransportMode = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const {
      defaultOptions: { transportModes }
    } = itineraryOptions
    return transportModes.find(({ providers }) => providers.findIndex(provider => provider === modeId) !== -1)
  })

export const selectModeMetadata = modeId =>
  createCustomSelector(selectItineraryOptionsState, selectTransportMode(modeId), (itineraryOptions, transportMode) => {
    const {
      defaultOptions: { vehiclesOptions }
    } = itineraryOptions

    return {
      ...vehiclesOptions.find(opts => opts?.id === modeId),
      transportMode
    }
  })

export const selectVehicleList = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const { vehicles } = selectModeMetadata(modeId)({ itineraryOptions })
    return vehicles ?? []
  })

export const selectFuelList = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const preferredVehicle = selectPreferredVehicle(modeId)({ itineraryOptions })
    const { fuels } = preferredVehicle || {}
    return fuels ?? []
  })

export const selectPreferredVehicle = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const preferredVehicleId = selectPreferredVehicleId(modeId)({ itineraryOptions })
    const vehicles = selectVehicleList(modeId)({ itineraryOptions })

    return vehicles.find(vehicule => vehicule?.id === preferredVehicleId) ?? {}
  })

export const selectPreferredFuel = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const preferredFuelId = selectPreferredFuelId(modeId)({ itineraryOptions })
    const fuels = selectFuelList(modeId)({ itineraryOptions })

    const preferredFuel = fuels.find(fuel => fuel?.id === preferredFuelId) ?? {}

    const defaultFuelPrices = selectDefaultFuelPrices({ itineraryOptions })
    const defaultPrice = defaultFuelPrices[preferredFuelId]
    const price = itineraryOptions?.preferredOptions?.[modeId]?.[PREFERRED_FUEL_PRICE] ?? defaultPrice ?? 0
    const consumption =
      itineraryOptions?.preferredOptions?.[modeId]?.[PREFERRED_FUEL_CONSUMPTION] ??
      preferredFuel?.default_consumption ??
      0

    return {
      ...preferredFuel,
      consumption,
      price
    }
  })

export const selectDefaultFuelPrices = createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
  const {
    defaultOptions: { fuelPrices }
  } = itineraryOptions

  return fuelPrices || []
})

export const selectPreferredVehicleId = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const { default_vehicle } = selectModeMetadata(modeId)({ itineraryOptions })
    const preferredVehicleId = itineraryOptions?.preferredOptions?.[modeId]?.[PREFERRED_VEHICLE_ID]

    return preferredVehicleId || default_vehicle
  })

export const selectPreferredFuelId = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const preferredFuelId = itineraryOptions?.preferredOptions?.[modeId]?.[PREFERRED_FUEL_ID]
    const preferredVehicle = selectPreferredVehicle(modeId)({ itineraryOptions })
    const { default_fuel } = preferredVehicle

    return preferredFuelId || default_fuel
  })

export const selectFavouriteModes = createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
  const transportModes = itineraryOptions?.defaultOptions?.transportModes ?? []
  return transportModes.filter(mode => (mode?.fit_for_favourite ?? false) === true)
})

const selectFirstFavouriteModeId = createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
  const modes = selectFavouriteModes({
    itineraryOptions
  })
  const first = head(modes)
  return first?.id ?? ''
})

export const selectPreferredMode = createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
  return (
    itineraryOptions?.preferredOptions?.favoriteMode ??
    selectFirstFavouriteModeId({
      itineraryOptions
    })
  )
})

export const selectPreferredSpeed = modeId =>
  createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
    const DEFAULT_SPEED = 'normal'
    return itineraryOptions?.preferredOptions?.[modeId]?.speedType ?? DEFAULT_SPEED
  })

export const selectVehicleOwn = modeId =>
  createCustomSelector(
    selectItineraryOptionsState,
    itineraryOptions => itineraryOptions?.preferredOptions?.[modeId]?.[VEHICLE_OWN] ?? true
  )

export const selectCritAirId = modeId =>
  createCustomSelector(
    selectItineraryOptionsState,
    itineraryOptions => itineraryOptions?.preferredOptions?.[modeId]?.[CRITAIR_ID]
  )

const skippableProvidersByMode = [
  { mode: 'motorbike', providers: ['motorbike'] },
  { mode: 'bike', providers: ['bike'] },
  { mode: 'trottinette', providers: ['trottinette'] }
]

export const selectSkippedProviders = createCustomSelector(selectItineraryOptionsState, itineraryOptions => {
  return skippableProvidersByMode
    .filter(({ mode }) => {
      return !(itineraryOptions?.preferredOptions?.[mode]?.own ?? true)
    })
    .reduce((skippedProviders, { providers }) => {
      return [...skippedProviders, ...providers]
    }, [])
})

export const selectModeOptionsWithDefaults = modeId =>
  createCustomSelector(selectPreferredVehicleId(modeId), selectPreferredFuel(modeId), (vehicleId, fuel) => ({
    vehicleId,
    fuelTypeId: fuel.id,
    fuelConsumption: fuel.consumption,
    critAirId: CRITAIR_DEFAULT_ID
  }))

export const selectPreferredOptionsForService = createCustomSelector(
  selectPreferredOptions,
  selectModeOptionsWithDefaults('car'),
  selectModeOptionsWithDefaults('motorbike'),
  (preferredOptions, carMetaData, motorbikeMetaData) => {
    const options = {
      ...preferredOptions
    }
    if (options.car) {
      options.car = {
        ...carMetaData,
        ...options.car
      }
    }
    if (options.motorbike) {
      options.motorbike = {
        ...motorbikeMetaData,
        ...options.motorbike
      }
    }
    ;['bike', 'trottinette', 'walk'].forEach(mode => {
      if (options[mode]) {
        options[mode] = {
          speedType: DEFAULT_TRAVEL_SPEED,
          ...options[mode]
        }
      }
    })

    return options
  }
)
