export const CRITAIR_DEFAULT_ID = '2'

const TRAVEL_SPEED_SLOW = 'slow'
const TRAVEL_SPEED_NORMAL = 'normal'
const TRAVEL_SPEED_FAST = 'fast'

export const DEFAULT_TRAVEL_SPEED = TRAVEL_SPEED_NORMAL

export const TRAVEL_SPEEDS = [TRAVEL_SPEED_SLOW, TRAVEL_SPEED_NORMAL, TRAVEL_SPEED_FAST]

export const TRAVEL_SPEED_VALUES_BY_MODE = {
  walk: {
    [TRAVEL_SPEED_SLOW]: 4,
    [TRAVEL_SPEED_NORMAL]: 5,
    [TRAVEL_SPEED_FAST]: 6
  },
  bike: {
    [TRAVEL_SPEED_SLOW]: 12,
    [TRAVEL_SPEED_NORMAL]: 15,
    [TRAVEL_SPEED_FAST]: 18
  },
  trottinette: {
    [TRAVEL_SPEED_SLOW]: 12,
    [TRAVEL_SPEED_NORMAL]: 15,
    [TRAVEL_SPEED_FAST]: 18
  }
}

export const PREFERRED_VEHICLE_ID = 'vehicleId'
export const PREFERRED_FUEL_ID = 'fuelTypeId'
export const PREFERRED_FUEL_PRICE = 'fuelPrice'
export const PREFERRED_FUEL_CONSUMPTION = 'fuelConsumption'
export const PREFERRED_SPEED = 'speedType'
export const VEHICLE_OWN = 'own'
export const CRITAIR_ID = 'critAirId'
