import { QUIZ_TYPES } from '../quiz/quiz.constants'

const analyticsConstants = {
  user: {
    connected: 'user_connecte',
    notconnected: 'user_non_connecte',
    favorites: {
      action: {
        listView: 'voirFavorisListe',
        listAdd: 'ajouterFavorisListe',
        listEdit: 'modifierFavorisListe',
        listDelete: 'supprimerFavorisListe',
        cardAdd: 'ajouterFavorisFiche',
        cardDelete: 'supprimerFavorisFiche',
        cardAddIncentive: 'incitationCreationCompteFiche'
      },
      type: {
        home: 'maison',
        work: 'boulot',
        other: 'autre'
      },
      pushFavoriteFilled: 'favoris_renseigne',
      pushFavoriteNotFilled: 'favoris_non_renseigne'
    }
  },
  itinerary: {
    dragndrop: {
      action: {
        arrival: 'Drop_arrivee',
        departure: 'Drop_depart',
        step: 'Drop_etape'
      }
    }
  },
  autopromo: {
    stores: {
      android: 'Android',
      ios: 'iOS',
      qrcode: 'QRcode'
    },
    area: {
      main: 'Telecommande',
      header: 'Header',
      popin: 'popin'
    }
  },
  quiz: {
    types: {
      [QUIZ_TYPES.CITIES]: 'QuizDesVilles',
      [QUIZ_TYPES.REGION_CITIES]: 'QuizDesVillesDeRégion',
      [QUIZ_TYPES.DEPARTMENT_CITIES]: 'QuizDesVillesDeDépartement'
    }
  }
}

export default analyticsConstants

export const CLICKED_BUTTON = 'bouton_clique'

export const NA = 'NA'

export const ReponseItineraireChoix = 'ReponseItineraireChoix'
