import { createSlice } from '@reduxjs/toolkit'
import { buildCityQuestion } from '../../domain/quiz/quiz.utils'

const initialState = {
  type: undefined,
  cities: null,
  currentCityIndex: 0,
  level: undefined,
  config: {},
  links: {}
}

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    resetQuiz: (state, action) => {
      return {
        ...initialState,
        links: state.links
      }
    },
    setQuizCities: (state, action) => {
      state.cities = action.payload.map(buildCityQuestion)
      state.currentCityIndex = 0
    },
    setQuizType: (state, action) => {
      state.type = action.payload
    },
    setQuizConfig: (state, action) => {
      state.config = action.payload
    },
    setQuizLevel: (state, action) => {
      state.level = action.payload
    },
    setQuizCurrentCityIndex: (state, action) => {
      state.currentCityIndex = action.payload
    },
    setQuizGuessCoordinates: (state, action) => {
      const { cityIndex, guessCoordinates } = action.payload
      const cities = state.cities?.slice(0)
      const currentCity = cities?.[cityIndex]
      if (currentCity?.answer) {
        state.cities[cityIndex].answer.guessCoordinates = guessCoordinates
      }
    },
    setQuizCityAnswer: (state, action) => {
      const { cityIndex, answer } = action.payload
      const cities = state.cities?.slice(0)
      const currentCity = cities?.[cityIndex]
      if (currentCity?.answer) {
        cities[cityIndex] = {
          ...currentCity,
          answer: {
            ...currentCity.answer,
            ...answer
          }
        }
        state.cities = cities
      }
    },
    setQuizLinks: (state, action) => {
      state.links = {
        ...state.links,
        ...action.payload
      }
    }
  }
})

export const {
  resetQuiz,
  setQuizCities,
  setQuizType,
  setQuizConfig,
  setQuizLevel,
  setQuizCurrentCityIndex,
  setQuizGuessCoordinates,
  setQuizCityAnswer,
  setQuizLinks
} = quizSlice.actions

export default quizSlice
