import {
  requestGeoentitiesAtAddressConfig,
  requestPoiassetsConfig,
  requestPoisOnRouteConfig
} from '../../dataSource/poiassets/poiassets.requests'
import { POIASSETS_STATUS } from './poiassets.constants'
import {
  extractApps,
  extractCategories,
  extractFreeApps,
  extractMenus,
  extractSubcategories,
  parseGeoentitiesConfig
} from './poiassets.dataParsers'
import { selectGeoentitiesAtAddressConfig, selectPoiAssetsStatus, selectPoisOnRouteConfig } from './poiassets.selectors'
import {
  setGeoentitiesAtAddressConfig,
  setPoiAssetsConfig,
  setPoiAssetsStatus,
  setPoisOnRouteConfig
} from './poiassetsSlice'

export const loadPoiAssetsConfiguration = () => (dispatch, getState) => {
  const status = selectPoiAssetsStatus(getState())

  if (status === POIASSETS_STATUS.REQUESTING || status === POIASSETS_STATUS.DONE) return Promise.resolve()

  dispatch(setPoiAssetsStatus(POIASSETS_STATUS.REQUESTING))

  return requestPoiassetsConfig().then(response => {
    if (!response.data) {
      dispatch(setPoiAssetsStatus(POIASSETS_STATUS.INITIAL))
      return Promise.resolve()
    }

    dispatch(
      setPoiAssetsConfig({
        menus: extractMenus(response.data),
        apps: extractApps(response.data),
        freeapps: extractFreeApps(response.data),
        categories: extractCategories(response.data),
        subcategories: extractSubcategories(response.data)
      })
    )
  })
}

const loadGeoentitiesAtAddressConfiguration = () => (dispatch, getState) => {
  if (selectGeoentitiesAtAddressConfig(getState())) return

  return requestGeoentitiesAtAddressConfig().then(response => {
    if (!response.data) return

    dispatch(setGeoentitiesAtAddressConfig(parseGeoentitiesConfig(response.data)))
  })
}

const loadPoisOnRouteConfiguration = () => (dispatch, getState) => {
  if (selectPoisOnRouteConfig(getState())) return

  return requestPoisOnRouteConfig().then(response => {
    if (!response.data) return

    dispatch(setPoisOnRouteConfig(parseGeoentitiesConfig(response.data)))
  })
}

export const loadCompleteGeoentitiesAtAddressConfiguration = () => (dispatch, getState) =>
  Promise.all([
    loadPoiAssetsConfiguration()(dispatch, getState),
    loadGeoentitiesAtAddressConfiguration()(dispatch, getState)
  ])

export const loadCompletePoisOnRouteConfiguration = () => (dispatch, getState) =>
  Promise.all([loadPoiAssetsConfiguration()(dispatch, getState), loadPoisOnRouteConfiguration()(dispatch, getState)])
