import { isPE } from '../../domain/Device'
import { HEADER_DEFAULT_HEIGHT_GE, HEADER_DEFAULT_HEIGHT_PE } from '../../domain/header/header-constants'
import { loadMultiAddressSuccess } from '../address/addressSlice'
import {
  isASearchOnItineraryPage,
  isItineraryRoute,
  selectCurrentHistoryRoute,
  selectIsCurrentHistoryRoutePageless
} from '../history/history.selectors'
import { pushHistory, saveHistoryPostPop } from '../history/historySlice'
import { addStep, removeStep, resetItinerary, setStepLocation } from '../itinerary/itinerarySlice'
import { UI_ELEMENTS } from './ui.constants'
import { hideUiElement, setUiHeaderHeight, showUiElement } from './uiSlice'

const HEADER_DEFAULT_HEIGHT = isPE() ? HEADER_DEFAULT_HEIGHT_PE : HEADER_DEFAULT_HEIGHT_GE

const getItineraryHeaderHeight = getState => {
  const {
    itinerary: { steps }
  } = getState()
  return 48 * steps.length + (isPE() ? 15 : 0)
}

export const headerHeightMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    let height = null

    switch (action.type) {
      case pushHistory.type:
      case saveHistoryPostPop.type: {
        const route = action?.payload?.route
        if (!selectIsCurrentHistoryRoutePageless(getState())) {
          if (isItineraryRoute(route) && !isASearchOnItineraryPage(route)) {
            height = getItineraryHeaderHeight(getState)
          } else {
            height = HEADER_DEFAULT_HEIGHT
          }
        }
        break
      }
      case addStep.type:
      case removeStep.type:
      case setStepLocation.type:
      case resetItinerary.type: {
        const route = selectCurrentHistoryRoute(getState())
        if (isItineraryRoute(route) && !isASearchOnItineraryPage(route)) {
          height = getItineraryHeaderHeight(getState)
        }
        break
      }
      case hideUiElement.type:
        const route = selectCurrentHistoryRoute(getState())
        if (action.payload === UI_ELEMENTS.SEARCH_DIALOG && isItineraryRoute(route)) {
          height = getItineraryHeaderHeight(getState)
        }
        break
      case showUiElement.type:
        if (action.payload === UI_ELEMENTS.SEARCH_DIALOG) height = HEADER_DEFAULT_HEIGHT
        break
    }

    if (height !== null) {
      dispatch(setUiHeaderHeight(height))
    }

    return r
  }

export const hideSuggestOnReceivingMultipleAddresses =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === loadMultiAddressSuccess.type) {
      dispatch(hideUiElement(UI_ELEMENTS.SUGGEST))
    }

    return next(action)
  }

export default [headerHeightMiddleware, hideSuggestOnReceivingMultipleAddresses]
