import {
  requestFuels,
  requestGeoentitiesForApp,
  requestGeoentity,
  requestGeoentityTab
} from '../../dataSource/geoentity/geoentity.requests'
import { parseGeoentityFilters, parseGeoentityListResponse, parseGeoentityResponse } from './geoentity.dataParser'
import {
  selectAllGeoentities,
  selectCurrentGeoentityId,
  selectGeoentityComputedBbox,
  selectGeoentityRubricId,
  selectHasVdeGeoentities,
  selectSelectedGeoentityContextualPoiUrl,
  selectSelectedGeoentityCurrentTab
} from './geoentity.selectors'

import requestSearch from '../../dataSource/search/search.requests'
import { SPD_DETAIL_GEOENTITY_LIST } from '../../domain/analytics/BiTagger'
import GeoentityNotFoundError from '../../domain/error/GeoentityNotFoundError'
import { selectMapActiveBbox, selectMapBbox } from '../map/map.selectors'
import { selectLocale } from '../navigation/navigation.selectors'
import { selectSearchCategoryParameter } from '../search/search.selectors'
import { searchServiceCtx } from '../search/search.service.constants'
import { selectCurrentFuelFilterInfo, selectFuelInformation } from './fuels.selectors'
import {
  addGeoentityList,
  refreshSelectedGeoentityData,
  resetGeoentityList,
  setCurrentGeoentityTabContent,
  setFuelInformation,
  setGeoentitiesReceived,
  setGeoentitiesRequested,
  setGeoentityCurrentPoiId,
  setGeoentityFocusedPoiId,
  setGeoentityList,
  setGeoentityTabId,
  setSingleGeoentity
} from './geoentitySlice'

export const searchGeoentities =
  ({
    appOrRubricIds,
    filterParams,
    max,
    biTagId,
    bbox,
    vde,
    extendBbox,
    setGeoentityListActionType = setGeoentityList.type,
    noRequestedDispatch
  }) =>
  (dispatch, getState) => {
    if (!bbox) bbox = selectMapActiveBbox(getState())

    if (!noRequestedDispatch) dispatch(setGeoentitiesRequested())

    const fuels = selectFuelInformation(getState())
    const currentFuelInformation = selectCurrentFuelFilterInfo(getState())
    const locale = selectLocale(getState())

    return requestGeoentitiesForApp({ appOrRubricIds, filterParams, max, biTagId, bbox, extendBbox, locale })
      .then(response => {
        const data = response?.data
        if (data) {
          const category = selectSearchCategoryParameter(getState())
          dispatch({
            type: setGeoentityListActionType,
            payload: {
              pois: parseGeoentityListResponse(data, vde),
              viewport: data.viewport,
              filters: parseGeoentityFilters(data, category, fuels, currentFuelInformation),
              extendBbox
            }
          })
        }
      })
      .catch(e => {
        dispatch(resetGeoentityList())
      })
      .finally(() => {
        dispatch(setGeoentitiesReceived())
      })
  }

export const loadFuelInformation = () => (dispatch, getState) => {
  try {
    return requestFuels().then(response => {
      const data = response?.data
      if (data) dispatch(setFuelInformation(data.fuels))
    })
  } catch (e) {
    console.error('Failed to load fuel intormation : ', e)
    return Promise.resolve()
  }
}

export const searchGeoentitiesAround =
  ({ biTagId }) =>
  (dispatch, getState) => {
    const store = getState()
    const appOrRubricIds = selectGeoentityRubricId(store)
    const bbox = selectGeoentityComputedBbox(store)
    const locale = selectLocale(getState())

    return requestGeoentitiesForApp({ appOrRubricIds, bbox, biTagId, locale }).then(response => {
      if (response.data) {
        addGeoentitiesFromServiceResponse(response.data)(dispatch, getState)
      }
    })
  }

export const searchGeoentitiesAtAddress = terms => (dispatch, getState) => {
  dispatch(setGeoentitiesRequested())

  const state = getState()
  const bbox = selectMapBbox(state)
  const locale = selectLocale(state)

  return requestSearch({
    terms,
    searchFilterType: searchServiceCtx.filter.poi,
    locale,
    bbox
  })
    .then(({ pois = [], viewport }) => {
      if (pois.length > 0) {
        dispatch(
          setGeoentityList({
            pois,
            viewport
          })
        )
      }
    })
    .finally(() => {
      dispatch(setGeoentitiesReceived())
    })
}

export const searchSingleGeoentity = poiId => (dispatch, getState) => {
  dispatch(setGeoentitiesRequested())

  const contextualUrl = selectSelectedGeoentityContextualPoiUrl(getState(), { id: poiId })
  const locale = selectLocale(getState())

  return requestGeoentity({
    id: poiId,
    relativeUrl: contextualUrl,
    locale
  })
    .then(data => dispatch(setSingleGeoentity(parseGeoentityResponse(data))))
    .finally(() => {
      dispatch(setGeoentitiesReceived())
    })
}

export const requestGeoentitiesForBI = id => (dispatch, getState) => {
  const store = getState()
  const appOrRubricIds = selectGeoentityRubricId(store)
  const bbox = selectGeoentityComputedBbox(store)
  const tabUrl = selectSelectedGeoentityCurrentTab(getState())?.url
  const locale = selectLocale(getState())

  return Promise.all([
    requestGeoentity({ id, locale }),
    tabUrl ? requestGeoentityTab(tabUrl, locale) : Promise.resolve(),
    requestGeoentitiesForApp({ appOrRubricIds, bbox, biTagId: SPD_DETAIL_GEOENTITY_LIST, locale })
  ])
}

export const addGeoentitiesFromServiceResponse = data => dispatch =>
  dispatch(addGeoentityList({ pois: parseGeoentityListResponse(data) }))

export const resetVdeGeoentities = () => (dispatch, getState) => {
  if (selectHasVdeGeoentities(getState())) {
    dispatch(resetGeoentityList({ filter: { vde: true } }))
  }
}

export const selectGeoentity = currentPoiId => (dispatch, getState) => {
  const pois = selectAllGeoentities(getState())
  const idx = pois.findIndex(({ id }) => id === currentPoiId)
  if (idx === -1) throw new GeoentityNotFoundError()
  dispatch(setGeoentityCurrentPoiId(currentPoiId))
}

export const refreshAndPopulateCurrentGeoentity = () => (dispatch, getState) =>
  requestGeoentity({
    id: selectCurrentGeoentityId(getState()),
    locale: selectLocale(getState())
  })
    .then(data => dispatch(refreshSelectedGeoentityData(parseGeoentityResponse(data))))
    .then(() => findCurrentGeoentityTabContent()(dispatch, getState))

export const findCurrentGeoentityTabContent = () => (dispatch, getState) => {
  const tabRelativeUrl = selectSelectedGeoentityCurrentTab(getState())?.url
  const locale = selectLocale(getState())
  if (!tabRelativeUrl) return

  return requestGeoentityTab(tabRelativeUrl, locale).then(data => dispatch(setCurrentGeoentityTabContent(data.tabs)))
}
export const loadGeoentityTabForAppId = appId => (dispatch, getState) => {
  dispatch(setGeoentityTabId(appId))
  return findCurrentGeoentityTabContent()(dispatch, getState)
}

export const focusGeoentity = focusedPoiId => (dispatch, getState) => {
  const pois = selectAllGeoentities(getState())
  const idx = pois.findIndex(({ id }) => id === focusedPoiId)
  if (idx === -1) throw new GeoentityNotFoundError()
  dispatch(setGeoentityFocusedPoiId(focusedPoiId))
}
