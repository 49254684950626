import { ONE_HOUR } from '../domain/utils/date'

export const NO_CACHE = 0

export const setCacheHeader = (
  res,
  maxAge = ONE_HOUR /* browser */,
  smaxAge = ONE_HOUR /* varnish */,
  stale = NO_CACHE /* if node out */
) => {
  const cacheControl =
    maxAge > NO_CACHE ? `max-age=${maxAge}, public, s-maxage=${smaxAge}, stale-if-error=${stale}` : 'no-cache, no-store'
  return res.set('Cache-Control', cacheControl)
}
