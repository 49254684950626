import { setUserIdInTag, unsetUserIdInTag } from '../../domain/analytics/ATInternetService'
import { once } from '../../utils/function'
import {
  setUserAccountIncentiveItineraryResultsDisplayed,
  setUserAccountIncentivePopinDisplayed,
  showUserAccountPreferencesIncentive
} from '../incentive/incentiveSlice'
import { setPreferredMode, setPreferredOptionsForMode } from '../itineraryOptions/itineraryOptionsSlice'
import { loadUserFavorites, loadUserItineraryOptions } from './userAccount.actions'
import { selectUserIdForTag } from './userAccount.selectors'
import { setUserAccountConnected, setUserAccountNotConnected } from './userAccountSlice'

export const loadFavoritesAndItineraryOptionsOnConnected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setUserAccountConnected.type) {
      loadUserFavorites()(dispatch, getState)
      loadUserItineraryOptions()(dispatch, getState)
    }
    return n
  }

export const setUserIdInTagWhenConnected =
  ({ getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setUserAccountConnected.type) setUserIdInTag(selectUserIdForTag(getState()))
    return n
  }

export const unsetUserIdInTagWhenDisconnected = () => next => action => {
  const n = next(action)
  if (action.type === setUserAccountNotConnected.type) unsetUserIdInTag()
  return n
}

export const setIncentivePopinTimestampWhenConnected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === setUserAccountConnected.type) {
      dispatch(setUserAccountIncentivePopinDisplayed())
      dispatch(setUserAccountIncentiveItineraryResultsDisplayed())
    }
    return n
  }

const showUserAccountPreferencesIncentiveOnce = once(dispatch => dispatch(showUserAccountPreferencesIncentive()))

export const displayIncentiveWhenPreferencesChanged =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    switch (action.type) {
      case setPreferredMode.type:
      case setPreferredOptionsForMode.type:
        showUserAccountPreferencesIncentiveOnce(dispatch)
    }
    return n
  }

export default [
  loadFavoritesAndItineraryOptionsOnConnected,
  setUserIdInTagWhenConnected,
  unsetUserIdInTagWhenDisconnected,
  setIncentivePopinTimestampWhenConnected,
  displayIncentiveWhenPreferencesChanged
]
