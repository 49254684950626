import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { createCustomSelector } from '../../domain/utils/react-reselect'

export const selectItemById = selector =>
  createCustomSelector(
    selector,
    (_, props) => props.id,
    (data, idToLookup) => data.find(({ id }) => id === idToLookup)
  )

export const useRefFromSelector = selector => {
  const ref = useRef()
  ref.current = useSelector(selector)
  return ref
}
