import { compact, flatten } from '../../utils/array'

import { findAddressforGeolocation } from '../../dataSource/address/address.request'
import { areStepsFilled, selectFilledSteps } from '../../domain/itinerary/steps/steps.selectors'
import {
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_TO,
  ROUTE_SUGGEST
} from '../../routes'
import {
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION,
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_FALSE,
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_TRUE,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../utils/browserStorageService'
import { isEqual } from '../../utils/lang'
import { resetAddress } from '../address/addressSlice'
import { resetGeoentityList } from '../geoentity/geoentitySlice'
import { getCurrentLocation } from '../geolocation/geolocation.actions'
import { navigateBack, navigateTo } from '../history/history.actions'
import {
  selectCurrentHistoryRoute,
  selectIsItineraryUniverse,
  selectPreviousHistoryRoute
} from '../history/history.selectors'
import { setNavigationDone } from '../history/historySlice'
import { toggleFullscreen } from '../map/mapSlice'
import { selectLocale } from '../navigation/navigation.selectors'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { hideUiElement, showUiElement } from '../ui/uiSlice'
import {
  computeProvider,
  mapFitCurrentPolyline,
  mapFitCurrentSteps,
  navigateToFirstSortedItineraryRoute,
  setRouteIdForCurrentProviderOrMode,
  setStepFromAddress
} from './itinerary.actions'
import {
  selectFirstProviderWithRoutes,
  selectProviders,
  selectShouldLoadPositionOnFirstItineraryStep
} from './itinerary.selectors'
import {
  invertSteps,
  removeStep,
  resetPoisOnRoute,
  setActiveSort,
  setCurrentProvider,
  setCurrentRoute,
  setCurrentSteps,
  setItineraryIsComplete,
  setItineraryIsComputing,
  setItineraryIsRecomputing,
  setProviderIsComplete,
  setStepIdx,
  setStepLocation,
  setStepResolvedLocation
} from './itinerarySlice'
import { selectDoesCurrentModeHaveRoutes, selectRoutes, selectRoutesForMonomode } from './routes.selectors'

const stepLabelMapper = ({ label } = {}) => label
const stepsAreDifferent = (stepsBefore = [], stepsNow = []) =>
  !isEqual(stepsBefore.map(stepLabelMapper), stepsNow.map(stepLabelMapper))

export const computeItineraryIfEnoughSteps =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const filledStepsBefore = selectFilledSteps(getState())
    const areStepsFilledBefore = areStepsFilled(getState())
    const r = next(action)

    switch (action.type) {
      case setStepLocation.type:
      case invertSteps.type:
      case removeStep.type:
      case setCurrentSteps.type:
        const stepsHaveChanged = stepsAreDifferent(filledStepsBefore, selectFilledSteps(getState()))
        if (areStepsFilled(getState())) {
          const hasRoutes = selectRoutes(getState()).length > 0
          if (stepsHaveChanged || !areStepsFilledBefore || !hasRoutes) {
            navigateTo({ route: ROUTE_ITINERARY_COMPUTING })(dispatch, getState)
          } else {
            navigateTo({ route: ROUTE_ITINERARY_RESULTS_BY_ROUTE, routeOptions: { avoidRefetchingPageData: true } })(
              dispatch,
              getState
            )
          }
        }

        break
    }
    return r
  }

const computeOthersSimplifiedOrOptionalProvider = (providers, provider) => (dispatch, getState) => {
  const nextMode = provider.mode
  const simplifiedProviderForThisMode = (providers || []).filter(p => p.mode === nextMode)
  return simplifiedProviderForThisMode.map(p => {
    // we dont fetch the selected provider to avoid recomputing in the next condition
    if ((p.simplified || p.optional) && p.name !== provider.name) {
      return computeProvider(p.name)(dispatch, getState)
    }
    return null
  })
}

export const computeProviderIfRouteSimplifiedOrOptional =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case setCurrentProvider.type:
        const nextProvider = action.payload
        const {
          itinerary: { providers }
        } = getState()
        const provider = (providers || []).find(p => p.name === nextProvider)

        const computeItineraries = []
        if (provider) {
          computeItineraries.push(computeOthersSimplifiedOrOptionalProvider(providers, provider)(dispatch, getState))
        }

        if (provider && (provider.simplified === true || provider.optional === true)) {
          computeItineraries.push(computeProvider(nextProvider)(dispatch, getState))
          Promise.all(compact(computeItineraries))
            .then(flatten)
            .then(compact)
            .then(() => {
              dispatch(setProviderIsComplete())
              setRouteIdForCurrentProviderOrMode()(dispatch, getState)
            })
        }
        break
    }
    return r
  }

export const computeAllProviderWhenSortIsActive =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case setActiveSort.type:
        const simplifiedProviders = selectProviders(getState()).filter(({ simplified }) => simplified)
        if (simplifiedProviders.length === 0) return r

        Promise.all(simplifiedProviders.map(provider => computeProvider(provider.name)(dispatch, getState))).then(
          () => {
            dispatch(setProviderIsComplete())
            navigateToFirstSortedItineraryRoute()(dispatch, getState)
          }
        )
        break
    }
    return r
  }

export const recomputeShouldRedirectToRouteByProvider =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case setItineraryIsRecomputing.type:
        navigateTo({ route: ROUTE_ITINERARY_RESULTS_BY_PROVIDER })(dispatch, getState)
        break
    }

    return r
  }

export const reselectCurrentProviderIfHasNoRoute =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case setItineraryIsComplete.type:
        const { itinerary } = getState()

        if (!selectDoesCurrentModeHaveRoutes(getState())) {
          const newCurrentProvider = selectFirstProviderWithRoutes(itinerary)?.name ?? 'car'
          dispatch(setCurrentProvider(newCurrentProvider))

          navigateTo({
            route: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
            routeOptions: {
              avoidRefetchingPageData: true
            },
            selectedStoreState: {
              provider: newCurrentProvider.name,
              mode: newCurrentProvider.mode,
              routeId: selectRoutesForMonomode(getState())?.[0]?.routeId
            }
          })(dispatch, getState)
        }

        break
    }

    return r
  }

export const handleBackFromSuggestToItineraryHome =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      action.type === setStepLocation.type &&
      selectCurrentHistoryRoute(getState()) === ROUTE_SUGGEST &&
      !areStepsFilled(getState())
    ) {
      if (
        selectPreviousHistoryRoute(getState()) === ROUTE_ITINERARY_HOME ||
        selectPreviousHistoryRoute(getState()) === ROUTE_ITINERARY_HP_BY_MODE
      ) {
        navigateBack()(dispatch, getState)
      } else if (selectPreviousHistoryRoute(getState()) === ROUTE_ITINERARY_TO) {
        navigateTo({
          route: ROUTE_ITINERARY_HOME,
          routeOptions: {
            avoidReset: true
          }
        })(dispatch, getState)
      }
    }
    return r
  }

export const moveMapOnItineraryActions =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case toggleFullscreen.type:
        if (selectIsItineraryUniverse(getState())) {
          mapFitCurrentPolyline()(dispatch, getState)
        }
        break
      case setStepResolvedLocation.type: // move map when page load, even before we’ve got polyline
      case setItineraryIsComputing.type: // when changing steps after an itinerary, setStepResolvedLocation.type is not called
        mapFitCurrentSteps()(dispatch, getState)
        break
      case setItineraryIsComplete.type:
      case setCurrentRoute.type:
      case setCurrentProvider.type: // move map when route or provider change (polyline may go outside current bbox)
        mapFitCurrentPolyline()(dispatch, getState)
        break
      case showUiElement.type:
      case hideUiElement.type:
        if (action?.payload === UI_ELEMENTS.POPIN) {
          mapFitCurrentPolyline()(dispatch, getState)
        }
    }
    return r
  }

export const handleSortToNavigateToFirstRouteMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setActiveSort.type) {
      navigateToFirstSortedItineraryRoute()(dispatch, getState)
    }
    return r
  }

export const setLocationOnFirstStep =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setNavigationDone.type && selectShouldLoadPositionOnFirstItineraryStep(getState())) {
      const allowDefaultUserPosition = getFromLocalStorage(BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION)
      if (allowDefaultUserPosition === BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_TRUE) {
        const locale = selectLocale(getState())
        getCurrentLocation()(dispatch, getState)
          .then(geolocation => findAddressforGeolocation({ ...geolocation, locale }))
          .then(response => {
            const address = response?.addresses?.[0]

            if (address) {
              dispatch(setStepIdx(0))
              setStepFromAddress(address)(dispatch, getState)
            }
          })
          .catch(error => {
            saveToLocalStorage(
              BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION,
              BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_FALSE
            )
            console.error('Resolution of current location went wrong', error)
          })
      }
    }
    return r
  }

export const cleanAddressOnSetStepLocation =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === setStepLocation.type) {
      dispatch(resetAddress())
    }
    return r
  }

export const resetGeoentitesWhenResetPoisOnRoute =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === resetPoisOnRoute.type) {
      dispatch(resetGeoentityList())
    }
    return r
  }

export default [
  handleBackFromSuggestToItineraryHome,
  computeItineraryIfEnoughSteps,
  computeProviderIfRouteSimplifiedOrOptional,
  computeAllProviderWhenSortIsActive,
  recomputeShouldRedirectToRouteByProvider,
  reselectCurrentProviderIfHasNoRoute,
  moveMapOnItineraryActions,
  handleSortToNavigateToFirstRouteMiddleware,
  setLocationOnFirstStep,
  cleanAddressOnSetStepLocation,
  resetGeoentitesWhenResetPoisOnRoute
]
