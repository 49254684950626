import { oncePerHour } from '../../domain/utils/oncePerTime'
import request from '../../domain/utils/request'
import { getService, getSvgIconUrl } from '../../parameters'
import SERVICES from '../../services.constants'

const POI_ASSET_FR = 'fr_FR'

export const URL_POI_ASSET_CONFIGURATION = `/configuration/configuration.${POI_ASSET_FR}.json`
export const URL_POI_ASSET_QUICK_ACCESS = '/configuration/quickaccess.web.json'
const URL_POI_ASSET_SUR_MA_ROUTE = '/configuration/surmaroute.web.json'

export const requestPoiassetsConfig = oncePerHour(() =>
  request.get(getService(SERVICES.POIASSETS, URL_POI_ASSET_CONFIGURATION))
)

export const requestSvgIcon = (timestamp, icon) => {
  const url = getSvgIconUrl(timestamp, icon)
  return request.get(url)
}

export const requestSvgUrl = url => request.get(url)

export const requestGeoentitiesAtAddressConfig = oncePerHour(() =>
  request.get(getService(SERVICES.POIASSETS, URL_POI_ASSET_QUICK_ACCESS))
)

export const requestPoisOnRouteConfig = oncePerHour(() =>
  request.get(getService(SERVICES.POIASSETS, URL_POI_ASSET_SUR_MA_ROUTE))
)
