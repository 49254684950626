import { POI } from '../search/location.types'

import { pick } from '../../utils/object'
import replacePropertyValueDeep from '../../domain/utils/replacePropertyValueDeep'
import { populateGeoentityUrl } from '../../domain/utils/geoentity'
import {
  POIS_FILTERS,
  CATEGORY_HOTEL_HEBERGEMENT_EXCEPTION,
  CATEGORY_DEPLACEMENT
} from '../../domain/search/filters/filters.constants'

const reDataPoiPath = /.*\/data\/poi\/5.3/

const removeDomainFromUrl = url => url.replace(reDataPoiPath, '')

const removeDomainFromContextualPoiUrl = contextualPoiUrl => {
  if (!contextualPoiUrl) return {}
  return { contextualPoiUrl: removeDomainFromUrl(contextualPoiUrl) }
}

const removeDomainFromTabs = tabs =>
  tabs.map((tab = {}) => {
    const urlWithoutDomain = tab.url ? { url: removeDomainFromUrl(tab.url) } : {}
    return {
      ...tab,
      ...urlWithoutDomain
    }
  })

export const parseGeoentityResponse = response => {
  const { name, lng, lat, way, town, townSeo, townCode, pCode, pCodeSeo, tabs = [], contextualPoiUrl } = response
  const postcode = __SERVER__ && pCodeSeo ? pCodeSeo : pCode
  const addressLabel = `${way ? way + ', ' : ''}${postcode} ${town}`
  const split_label = [name, addressLabel]
  const label = split_label.join(' - ')

  return {
    type: POI,
    coordinates: { lng, lat },
    label,
    addressLabel,
    split_label,
    town: __SERVER__ && townSeo ? townSeo : town,
    postcode,
    towncode: townCode,
    departmentCode: postcode ? postcode.substring(0, 2) : '',
    tabs: removeDomainFromTabs(tabs),
    ...removeDomainFromContextualPoiUrl(contextualPoiUrl),
    ...pick(response, [
      'id',
      'name',
      'rubric',
      'prov',
      'offer',
      'way',
      'regionCode',
      'countryCode',
      'transportInfo',
      'communication',
      'additionalInfo',
      'reviews',
      'openingHours',
      'openingStatus',
      'pjId',
      'brand',
      'brandIconUrl',
      'bigIconUrl',
      'roundBrandIconUrl',
      'closestPanoramicId',
      'cornerShops'
    ])
  }
}

const removeDuplicatedFieldsOnImageList = (list = {}) =>
  list.map(item => {
    const { aspect, type, thumbnail, hd, image } = item
    if (type !== 'image') return item
    const bestImageToUse = hd || image
    return {
      type,
      aspect,
      image: bestImageToUse,
      ...(thumbnail !== bestImageToUse ? { thumbnail } : {})
    }
  })

export const parseGeoentityTabResponse = response => {
  const tabs = response?.tabs

  return {
    ...response,
    tabs: replacePropertyValueDeep(tabs, {
      templatizedUrl: populateGeoentityUrl,
      list: removeDuplicatedFieldsOnImageList
    })
  }
}

export const parseGeoentityListResponse = (response, vde = false) =>
  (response.pois || []).map(parseGeoentityResponse).map(poi => {
    return { ...poi, vde }
  })

const addIdToFilter = (filter = {}, idx) => {
  const { type, shortLabel = idx } = filter
  return {
    id: `${type}-${shortLabel.toLowerCase()}`,
    ...filter
  }
}

const parseGeoentityFilter = filter =>
  pick(filter, [
    'type',
    'shortLabel',
    'sections',
    'parameter',
    'values',
    'fromDateParameter',
    'fromDateLabel',
    'toDateParameter',
    'toDateLabel',
    'sublabel',
    'step',
    'minParameter',
    'maxParameter',
    'min',
    'max',
    'unit',
    'tags',
    'tagsParameter',
    'actionId'
  ])

export const parseGeoentityFilters = (response, category, fuels, currentFuelInformation) => {
  const filters = (response?.filters ?? []).map(parseGeoentityFilter)
  const fuelPriceFilter = filters.find(filter => filter.actionId === 'fuels')
  const filtersWithCategoriesFilter =
    category && category !== CATEGORY_HOTEL_HEBERGEMENT_EXCEPTION ? filters.concat([POIS_FILTERS.categories]) : filters

  if (fuelPriceFilter && fuels) {
    if (category !== CATEGORY_DEPLACEMENT) filtersWithCategoriesFilter.splice(1, 0, createFuelFilter(fuels))
    const fuelPriceIndex = filtersWithCategoriesFilter.indexOf(fuelPriceFilter)
    const newFuelPriceFilter = {
      ...fuelPriceFilter,
      shortLabel: 'Prix',
      sublabel: `Prix du ${currentFuelInformation?.name} au litre`,
      tags: currentFuelInformation?.tag,
      min: currentFuelInformation?.prices.min,
      max: currentFuelInformation?.prices.max
    }
    filtersWithCategoriesFilter[fuelPriceIndex] = newFuelPriceFilter
  }

  return filtersWithCategoriesFilter.map(addIdToFilter)
}

const createFuelFilter = fuels => {
  return {
    id: 'combobox-fuel',
    type: 'combobox',
    shortLabel: 'Carburant',
    parameter: 'currentFuel',
    sections: [
      {
        values: fuels.map(fuel => ({
          id: fuel.id,
          label: fuel.name,
          target: ''
        }))
      }
    ]
  }
}
