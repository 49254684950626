export const QUIZ_TYPES = {
  CITIES: 'cities',
  REGION_CITIES: 'regionCities',
  DEPARTMENT_CITIES: 'departmentCities'
}

export const QUIZ_CITIES_LEVELS = {
  2: 'Facile',
  4: 'Moyen',
  8: 'Difficile',
  12: 'Expert'
}

export const CITY_COUNT_PER_QUIZ = 15

export const QUIZ_CITIES_BONUS_POINTS = 50
