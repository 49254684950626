import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_COMPUTE_CIRCLE_RADIUS } from '../../domain/tools/tools.constants'

const initialState = {
  type: undefined,
  position: null,
  radius: DEFAULT_COMPUTE_CIRCLE_RADIUS * 1000,
  fromMarker: false
}

const toolsSlice = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    setComputeRadiusPosition: (state, action) => {
      state.position = action.payload?.position
      state.fromMarker = Boolean(action.payload?.fromMarker)
    },
    setComputeRadius: (state, action) => {
      state.radius = action.payload.radius
    }
  }
})

export const { setComputeRadiusPosition, setComputeRadius } = toolsSlice.actions

export default toolsSlice
