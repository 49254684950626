import { selectCurrentHistoryRouteOptions } from '../../store/history/history.selectors'
import { routeParameterToBbox } from '../../domain/map/conversion'

export const getRouteBbox = storeState => {
  const bbox = selectCurrentHistoryRouteOptions(storeState)?.bbox
  if (bbox) return bbox

  if (__BROWSER__) {
    try {
      // TODO: NES-2038
      const bboxStr = decodeURIComponent(window.location.hash.match(/bbox=([^&]+)/)[1])
      return routeParameterToBbox(bboxStr)
    } catch (e) {}
  }
}
