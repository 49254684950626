import React from 'react'
import { useSelector } from 'react-redux'
import s from './PopupPano.less'
import T from '../../i18n/T'
import { selectUiOutdoor } from '../../../store/ui/ui.outdoor.selectors'

export const POPUP_PANO_OFFSET = {
  bottom: [0, -12]
}

const PopupPano = () => {
  const { url, lat, lng } = useSelector(selectUiOutdoor)

  if (!lat || !lng) return <></>

  if (!url) {
    return (
      <div className={s.noPano}>
        <T id={'outdoor.notfound'} />
      </div>
    )
  }

  return (
    <div className={s.root}>
      <img src={url} />
    </div>
  )
}

export default PopupPano
