import { isString } from './string'

export const round = (num, precision = 1) => {
  const modifier = 10 ** precision
  return Math.round(num * modifier) / modifier
}

export const parsePositiveNumber = (strNumber, radix) => {
  if (typeof strNumber === 'number') {
    return round(strNumber, radix)
  }

  if (!isString(strNumber)) {
    return
  }

  const positiveFloat = strNumber.replace(',', '.').replace('-', '')
  const parsedFloat = parseFloat(positiveFloat)

  return round(parsedFloat, radix)
}

export const formatNumber = number => (number ? String(number).replace('.', ',') : '')

export const isStringANumber = str => {
  if (!str) return false
  const nb = parseInt(str, 10)
  return !Number.isNaN(nb)
}

export const isStringAPositiveNumber = str => {
  if (typeof str === 'string' && !str) return false
  const nb = parseInt(str, 10)
  return !Number.isNaN(nb) && nb >= 0
}

export const parseNumber = str => {
  if (typeof str === 'string' && !str) throw new Error('Not a number')
  const nb = parseInt(str, 10)
  if (Number.isNaN(nb)) throw new Error('Not a number')
  return nb
}

export const isNumber = nb => typeof nb === 'number'

export const mean = (arr = []) => arr.reduce((acc, num) => acc + num, 0) / arr.length
