import React, { useMemo } from 'react'
import { useStore, useSelector } from 'react-redux'
import { isEmpty } from '../../utils/lang'
import { areAllEntriesTruthy } from '../../utils/array'

export const useAction = action => {
  const store = useStore()

  return useMemo(
    () =>
      (...args) =>
        action(...args)(store.dispatch, store.getState),
    [action, store]
  )
}

export const useSelectorEmptiness = selector => useSelector(store => !isEmpty(selector(store)))

export const useSelectorWithProps = (selector, props) => useSelector(store => selector(store, props))

export const withEverySelectorTruthy =
  (...selectors) =>
  Component =>
  // eslint-disable-next-line react/display-name
  props => {
    const selectorReturns = selectors.map(selector => useSelector(selector))

    return areAllEntriesTruthy(selectorReturns) ? <Component {...props} /> : null
  }
