import { selectAddressContour, selectFirstAddressCoordinates } from '../../../store/address/address.selectors'
import {
  selectFocusedPoi,
  selectFocusedPoiId,
  selectFocusedPoiIllustrationUrl,
  selectSelectedGeoentityLatLng
} from '../../../store/geoentity/geoentity.selectors'
import { selectPopupType } from '../../../store/map/map.selectors'
import { selectUiOutdoor } from '../../../store/ui/ui.outdoor.selectors'
import { createCustomSelector } from '../../utils/react-reselect'
import { POPUP_ADDRESS_OFFSET } from './PopupAddress'
import { POPUP_PANO_OFFSET } from './PopupPano'
import { POPUP_POI_OFFSET } from './PopupPoi'

export const POPUP_TYPE_PANO = 'pano'
export const POPUP_TYPE_POI = 'poi'
export const POPUP_TYPE_ADDRESS = 'address'

export const selectPopupData = createCustomSelector(
  selectPopupType,
  selectUiOutdoor,
  selectFirstAddressCoordinates,
  selectFocusedPoiId,
  selectFocusedPoi,
  selectFocusedPoiIllustrationUrl,
  (type, uiOutdoor, firstAddressCoordinates, focusedPoiId, focusedPoi, focusedPoiIllustrationUrl) => {
    switch (type) {
      case POPUP_TYPE_PANO: {
        const { lat, lng } = uiOutdoor
        return {
          type,
          coords: { lat, lng },
          offset: POPUP_PANO_OFFSET
        }
      }
      case POPUP_TYPE_POI: {
        return focusedPoiId
          ? {
              type,
              coords: selectSelectedGeoentityLatLng({}, { geoentity: focusedPoi }),
              offset: POPUP_POI_OFFSET,
              maxWidth: 320,
              withIllustration: Boolean(focusedPoiIllustrationUrl)
            }
          : {}
      }
      case POPUP_TYPE_ADDRESS:
        return {
          type,
          coords: firstAddressCoordinates,
          offset: POPUP_ADDRESS_OFFSET,
          anchor: 'top'
        }
    }
    return {}
  }
)

export const selectShouldDisplayPopup = createCustomSelector(
  selectAddressContour,
  selectPopupType,
  (contour, type) => !contour || type !== POPUP_TYPE_ADDRESS
)
