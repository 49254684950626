import debug from 'debug'
import { intersection } from '../../utils/array'

const d = debug('CMP')

export class CMPService {
  constructor(didomiSDK) {
    this.didomiSDK = didomiSDK
  }

  static apiKey = '003a8ab9-d71e-466e-8ba7-4a4e0ae36a46'

  static noticeId = 'm4B3JTgL'

  static StandardPurposes = {
    Cookies: 'cookies',
    SelectBasicAds: 'select_basic_ads',
    CreateAdsProfile: 'create_ads_profile',
    SelectPersonalizedAds: 'select_personalized_ads',
    MeasureAdPerformance: 'measure_ad_performance',
    MarketResearch: 'market_research'
    /* Unused (for now) purposes :
    MeasureContentPerformance: 'measure_content_performance',
    ImproveProducts: 'improve_products',
    CreateContentProfile: 'create_content_profile',
    SelectPersonalizedContent: 'select_personalized_content',
    GeolocationData: 'geolocation_data',
    DeviceCharacteristics: 'device_characteristics'
    */
  }

  static CustomPurposes = {
    PushNotifications: 'personnali-JGjwg3gy',
    Analytics: 'mesureda-b9AFF6XD',
    LiveRamp: 'personnali-n6LkBEKw',
    Utiq: 'utiq-iX4kPm2N'
  }

  static CustomVendors = {
    Mappy: 'c:mappy-ZEsPmiLa',
    MappyNativeAds: 'c:mappyvde-yAgyyyBi',
    LiveRamp: 'c:liveramp-4pckKAEM',
    Utiq: 'c:utiq-Gx2xdQAa'
  }

  static vendors = {
    LiveRamp: 97
  }

  get version() {
    return this.didomiSDK?.version
  }

  setSDK(didomiSDK) {
    this.didomiSDK = didomiSDK

    if (__DEV__) global.didomiSDK = didomiSDK
  }

  isVendorEnabled(vendorId) {
    const enabledVendors = this.didomiSDK.getUserConsentStatusForAll().vendors.enabled || []

    return enabledVendors.includes(vendorId)
  }

  arePurposesEnabled(...purposesId) {
    const enabledPurposes = this.didomiSDK.getUserConsentStatusForAll().purposes.enabled || []
    return intersection(enabledPurposes, [...purposesId]).length === purposesId.length
  }

  getConsents() {
    const c = {
      tracking:
        this.isVendorEnabled(CMPService.CustomVendors.Mappy) &&
        this.arePurposesEnabled(CMPService.CustomPurposes.Analytics),

      push_notifications:
        this.isVendorEnabled(CMPService.CustomVendors.Mappy) &&
        this.arePurposesEnabled(CMPService.CustomPurposes.PushNotifications),

      native_advertising:
        this.isVendorEnabled(CMPService.CustomVendors.MappyNativeAds) &&
        this.arePurposesEnabled(
          CMPService.StandardPurposes.Cookies,
          CMPService.StandardPurposes.MeasureAdPerformance,
          CMPService.StandardPurposes.SelectBasicAds,
          CMPService.StandardPurposes.SelectPersonalizedAds
        ),

      send_keywords: this.arePurposesEnabled(
        CMPService.StandardPurposes.Cookies,
        CMPService.StandardPurposes.CreateAdsProfile,
        CMPService.StandardPurposes.SelectPersonalizedAds
      ),

      share_hashed_email:
        this.isVendorEnabled(CMPService.vendors.LiveRamp) &&
        this.isVendorEnabled(CMPService.CustomVendors.LiveRamp) &&
        this.arePurposesEnabled(CMPService.CustomPurposes.LiveRamp)
    }
    d('consent', c)
    return c
  }

  open() {
    if (this.didomiSDK) {
      this.didomiSDK.preferences.show()
    }
  }

  getConsentString() {
    return new Promise(resolve => global.__tcfapi('getTCData', null, result => resolve(result.tcString)))
  }
}

export default new CMPService()
