import debug from 'debug'
import { ROUTE_HOME } from '../../routes'
import { navigateTo } from '../../store/history/history.actions'
import { selectCurrentHistoryFullRoute, selectCurrentHistoryRoute } from '../../store/history/history.selectors'
import { pick } from '../../utils/object'
import { isLegacyUrl } from '../utils/legacy'
import { sanitizeRoute } from '../utils/url'
import {
  _reverseResolveRoute,
  formatFullRouteFromHistory,
  generateFullRoute,
  getMatchingRoute,
  isPagelessRoute,
  isPathTraversalAttack
} from './routeUtils'
import router from './universalRouter'
import { saveHistoryPostPop } from '../../store/history/historySlice'

const d = debug('router')

export default class RouterService {
  constructor({ store, history }) {
    this.store = store
    this.history = history
  }

  parseQueryInHash(location) {
    const cleanedSearchInHash = (location?.hash ?? '').includes('?') ? location.hash.split('?')[1] : ''
    return new URLSearchParams(cleanedSearchInHash)
  }

  resolveCurrentRoute(routeOptions) {
    const fullRoute = formatFullRouteFromHistory(this.history)
    if (d.enabled) d('resolveCurrentRoute', fullRoute, routeOptions)

    return this.resolveRoute({
      ...routeOptions,
      fullRoute
    })
  }

  resolveRoute({ fullRoute, ...routeOptions }) {
    let finalFullRoute = fullRoute

    const matchedRoute = getMatchingRoute(fullRoute)

    const isOnboardingContext = !(routeOptions?.action ?? false)

    if (d.enabled) d('resolveRoute', finalFullRoute, matchedRoute)

    if (
      isOnboardingContext &&
      (isPagelessRoute(matchedRoute) || isPathTraversalAttack(finalFullRoute) || isLegacyUrl(finalFullRoute))
    ) {
      finalFullRoute = generateFullRoute(ROUTE_HOME)

      const { dispatch, getState } = this.store

      if (d.enabled) d('resolveRoute, navigateToHome')
      navigateTo({
        route: ROUTE_HOME
      })(dispatch, getState)
    }

    const routeContext = {
      store: this.store,
      history: this.history,
      query: this.parseQueryInHash(global?.location),
      ...routeOptions
    }

    return router
      .resolve({
        pathname: sanitizeRoute(finalFullRoute),
        ...routeContext
      })
      .then(route => {
        if (route) {
          if (route.redirect && __BROWSER__) {
            location = route.redirect
            return null
          }

          if (route.component) return route.component
        }
      })
  }

  getPrevAndNextRoute() {
    const route = selectCurrentHistoryRoute(this.store.getState())
    const fullRoute = selectCurrentHistoryFullRoute(this.store.getState())
    const prevRoute = { route, fullRoute }
    const nextRoute = _reverseResolveRoute(formatFullRouteFromHistory(this.history))

    return {
      prevRoute: {
        ...prevRoute,
        ...pick(_reverseResolveRoute(prevRoute.fullRoute), ['params'])
      },
      nextRoute: pick(nextRoute, ['route', 'fullRoute', 'params'])
    }
  }

  onPageChange(cb) {
    const onLocationChange = ({ location, action }) => {
      const { prevRoute, nextRoute } = this.getPrevAndNextRoute()

      const actionIsPop = action === 'POP'
      if (actionIsPop) this.store.dispatch(saveHistoryPostPop())

      this.resolveCurrentRoute({
        action,
        state: actionIsPop ? {} : location.state,
        prevRoute,
        nextRoute
      })
        .then(currentPageComponent => {
          cb(null, currentPageComponent)
        })
        .catch(err => {
          console.log('RouterService:onLocationChange', err)
          cb(err)
        })
    }

    this.unlisten = this.history.listen(onLocationChange.bind(this))
  }

  isCurrentRoutePageless() {
    const currentFullRoute = formatFullRouteFromHistory(this.history)
    const currentRoute = getMatchingRoute(currentFullRoute)

    return isPagelessRoute(currentRoute)
  }

  destroy() {
    this.unlisten()
  }
}
