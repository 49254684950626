import { getBIParamsAsString, SPD_RESPONSE_APPLICATION } from '../../domain/analytics/BiTagger'
import { POIS_ON_ROUTE_TYPES } from '../../domain/itinerary/poisonroute/poisOnRoute.constants'
import request from '../../domain/utils/request'
import { paramsSerializerRepeat } from '../../domain/utils/url'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import { selectCurrentPolylineVisibleSections } from '../../store/itinerary/polylines.selectors'
import { isEmpty } from '../../utils/lang'

const JOURNEY_MAX_POI = 20

export const NO_POLYLINE_DATA = 'NO_POLYLINE_DATA'

const getZoneParameter = data => {
  const polylineSections = data.length
  if (polylineSections === 1) {
    const points = data[0].length
    if (points < 5) return 1
    if (points < 10) return 2
  }
  return 3
}

const getBufferParameter = zoom => {
  if (zoom > 10) return 0.01
  if (zoom > 5) return 0.02
  return 0.03
}

const duplicatePointIfOnlyOne = data => (data.length === 1 && data[0].length === 1 ? [[data[0][0], data[0][0]]] : data)

export const requestPoiOnRoute = (type, ids, itinerary, bbox, zoom, locale) => {
  const data = selectCurrentPolylineVisibleSections(itinerary, bbox)
  const biQueryParams = getBIParamsAsString({ tagid: SPD_RESPONSE_APPLICATION })

  if (isEmpty(data)) return Promise.reject(new Error(NO_POLYLINE_DATA))
  const param = paramsSerializerRepeat({
    [type === POIS_ON_ROUTE_TYPES.application ? POIS_ON_ROUTE_TYPES.application : POIS_ON_ROUTE_TYPES.rubric]: ids
  })

  const service = getService(
    SERVICES.JOURNEY,
    `/?${param}&maxPoi=${JOURNEY_MAX_POI}&zone=${getZoneParameter(data)}&buffer=${getBufferParameter(
      zoom
    )}&${biQueryParams}`
  )

  return request.post(service, { locale, data: duplicatePointIfOnlyOne(data) }).then(response => response)
}
