import { getBIParams } from '../../domain/analytics/BiTagger'
import AddressNotFoundError from '../../domain/error/AddressNotFoundError'
import request from '../../domain/utils/request'
import { getService, getServiceParameters } from '../../parameters'
import SERVICES from '../../services.constants'
import parseAddresses from '../../store/address/address.dataParser'
import { geocodeForwardCtx } from '../../store/search/search.service.constants'
import { isEmpty } from '../../utils/lang'
import { omitBy } from '../../utils/object'

export const findCityforGeolocation = ({ lat, lng, locale }) =>
  geocodeReverse({ lat, lng, filter: geocodeForwardCtx.filter.town, locale })

export const findAddressforGeolocation = ({ lat, lng, locale }) => geocodeReverse({ lat, lng, locale })
export const findLocationForGeolocation = ({ lat, lng, filter, contour, locale }) =>
  geocodeReverse({ lat, lng, filter, contour, locale })

const geocodeReverse = ({ lat, lng, filter, contour, locale }) => {
  const service = getService(SERVICES.GEOCODE_REVERSE)

  return request
    .get(service, {
      locale,
      params: {
        x: lng,
        y: lat,
        f: filter,
        contour,
        ...getServiceParameters(SERVICES.GEOCODE_REVERSE, locale),
        ...getBIParams()
      }
    })
    .then(({ data }) => {
      if (isEmpty(data)) throw new Error('No address has been found')
      const addresses = parseAddresses(data)
      return {
        addresses
      }
    })
}

export const geocodeForward = ({ location, contour, locale, geographicType }) => {
  const service = getService(SERVICES.GEOCODE_FORWARD)

  return request
    .get(service, {
      locale,
      params: omitBy(
        {
          q: location,
          ...getServiceParameters(SERVICES.GEOCODE_FORWARD, locale),
          ...getBIParams(),
          f: geographicType,
          contour
        },
        value => value === undefined
      )
    })
    .then(({ data }) => {
      if (!data?.addresses?.features?.length) throw new AddressNotFoundError()
      const addresses = parseAddresses(data)
      const viewport = addresses?.[0]?.bbox
      return { addresses, viewport }
    })
}
