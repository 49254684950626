export const ServerErrorCode = 503

export default class ServerError extends Error {
  constructor(e = 'Erreur serveur') {
    super(e)
    this.originalError = e
    this.name = 'ServerError'
    this.type = ServerErrorCode
  }
}
