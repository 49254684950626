import React from 'react'
import Sentry from './SentryService'
import { isProduction } from '../../parameters'
import { parseComponentName } from '../utils/debug'

/* eslint-disable react/display-name */
const emptyFallbackByProps = props => e => {
  if (__BROWSER__ && !isProduction()) {
    console.error('EmptyFallbackErrorBoundary', parseComponentName(props.componentName), e.error)
    alert(
      `EmptyFallbackErrorBoundary: le composant "${parseComponentName(
        props.componentName
      )}" s'est démonté pour une raison interdéminée ! #AlerteMaximale #Rollback`
    )
  }
  return <></>
}

export const EmptyFallbackErrorBoundary = props => (
  <Sentry.ErrorBoundary fallback={emptyFallbackByProps(props)} showDialog={false}>
    {props.children}
  </Sentry.ErrorBoundary>
)

export const withEmptyFallbackErrorBoundary = Component => props =>
  (
    <EmptyFallbackErrorBoundary componentName={Component}>
      <Component {...props} />
    </EmptyFallbackErrorBoundary>
  )

export default EmptyFallbackErrorBoundary
