import classnames from 'classnames'
import React from 'react'
import T from '../../i18n/T'
import SVGIcon from '../../svg/SVGIcon'
import ActionButton from './ActionButton'
import s from './RectangleActionButton.less'

export const COLOR_GREEN = 'green'
export const COLOR_PINEGREEN = 'pinegreen'
export const COLOR_ORANGE = 'orange'
export const COLOR_BLUE = 'blue'
export const COLOR_DARKBLUE = 'darkblue'
export const COLOR_GREEN_EMPTY = 'green_empty'
export const COLOR_ORANGE_EMPTY = 'orange_empty'
export const COLOR_BLUE_EMPTY = 'blue_empty'
export const COLOR_DARKBLUE_EMPTY = 'darkblue_empty'
export const COLOR_GREY_EMPTY = 'grey_empty'

const SUPPORTED_COLORS = [
  COLOR_GREEN,
  COLOR_PINEGREEN,
  COLOR_ORANGE,
  COLOR_BLUE,
  COLOR_DARKBLUE,
  COLOR_GREEN_EMPTY,
  COLOR_ORANGE_EMPTY,
  COLOR_BLUE_EMPTY,
  COLOR_DARKBLUE_EMPTY,
  COLOR_GREY_EMPTY
]

export const SIZE_NORMAL = 'normal'
export const SIZE_SMALL = 'small'
export const SIZE_BIG = 'big'

const SUPPORTED_SIZES = [SIZE_NORMAL, SIZE_SMALL, SIZE_BIG]

const ICON_LEFT = 'left'
const ICON_RIGHT = 'right'

const SUPPORTED_ICON_POSITIONS = [ICON_LEFT, ICON_RIGHT]

const getColor = ({ color }) => (SUPPORTED_COLORS.includes(color) ? color : SUPPORTED_COLORS[0])
const getSize = ({ size }) => (SUPPORTED_SIZES.includes(size) ? size : SUPPORTED_SIZES[0])
const getIconPosition = ({ iconPosition }) =>
  SUPPORTED_ICON_POSITIONS.includes(iconPosition) ? iconPosition : SUPPORTED_ICON_POSITIONS[0]

export const getClassNameValue = props => {
  const { iconId, iconUrl, className, imgSrc, label } = props
  const color = getColor(props)
  const size = getSize(props)
  const withIcon = imgSrc || iconId || iconUrl
  const iconPosition = withIcon && getIconPosition(props)
  const onlyIcon = withIcon && !label

  return classnames(
    s.button,
    s[color],
    s[size],
    withIcon && s[`icon_${iconPosition}`],
    onlyIcon && s.onlyIcon,
    iconId && s[`withIcon_${iconId}`],
    className
  )
}

const RectangleActionButton = props => {
  const { label, iconId, iconUrl, imgSrc, imgAlt, ssrWhite = false, ...otherProps } = props

  return (
    <ActionButton {...otherProps} className={getClassNameValue(props)}>
      {iconId && <SVGIcon icon={iconId} className={s.icon} ssrWhite={ssrWhite} />}
      {iconUrl && <SVGIcon url={iconUrl} className={s.icon} ssrWhite={ssrWhite} />}
      {imgSrc && <img className={s.img} src={imgSrc} alt={imgAlt} />}
      {label && <T id={label} />}
    </ActionButton>
  )
}

export default RectangleActionButton
