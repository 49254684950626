import {
  GENERIC_MARKER,
  IMAGE_MARKER,
  MARKERS_Z_INDEX,
  STYLE_POI_BIG_MARKER,
  STYLE_POI_MEDIUM_MARKER,
  STYLE_POI_SMALL_MARKER
} from '../../domain/map/markers/MarkerConstants'
import { getFutureDateISO } from '../../domain/utils/date'
import { getImageUrl } from '../../domain/utils/image'
import { transformElementToArray } from '../../utils/array'

export const getMarkerInformations = ({ poi, isCurrent, isFocus }) => {
  const { offer, bigIconUrl, roundBrandIconUrl, rubric = {} } = poi
  const { id: rubricId, icon, color } = rubric
  if (isCurrent || isFocus) {
    const zIndex = isFocus ? MARKERS_Z_INDEX.focus : MARKERS_Z_INDEX.current
    if (bigIconUrl)
      return {
        type: IMAGE_MARKER,
        src: getImageUrl(bigIconUrl),
        zIndex,
        ...STYLE_POI_BIG_MARKER
      }
    return {
      type: GENERIC_MARKER,
      icon: icon || rubricId,
      zIndex,
      color,
      ...STYLE_POI_BIG_MARKER
    }
  }

  const style = offer !== 'FREE' ? STYLE_POI_MEDIUM_MARKER : STYLE_POI_SMALL_MARKER

  if (getImageUrl(roundBrandIconUrl)) {
    return {
      type: IMAGE_MARKER,
      src: getImageUrl(roundBrandIconUrl),
      zIndex: style === STYLE_POI_MEDIUM_MARKER ? MARKERS_Z_INDEX.brandedMedium : MARKERS_Z_INDEX.brandedSmall,
      ...style
    }
  }

  return {
    type: GENERIC_MARKER,
    icon: icon || rubricId,
    zIndex: style === STYLE_POI_MEDIUM_MARKER ? MARKERS_Z_INDEX.genericMedium : MARKERS_Z_INDEX.genericSmall,
    color,
    ...style
  }
}

export const getColorFromOpeningStatus = status => {
  switch (status) {
    case 'closed':
      return '#F44225'
    case 'closed_open_today':
      return '#FF9000'
    default:
      return '#03AC46'
  }
}

export const getFuelLabelFromId = fuelId => {
  switch (fuelId) {
    case 'E5_SP98':
      return 'SP 98'
    case 'E5_SP95':
      return 'SP 95'
    case 'E10':
      return 'SP95-E10'
    case 'E85':
      return 'E 85'
    case 'LPG':
      return 'GPL'
    default:
      return 'Gazole'
  }
}

export const isStationServiceRubric = rubricIds => transformElementToArray(rubricIds).includes('station-service')

export const getFuelPriceDisplayValue = matchingFuel => {
  const { available, price } = matchingFuel

  if (available) {
    if (price) return `${price} €`

    return '-'
  }

  return 'Rupture'
}

export const getHotelFutureDates = dates => ({
  from: dates?.from ?? getFutureDateISO({ days: 7 }),
  to: dates?.to ?? getFutureDateISO({ days: 8 })
})
