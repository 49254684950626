import { invert } from '../../utils/object'
import ServerError from '../error/ServerError'

export const LOCALES = {
  fr_FR: 'fr-FR',
  fr_BE: 'fr-BE',
  en_GB: 'en-GB',
  nl_BE: 'nl-BE'
}

export const SUBDOMAIN_FR = 'fr'
export const SUBDOMAIN_NL_BE = 'nl-be'
export const SUBDOMAIN_FR_BE = 'fr-be'
export const SUBDOMAIN_EN = 'en'

export const SUBDOMAINS = [SUBDOMAIN_FR, SUBDOMAIN_NL_BE, SUBDOMAIN_FR_BE, SUBDOMAIN_EN]

export const SUBDOMAINS_TO_LOCALES = {
  [SUBDOMAIN_FR]: LOCALES.fr_FR,
  [SUBDOMAIN_FR_BE]: LOCALES.fr_BE,
  [SUBDOMAIN_NL_BE]: LOCALES.nl_BE,
  [SUBDOMAIN_EN]: LOCALES.en_GB
}

const LOCALES_TO_SUBDOMAINS = invert(SUBDOMAINS_TO_LOCALES)

export const getHrefLangBySubdomain = subdomain => (subdomain === SUBDOMAIN_NL_BE ? 'nl' : subdomain)

export const localeIsValid = locale => [LOCALES.fr_FR, LOCALES.fr_BE, LOCALES.en_GB, LOCALES.nl_BE].includes(locale)

export function extractLanguageFromLocale(locale) {
  return (locale || '').substring(0, 2)
}

export const localeLanguageIsFrench = (locale = '') => locale.indexOf('fr-') === 0

export const localeLanguageIsDutch = (locale = '') => locale.indexOf('nl-') === 0

export const localeCountryIsFrance = (locale = '') => locale === LOCALES.fr_FR

export const localeCountryIsUK = (locale = '') => locale === LOCALES.en_GB

export const localeCountryIsBelgium = (locale = '') => locale === LOCALES.fr_BE || locale === LOCALES.nl_BE

export function extractLocaleFromHostname(hostname) {
  if (hostname.startsWith(`${SUBDOMAIN_FR}.`) || hostname.endsWith('.k8s.mappysnap.com')) return LOCALES.fr_FR
  if (hostname.startsWith(`${SUBDOMAIN_FR_BE}.`)) return LOCALES.fr_BE
  if (hostname.startsWith(`${SUBDOMAIN_NL_BE}.`)) return LOCALES.nl_BE
  if (hostname.startsWith(`${SUBDOMAIN_EN}.`)) return LOCALES.en_GB
  throw new ServerError('Unrecognized host')
}

export const getLocaleUnderscored = locale => locale?.replace('-', '_')

export const getCountryByLocale = locale => {
  switch (locale) {
    case LOCALES.fr_FR:
      return 'France'
    case LOCALES.fr_BE:
      return 'Belgique'
    case LOCALES.nl_BE:
      return 'België'
    case LOCALES.en_GB:
      return 'UK'
  }
}

export const getSubDomainByLocale = locale => LOCALES_TO_SUBDOMAINS[locale]
