export const FAVORITE_TYPES = {
  HOME: 'home',
  WORK: 'work',
  OTHER: 'other'
}

export const HOME_ICON = 'maison'
export const WORK_ICON = 'sacoche'
export const OTHER_ICON = 'favori'

export const MAX_FAVORITES = 52 // 50 + home & work

// legacy_id & sub are used as user id for AT Internet
export const USER_ID_LEGACY = 'legacy_id'
export const USER_ID_SUB = 'sub'
