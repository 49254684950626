import { setItineraryIsRecomputing } from '../itinerary/itinerarySlice'
import { setMapMode, setMapTheme } from '../map/mapSlice'
import { configureAndSendTag } from '../../domain/analytics/analytics.utils'

const actionForClickTagList = [setItineraryIsRecomputing.type, setMapMode.type, setMapTheme.type]

export const actionToClickTagMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)
    if (actionForClickTagList.includes(action.type)) configureAndSendTag(action, getState())
    return r
  }

export default [actionToClickTagMiddleware]
