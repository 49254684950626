export const CONFIG_PAGE_AD_STATUS_NONE = 'CONFIG_PAGE_AD_STATUS_NONE'
export const CONFIG_PAGE_AD_STATUS_REQUESTED = 'CONFIG_PAGE_AD_STATUS_REQUESTED'
export const CONFIG_PAGE_AD_STATUS_LOADING = 'CONFIG_PAGE_AD_STATUS_LOADING'
export const CONFIG_PAGE_AD_STATUS_LOADED = 'CONFIG_PAGE_AD_STATUS_LOADED'

export const MAXIMAL_NB_OF_GEOENTITY_BLOCK_FOR_ADS = 4

export const MINIMUM_ZOOM_VALUE_TO_SEARCH_VDE_GEOENTITIES = 7

export const AD_BANNER_WIDTH = 728
export const AD_RECTANGLE_WIDTH = 300
