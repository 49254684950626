import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isUIElementDisplayed } from '../../store/ui/ui.selectors'
import { hideUiElement, showUiElement } from '../../store/ui/uiSlice'

const useUIDisplay = uiElement => {
  const dispatch = useDispatch()
  const isUIDisplayed = useSelector(isUIElementDisplayed(uiElement))
  const showUI = useCallback(() => dispatch(showUiElement(uiElement)), [uiElement])
  const hideUI = useCallback(() => dispatch(hideUiElement(uiElement)), [uiElement])
  return {
    isUIDisplayed,
    showUI,
    hideUI
  }
}

export default useUIDisplay
