import { ROUTE_SEO_DEPARTMENT, ROUTE_SEO_REGION } from '../../routes'
import { selectFirstAddress } from '../../store/address/address.selectors'
import { ROUTE_TO_PAGE_TAG } from './pageTags.constants'
import { getPageTagForScreen } from '../../store/analytics/userPageTags.middlewares'
import { selectCurrentRouteIgnoringSuggestion } from '../../store/history/history.selectors'
import { PROVIDER_BLABLACAR } from '../../store/itinerary/itinerary.constants'
import {
  selectActiveSort,
  selectCurrentMode,
  selectCurrentProvider,
  selectQid
} from '../../store/itinerary/itinerary.selectors'
import { selectActivePoisOnRoute } from '../../store/itinerary/poisOnRoute.selectors'
import {
  getRouteTimeLabel,
  selectCurrentItineraryRoute,
  selectCurrentItineraryRouteDistance,
  selectCurrentRoute,
  selectCurrentRouteMonomodeTitle,
  selectDisplayedRoutes,
  selectNumberOfDisplayedRoutes,
  selectRoutes,
  selectRoutesForMonomode,
  selectTotalPriceValue
} from '../../store/itinerary/routes.selectors'
import { MAP_MODES, MAP_THEMES } from '../../store/map/map.constants'
import { selectMapMode, selectMapTheme } from '../../store/map/map.selectors'
import { findCategoryForItem } from '../../store/poiassets/poiassets.selectors'
import { searchServiceCtx } from '../../store/search/search.service.constants'
import { selectCurrentRubric } from '../../store/seo/seo.selectors'
import { UI_ELEMENTS } from '../../store/ui/ui.constants'
import {
  isUIElementDisplayed,
  selectAreGeoentitiesDisplayedAsMap,
  selectUserUIScreen
} from '../../store/ui/ui.selectors'
import { noop } from '../../utils/function'
import { isNumber } from '../../utils/number'
import { mapValues } from '../../utils/object'
import { removeDiacritics } from '../../utils/string'
import { isPE } from '../Device'
import { selectArrivalStep, selectDepartureStep } from '../itinerary/steps/steps.selectors'
import { SUGGEST_FROM_VALUES } from '../suggest/suggest.constants'
import {
  convertHourAndMinuteFormatToSeconds,
  convertMinutesFormatToSeconds,
  convertSecondsToMinutes
} from '../utils/date'
import { createCustomSelector } from '../utils/react-reselect'
import {
  ADDRESS,
  ANCRAGE,
  APP_OR_SUBCAT_ID,
  CAT_ID,
  DISTANCE,
  FAVORITE,
  HISTORY,
  MODE_ID,
  MYPOSITION,
  NUMBER_OF_DISPLAYED_ROUTES_RESULTS,
  PAGE_ACTIVE,
  PLACEMENT,
  POI,
  POI_ARRIVAL_TYPE,
  POI_DEPARTURE_TYPE,
  PROVIDER_ID,
  QID,
  ROUTE_DURATION,
  ROUTE_LABEL,
  ROUTE_LENGTH,
  ROUTE_POSITION_CLICK,
  ROUTE_PRICE,
  ROUTE_RESULTS_ORDER_TYPE as ROUTE_RESULTS_ACTIVE_SORT,
  ROUTE_RESULTS_DURATIONS,
  ROUTE_RESULTS_LABELS,
  ROUTE_RESULTS_LENGTHS,
  ROUTE_RESULTS_PRICES,
  RUBRIC,
  STORE,
  SUGGESTION,
  SUGGEST_DETAILS,
  SUGGEST_ORIGIN,
  SUGGEST_SUBDETAILS,
  SUGGEST_SUBSUBDETAILS,
  ZIP,
  ZIP_ARRIVAL,
  ZIP_DEPARTURE
} from './ClickTagsService'
import analyticsConstants, { NA } from './analytics.constants'

export const PLACEMENT_TYPE_MAP = 'Carte'
export const PLACEMENT_TYPE_LIST = 'Liste'

const replaceKeysInValue = values => valueToReplace => {
  if (typeof valueToReplace !== 'string') return valueToReplace

  if (valueToReplace.startsWith('!')) {
    const key = valueToReplace.substring(1)

    return values[key] ?? NA
  }

  const replacedValue = Object.keys(values).reduce(
    (acc, curr) => acc.replace(`#${curr}`, values[curr] || NA),
    valueToReplace
  )

  return replacedValue.replace(/#[a-z]+/i, NA)
}

export const replaceValues = (tag, values) => {
  const replacePartsFn = replaceKeysInValue(values)
  return mapValues(tag, replacePartsFn)
}

export const getPageActiveObj = ({ store }) => {
  const route = selectCurrentRouteIgnoringSuggestion(store)
  const pageTag = (ROUTE_TO_PAGE_TAG[route] || noop)(store)
  return { [PAGE_ACTIVE]: pageTag?.name }
}

export const getCurrentModeAndProviderForDisplayTagObj = ({ store }) =>
  selectActiveSort(store) ? {} : getCurrentModeAndProviderObj({ store })

export const getCurrentModeAndProviderObj = ({ store }) => {
  const mode = selectCurrentMode(store)
  const provider = selectCurrentProvider(store)

  return { [MODE_ID]: mode, [PROVIDER_ID]: provider }
}

export const forcePageActiveObj = page => () => {
  return { [PAGE_ACTIVE]: page }
}
export const getUserScreenOrPageActiveObj = ({ store }) => {
  const userScreen = selectUserUIScreen(store)
  const displayed = isUIElementDisplayed(UI_ELEMENTS.USER)(store)
  if (displayed && userScreen) return { [PAGE_ACTIVE]: getPageTagForScreen(store, userScreen)?.name }
  return getPageActiveObj({ store })
}

export const getSuggestOrigin = ({ action }) => {
  const suggestType = action?.tagInfo?.type
  const from = action?.tagInfo?.from

  if (from === SUGGEST_FROM_VALUES.favorites) return { [SUGGEST_ORIGIN]: FAVORITE }
  return { [SUGGEST_ORIGIN]: suggestType ? SUGGESTION : NA }
}
export const getSuggestDetails = ({ action }) => {
  const suggestType = action?.tagInfo?.type
  const suggestSubtypeValue = action?.tagInfo?.subtype?.id
  const from = action?.tagInfo?.from

  switch (from) {
    case SUGGEST_FROM_VALUES.history:
      return { [SUGGEST_DETAILS]: HISTORY, [SUGGEST_SUBDETAILS]: NA }
    case SUGGEST_FROM_VALUES.geolocation:
      return { [SUGGEST_DETAILS]: MYPOSITION, [SUGGEST_SUBDETAILS]: NA }
    case SUGGEST_FROM_VALUES.favorites:
      return { [SUGGEST_DETAILS]: suggestType, [SUGGEST_SUBDETAILS]: NA }
  }

  switch (suggestType) {
    case 'address':
      // Adresse:#subtype,
      return { [SUGGEST_DETAILS]: ADDRESS, [SUGGEST_SUBDETAILS]: suggestSubtypeValue }
    case 'poi':
      // POI:#rubric_id
      return { [SUGGEST_DETAILS]: POI, [SUGGEST_SUBDETAILS]: suggestSubtypeValue }
    case 'rubric':
      // Rubrique:#rubric_id,
      return { [SUGGEST_DETAILS]: RUBRIC, [SUGGEST_SUBDETAILS]: suggestSubtypeValue }
    case 'store_chain':
      // Enseigne:NA
      return { [SUGGEST_DETAILS]: STORE, [SUGGEST_SUBDETAILS]: NA }
    default:
      // NA:NA
      return { [SUGGEST_DETAILS]: NA, [SUGGEST_SUBDETAILS]: NA }
  }
}

export const getSuggestHomeWorkDetails = ({ action = {} } = {}) => {
  const { tagInfo = {} } = action
  const { type, isFavoriteFilled, isUserConnected } = tagInfo

  const subdetails =
    isFavoriteFilled === undefined
      ? NA
      : isFavoriteFilled
        ? analyticsConstants.user.favorites.pushFavoriteFilled
        : analyticsConstants.user.favorites.pushFavoriteNotFilled

  const subsubdetails =
    isUserConnected === undefined
      ? NA
      : isUserConnected
        ? analyticsConstants.user.connected
        : analyticsConstants.user.notconnected
  return {
    [SUGGEST_ORIGIN]: FAVORITE,
    [SUGGEST_DETAILS]: type ? type.toUpperCase() : NA,
    [SUGGEST_SUBDETAILS]: subdetails,
    [SUGGEST_SUBSUBDETAILS]: subsubdetails
  }
}

export const getGeoentitiesOnRouteObj = ({ store }) =>
  createCustomSelector(selectActivePoisOnRoute, poisOnRoute => {
    const { type, id } = poisOnRoute
    const cat = findCategoryForItem(store, { type, id })
    return { [CAT_ID]: cat?.id, [APP_OR_SUBCAT_ID]: id }
  })(store)

export const getDistanceValue = distance => {
  if (distance === 0) return '0' // I know, it should not happens but it’s wanted by analytics team
  if (distance < 5000) return '5KM'
  if (distance < 10000) return '10KM'
  if (distance < 20000) return '20KM'
  if (distance < 50000) return '50KM'
  if (distance < 200000) return '200KM'
  return '>200KM'
}

export const getItineraryObj = ({ store }) => {
  const currentRoute = selectCurrentItineraryRoute(store)
  const provider = currentRoute?.provider?.id
  const mode = selectCurrentMode(store)
  const length = currentRoute?.length?.value
  const distance = getDistanceValue(length)
  return { [MODE_ID]: mode, [PROVIDER_ID]: provider, [DISTANCE]: distance }
}
export const getCityActivityObj = ({ store }) => {
  const { id } = selectCurrentRubric(store)
  const { postcode } = selectFirstAddress(store)
  return { [RUBRIC]: id, [ZIP]: postcode }
}

export const checkMapTheme = ({ action }) => action?.payload !== MAP_THEMES.normal

export const checkMapMode = ({ action }) => action?.payload !== null

export const getModeAffichageObj = ({ store }) => {
  return { ModeAffichage: getModeAffichage(store) }
}
export const getModeAffichage = store => {
  const mode = selectMapMode(store).mode
  const theme = selectMapTheme(store)

  if (mode !== MAP_MODES.neutral && theme !== MAP_THEMES.normal) return 'ActiverLayerMixte'
  if (mode === MAP_MODES.tc) return 'ActiverLayerTc'
  if (mode === MAP_MODES.traffic) return 'ActiverLayerTrafic'
  if (mode === MAP_MODES.bicycle) return 'ActiverLayerVelo'
  if (mode === MAP_MODES.zfe) return 'ActiverLayerCritair'
  if (theme === MAP_THEMES.satellite) return 'ActiverLayerPhotoAerienne'
  if (theme === MAP_THEMES.simple) return 'ActiverLayerSimple'
  if (theme === MAP_THEMES.nature) return 'ActiverLayerNature'
  if (theme === MAP_THEMES.dark) return 'ActiverLayerNuit'
}

export const getTagInfo = ({ action }) => {
  const { tagInfo } = action
  return { ...tagInfo }
}

export const getPlacement = ({ store }) => {
  return { [PLACEMENT]: isPE() && selectAreGeoentitiesDisplayedAsMap(store) ? PLACEMENT_TYPE_MAP : PLACEMENT_TYPE_LIST }
}

export const getImmersiveAncrage =
  (ancrage = NA) =>
  () => ({ [ANCRAGE]: ancrage })

export const getTagLocaleFromRoute = route => {
  switch (route) {
    case ROUTE_SEO_DEPARTMENT:
      return 'Departement'
    case ROUTE_SEO_REGION:
      return 'Region'
    default:
      return 'Pays'
  }
}

export const getStcLocaliteByGeocodeLevel = geocodeLevel => {
  if (geocodeLevel === searchServiceCtx.geocodeLevel.admin1) return 'Region'
  if (geocodeLevel === searchServiceCtx.geocodeLevel.admin7) return 'Departement'
  return 'Ville'
}

export const getItineraryZipCodesObj = ({ store }) => {
  const departureStep = selectDepartureStep(store)
  const arrivalStep = selectArrivalStep(store)
  return { [ZIP_DEPARTURE]: departureStep?.postcode, [ZIP_ARRIVAL]: arrivalStep?.postcode }
}

export const getItineraryPoiTypesObj = ({ store }) => {
  const departureStep = selectDepartureStep(store)
  const arrivalStep = selectArrivalStep(store)
  return { [POI_DEPARTURE_TYPE]: departureStep?.type, [POI_ARRIVAL_TYPE]: arrivalStep?.type }
}

export const getRouteLabelObj = ({ store }) => {
  const routeTitle = selectCurrentRouteMonomodeTitle(store)
  return { [ROUTE_LABEL]: removeDiacritics(routeTitle?.replaceAll(' ', '_').toLowerCase()) }
}

export const getQidObj = ({ store }) => ({ [QID]: selectQid(store) })

export const getNumberOfDisplayedItinerariesObj = ({ store }) => ({
  [NUMBER_OF_DISPLAYED_ROUTES_RESULTS]: selectNumberOfDisplayedRoutes(store)
})

export const getCurrentRouteParsedDetailsObj = ({ store }) => {
  const currentRoute = selectCurrentRoute(store)
  const routeDistance = selectCurrentItineraryRouteDistance(store)
  const price = selectTotalPriceValue(store)
  const displayedRoutes = selectDisplayedRoutes(store)
  const duration = getRouteTimeLabel(currentRoute)

  const routePosition = displayedRoutes?.findIndex((route, idx) => route.routeId === currentRoute.routeId)

  const parsedLength = parseInt(routeDistance?.label?.split?.(' ')[0])
  const convertedDuration = duration?.includes('min')
    ? convertMinutesFormatToSeconds(duration)
    : convertHourAndMinuteFormatToSeconds(duration)

  return {
    [ROUTE_PRICE]: price,
    [ROUTE_DURATION]: isNaN(convertedDuration) ? undefined : convertedDuration,
    [ROUTE_POSITION_CLICK]: routePosition !== -1 ? routePosition + 1 : undefined,
    [ROUTE_LENGTH]: isNaN(parsedLength) ? undefined : parsedLength
  }
}

export const getItinerariesParsedDetailsObj = ({ store }) => {
  const provider = selectCurrentProvider(store)
  const routes = selectRoutesForMonomode(store)
  const allRoutes = selectRoutes(store)
  const activeSort = selectActiveSort(store)

  const displayedRoutes = !activeSort ? routes[provider] : allRoutes

  return {
    [ROUTE_RESULTS_LABELS]: parseItineraryResultValues(
      displayedRoutes.map(route =>
        provider === PROVIDER_BLABLACAR
          ? PROVIDER_BLABLACAR
          : (route.title?.text ?? route.titles?.multimode?.text ?? route.titles?.monomode?.text ?? route.mode)
      )
    ),
    [ROUTE_RESULTS_PRICES]: parseItineraryResultValues(
      displayedRoutes.map(route => route.price?.value ?? route.prices?.total?.value ?? NA)
    ),
    [ROUTE_RESULTS_DURATIONS]: parseItineraryResultValues(
      displayedRoutes.map(route => (route.time?.value ? convertSecondsToMinutes(route.time.value) : NA))
    ),
    [ROUTE_RESULTS_LENGTHS]: parseItineraryResultValues(
      displayedRoutes.map(route => (isNumber(route?.length?.value) ? Math.round(route.length.value / 1000) : NA))
    )
  }
}

export const getZoneObj = ({ store }) => {
  const { geocode_level, regionCode, departmentCode } = selectFirstAddress(store)
  switch (geocode_level) {
    case searchServiceCtx.geocodeLevel.admin1:
      return { zone: regionCode ?? NA }
    case searchServiceCtx.geocodeLevel.admin7:
      return { zone: departmentCode ?? NA }
  }
  return { zone: NA }
}

export const parseItineraryResultValues = (values = []) =>
  removeDiacritics(
    values
      .map((value, idx) => `${idx + 1}:${value?.toString().replaceAll(' ', '_')}`)
      .join('-')
      .toLowerCase()
  )

export const getItinerariesActiveSort = ({ store }) => {
  let activeSort

  switch (selectActiveSort(store)) {
    case 'duration':
      activeSort = 'duree'
      break
    case 'price':
      activeSort = 'prix'
      break
    case 'co2':
      activeSort = 'emission'
      break
  }

  return { [ROUTE_RESULTS_ACTIVE_SORT]: activeSort ? `tri_${activeSort}` : NA }
}
