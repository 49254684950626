import {
  ROUTE_ADDRESS,
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_HOME,
  ROUTE_ITINERARY_EXPENSE_REPORT,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_TO,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_GEOENTITY,
  ROUTE_SEO_REGION
} from '../../routes'

export const genericOrangeAdConfig = [
  {
    pageIds: [ROUTE_HOME],
    pageAdId: 'hp.lieux',
    ads: [
      {
        adName: 'hp_banner_desktop',
        adId: 'r1_300x250_hp.lieux'
      },
      {
        adName: 'hp_vde_desktop',
        adId: 'i1_1x1_hp.lieux.vde'
      },
      {
        adName: 'hp_pagessponso',
        adId: 'i1m_1x1_hp.lieux.bloc.pagessponso'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_hp.lieux.publi.menuthema'
      }
    ]
  },
  {
    pageIds: [ROUTE_SEO_ADDRESS, ROUTE_ADDRESS, ROUTE_SEO_COUNTRY, ROUTE_SEO_DEPARTMENT, ROUTE_SEO_REGION],
    pageAdId: 'ficheadresse.lieux',
    ads: [
      {
        adName: 'address_banner_desktop',
        adId: 'b2_728x90_ficheadresse.lieux'
      },
      {
        adName: 'address_banner_mobile',
        adId: 'b1m_320x50_ficheadresse.lieux'
      },
      {
        adName: 'address_rectangle_desktop',
        adId: 'r1_300x250_ficheadresse.lieux'
      },
      {
        adName: 'address_vde_desktop',
        adId: 'i1_1x1_ficheadresse.lieux.vde'
      },
      {
        adName: 'address_vde_mobile',
        adId: 'i1m_1x1_ficheadresse.lieux.vde'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_ficheadresse.lieux.publi.menuthema'
      }
    ]
  },
  {
    pageIds: [
      ROUTE_SEO_GEOENTITY,
      ROUTE_GEOENTITY_AT_ADDRESS,
      ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
      ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
      ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
      ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
      ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY
    ],
    pageAdId: 'fichepoi.lieux',
    ads: [
      {
        adName: 'poi_rectangle_desktop',
        adId: 'r1_300x250_fichepoi.lieux'
      },
      {
        adName: 'poi_banner_mobile',
        adId: 'b1m_320x50_fichepoi.lieux'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_fichepoi.lieux.publi.menuthema'
      },
      {
        adName: 'ad_video',
        adId: '1m_1x1_callback.impactify'
      }
    ]
  },
  {
    pageIds: [
      ROUTE_SEARCH_BY_CATEGORY,
      ROUTE_SEARCH_BY_SUBCATEGORY,
      ROUTE_SEARCH_BY_RUBRIC,
      ROUTE_SEARCH_BY_APP,
      ROUTE_SEARCH_BY_QUERY
    ],
    pageAdId: 'listereponse.lieux',
    ads: [
      {
        adName: 'poi_list_banner_mobile',
        adId: 'b1m_320x50_listereponse.lieux'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_listereponse.lieux.publi.menuthema'
      }
    ]
  },
  {
    pageIds: [ROUTE_ITINERARY_HOME, ROUTE_ITINERARY_TO, ROUTE_ITINERARY_HP_BY_MODE],
    pageAdId: 'hp.itineraire',
    ads: [
      {
        adName: 'hp_itinerary_banner_desktop',
        adId: 'r1_300x250_hp.itineraire'
      },
      {
        adName: 'hp_itinerary_banner_mobile',
        adId: 'b1m_320x50_hp.itineraire'
      },
      {
        adName: 'hp_itinerary_vde_desktop',
        adId: 'i1_1x1_hp.itineraire.vde'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_hp.itineraire.publi.menuthema'
      }
    ]
  },
  {
    pageIds: [ROUTE_ITINERARY_EXPENSE_REPORT],
    pageAdId: 'notedefrais.itineraire',
    ads: [
      {
        adName: 'expense_report_banner',
        adId: 'b2_728x90_notedefrais.itineraire'
      },
      {
        adName: 'expense_report_rectangle_right',
        adId: 'r1_300x250_notedefrais.itineraire'
      },
      {
        adName: 'expense_report_rectangle_left',
        adId: 'r2_300x250_notedefrais.itineraire'
      }
    ]
  },
  {
    pageIds: [ROUTE_ITINERARY_RESULTS_BY_ROUTE],
    pageAdId: 'resultat.itineraire',
    ads: [
      {
        adName: 'itinerary_results_banner_desktop',
        adId: 'b2_728x90_resultat.itineraire'
      },
      {
        adName: 'itinerary_routelist_rectangle_desktop',
        adId: 'r1_300x250_resultat.itineraire'
      },
      {
        adName: 'opspe_bloc_driver_1_mobile',
        adId: 'i1m_1x1_resultat.itineraire.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_2_mobile',
        adId: 'i2m_1x1_resultat.itineraire.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_1_desktop',
        adId: 'i1_1x1_resultat.itineraire.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_2_desktop',
        adId: 'i2_1x1_resultat.itineraire.blocdriver'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_resultat.itineraire.publi.menuthema'
      },
      {
        adName: 'ad_video',
        adId: '1m_1x1_callback.impactify'
      }
    ]
  },
  {
    pageIds: [ROUTE_ITINERARY_ROADBOOK],
    pageAdId: 'feuillederoute.itineraire',
    ads: [
      {
        adName: 'roadbook_banner_mobile',
        adId: 'b1m_320x50_feuillederoute.itineraire'
      },
      {
        adName: 'roadbook_routelist_rectangle_desktop',
        adId: 'r1_300x250_feuillederoute.itineraire'
      },
      {
        adName: 'opspe_roadbook_1_mobile',
        adId: 'i1m_1x1_feuillederoute.securitetraffic'
      },
      {
        adName: 'opspe_roadbook_2_mobile',
        adId: 'i2m_1x1_feuillederoute.securitetraffic'
      },
      {
        adName: 'opspe_roadbook_1_desktop',
        adId: 'i1_1x1_feuillederoute.securitetraffic'
      },
      {
        adName: 'opspe_roadbook_2_desktop',
        adId: 'i2_1x1_feuillederoute.securitetraffic'
      },
      {
        adName: 'opspe_bloc_driver_fdr_1_desktop',
        adId: 'i1_1x1_feuillederoute.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_fdr_2_desktop',
        adId: 'i2_1x1_feuillederoute.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_fdr_1_mobile',
        adId: 'i1m_1x1_feuillederoute.blocdriver'
      },
      {
        adName: 'opspe_bloc_driver_fdr_2_mobile',
        adId: 'i2m_1x1_feuillederoute.blocdriver'
      },
      {
        adName: 'menuthema',
        adId: 'i1_1x1_feuillederoute.itineraire.publi.menuthema'
      }
    ]
  }
]
