import { pick } from '../../utils/object'

export const getStepsWithOneMoreStop = (steps = []) => {
  const newSteps = steps.slice()
  const n = newSteps.length - 1
  newSteps.splice(-1, 0, {
    ui: {
      stepId: `step-${n}`
    }
  })
  return newSteps
}

export const getStepsWithoutIdxStop = (steps = [], stepId) => {
  const newSteps = steps.filter(step => step.ui.stepId !== stepId)
  for (let i = 1; i < newSteps.length - 1; i++) {
    newSteps[i].ui.stepId = `step-${i}`
  }
  return newSteps
}

export const getStepsInReverseOrder = (steps = [], stepId) => {
  const stepIndex = steps.findIndex(step => step.ui.stepId === stepId)
  if (stepIndex === -1) return steps

  const newSteps = steps.slice()
  newSteps[stepIndex] = steps[stepIndex + 1]
  newSteps[stepIndex + 1] = steps[stepIndex]

  return newSteps.map((step, idx) => ({ ...step, ui: { ...steps[idx].ui } }))
}

export const getStepsWithIdxStepPopulate = (steps = [], address = {}, idx) => {
  const newSteps = steps.slice()
  newSteps[idx] = {
    ...newSteps[idx],
    ...pick(address, [
      'coordinates',
      'label',
      'split_label',
      'type',
      'postcode',
      'regionCode',
      'departmentCode',
      'countryCode',
      'towncode',
      'town',
      'rubric',
      'geocode_level',
      'mergedCity',
      'townWithoutDistrict',
      'from'
    ])
  }
  return newSteps
}

export const resetProviderOptionalOrSimplifiedStatus = (providers, providerName) => {
  const provider = (providers || []).find(provider => provider.name === providerName)
  const providerIdx = providers.indexOf(provider)
  const newProvider = {
    ...provider,
    optional: false,
    simplified: false
  }

  return [...providers.slice(0, providerIdx), newProvider, ...providers.slice(providerIdx + 1)]
}
