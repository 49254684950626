export const TYPE_VDE = {
  VDE: 'vde',
  SUBCATEGORY: 'subcategory',
  EDITO: 'edito'
}

export const REDIRECT_TYPE = {
  APP: 'app',
  SUBCATEGORY: 'subcategory',
  RUBRIC: 'rubric',
  EDITO: 'edito'
}
