import { string, shape, arrayOf, number } from 'prop-types'

export const coordinatesPropTypes = shape({
  lat: number,
  lng: number
})

export const addressPropTypes = shape({
  type: string,
  bbox: arrayOf(number),
  label: string,
  split_label: arrayOf(string),
  coordinates: coordinatesPropTypes,
  town: string,
  postcode: string,
  towncode: string,
  countryCode: string,
  regionCode: string,
  departmentCode: string,
  geocode_level: string
})

export const addressesPropTypes = arrayOf(addressPropTypes)

export const descriptionPropTypes = string
