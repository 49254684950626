import { getBIParams, SPD_DETAIL_GEOENTITY, SPD_DETAIL_GEOENTITY_CONTENT } from '../../domain/analytics/BiTagger'
import GeoentityError from '../../domain/error/GeoentityError'
import NoGeoentityError from '../../domain/error/NoGeoentityError'
import { LOCALES } from '../../domain/i18n'
import { bboxToSearchParameter } from '../../domain/map/conversion'
import { oncePerDay, oncePerHour } from '../../domain/utils/oncePerTime'
import request from '../../domain/utils/request'
import { paramsSerializerComma } from '../../domain/utils/url'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import { parseGeoentityTabResponse } from '../../store/geoentity/geoentity.dataParser'

export const requestGeoentitiesForApp = ({ appOrRubricIds, filterParams, max, biTagId, bbox, extendBbox, locale }) => {
  const ids = Array.isArray(appOrRubricIds) ? appOrRubricIds.join(',') : appOrRubricIds
  const service = getService(SERVICES.GEOENTITY, `/applications/${ids}`)
  const params = Object.assign(
    {},
    {
      bbox: bboxToSearchParameter(bbox),
      max: max || 50,
      extend_bbox: extendBbox
    },
    filterParams,
    getBIParams(biTagId ? { tagid: biTagId } : {})
  )

  return request
    .get(service, {
      params,
      paramsSerializer: paramsSerializerComma,
      locale
    })
    .then(response => response)
}

export const requestFuelsUncached = () => {
  const service = getService(SERVICES.GEOENTITY_V2, '/repository/fuels')
  return request.get(service, { locale: LOCALES.fr_FR })
}

export const requestFuels = oncePerHour(requestFuelsUncached)

export const requestGeoentityVde = (bbox, locale) => {
  const service = getService(SERVICES.GEOENTITY, `/vde/${bboxToSearchParameter(bbox)}.json`)
  const queryParams = getBIParams()

  return request
    .get(service, { params: queryParams, locale })
    .then(response => Promise.resolve({ appIds: response?.data?.appIds }))
}

export const requestGeoentity = ({ id, relativeUrl, locale }) => {
  const service = getService(SERVICES.GEOENTITY, relativeUrl || `/geoentity/${id}.json`)

  const params = {
    ...getBIParams({ tagid: SPD_DETAIL_GEOENTITY })
  }

  return request
    .get(service, { params, locale })
    .then(response => {
      if (response.data) return response.data
      throw new NoGeoentityError()
    })
    .catch(error => {
      if ([404, 400].includes(error?.response?.status)) throw new NoGeoentityError()
      throw error
    })
}

export const requestGeoentityTab = (tabRelativeUrl, locale) => {
  const params = {
    ...getBIParams({ tagid: SPD_DETAIL_GEOENTITY_CONTENT })
  }

  return request.get(getService(SERVICES.GEOENTITY, tabRelativeUrl), { params, locale }).then(response => {
    if (response.data) return parseGeoentityTabResponse(response.data)
    throw new GeoentityError()
  })
}

export const requestGeoentityContentWidget = ({ id, checkInDate, checkOutDate, people, locale }) => {
  const service = getService(SERVICES.GEOENTITY, `/hotel/${id}/rooms/${checkInDate},${checkOutDate}.json`)
  const params = {
    ...getBIParams()
  }
  if (people) params.capacity = people

  return request.get(service, { params, locale }).then(response => {
    if (response.data) return response.data
    throw new GeoentityError()
  })
}

export const requestRubrics = oncePerDay(() => {
  const service = getService(SERVICES.GEOENTITY, '/referencedata/rubrics')

  return request.get(service, { locale: LOCALES.fr_FR }).then(response => {
    if (response.data) {
      return response.data
    } else {
      throw new GeoentityError()
    }
  })
})
