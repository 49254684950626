import { isEmpty } from '../../utils/lang'

export const getProvider = (providers = [], currentProvider) => providers.find(({ name }) => name === currentProvider)

export const getProviderMode = ({ providers, currentProvider }) => {
  const provider = getProvider(providers, currentProvider)
  return provider?.mode
}

export const sortByProvider = providers => {
  if (!providers) return () => 0
  providers = providers.map(({ name }) => name)
  return ({ provider: providerA }, { provider: providerB }) =>
    providers.indexOf(providerA) - providers.indexOf(providerB)
}

export const getFirstRouteIdForProvider = (provider, routes) => {
  const firstRoute = routes.find(route => route?.provider?.id === provider)
  return firstRoute?.routeId
}

export const getProviderForRouteId = (routeId, routes) => {
  const firstRoute = routes.find(route => route?.routeId === routeId)
  return firstRoute?.provider.id
}

export const getProvidersByMode = (providers = [], mode) => providers.filter(provider => provider.mode === mode)

export const getDesiredProvider = ({ currentProvider, currentMode, providers }) => {
  const provider = getProvider(providers, currentProvider)
  if (provider) return provider
  if (currentMode) {
    const providersForMode = getProvidersByMode(providers, currentMode)
    if (!isEmpty(providersForMode)) return providersForMode?.[0]
  }
  return providers?.[0]
}

export const removeSimplifiedAndOptionalForProvidersInCurrentMode = (providers, currentMode, currentProvider) => {
  return providers.map(provider => {
    const { mode, simplified, optional, name } = provider
    const force = mode === currentMode || name === currentProvider
    return {
      ...provider,
      simplified: force ? false : simplified,
      optional: force ? false : optional
    }
  })
}
