import { isGE } from '../../domain/Device'
import { getAdIdByAdName, getPageAdIdByPageId } from '../../domain/advertising/AdConfigService'
import { AdService } from '../../domain/advertising/AdService'
import { TYPE_VDE } from '../../domain/advertising/vde.constants'
import { SPD_PUSH_EDITO, SPD_PUSH_VDE } from '../../domain/analytics/BiTagger'
import { resetVdeGeoentities, searchGeoentities } from '../geoentity/geoentity.actions'
import { addGeoentityList } from '../geoentity/geoentitySlice'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { selectMapZoom } from '../map/map.selectors'
import { selectAppOrRubricId } from '../poiassets/poiassets.selectors'
import { MINIMUM_ZOOM_VALUE_TO_SEARCH_VDE_GEOENTITIES } from './ad.constants'
import { selectBboxForVde, selectPageIdForAdConfig, selectVdeName } from './ad.selectors'
import {
  pageAdConfigLoaded,
  pageAdConfigLoading,
  pageAdConfigNone,
  pageAdConfigRequested,
  removeAdData
} from './adSlice'

export const requestPageAdConfig = pageId => (dispatch, getState) => {
  const currentPageId = selectPageIdForAdConfig(getState())
  if (currentPageId !== pageId) dispatch(pageAdConfigRequested(pageId))
}

export const loadPageAdConfig = () => (dispatch, getState) => {
  const pageId = selectPageIdForAdConfig(getState())
  const pageAdId = getPageAdIdByPageId(pageId)

  const srcRoute = selectCurrentHistoryRoute(getState())

  if (pageAdId && pageId === selectCurrentHistoryRoute(getState())) {
    dispatch(pageAdConfigLoading())

    return AdService.loadPageAdConfig(pageAdId).then(() => {
      const dstRoute = selectCurrentHistoryRoute(getState())

      if (srcRoute === dstRoute) {
        dispatch(pageAdConfigLoaded())
      } else {
        dispatch(pageAdConfigNone())
      }
    })
  } else {
    dispatch(pageAdConfigNone())
  }
}

export const searchGeoentitiesForVde = vdeData => (dispatch, getState) => {
  const { type, vdeAppId, subcategoryId, editoRubricIds } = vdeData
  const id = vdeAppId || subcategoryId || editoRubricIds
  const zoom = selectMapZoom(getState())
  const canSearchGeoentities = zoom >= MINIMUM_ZOOM_VALUE_TO_SEARCH_VDE_GEOENTITIES
  if (id && canSearchGeoentities) {
    const store = getState()
    const bbox = selectBboxForVde(store)
    const appOrRubricIds = selectAppOrRubricId(store, { id })
    return searchGeoentities({
      appOrRubricIds,
      max: isGE() ? 20 : 2,
      biTagId: type === TYPE_VDE.EDITO ? SPD_PUSH_EDITO : SPD_PUSH_VDE,
      setGeoentityListActionType: addGeoentityList.type,
      vde: true,
      bbox
    })(dispatch, getState)
  }
}

export const closeVde = () => (dispatch, getState) => {
  const adName = selectVdeName(getState())
  const pageId = selectPageIdForAdConfig(getState())
  const adId = getAdIdByAdName(adName, pageId)
  dispatch(removeAdData(adId))
  resetVdeGeoentities()(dispatch, getState)
}
