import { createSlice } from '@reduxjs/toolkit'
import { POIASSETS_STATUS } from './poiassets.constants'

const initialState = {
  status: POIASSETS_STATUS.INITIAL
}

const poiAssetsSlice = createSlice({
  name: 'poiassets',
  initialState,
  reducers: {
    setPoiAssetsStatus: (state, action) => {
      state.status = action.payload
    },
    setPoiAssetsConfig: (state, action) => {
      const { menus, apps, freeapps, categories, subcategories } = action.payload
      state.status = POIASSETS_STATUS.DONE
      state.menus = menus
      state.apps = apps
      state.freeapps = freeapps
      state.categories = categories
      state.subcategories = subcategories
    },
    setGeoentitiesAtAddressConfig: (state, action) => {
      state.geoentitiesAtAddressConfig = action.payload
    },
    setPoisOnRouteConfig: (state, action) => {
      state.poisOnRouteConfig = action.payload
    }
  }
})

export const { setPoiAssetsStatus, setPoiAssetsConfig, setGeoentitiesAtAddressConfig, setPoisOnRouteConfig } =
  poiAssetsSlice.actions

export default poiAssetsSlice
