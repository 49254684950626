import { ADDRESS } from '../search/location.types'

export default function (response) {
  const { addresses } = response

  return (addresses?.features ?? []).map(address => {
    const { properties = {}, bbox, geometry } = address
    const { formatted_address, address_components, viewport, geocode_level } = properties
    const [lng, lat] = geometry?.geometries?.[0]?.coordinates ?? []
    const contour = geometry?.geometries?.[1] ?? null

    return {
      type: ADDRESS,
      viewport,
      bbox,
      label: formatted_address?.label,
      split_label: formatted_address?.split_label,
      coordinates: { lng, lat },
      town: address_components?.town?.label,
      postcode: address_components?.postcode,
      towncode: address_components?.town?.code,
      departmentCode: address_components?.admin_7?.code,
      departmentLabel: address_components?.admin_7?.label,
      regionCode: address_components?.admin_1?.code,
      regionLabel: address_components?.admin_1?.label,
      countryCode: address_components?.country?.code,
      countryLabel: address_components?.country?.label,
      named_place: address_components?.named_place,
      geocode_level,
      contour
    }
  })
}
