import { ONE_DAY_MS, ONE_MONTH_MS } from '../../domain/utils/date'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectIsFrenchSite, selectVisitCount } from '../navigation/navigation.selectors'
import { selectIsAGeoentityOnSearchPage, selectIsItineraryUniverse } from '../history/history.selectors'

const selectIncentiveState = store => store?.incentive

const selectIncentivePushState = createCustomSelector(selectIncentiveState, incentive => incentive?.push)

export const selectHasAcceptedPush = createCustomSelector(selectIncentivePushState, push => push?.accepted ?? false)

export const selectPushLastTimeDisplayed = createCustomSelector(
  selectIncentivePushState,
  push => push?.lastTimeDisplayed ?? 0
)

export const DELAY_BETWEEN_PUSH = ONE_DAY_MS * 7

export const selectShouldDisplayPush = createCustomSelector(
  selectHasAcceptedPush,
  selectPushLastTimeDisplayed,
  selectVisitCount,
  (hasAccepted, lastDisplay, visitCount) => {
    if (hasAccepted) return false
    return visitCount > 2 && Date.now() - lastDisplay > DELAY_BETWEEN_PUSH
  }
)

const selectIncentiveSurveyLastTimeDisplayed = createCustomSelector(
  selectIncentiveState,
  incentive => incentive?.survey?.lastTimeDisplayed ?? 0
)

export const selectShouldDisplayNPSSurvey = createCustomSelector(
  selectIsFrenchSite,
  selectIncentiveSurveyLastTimeDisplayed,
  selectIsItineraryUniverse,
  selectIsAGeoentityOnSearchPage,
  (localeFR, lastTimeDisplayed, isItineraryUniverse, isAGeoentitySearchPage) => {
    if (!localeFR || (!isItineraryUniverse && !isAGeoentitySearchPage)) return false
    return !lastTimeDisplayed || Date.now() - lastTimeDisplayed > ONE_MONTH_MS
  }
)

export const selectUserAccountIncentivePopinDisplayTimestamp = createCustomSelector(
  selectIncentiveState,
  incentive => incentive?.userAccount?.lastTimeDisplayedPopin ?? 0
)

export const selectUserAccountIncentiveItineraryResultsDisplayTimestamp = createCustomSelector(
  selectIncentiveState,
  incentive => incentive?.userAccount?.lastTimeDisplayedItineraryResults ?? 0
)

export const selectIsPreferencesUserAccountIncentiveDisplayed = createCustomSelector(
  selectIncentiveState,
  incentive => incentive?.userAccount?.preferencesDisplayed ?? false
)

export const selectShouldDisplayPoisOnRouteIncentivePopin = createCustomSelector(selectIncentiveState, incentive => {
  return !incentive?.poisOnRoute?.lastTimeDisplayed
})
