import React, { useEffect } from 'react'
import useConfigureAndSendTag from '../analytics/useConfigureAndSendTag'
import { defer } from '../../utils/function'

export const TagActionButton = ({
  tagInfo,
  type,
  shouldTagImpression,
  stopPropagation,
  elementRef,
  className,
  children,
  onClick,
  isSubmitButton = false,
  deferClick = false,
  onClickBeforeTag,
  dataset = {},
  ...props
}) => {
  const configureAndSendTag = useConfigureAndSendTag()

  useEffect(() => {
    if (shouldTagImpression) {
      configureAndSendTag({
        type,
        tagInfo,
        isImpression: true
      })
    }
  }, [])

  const handleClick = evt => {
    if (stopPropagation) {
      evt.stopPropagation()
      evt.preventDefault()
    }

    if (onClickBeforeTag) onClickBeforeTag(evt)

    const tagInfoWithOptionnalDataSet = { ...tagInfo, ...evt.target.dataset }

    configureAndSendTag({
      type,
      tagInfo: tagInfoWithOptionnalDataSet
    })

    if (!onClick) return

    if (deferClick) {
      defer(onClick, evt)
    } else {
      onClick(evt)
    }
  }

  const dataAttributes = Object.entries(dataset ?? {})?.reduce((acc, current) => {
    const [key, value] = current
    acc[`data-${key}`] = value
    return acc
  }, {})

  const elProps = {
    ref: elementRef,
    onClick: handleClick,
    className,
    ...dataAttributes
  }

  return isSubmitButton ? (
    <button {...elProps} type="submit" {...props}>
      {children}
    </button>
  ) : (
    <span {...elProps} {...props}>
      {children}
    </span>
  )
}

export default TagActionButton
