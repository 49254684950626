import { createCustomSelector } from '../../domain/utils/react-reselect'
import { GEOLOC_STATUS_ACTIVE, GEOLOC_STATUS_FOLLOW } from '../map/map.constants'
import { selectIsGE } from '../navigation/navigation.selectors'

const selectGeolocation = store => store?.geolocation

export const selectGeolocationPosition = createCustomSelector(selectGeolocation, geolocation => geolocation?.position)

export const selectGeolocationStatus = createCustomSelector(selectGeolocation, geolocation => geolocation?.status)

export const selectGeolocationRequestStatus = createCustomSelector(
  selectGeolocation,
  geolocation => geolocation?.requestStatus
)

export const selectGeolocationFetchOptions = createCustomSelector(selectGeolocation, selectIsGE, (geolocation, ge) => {
  const options = geolocation?.fetchOptions ?? {}
  return {
    ...options,
    ...((ge && options.GE) || {})
  }
})

export const selectIsGeoLocationOn = createCustomSelector(
  selectGeolocationStatus,
  status => status === GEOLOC_STATUS_ACTIVE || status === GEOLOC_STATUS_FOLLOW
)
