import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import request from '../../domain/utils/request'

export const fetchQuizCities = ({ level = 2 } = {}) => {
  const serviceUrl = getService(SERVICES.WEB_QUIZ, `/cities`)
  return request.get(serviceUrl, { params: { level } }).then(({ data }) => data)
}

export const fetchRegionQuizCities = ({ code } = {}) => {
  const serviceUrl = getService(SERVICES.WEB_QUIZ, `/cities/region`)
  return request.get(serviceUrl, { params: { code } }).then(({ data }) => data)
}

export const fetchDepartmentQuizCities = ({ code } = {}) => {
  const serviceUrl = getService(SERVICES.WEB_QUIZ, `/cities/department`)
  return request.get(serviceUrl, { params: { code } }).then(({ data }) => data)
}
