import React, { useEffect, useState } from 'react';
import debug from 'debug'
const d = debug('Router')

const Router = ({ currentPageComponent, routerService }) => {

  const [currentPage, setCurrentPage] = useState(currentPageComponent);

  useEffect(() => {
    d('componentDidMount');
    routerService.onPageChange((err, currentPageComponent) => {
      if (err) return console.error(err);
      setCurrentPage(currentPageComponent);
    });
  }, []);
  d('render');
  return <>{currentPage}</>;
};

export default Router;
