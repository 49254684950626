import { once } from '../../utils/function'
import { ONE_DAY_MS, ONE_HOUR_MS, ONE_MINUTE_MS } from './date'

let disable = false

export const disableOncePerTimeCache = () => {
  if (!process.env.TEST) console.error('Disable oncePerTime cache - do this only for tests')
  disable = true
}

export const oncePerTime = (ms, fn) => {
  let onceFn, onceTimestamp
  return () => {
    const maxTimeReached = Date.now() - onceTimestamp >= ms
    if (disable || !onceFn || maxTimeReached) {
      onceFn = once(fn)
      onceTimestamp = Date.now()
    }
    return onceFn().catch(e => {
      onceFn = undefined
      return Promise.reject(e)
    })
  }
}
export const oncePerDay = fn => oncePerTime(ONE_DAY_MS, fn)

export const oncePerHour = fn => oncePerTime(ONE_HOUR_MS, fn)

export const oncePerMinute = fn => oncePerTime(ONE_MINUTE_MS, fn)
