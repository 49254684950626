import { pick } from '../../utils/object'

import { SPD_RESPONSE_APPLICATION, SPD_RESPONSE_CATEGORY } from '../../domain/analytics/BiTagger'
import {
  CATEGORY_HOTEL_HEBERGEMENT_EXCEPTION,
  CATEGORY_HOTEL_RUBRIC_ID
} from '../../domain/search/filters/filters.constants'
import { hasDateAlreadyPassed } from '../../domain/utils/date'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectItemById } from '../../domain/utils/selector'
import { compact, flatten } from '../../utils/array'
import { selectCurrentHistoryRouteOptions } from '../history/history.selectors'
import { selectSearchParameters } from '../search/search.selectors'
import { selectRubricById } from '../seo/seo.selectors'
import { selectIsGE } from '../navigation/navigation.selectors'

export const CONFIG_TYPES = {
  category: 'category',
  menu: 'menu',
  application: 'application',
  subcategory: 'subcategory'
}

const selectPoiAssetsState = store => store?.poiassets ?? {}

export const selectPoiAssetsStatus = createCustomSelector(selectPoiAssetsState, ({ status }) => status)

export const selectMenu = createCustomSelector(
  selectPoiAssetsState,
  selectIsGE,
  (poiassets, ge) => poiassets?.menus?.[ge ? 'web' : 'webmobile'] ?? []
)

export const selectFreeApps = createCustomSelector(selectPoiAssetsState, poiassets => poiassets?.freeapps ?? [])

export const selectApps = createCustomSelector(selectPoiAssetsState, poiassets => poiassets?.apps ?? [])

export const selectPushSeoApps = createCustomSelector(selectApps, apps => apps.filter(({ pushSeo }) => pushSeo))

export const selectCategories = createCustomSelector(selectPoiAssetsState, poiassets => poiassets?.categories ?? [])

export const selectAppsFromCategories = createCustomSelector(selectCategories, selectApps, (categories, apps) =>
  flatten(categories.map(({ apps: appIds }) => appIds)).map(appId => apps.find(({ id }) => id === appId))
)

export const selectSubCategories = createCustomSelector(
  selectPoiAssetsState,
  poiassets => poiassets?.subcategories ?? []
)

export const selectGeoentitiesAtAddressConfig = createCustomSelector(
  selectPoiAssetsState,
  poiassets => poiassets?.geoentitiesAtAddressConfig
)

export const selectPoisOnRouteConfig = createCustomSelector(
  selectPoiAssetsState,
  poiassets => poiassets?.poisOnRouteConfig
)

export const selectCategoriesItemById = selectItemById(selectCategories)

export const selectSubCategoriesItemById = selectItemById(selectSubCategories)

export const selectAppItemById = selectItemById(selectApps)

export const selectFreeAppItemById = selectItemById(selectFreeApps)

export const selectMenuItemById = selectItemById(selectMenu)

export const selectCategoriesMenu = createCustomSelector(selectMenu, selectCategories, (menu, categories) =>
  menu.map(({ id }) => pick(findById(categories, id), ['id', 'shortLabel', 'iconId', 'color']))
)

export const selectSubCategoryById = createCustomSelector(
  selectSubCategories,
  (_, props) => props?.id,
  (categories, subCategoryId) => categories.find(category => category.id === subCategoryId)
)

export const selectSubCategoriesForCategory = createCustomSelector(
  selectMenu,
  selectSubCategories,
  (_, props) => props?.id,
  (menu, subcategories, categoryId) => {
    const categoryMenu = findById(menu, categoryId)
    return (categoryMenu?.subcategories ?? []).map(subCategoryId => findById(subcategories, subCategoryId))
  }
)

export const selectAppsForCategory = createCustomSelector(
  selectCategories,
  selectApps,
  (_, props) => props?.id,
  (categories, apps, categoryId) => {
    const category = findById(categories, categoryId)
    return category?.apps.map(appId => findById(apps, appId))
  }
)

export const selectCategoryColorByCategoryId = createCustomSelector(
  selectCategoriesItemById,
  category => category?.color
)

export const selectCategorySegmentByCategoryId = createCustomSelector(
  selectCategoriesItemById,
  category => category?.segment
)

export const selectSubCategoriesMenu = createCustomSelector(
  selectSubCategoriesForCategory,
  selectAppsForCategory,
  selectCategoryColorByCategoryId,
  (subcategories, apps, color) => ({ subcategories, apps, color })
)

const findById = (array, lookupId) => array.find(({ id }) => id === lookupId)

export const selectSubCategoryRubricIds = createCustomSelector(selectSubCategoriesItemById, subCategory =>
  (subCategory?.rubricIds?.length ?? 0) > 0 ? subCategory.rubricIds : null
)

export const selectCategoryRubricIds = createCustomSelector(
  selectMenuItemById,
  selectSubCategories,
  (categoryMenu, subCategories) => {
    if (!categoryMenu) return null

    const subcategoriesIds = categoryMenu.subcategories || []

    if (subcategoriesIds.length === 0) {
      return [categoryMenu.id]
    } else {
      return flatten(
        subcategoriesIds.map(
          subcategoryId => subCategories.find(subcateg => subcateg.id === subcategoryId)?.rubricIds || []
        )
      )
    }
  }
)

export const selectSubCategoryContainingApp = createCustomSelector(
  selectSubCategories,
  (_, props) => props,
  (subCategories, { id }) => subCategories.find(({ filterApps = [] }) => filterApps.includes(id))
)

export const findCategoryForItem = createCustomSelector(
  selectMenu,
  selectCategories,
  selectSubCategories,
  (_, props) => props,
  (menu, categories, subcategories, item = {}) => {
    const { id, type } = item
    if (!id || !type) return {}
    const isApp = type === CONFIG_TYPES.application
    const subCat = (!isApp && subcategories.find(subCat => subCat.id === id)) || {}
    return isApp
      ? categories.find(({ apps }) => apps.includes(id))
      : menu.find(({ subcategories }) => subcategories.includes(subCat.id))
  }
)

export const findCategoryIdForApp = appId =>
  createCustomSelector(selectCategories, categories => {
    if (!appId) return
    const cat = categories.find(({ apps }) => apps.includes(appId))
    return cat?.id
  })

const filterByDate = ({ from, to }) =>
  !from || !to || (from && to && hasDateAlreadyPassed(from) && !hasDateAlreadyPassed(to))

const getGeoentityConfigItemBuilder = (categories, subcategories, apps, menu) => geoentityConfig => {
  const { id: targetId, type, label, logo } = geoentityConfig
  if (type === CONFIG_TYPES.application) {
    const app = apps.find(({ id }) => id === targetId)
    const cat = categories.find(({ apps }) => apps.includes(targetId))
    return {
      id: targetId,
      type,
      catId: cat?.id,
      label: label || app?.name,
      logoUrl: logo || app?.mob_roundImage || '',
      iconId: '',
      primaryColor: 'grey'
    }
  } else if (type === CONFIG_TYPES.subcategory) {
    const subcat = subcategories.find(({ id }) => id === targetId)
    const cat = menu.find(({ subcategories }) => subcategories.includes(subcat.id))
    return {
      id: targetId,
      type,
      catId: cat?.id,
      rubric: subcat?.rubricIds,
      label: label || subcat?.label,
      logoUrl: logo || '',
      iconId: subcat?.iconId || '',
      primaryColor: subcat?.color || 'grey'
    }
  }
}

const computeCompleteGeoentitiesConfig = (geoentitiesConfig, subcategories, categories, apps, menu) => {
  if (!geoentitiesConfig || !subcategories || !apps || !menu) return
  const getGeoentityConfigItem = getGeoentityConfigItemBuilder(categories, subcategories, apps, menu)
  return compact(geoentitiesConfig.filter(filterByDate).map(getGeoentityConfigItem))
}

export const selectCompleteGeoentitiesAddressConfig = createCustomSelector(
  selectGeoentitiesAtAddressConfig,
  selectSubCategories,
  selectCategories,
  selectApps,
  selectMenu,
  computeCompleteGeoentitiesConfig
)

export const selectCompleteGeoentitiesPoisOnRouteConfig = createCustomSelector(
  selectPoisOnRouteConfig,
  selectSubCategories,
  selectCategories,
  selectApps,
  selectMenu,
  computeCompleteGeoentitiesConfig
)

export const selectAppOrRubricId = createCustomSelector(
  selectCategoryRubricIds,
  selectSubCategoryRubricIds,
  (_, { id }) => id,
  (catRubId, subCatRubId, id) => catRubId || subCatRubId || id
)

export const getAppOrFreeAppLabel = (storeState, query) => {
  const appItem = selectAppItemById(storeState, query)
  if (appItem) return appItem?.name
  const freeAppItem = selectFreeAppItemById(storeState, query)
  return freeAppItem?.label
}

export const getGeoentityTypeDataForSearch = (storeState, { category, subcategory, app, rubric, query } = {}) => {
  if (category) {
    return {
      label: selectCategoriesItemById(storeState, { id: category })?.label,
      // special treatment for hotel in which we do not want to send rubricIds, but just hotel
      appOrRubricIds:
        category === CATEGORY_HOTEL_HEBERGEMENT_EXCEPTION
          ? CATEGORY_HOTEL_RUBRIC_ID
          : selectAppOrRubricId(storeState, { id: category }),
      biTagId: SPD_RESPONSE_CATEGORY,
      isCategory: true
    }
  } else if (subcategory) {
    return {
      label: selectSubCategoriesItemById(storeState, { id: subcategory })?.label,
      appOrRubricIds: selectAppOrRubricId(storeState, { id: subcategory }),
      biTagId: SPD_RESPONSE_CATEGORY,
      isSubcategory: true
    }
  } else if (app) {
    const label = getAppOrFreeAppLabel(storeState, { id: app })
    const appOrRubricIds = selectAppOrRubricId(storeState, { id: app })
    return {
      label,
      appOrRubricIds,
      biTagId: SPD_RESPONSE_APPLICATION,
      isApp: true
    }
  } else if (rubric) {
    const rubricData = selectRubricById(storeState, { rubricId: rubric })
    return {
      label: rubricData?.label || rubric,
      appOrRubricIds: rubric,
      biTagId: SPD_RESPONSE_CATEGORY,
      isRubric: true,
      isHotel: rubric === CATEGORY_HOTEL_RUBRIC_ID
    }
  } else if (query) {
    return {
      label: query,
      searchTerms: query,
      isQuery: true
    }
  }
  return {}
}

export const selectGeoentityTypeDataForSearch = createCustomSelector(
  store => store,
  selectSearchParameters,
  (storeState, searchParameters) => getGeoentityTypeDataForSearch(storeState, searchParameters)
)

export const selectGeoentityIconDataForSearch = createCustomSelector(
  selectCurrentHistoryRouteOptions,
  store => store,
  (routeOptions = {}, store) => {
    const { app, category, subcategory } = routeOptions
    if (app) return pick(selectAppItemById(store, { id: app }), 'mob_roundImage')
    if (category) return pick(selectCategoriesItemById(store, { id: category }), 'iconId')
    if (subcategory) return pick(selectSubCategoriesItemById(store, { id: subcategory }), 'iconId')
    return {}
  }
)

export const selectHasSearchIcon = createCustomSelector(selectGeoentityIconDataForSearch, searchIcon =>
  Boolean(Object.keys(searchIcon).length)
)

export const selectAppIcon = appId =>
  createCustomSelector(selectApps, apps => {
    const app = apps.find(app => app.id === appId)
    if (app) return app.mob_roundImage
    return null
  })
