import { createReduxEnhancer } from '@sentry/react'
import { minimalDebugActionSanitizer, minimalDebugStateSanitizer } from './storeSanitizers'

const sentryReduxEnhancer = () =>
  createReduxEnhancer({
    actionTransformer: minimalDebugActionSanitizer,
    stateTransformer: minimalDebugStateSanitizer
  })

export default sentryReduxEnhancer
