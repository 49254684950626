import { noop } from '../../utils/function'
import tagsParamResolvers from './TagsParamResolvers'

export const PAGE_ADDRESS_NAME = 'FicheAdresse'
export const PAGE_POI_NAME = 'FichePoi'

export const PAGE_TAG = {}

PAGE_TAG.PAGE_LIEUX = {
  level2: 1,
  name: 'HPLieu'
}

PAGE_TAG.PAGE_HOME_PLAN = {
  level2: 1,
  name: 'HomePlan'
}

PAGE_TAG.PAGE_ADDRESS = {
  level2: 3,
  name: PAGE_ADDRESS_NAME,
  paramsToResolve: ['stc', 'x11', 'x15']
}

PAGE_TAG.PAGE_POI = {
  level2: 3,
  name: PAGE_POI_NAME,
  paramsToResolve: ['stc', 'x4', 'x5', 'x11', 'x15']
}

PAGE_TAG.PAGE_COMPUTE_RADIUS = {
  level2: 1,
  name: 'CalculRayon'
}

PAGE_TAG.PAGE_RESPONSE_SEARCH = {
  level2: 1,
  name: 'ReponseMoteur'
}

PAGE_TAG.PAGE_ITINERARY = {
  level2: 2,
  name: 'Itineraire'
}

PAGE_TAG.PAGE_LIST_ITINERARY = {
  level2: 2,
  name: 'ReponseItineraire',
  paramsToResolve: ['stc', 'x4', 'x6', 'x7', 'x11', 'x13', 'x15', 'x17', 'f3']
}

PAGE_TAG.PAGE_LIST_ITINERARY_FOCUS = {
  level2: 2,
  name: 'ReponseItineraireChoix',
  paramsToResolve: ['stc', 'x4', 'x6', 'x7', 'x11', 'x13', 'x15', 'x17', 'f3']
}

PAGE_TAG.PAGE_DETAIL_ITINERARY = {
  level2: 2,
  name: 'FeuilleDeRoute',
  paramsToResolve: ['stc', 'x6', 'x7', 'x11', 'x13', 'x15', 'x17']
}

PAGE_TAG.PAGE_RESPONSE_POI_SMR = {
  level2: 2,
  name: 'ReponsePoiSurMaRoute',
  paramsToResolve: ['stc', 'x4', 'x6', 'x7', 'x11', 'x13', 'x15', 'x17', 'f3']
}

PAGE_TAG.PAGE_POI_SMR = {
  level2: 3,
  name: 'FichePoiSurMaRoute',
  paramsToResolve: ['stc', 'x4', 'x5', 'x11', 'x15']
}

PAGE_TAG.PAGE_OPTIONS_DEPLACEMENT = {
  level2: 2,
  name: 'Options'
}

PAGE_TAG.PAGE_OPTIONS_DATETIME = {
  level2: 2,
  name: 'Date_time'
}

PAGE_TAG.PAGE_LANDING_ITINERARY = {
  level2: 2,
  name: 'LandingItineraire',
  paramsToResolve: ['stc', 'x6', 'x7', 'x9', 'x11', 'x13', 'x15', 'x17']
}

PAGE_TAG.PAGE_LANDING_CITY_ACTIVITY = {
  level2: 2,
  name: 'LandingActiviteVille',
  paramsToResolve: ['stc', 'x2', 'x4']
}

PAGE_TAG.PAGE_LANDING_CITY_APP = {
  level2: 2,
  name: 'LandingActiviteAppVille',
  paramsToResolve: ['stc', 'x3', 'x4', 'x5', 'x10', 'x11']
}

PAGE_TAG.PAGE_RESPONSE_CATEGORY_MAP = {
  level2: 1,
  name: 'ReponseCategorieCarte',
  paramsToResolve: ['stc', 'x3', 'x4', 'x5', 'x10', 'x11']
}

PAGE_TAG.PAGE_HP_DEPARTEMENT = {
  level2: 1,
  name: 'HPDepartement'
}

PAGE_TAG.PAGE_HP_REGION = {
  level2: 1,
  name: 'HPRegion'
}

PAGE_TAG.PAGE_TRAFFIC_HOME = {
  level2: 1,
  name: 'Trafic'
}

PAGE_TAG.PAGE_BICYCLE_HOME = {
  level2: 1,
  name: 'PisteCyclable'
}

PAGE_TAG.PAGE_ZFE_HOME = {
  level2: 1,
  name: 'ZoneCritAir'
}

PAGE_TAG.PAGE_RESTRICTION_HOME = {
  level2: 1,
  name: 'RestrictionCirculation'
}

PAGE_TAG.PAGE_TRAFFIC_RESPONSE = {
  level2: 1,
  name: 'ReponseTrafic',
  paramsToResolve: ['stc', 'x11']
}

PAGE_TAG.PAGE_BICYCLE_RESPONSE = {
  level2: 1,
  name: 'ReponseCyclable',
  paramsToResolve: ['stc', 'x11']
}

PAGE_TAG.PAGE_ZFE_RESPONSE = {
  level2: 1,
  name: 'ReponseCritAir',
  paramsToResolve: ['stc', 'x11']
}

PAGE_TAG.PAGE_RESTRICTION_RESPONSE = {
  level2: 1,
  name: 'ReponseRestriction',
  paramsToResolve: ['stc', 'x11']
}

PAGE_TAG.PAGE_ERROR_403 = {
  level2: 5,
  name: 'Erreur403'
}

PAGE_TAG.PAGE_ERROR_404 = {
  level2: 5,
  name: 'Erreur404'
}

PAGE_TAG.PAGE_ERROR_500 = {
  level2: 5,
  name: 'Erreur500'
}

PAGE_TAG.PAGE_BROWSER_UPDATE = {
  level2: 5,
  name: 'BrowserUpdate'
}

PAGE_TAG.PAGE_ERROR_WEBGL = {
  level2: 5,
  name: 'ErreurWebGL'
}

PAGE_TAG.PAGE_RESET_PASSWORD_V1 = {
  level2: 5,
  name: 'ResetPasswordV1'
}

PAGE_TAG.PAGE_COMPTE_NON_CONNECTE = {
  level2: 12,
  name: 'ComptePerso:EcranNonConnecte'
}

PAGE_TAG.PAGE_COMPTE_CONNECTE = {
  level2: 12,
  name: 'ComptePerso:AccueilMonCompte'
}

PAGE_TAG.PAGE_COMPTE_LIEUXFAVORIS = {
  level2: 12,
  name: 'ComptePerso:MesLieuxFavoris'
}

PAGE_TAG.PAGE_COMPTE_OPTIONS_DEPLACEMENT = {
  level2: 12,
  name: 'ComptePerso:Options'
}

PAGE_TAG.PAGE_ANNUAIRE_CITIES = {
  level2: 9,
  name: 'AnnuaireVilles',
  paramsToResolve: []
}

PAGE_TAG.PAGE_ANNUAIRE_POI = {
  level2: 9,
  name: 'AnnuairesPOI',
  paramsToResolve: []
}

PAGE_TAG.PAGE_ANNUAIRE_ACTIVITIES = {
  level2: 9,
  name: 'AnnuaireActivites',
  paramsToResolve: []
}

PAGE_TAG.PAGE_ANNUAIRE_ITINERARIES = {
  level2: 9,
  name: 'AnnuaireItineraires',
  paramsToResolve: []
}

PAGE_TAG.PAGE_ANNUAIRE_OVM = {
  level2: 9,
  name: 'AnnuaireOVM',
  paramsToResolve: []
}

PAGE_TAG.PAGE_ANNUAIRE_ACTIVITIES_OVM = {
  level2: 9,
  name: 'AnnuaireActivitesOVM',
  paramsToResolve: []
}

PAGE_TAG.PAGE_HOME_QUIZ = {
  level2: 1,
  name: 'Quiz'
}

PAGE_TAG.PAGE_QUIZ_DES_VILLES = {
  level2: 1,
  name: 'QuizDesVilles'
}

PAGE_TAG.PAGE_QUIZ_DES_VILLES_DE_REGION = {
  level2: 1,
  name: 'QuizDesVillesDeRégion:#Region',
  paramsToResolve: ['name']
}

PAGE_TAG.PAGE_QUIZ_DES_VILLES_DE_DEPARTEMENT = {
  level2: 1,
  name: 'QuizDesVillesDeDépartement:#Department',
  paramsToResolve: ['name']
}

PAGE_TAG.PAGE_PRINT_NDF = {
  level2: 2,
  name: 'ImpressionNoteDeFrais'
}

const TYPE_PAGE = 'F'
const paramsToResolveOnAllPages = ['x2']

export const SITE_PARAMETERS = /^x\d+$/
export const PAGE_PARAMETERS = /^f\d+$/

export const extractParameters = (resolvedParams, testRegex) =>
  Object.keys(resolvedParams ?? {}).reduce((acc, attr) => {
    if (!testRegex.test(attr)) return acc

    return {
      ...acc,
      [attr.substring(1)]: (resolvedParams || {})[attr]
    }
  }, {})

export const resolvePageTag = ({ pageTag, store = {}, query }) => {
  const resolvedParams = [...paramsToResolveOnAllPages, ...(pageTag.paramsToResolve || [])].reduce(
    (acc, tagParamKey) => {
      return {
        ...acc,
        [tagParamKey]: (tagsParamResolvers[tagParamKey] || noop)(store, pageTag, query)
      }
    },
    {}
  )

  const { stc, name } = resolvedParams
  const siteParameters = extractParameters(resolvedParams, SITE_PARAMETERS)
  const pageParameters = extractParameters(resolvedParams, PAGE_PARAMETERS)

  return {
    ...pageTag,
    ...(name ? { name } : {}),
    type: TYPE_PAGE,
    customVars: {
      site: siteParameters,
      page: pageParameters
    },
    customObject: stc
  }
}
