import { SPD_RESPONSE_SEARCH, getBIParams } from '../../domain/analytics/BiTagger'
import NoResultError from '../../domain/error/NoResultError'
import { bboxToSearchParameter } from '../../domain/map/conversion'
import request from '../../domain/utils/request'
import { getService, getServiceParameters } from '../../parameters'
import SERVICES from '../../services.constants'
import parseAddresses from '../../store/address/address.dataParser'
import { parseGeoentityListResponse } from '../../store/geoentity/geoentity.dataParser'
import { getSearchMaxPois } from '../../store/search/search.selectors'
import { searchServiceCtx } from '../../store/search/search.service.constants'
import { isEmpty } from '../../utils/lang'

const composeHeader = data => ({
  storeChainsId: data?.store_chains_id,
  rubricId: data?.rubric_id,
  located: data.search_hint === 'located'
})

const requestSearch = ({
  terms,
  searchFilterType = searchServiceCtx.filter.default,
  tagid = SPD_RESPONSE_SEARCH,
  bbox,
  locale,
  extendBbox = 1,
  maxResults = getSearchMaxPois()
}) => {
  const params = {
    q: terms,
    f: searchFilterType,
    bbox: bboxToSearchParameter(bbox),
    extend_bbox: extendBbox,
    max_results: maxResults,
    ...getServiceParameters(SERVICES.SEARCH, locale),
    ...getBIParams({ tagid })
  }

  return requestSearchData(params, locale)
    .then(({ data }) => {
      if (!isEmpty(data)) {
        const pois = parseGeoentityListResponse(data)
        const addresses = parseAddresses(data)
        const viewport = data.viewport
        const header = composeHeader(data)

        return { pois, addresses, viewport, header }
      } else {
        throw new NoResultError()
      }
    })
    .catch(originalError => {
      throw originalError || new NoResultError()
    })
}

export const requestSearchData = (params, locale) => {
  const service = getService(SERVICES.SEARCH)
  return request.get(service, { params, locale })
}
export default requestSearch
