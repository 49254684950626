import debug from 'debug'
import request from '../../domain/utils/request'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'
import { MAP_MODES } from '../../store/map/map.constants'

const dSeo = debug('seo')

const faqTypes = {
  ITINERARY_HP: 'itinerary-hp',
  CITY_ACTIVITY: 'city-activity',
  LAYER: 'layer',
  ITINERARY_LANDING_PAGE: 'itinerary-landingpage'
}

const handleSeoCatch = context => e => {
  if (e?.response?.status === 404) {
    if (dSeo.enabled) {
      dSeo(`fetch seo: 404 on ${context}`)
    }
  } else {
    console.error(`fetch error on ${context}`, e)
  }
  return {}
}

export const fetchFaq = ({ type, subtype }) => {
  if (!type) return Promise.reject(new Error('missing type'))
  if (!subtype) return Promise.reject(new Error('missing subtype'))

  const path = `/${type}/${subtype}`

  const serviceUrl = getService(SERVICES.WEB_SEO_FAQ, path)
  return request
    .get(serviceUrl, { silent: true })
    .then(({ data }) => data)
    .catch(handleSeoCatch(`${SERVICES.WEB_SEO_FAQ} ${path}`))
}

export const fetchItineraryHPFaq = ({ mode }) => fetchFaq({ type: faqTypes.ITINERARY_HP, subtype: mode })

export const fetchCityActivityFaq = ({ rubricId }) => fetchFaq({ type: faqTypes.CITY_ACTIVITY, subtype: rubricId })

export const fetchLayerFaq = ({ pageKey }) => fetchFaq({ type: faqTypes.LAYER, subtype: pageKey })

export const fetchItineraryLandingPageFaq = ({ mode }) =>
  fetchFaq({ type: faqTypes.ITINERARY_LANDING_PAGE, subtype: mode })

const descriptionTypes = {
  CITY: 'city',
  TRAFFIC: 'traffic',
  BICYCLE: 'bicycle',
  ZFE: 'zfe',
  DEPARTMENT: 'department',
  REGION: 'region',
  TRAFFIC_DEPARTMENT: 'traffic-department',
  BICYCLE_DEPARTMENT: 'bicycle-department',
  ZFE_DEPARTMENT: 'zfe-department',
  TRAFFIC_REGION: 'traffic-region',
  BICYCLE_REGION: 'bicycle-region',
  ZFE_REGION: 'zfe-region'
}

const fetchDescription = ({ type, towncode, postcode }) => {
  if (!type) return Promise.reject(new Error('missing type'))
  if (!towncode || !postcode) return Promise.reject(new Error('missing towncode / postcode'))
  const path = `/${type}/${towncode}-${postcode}`

  const serviceUrl = getService(SERVICES.WEB_SEO_DESCRIPTION, path)

  return request
    .get(serviceUrl, { silent: true })
    .then(({ data }) => data)
    .catch(handleSeoCatch(`${SERVICES.WEB_SEO_DESCRIPTION} ${path}`))
}

const fetchZoneDescription = ({ type, countryCode, zoneCode }) => {
  if (!type) return Promise.reject(new Error('missing type'))
  if (!countryCode || !zoneCode) return Promise.reject(new Error('missing countryCode / zoneCode'))

  const path = `/${type}/${countryCode}-${zoneCode}`

  const serviceUrl = getService(SERVICES.WEB_SEO_DESCRIPTION, path)
  return request
    .get(serviceUrl, { silent: true })
    .then(({ data }) => data)
    .catch(handleSeoCatch(`${SERVICES.WEB_SEO_DESCRIPTION} ${path}`))
}

export const fetchCityDescription = ({ towncode, postcode }) =>
  fetchDescription({ type: descriptionTypes.CITY, towncode, postcode })

const layersToDescriptionTypes = {
  [MAP_MODES.traffic]: descriptionTypes.TRAFFIC,
  [MAP_MODES.bicycle]: descriptionTypes.BICYCLE,
  [MAP_MODES.zfe]: descriptionTypes.ZFE
}

export const fetchLayerCityDescription = ({ towncode, postcode, layer }) =>
  fetchDescription({ type: layersToDescriptionTypes[layer], towncode, postcode })

export const fetchDepartmentDescription = ({ countryCode, zoneCode }) =>
  fetchZoneDescription({ type: descriptionTypes.DEPARTMENT, countryCode, zoneCode })

export const fetchRegionDescription = ({ countryCode, zoneCode }) =>
  fetchZoneDescription({ type: descriptionTypes.REGION, countryCode, zoneCode })

export const fetchLayerDepartmentDescription = ({ countryCode, zoneCode, layer }) =>
  fetchZoneDescription({
    type: `${layersToDescriptionTypes[layer]}-${descriptionTypes.DEPARTMENT}`,
    countryCode,
    zoneCode
  })

export const fetchLayerRegionDescription = ({ countryCode, zoneCode, layer }) =>
  fetchZoneDescription({
    type: `${layersToDescriptionTypes[layer]}-${descriptionTypes.REGION}`,
    countryCode,
    zoneCode
  })

export const fetchItineraryDescription = ({ mode, postcode }) => {
  if (!mode || !postcode) return Promise.reject(new Error('missing mode / postcode'))

  const path = `/itinerary/${postcode}-${mode}`

  const serviceUrl = getService(SERVICES.WEB_SEO_DESCRIPTION, path)
  return request
    .get(serviceUrl, { silent: true })
    .then(({ data }) => data)
    .catch(handleSeoCatch(`${SERVICES.WEB_SEO_DESCRIPTION} ${path}`))
}

export const fetchSeoLinks = path => {
  if (!path) return Promise.reject(new Error('missing route'))

  const serviceUrl = getService(SERVICES.WEB_SEO_LINKS, path)

  return request
    .get(serviceUrl, { silent: true })
    .then(({ data }) => data)
    .catch(handleSeoCatch(`${SERVICES.WEB_SEO_LINKS} ${path}`))
}
