import pageTagsMiddlewares from './pageTags.middlewares'
import { multipathPublisherTagsMiddleware } from './multipathPublisherTags.middlewares'
import geoentityBiTags from './geoentityBiTags.middlewares'
import handleConsent from './analyticsGDPR.middlewares'
import userPageTagsMiddlewares from './userPageTags.middlewares'
import abtestTagsMiddlewares from './abtestTags.middlewares'
import actionToTagMiddlewares from './actionToTag.middlewares'

export default [
  ...pageTagsMiddlewares,
  ...actionToTagMiddlewares,
  ...userPageTagsMiddlewares,
  multipathPublisherTagsMiddleware,
  ...geoentityBiTags,
  ...abtestTagsMiddlewares,
  handleConsent
]
