import debug from 'debug'
import {
  checkMapMode,
  checkMapTheme,
  forcePageActiveObj,
  getCityActivityObj,
  getGeoentitiesOnRouteObj,
  getImmersiveAncrage,
  getItineraryObj,
  getModeAffichageObj,
  getPageActiveObj,
  getPlacement,
  getSuggestDetails,
  getSuggestHomeWorkDetails,
  getSuggestOrigin,
  getTagInfo,
  getUserScreenOrPageActiveObj
} from './analyticsUtils'
import { CLICK_TAG, resolveClickTag } from './ClickTagsService'

import { PAGE_POI_NAME } from './PageTagsService'
import { noop } from '../../utils/function'
import { setItineraryIsRecomputing } from '../../store/itinerary/itinerarySlice'
import { setMapMode, setMapTheme } from '../../store/map/mapSlice'
import {
  TAG_ADDRESS_IMMERSIVE,
  TAG_BACK,
  TAG_BLOC_HOTEL,
  TAG_BLOCK_AUTOPROMO,
  TAG_CATEGORY_LIST,
  TAG_CATEGORY_POPIN,
  TAG_CITY_ACTIVITY_LANDING_GOTO_POI,
  TAG_CLICK_CAMPAGNE_PUSH,
  TAG_CLICK_CATEGORY_BAR,
  TAG_CLICK_CATEGORY_FILTER,
  TAG_CLICK_CATEGORY_MORE,
  TAG_CLICK_CONTEXT_MENU,
  TAG_CLICK_MY_MAP,
  TAG_CLICK_POI,
  TAG_CLICK_POI_FICHE_ADDRESSE,
  TAG_CLICK_SHARE,
  TAG_CLICK_TOGGLE_HD_MAP,
  TAG_CLICK_TRAVEL_OPTIONS_CAR_VEHICLE,
  TAG_CLICK_TRAVEL_OPTIONS_MODEFAVORI,
  TAG_CLICK_TRAVEL_OPTIONS_MOTORBIKE_VEHICLE,
  TAG_CLICK_USER_ACCOUNT_BUTTON,
  TAG_CLICK_USER_BURGER,
  TAG_CLICK_USER_FAVORITES_BUTTON,
  TAG_CLICK_USER_FAVORITES_LIST,
  TAG_CLICK_USER_LOGIN_BUTTON,
  TAG_CLICK_USER_LOGOUT_BUTTON,
  TAG_CLOSE_AD_BANNER,
  TAG_DRAG_AND_DROP,
  TAG_FICHEPOI_IMMERSIVE,
  TAG_FOOTER_AUTOPROMO,
  TAG_FOOTER_SOCIAL_NETWORKS_LINKS,
  TAG_GOTO,
  TAG_GOTO_AJOUT_ETAPE,
  TAG_HOME_WORK,
  TAG_LANDING_GOTO_MP,
  TAG_LANDING_VIEW_MORE,
  TAG_LOGO_MAPPY,
  TAG_NDF,
  TAG_POI_ON_ROUTE,
  TAG_PRINT_ROADBOOK,
  TAG_SEARCH_GEOLOCATION,
  TAG_SLIDER,
  TAG_SUGGEST,
  TAG_UPI_IMMERSIVE,
  TAG_VDE_CLOSE,
  TAG_VDE_CTA
} from './analytics.tagids.constants'

const d = debug('analyticsMiddleware')

export const configureTagClick = ({ action, store }) => {
  const tagObj = (ACTION_TO_CLICK_TAG[action.type] || noop)({ action, store })
  if (!tagObj) return
  const { paramFns, clickTag } = tagObj
  d('configure click tag for action', action.type)
  const params = (paramFns || []).map(fn => fn({ store, action }))
  return resolveClickTag(Object.assign({ store, clickTag }, ...params))
}

// FIXME NES-1756
const ACTION_TO_CLICK_TAG = {
  [setItineraryIsRecomputing.type]: () => {
    return {
      clickTag: CLICK_TAG.XTC_ITINERARY_TIMEOUT,
      paramFns: [getPageActiveObj]
    }
  },
  [setMapMode.type]: ({ action }) => {
    return checkMapMode({ action })
      ? {
          clickTag: CLICK_TAG.XTC_VIEWMODE,
          paramFns: [getPageActiveObj, getModeAffichageObj]
        }
      : undefined
  },
  [setMapTheme.type]: ({ action }) => {
    return checkMapTheme({ action })
      ? {
          clickTag: CLICK_TAG.XTC_VIEWMODE,
          paramFns: [getPageActiveObj, getModeAffichageObj]
        }
      : undefined
  },
  [TAG_PRINT_ROADBOOK]: () => ({
    clickTag: CLICK_TAG.XTC_ITINERARY_PRINT_SIMPLE,
    paramFns: [getPageActiveObj]
  }),
  [TAG_NDF]: () => ({
    clickTag: CLICK_TAG.XTC_ITINERARY_NDF,
    paramFns: [getPageActiveObj]
  }),
  [TAG_FOOTER_AUTOPROMO]: () => {
    return {
      clickTag: CLICK_TAG.XTC_FOOTER_AUTO_PROMO_APPS,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_BLOCK_AUTOPROMO]: () => {
    return {
      clickTag: CLICK_TAG.XTC_APPLICATION_TELECHARGER,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_FOOTER_SOCIAL_NETWORKS_LINKS]: () => {
    return {
      clickTag: CLICK_TAG.XTC_FOOTER_RETROUVEZMAPPY,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLOSE_AD_BANNER]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CLOSE_AD,
      paramFns: [getPageActiveObj]
    }
  },
  [TAG_VDE_CTA]: () => {
    return {
      clickTag: CLICK_TAG.XTC_VDE_PUSH,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_VDE_CLOSE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_VDE_PUSH_CLOSED,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_POI]: () => {
    return {
      clickTag: CLICK_TAG.XTC_DETAIL_LISTMAP_POI,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_GOTO]: () => {
    return {
      clickTag: CLICK_TAG.XTC_GOTO,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_GOTO_AJOUT_ETAPE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_GOTO_AJOUT_ETAPE,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_SUGGEST]: () => {
    return {
      clickTag: CLICK_TAG.XTC_SEARCH_FORM,
      paramFns: [getPageActiveObj, getSuggestOrigin, getSuggestDetails]
    }
  },
  [TAG_HOME_WORK]: () => {
    return {
      clickTag: CLICK_TAG.XTC_SEARCH_FORM_HOME_WORK,
      paramFns: [getPageActiveObj, getSuggestHomeWorkDetails]
    }
  },
  [TAG_SLIDER]: () => {
    return {
      clickTag: CLICK_TAG.XTC_MULTIPATH_RESULTS_SLIDER,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_SEARCH_GEOLOCATION]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CARTE_LOC,
      paramFns: [getPageActiveObj]
    }
  },
  [TAG_DRAG_AND_DROP]: () => {
    return {
      clickTag: CLICK_TAG.XTC_DRAG_AND_DROP,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_BACK]: () => ({
    clickTag: CLICK_TAG.XTC_ITINERARY_RETURN,
    paramFns: [getPageActiveObj]
  }),
  [TAG_POI_ON_ROUTE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_SURMAROUTE,
      paramFns: [getPageActiveObj, getGeoentitiesOnRouteObj, getItineraryObj]
    }
  },
  [TAG_LOGO_MAPPY]: () => {
    return {
      clickTag: CLICK_TAG.XTC_ANCRAGE_HEADER,
      paramFns: [getTagInfo]
    }
  },
  [TAG_LANDING_GOTO_MP]: () => {
    return {
      clickTag: CLICK_TAG.XTC_LANDING_ITINERARY_GOTO_MP,
      paramFns: [getPageActiveObj, getItineraryObj, getTagInfo]
    }
  },
  [TAG_CITY_ACTIVITY_LANDING_GOTO_POI]: () => {
    return {
      clickTag: CLICK_TAG.XTC_LANDING_ACTIVITE_VILLE_GOTO_POI,
      paramFns: [getPageActiveObj, getCityActivityObj, getTagInfo]
    }
  },
  [TAG_LANDING_VIEW_MORE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_LANDING_ITINERARY_AFFICHER_SUITE,
      paramFns: [getPageActiveObj, getItineraryObj]
    }
  },
  [TAG_CATEGORY_POPIN]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_POPIN,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CATEGORY_LIST]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_LIST,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_ADDRESS_IMMERSIVE]: () => ({
    clickTag: CLICK_TAG.XTC_IMMERSIVE,
    paramFns: [getPageActiveObj, getImmersiveAncrage()]
  }),
  [TAG_FICHEPOI_IMMERSIVE]: () => ({
    clickTag: CLICK_TAG.XTC_IMMERSIVE,
    paramFns: [forcePageActiveObj(PAGE_POI_NAME), getImmersiveAncrage()]
  }),
  [TAG_UPI_IMMERSIVE]: () => ({
    clickTag: CLICK_TAG.XTC_IMMERSIVE,
    paramFns: [getPageActiveObj, getImmersiveAncrage('upi')]
  }),
  [TAG_CLICK_MY_MAP]: () => ({
    clickTag: CLICK_TAG.XTC_CLICK_MY_MAP,
    paramFns: [getPageActiveObj, getTagInfo]
  }),
  [TAG_BLOC_HOTEL]: () => ({
    clickTag: CLICK_TAG.XTC_BLOC_HOTEL,
    paramFns: [getPageActiveObj]
  }),
  [TAG_CLICK_POI_FICHE_ADDRESSE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_DETAIL_FICHE_ADRESSE,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_SHARE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_SHARE,
      paramFns: [getPageActiveObj]
    }
  },
  [TAG_CLICK_CATEGORY_MORE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_MORE,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_CATEGORY_BAR]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_BAR_CATEGORY,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_CATEGORY_FILTER]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CATEGORY_FILTER,
      paramFns: [getPageActiveObj, getPlacement, getTagInfo]
    }
  },
  [TAG_CLICK_CAMPAGNE_PUSH]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CAMPAGNE_PUSH,
      paramFns: [getTagInfo]
    }
  },
  [TAG_CLICK_USER_BURGER]: () => {
    return {
      clickTag: CLICK_TAG.XTC_COMPTE_PERSO_BOUTON_BURGER,
      paramFns: [getPageActiveObj]
    }
  },
  [TAG_CLICK_USER_LOGIN_BUTTON]: () => {
    return {
      clickTag: CLICK_TAG.XTC_COMPTE_PERSO_NON_CONNECTE,
      paramFns: [getUserScreenOrPageActiveObj]
    }
  },
  [TAG_CLICK_USER_ACCOUNT_BUTTON]: () => {
    return {
      clickTag: CLICK_TAG.XTC_COMPTE_PERSO_GERER_MON_COMPTE,
      paramFns: []
    }
  },
  [TAG_CLICK_USER_LOGOUT_BUTTON]: () => {
    return {
      clickTag: CLICK_TAG.XTC_SE_DECONNECTER,
      paramFns: []
    }
  },
  [TAG_CLICK_USER_FAVORITES_LIST]: () => {
    return {
      clickTag: CLICK_TAG.XTC_COMPTE_PERSO_LIEUX_FAVORIS_LISTE,
      paramFns: [getTagInfo]
    }
  },
  [TAG_CLICK_USER_FAVORITES_BUTTON]: () => {
    return {
      clickTag: CLICK_TAG.XTC_COMPTE_PERSO_LIEUX_FAVORIS_FICHE,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_TRAVEL_OPTIONS_MODEFAVORI]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CHOIX_MODEFAVORI,
      paramFns: [getUserScreenOrPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_TRAVEL_OPTIONS_CAR_VEHICLE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CHOIX_VEHICULE,
      paramFns: [getUserScreenOrPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_TRAVEL_OPTIONS_MOTORBIKE_VEHICLE]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CHOIX_DEUXROUES,
      paramFns: [getUserScreenOrPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_CONTEXT_MENU]: () => {
    return {
      clickTag: CLICK_TAG.XTC_MENU_CONTEXTUEL,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  },
  [TAG_CLICK_TOGGLE_HD_MAP]: () => {
    return {
      clickTag: CLICK_TAG.XTC_CARTE_HD_OFF,
      paramFns: [getPageActiveObj, getTagInfo]
    }
  }
}
