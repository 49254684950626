import { selectGeolocationPosition } from './geolocation.selectors'
import { isEmpty } from '../../utils/lang'
import geoLocationService from '../../geoLocationService'

export const getCurrentLocation = () => (_, getState) => {
  const position = selectGeolocationPosition(getState())
  if (!isEmpty(position)) return Promise.resolve(position)

  return new Promise((resolve, reject) =>
    geoLocationService.getCurrentPosition({
      positionHandler: ({ coords: { latitude, longitude } }) => resolve({ lat: latitude, lng: longitude }),
      errorHandler: reject
    })
  )
}
