import request from '../../domain/utils/request'
import { getService } from '../../parameters'
import SERVICES from '../../services.constants'

export const requestPano = ({ lat, lng, preview = true }, locale) => {
  const url = getService(
    SERVICES.PANO,
    `/panoramics/${lat},${lng},100/tiles/geodetic/${lat},${lng}.json?preview=${preview}`
  )

  return request.get(url, { locale }).then(result => result)
}
