import { POIS_FILTERS } from '../../domain/search/filters/filters.constants'
import { isATransactionalHotelAppId } from '../../domain/utils/geoentity'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { selectIsGE } from '../navigation/navigation.selectors'
import { selectAreGeoentitiesDisplayedAsList, selectUIGeoentityActiveFilterId } from '../ui/ui.selectors'
import { selectGeoentityApplicationId, selectGeoentityState } from './geoentity.selectors'

export const selectGeoentitiesFilters = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.filters ?? []
)

export const selectShouldDisplaySubHeaderForFilters = createCustomSelector(
  selectGeoentitiesFilters,
  filters => filters.length > 0
)

export const selectGeoentityActiveFilter = createCustomSelector(
  selectGeoentitiesFilters,
  selectUIGeoentityActiveFilterId,
  (filters, activeId) => activeId && filters.find(({ id }) => id === activeId)
)

export const selectHasFilterMultiDatePicker = createCustomSelector(selectGeoentitiesFilters, filters =>
  Boolean(filters.find(({ id }) => id === POIS_FILTERS.multidatepicker.id))
)

export const selectShouldDisplaySearchActiveFilter = createCustomSelector(
  selectUIGeoentityActiveFilterId,
  selectAreGeoentitiesDisplayedAsList,
  selectIsGE,
  (_, props = {}) => props,
  (activeFilterId, geoentitiesAsList, ge, { displayOverMap }) => {
    const noFilterId = !activeFilterId
    const overMapAndGE = ge && displayOverMap
    const noDisplayPE = !ge && ((displayOverMap && geoentitiesAsList) || (!displayOverMap && !geoentitiesAsList))
    return !noFilterId && !overMapAndGE && !noDisplayPE
  }
)

export const selectGeoentityFilterParameters = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.filterParameters
)

export const selectGeoentityActiveFilterParameter = createCustomSelector(
  selectGeoentityFilterParameters,
  selectUIGeoentityActiveFilterId,
  (filterParameters, filterId) => filterParameters && filterParameters[filterId]
)

export const selectIsTransactionalHotelGeoentity = createCustomSelector(
  selectGeoentityApplicationId,
  isATransactionalHotelAppId
)

export const selectFilterHotelDates = createCustomSelector(
  selectGeoentityFilterParameters,
  filterParameters => filterParameters && filterParameters[POIS_FILTERS.multidatepicker.id]
)
